define("@fixflo/frontend/controllers/admin/login", ["exports", "@fixflo/frontend/controllers/auth/login"], function (_exports, _login) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _login.default.extend({
    actions: {
      /**
       * Sends authentication request to server
       *
       * @void
       */
      async login() {
        // set default login properties
        this.setProperties({
          isProcessing: true,
          isValidating: true
        });

        // get user credentials
        const credentials = this.getProperties('email', 'password');
        const remember = this.remember;

        // validate login
        const validator = await this.validate();

        // if invalid form inputs
        if (validator.validations.get('isInvalid')) {
          return this.reset('fail');
        }

        // set the redirectTo
        this.session.setRedirect('admin.panel');

        // set timeout trigger
        this.set('timeout', setTimeout(this.slowConnection.bind(this), 12000));

        // start login request
        try {
          await this.session.authenticate('authenticator:fixflo', credentials, remember, 'auth/hq-login');
        } catch (error) {
          return this.failure(error);
        }

        // if login was successful
        if (this.session.isAuthenticated) {
          this.success();
        }
      }
    },
    /**
     * After successful authentication, redirect to where
     *
     * @var string
     */
    redirectTo: 'admin.panel'
  });
  _exports.default = _default;
});