define("@fixflo/frontend/templates/dialog/charterer-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lroZ6qeZ",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Name\\n    \"],[9],[0,\"\\n    \"],[5,\"fix-flo/input\",[],[[\"@type\",\"@value\",\"@placeholder\"],[\"text\",[24,[\"contextObject\",\"name\"]],\"Enter name\"]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Display Name\\n    \"],[9],[0,\"\\n    \"],[5,\"fix-flo/input\",[],[[\"@type\",\"@value\",\"@placeholder\"],[\"text\",[24,[\"contextObject\",\"display_name\"]],\"Enter a display name\"]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Aliases\\n    \"],[9],[0,\"\\n    \"],[5,\"tag-input\",[[12,\"class\",\"fixflo-input\"]],[[\"@tags\",\"@addTag\",\"@removeTagAtIndex\",\"@\",\"@allowSpacesInTags\",\"@placeholder\"],[[24,[\"contextObject\",\"aliases\"]],[22,\"addAlias\"],[22,\"removeAlias\"],\"\",true,\"Add alias names\"]],{\"statements\":[[0,\"\\n        \"],[1,[23,1,[]],false],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/charterer-form.hbs"
    }
  });
  _exports.default = _default;
});