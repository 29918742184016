define("@fixflo/frontend/models/fixture-set", ["exports", "@ember-data/model", "ember-cp-validations", "moment"], function (_exports, _model, _emberCpValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true)],
    status: [(0, _emberCpValidations.validator)('presence', true)]
  });
  var _default = _model.default.extend(Validations, {
    uuid: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    fixtures: (0, _model.hasMany)('fixture'),
    fixtures_count: (0, _model.attr)('number'),
    status: (0, _model.attr)('string'),
    slug: (0, _model.attr)('string'),
    deleted_at: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('string'),
    updated_at: (0, _model.attr)('string'),
    bypass_duplicate_check: false,
    updatedAgo: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).fromNow();
    }),
    updatedAt: Ember.computed('updated_at', function () {
      if (!this.updated_at) {
        return this.createdAt;
      }
      return (0, _moment.default)(this.updated_at).format('DD MMM YYYY');
    }),
    createdAgo: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).fromNow();
    }),
    createdAt: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).format('DD MMM YYYY');
    }),
    /**
     * Converts fixtures to table data
     *
     * @return array
     */
    toTableData: function () {
      return this.fixtures.map(fixture => {
        let newFixture = {
          line: Ember.get(fixture, 'raw_text'),
          cell_classname: Ember.get(fixture, 'cell_classname'),
          meta: Ember.get(fixture, 'meta.content') ? Ember.get(fixture, 'meta.content').serialize() : null
        };
        fixture.get('values').forEach(fv => {
          newFixture[fv.get('key')] = fv.serialize();
        });
        return newFixture;
      });
    },
    hasParams: function (params = []) {
      if (Ember.isArray(params)) {
        return typeof this.params === 'object' && params.every(p => this.params.hasOwnProperty(p));
      } else if (typeof params === 'string') {
        return typeof this.params === 'object' && this.params.hasOwnProperty(params);
      }
    }
  });
  _exports.default = _default;
});