define("@fixflo/frontend/components/placeholder/page-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Default class names applied to Component
     *
     * @type Array
     */
    classNames: ['mb-6']
  });
  _exports.default = _default;
});