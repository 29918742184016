define("@fixflo/frontend/utils/is-model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isModel;
  const isEmberModel = obj => obj && obj instanceof _model.default && ['_internalModel'].every(p => obj.hasOwnProperty(p));
  const typeMatches = (obj, type) => isEmberModel(obj) && type === obj.get('_internalModel.modelName');
  function isModel(obj, type = null) {
    return type ? typeMatches(obj, type) : isEmberModel(obj);
  }
});