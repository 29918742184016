define("@fixflo/frontend/controllers/dashboard/fixtures/view", ["exports", "@fixflo/frontend/mixins/fixtures-table", "@fixflo/frontend/mixins/can-insert-fixtures"], function (_exports, _fixturesTable, _canInsertFixtures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend(_fixturesTable.default, _canInsertFixtures.default, {
    actions: {
      /**
       * Publish a fixture set
       */
      publish: function (fixtureSet) {
        if (!fixtureSet) {
          fixtureSet = this.model;
        }
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Publish fixtures',
          acceptText: 'Confirm & Publish',
          message: `Are you sure you want to publish these fixtures?`
        }).then(() => {
          this.dialog.startLoading();
          fixtureSet.set('status', 'published');
          fixtureSet.set('bypass_duplicate_check', this.bypassDuplicateCheck);
          fixtureSet.save().then(() => {
            this.dialog.stopLoading();
            this.notifications.success('Fixtures successfully published');
          }).catch(error => {
            // rollback changes to fixture set
            fixtureSet.rollbackAttributes();
            // handle duplicates via dialog
            if (error.code === 'duplicates_found') {
              Ember.set(this, 'bypassDuplicateCheck', true);
              return this.send('handleDuplicates', error.duplicates, fixtureSet);
            }
            // display error
            this.notifications.serverError(error);
          });
        });
      },
      /**
       * Discretely publish a fixture set
       */
      autoPublish: function (fixtureSet) {
        if (fixtureSet.status === 'published') {
          return;
        }
        fixtureSet.set('status', 'published');
        fixtureSet.set('bypass_duplicate_check', this.bypassDuplicateCheck);
        fixtureSet.save().then(() => {
          this.dialog.stopLoading();
          this.notifications.success('Fixtures successfully published');
        }).catch(error => {
          // rollback changes to fixture set
          fixtureSet.rollbackAttributes();
          // handle duplicates via dialog
          if (error.code === 'duplicates_found') {
            Ember.set(this, 'bypassDuplicateCheck', true);
            return this.send('handleDuplicates', error.duplicates, fixtureSet);
          }
          // display error
          this.notifications.serverError(error);
        });
      },
      /**
       * Prompt dialog for user to handle duplicate fixtures
       */
      handleDuplicates: function (duplicates, fixtureSet) {
        // set dialog to controller
        this.dialog.one('created', presenter => {
          this.set('presenter', presenter);
        });
        // prompt user to handle dialogs
        return this.dialog.open('duplicates-on-publish', null, {
          title: 'Duplicates found, you must resolve before publishing',
          duplicates,
          acceptText: 'Confirm',
          acceptIcon: 'fa fa-save',
          controller: this,
          accept: () => {
            this.presenter._accept();
            this.send('publish', fixtureSet);
          }
        });
      },
      /**
       * Use Me, deletes the duplicate and unflags the other
       */
      deleteDuplicate: function (useMe, deleteMe, duplicates, index) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Remove Duplicate',
          acceptText: 'Confirm and delete',
          message: `Are you sure you want to use this fixture?`
        }).then(() => {
          return this.fetch.post('actions/select-from-duplicates', {
            data: {
              fixtureToUse: Ember.get(useMe, 'uuid'),
              fixtureToDelete: Ember.get(deleteMe, 'uuid')
            }
          }).then(() => {
            // delete from index
            duplicates.removeAt(index);
            // send notification
            this.notifications.success('Duplicate removed');
          }).catch(error => {
            this.notifications.serverError(error);
          });
        });
      },
      /**
       * Removed feeback from suggestions
       */
      ignoreDuplicate: function (duplicate, duplicates, index) {
        // console.log(duplicate);
        // set duplicate to be ignored
        this.fetch.put(`fixtures/${Ember.get(duplicate, 'uuid')}`, {
          data: {
            ignore_as_duplicate: 1
          }
        });
        // remove from duplicates payload
        duplicates.removeAt(index);
      }
    },
    /**
     * Determines if system needs to perform dupe check before publish
     */
    bypassDuplicateCheck: false,
    /**
     * Is viewing a board?
     */
    isBoard: false,
    /**
     * Page limit
     */
    limit: 50,
    /**
     * Default sort
     */
    sort: 'import_position',
    /**
     * The fixture values
     */
    fixtures: Ember.computed('model.fixtures.@each', function () {
      return Ember.get(this, 'model.fixtures').map(f => {
        const fixtureRow = Ember.get(f, 'asTableData');
        if (this.handsontable) {
          Ember.set(fixtureRow, 'isChecked', this.handsontable._all_checked ?? false);
        }
        return fixtureRow;
      });
    }),
    /**
     * Quick report types
     */
    quickReportTypes: Ember.computed.alias('model.quickReportTypes'),
    /**
     * Render the table when fixtures model has been reloaded
     */
    renderTable: Ember.observer('model.fixtures.@each', 'handsontable', function () {
      const handsontable = this.handsontable;
      const fixtures = this.fixtures;
      if (handsontable) {
        handsontable.loadData(fixtures);
      }
    })
  });
  _exports.default = _default;
});