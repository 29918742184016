define("@fixflo/frontend/services/tour", ["exports", "ember-query-params-reset/utils/reset-query-params", "intro.js"], function (_exports, _resetQueryParams, _intro) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let TourService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, (_class = (_temp = class TourService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "sentry", _descriptor2, this);
      _initializerDefineProperty(this, "fetch", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "router", _descriptor5, this);
      _defineProperty(this, "activeTour", void 0);
    }
    /**
     * Inject the current user service
     *
     * @var {Service}
     */
    /**
     * Inject the sentry service
     *
     * @var {Service}
     */
    /**
     * Inject the fetch service
     *
     * @var {Service}
     */
    /**
     * Inject the store service
     *
     * @var {Service}
     */
    /**
     * Inject the router service
     *
     * @var {Service}
     */
    /**
     * Determines if current user should start tour automatically
     *
     * @void
     */
    shouldAutoStart() {}

    /**
     * The current and active tour
     *
     * @var {Boolean} activeTour
     */

    /**
     * Set as active tour
     *
     * @param {String} tour the name of the tour
     */
    activateTour(tour) {
      this.activeTour = tour;
    }

    /**
     * Set as active tour
     */
    deactivateTour() {
      this.activeTour = undefined;
    }

    /**
     * Runs a series of tours in order
     *
     * @param {Array} stack
     * @void
     */
    async runStack(stack = [], controller, model) {
      for (let i = 0; i < stack.length; i++) {
        const hasCompleted = await this.hasCompletedTour(stack[i]);
        if (!hasCompleted) {
          return this[stack[i]](controller, model);
        }
      }
    }

    /**
     * Mark a tour as completed
     *
     * @param {String} tour the name of the tour
     */
    completeTour(tour) {
      return this.fetch.post('actions/complete-tour', {
        tour
      }).then(response => {
        // log tour as completed in user options
        this.currentUser.options.set(`${this.currentUser.id}:tour:${tour}`, true);
      });
    }

    /**
     * Checks if a user has completed the given tour
     *
     * @param {String} tour the name of the tour
     */
    async hasCompletedTour(tour, bypass = false) {
      if (bypass === true) {
        return false;
      }
      const isCompleteRemoteCheck = await this.fetch.get('actions/has-completed-tour', {
        tour
      });
      // check in localstorage
      const isCompleteLocalCheck = this.currentUser.options.get(`${this.currentUser.id}:tour:${tour}`) === true;
      // return responses
      return isCompleteLocalCheck || isCompleteRemoteCheck;
    }

    /**
     * Get a tours current step
     *
     * @param {Object} tour the tour instance
     */
    getCurrentStep(tour) {
      return tour._options.steps[tour._currentStep];
    }

    /**
     * Get a tours current step index
     *
     * @param {Object} tour the tour instance
     * @return {Integer}
     */
    getCurrentStepIndex(tour) {
      return tour._currentStep;
    }

    /**
     * Get a tours current step
     *
     * @param {Object} tour the tour instance
     */
    getNextStep(tour) {
      const nextStepIndex = tour._currentStep + 1;
      if (tour._options.steps[nextStepIndex]) {
        return tour._options.steps[nextStepIndex];
      }
      return false;
    }
    /**
     * Get a tours current step
     *
     * @param {Object} tour the tour instance
     */
    getPreviousStep(tour) {
      const previousStepIndex = tour._currentStep - 1;
      if (tour._options.steps[previousStepIndex]) {
        return tour._options.steps[previousStepIndex];
      }
      return false;
    }

    /**
     * Sets the into.js overlay opacity
     *
     * @void
     */
    setOverlayOpacity(opacity) {
      document.querySelector('.introjs-overlay').style.opacity = opacity;
    }

    /**
     * Returns the into.js overlay opacity
     *
     * @return {String} opacity
     */
    getOverlayOpacity() {
      return document.querySelector('.introjs-overlay').style.opacity;
    }

    /**
     * Simualtes a mouse event
     *
     * @return {String} opacity
     */
    simulateMouse(target, eventType) {
      const mouseEvent = document.createEvent('MouseEvents');
      mouseEvent.initEvent(eventType, true, true);
      target.dispatchEvent(mouseEvent);
    }

    /**
     * The overview tour
     *
     * @void
     */
    overview() {
      // check to make sure this tour is not already running
      if (this.activeTour === 'overview') {
        return;
      }
      // define the tour
      const tour = (0, _intro.default)().addSteps([{
        intro: `Welcome to FixFlo ${this.currentUser.name}, before you get started this tour will walk you through the basics of using FixFlo.`
      }, {
        intro: `FixFlo features three primary sections to help you increase your productivity. Let's check them out!`
      }, {
        element: 'nav.fixflo-side-nav a:nth-child(1)',
        highlightClass: 'navitem-tour-overlay',
        intro: `Boards are collections of fixtures with pre-applied filters, the boards section will help you organize your fixtures in order to help you find what you need quickly.`,
        position: 'right'
      }, {
        element: 'nav.fixflo-side-nav a:nth-child(2)',
        highlightClass: 'navitem-tour-overlay',
        intro: `The workplace is where you can import fixtures and manage all your imported fixtures.`,
        position: 'right'
      }, {
        element: 'nav.fixflo-side-nav a:nth-child(3)',
        highlightClass: 'navitem-tour-overlay',
        intro: `The reports section is where you can define your own custom report types as well as templates for reports.`,
        position: 'right'
      }, {
        element: '#profile-link',
        highlightClass: 'navitem-profile-link-tour-overlay',
        intro: `Here is where you can manage your profile and other settings.`,
        position: 'top-right-aligned'
      }, {
        intro: `That’s it, now let’s get started! Click "Done" to continue.`,
        tooltipClass: 'hide-introjs-tooltip-direction-buttons',
        scrollToElement: true,
        scrollTo: '#current-route-page-title'
      }]);
      // set options for this tour
      tour.setOptions({
        exitOnOverlayClick: false,
        exitOnEsc: false,
        disableInteraction: true,
        showBullets: false
      });
      // handle onchange events, add additional logic in this event
      tour.onchange(targetElement => {
        // for menu item steps we need to lower the opacity
        if (targetElement.classList.contains('side-nav-menu-item')) {
          this.setOverlayOpacity(0.3);
        } else if (this.getOverlayOpacity() === '0.3') {
          this.setOverlayOpacity(0.8);
        }
      });
      // on complete transition to next section with tour
      tour.oncomplete(() => {
        // transition to fixtures view
        this.router.transitionTo('dashboard.fixtures.index').finally(() => {
          this.workspace();
        });
      });
      // track this tour as completed
      tour.onexit(() => {
        this.completeTour('overview');
        this.deactivateTour();
      });
      // start the tour
      Ember.run.scheduleOnce('afterRender', this, () => {
        try {
          tour.start();
          this.activateTour('overview');
        } catch (error) {
          this.sentry.captureException(error);
        }
      });
    }

    /**
     * This tour gives the user an introduction to their workspace
     *
     * @void
     */
    workspace() {
      // check to make sure this tour is not already running
      if (this.activeTour === 'workspace') {
        return;
      }
      // define the tour
      const tour = (0, _intro.default)().addSteps([{
        intro: `Welcome to your workplace, this is where all your fixture imports can be found. Let’s get started by adding some fixtures.`
      }]);
      // set options for this tour
      tour.setOptions({
        exitOnOverlayClick: false,
        exitOnEsc: false,
        disableInteraction: true,
        showBullets: false
      });
      // on complete transition to next section with tour
      tour.oncomplete(() => {
        return this.router.transitionTo('dashboard.fixtures.new').then(() => {
          setTimeout(() => {
            this.addFixtures();
          }, 2400);
        });
      });
      // track this tour as completed
      tour.onexit(() => {
        this.completeTour('workspace');
        this.deactivateTour();
      });
      // start the tour
      Ember.run.scheduleOnce('afterRender', this, () => {
        try {
          tour.start();
          this.activateTour('workspace');
        } catch (error) {
          this.sentry.captureException(error);
        }
      });
    }

    /**
     * This tour instructs user on how to add fixtures
     *
     * @void
     */
    addFixtures(controller) {
      // check to make sure this tour is not already running
      if (this.activeTour === 'addFixtures') {
        return;
      }
      // sample fixtures to use when showing the import
      const sampleFixtures = `
            <div>DONG-A THETIS   	90 JET  10/AUG   KOREA/SIN        	USD1.9M             SHELL</div>
            <div>EAGLE LE HAVRE      90 UMS  07/AUG   MCHINA/SPORE         RNR                 CSSSA</div>
            <div>BW TAGUS            60 CPP  15/AUG   KOREA/USWC           USD1.275M           CNR</div>
            <div>GULF CASTLE     	60 CPP  09/AUG   CHINA/EAF        	RNR                 UNIPEC</div>
            <div>MARIBEL         	60 CPP 11/AUG   PHILI/SPORE          USD350K             SHELL</div>
            <div>NEW CENTURY         60 UMS  07/AUG   SPORE/SPORE-MEG      USD192.5K-335K      TRAFIGURA</div>
            <div>STRIMON         	40 JET  09/AUG   KOREA/USWC       	USD1.05M            VALERO</div>
        `;
      // define the tour
      const tour = (0, _intro.default)().addSteps([{
        element: 'button[title="Insert Fixture"]',
        intro: `Use this button to add fixture rows to the table below.`
      }, {
        element: 'button[title="Upload Fixtures"]',
        intro: `Use this button to upload fixtures from an Excel file.`
      }, {
        element: '#fixtureInput',
        intro: `Use this text input to paste fixtures from emails or documents for FixFlo to process into the table above.`,
        position: 'top'
      }, {
        element: '#fixtureInput',
        intro: `Now let's try adding fixtures. For this example we will use the text input.`,
        position: 'top',
        action: 'pasteSampleFixtures'
      }, {
        element: 'button[title="Process"]',
        intro: `The process button will parse whatever you paste into this text box, once completed your fixtures will appear in the table above. Click Next to process these fixtures.`,
        position: 'right'
      }, {
        intro: `Please wait while the fixtures are processed...`,
        tooltipClass: 'hide-introjs-tooltip-buttons',
        action: 'processFixtures'
      }, {
        element: '.minHeight > .handsontable',
        intro: `Once parsed your fixtures will appear in this table where you will be able to make changes if necessary.`,
        tooltipClass: 'hide-introjs-tooltip-prev-button',
        position: 'top'
      }, {
        element: 'button[title="Save"]',
        intro: `Once you have reviewed your imported fixtures you can click Save.`,
        position: 'left'
      }, {
        intro: `During this FixFlo will automatically associate fixture data to the actual vessels, ports, and more. If there are any issues FixFlo will prompt suggestions in which you can optionally make corrections before finalizing the import.`,
        tooltipClass: 'hide-introjs-tooltip-prev-button'
      }, {
        intro: 'Please wait while these new fixtures are saved...',
        tooltipClass: 'hide-introjs-tooltip-buttons',
        action: 'saveFixtures'
      }]);
      const finishTour = (0, _intro.default)().addSteps([{
        element: '#saveFixturesDialog',
        scrollToElement: false,
        intro: `After the fixtures are saved FixFlo will allow you to name your fixture import set, or set the status to either draft or publish the fixtures immediately.`,
        tooltipClass: 'hide-introjs-tooltip-prev-button',
        highlightClass: 'introjs-highlight-dialog',
        position: 'top'
      }, {
        element: '#saveFixturesDialog',
        scrollToElement: false,
        intro: `Finalizing the import, you will now be transitioned to the management view for these fixtures.`,
        highlightClass: 'introjs-highlight-dialog',
        position: 'top'
      }, {
        intro: 'Please wait...',
        tooltipClass: 'hide-introjs-tooltip-buttons',
        action: 'publishFixtures'
      }]);
      // set options for this tour
      tour.setOptions({
        exitOnOverlayClick: false,
        exitOnEsc: false,
        disableInteraction: true,
        showBullets: false,
        scrollToElement: false
      });
      finishTour.setOptions({
        exitOnOverlayClick: false,
        exitOnEsc: false,
        disableInteraction: true,
        showBullets: false,
        scrollToElement: false
      });
      // handle onchange events, add additional logic in this event
      tour.onchange(targetElement => {
        // get the current step
        const step = this.getCurrentStep(tour);
        // if step has an action handle execution
        if (step.hasOwnProperty('action')) {
          switch (step.action) {
            case 'pasteSampleFixtures':
              // pase sample fixtures after 600ms delay
              setTimeout(() => {
                document.querySelector(step.element).innerHTML = sampleFixtures;
              }, 600);
              break;
            case 'processFixtures':
              setTimeout(() => {
                const input = document.querySelector('#fixtureInput[contenteditable]');
                controller.fixturesTable.send('processRawText', input, () => {
                  // re-enable buttons for next steap
                  tour.setOption('showButtons', true);
                  setTimeout(() => {
                    // go to next step
                    tour.nextStep();
                  }, 300);
                });
              }, 800);
              break;
            case 'saveFixtures':
              setTimeout(() => {
                controller.send('save', true, () => {
                  tour.exit();
                  setTimeout(() => {
                    finishTour.start();
                  }, 600);
                });
              }, 2600);
              break;
          }
        }
      });
      // handle onchange events, add additional logic in this event
      finishTour.onchange(targetElement => {
        // get the current step
        const step = this.getCurrentStep(finishTour);
        // if step has an action handle execution
        if (step.hasOwnProperty('action')) {
          switch (step.action) {
            case 'publishFixtures':
              setTimeout(() => {
                controller.presenter.accept().finally(() => {
                  finishTour.exit();
                });
              }, 3600);
              break;
          }
        }
      });
      // track this tour as completed
      tour.onexit(() => {
        this.completeTour('addFixtures');
        this.deactivateTour();
      });
      // start the tour
      Ember.run.scheduleOnce('afterRender', this, () => {
        try {
          tour.start();
          this.activateTour('addFixtures');
        } catch (error) {
          this.sentry.captureException(error);
        }
      });
    }

    /**
     * This tour gives the user an introduction to a fixtures import view
     *
     * @void
     */
    viewFixtures(controller, {
      fixtureSet
    }) {
      // check to make sure this tour is not already running
      if (this.activeTour === 'viewFixtures') {
        return;
      }
      // define the tour
      const tour = (0, _intro.default)().addSteps([{
        intro: `Here you can add more fixtures to this import, edit, remove, and filter fixtures from your import.`
      }, {
        element: '#publishFixtures',
        intro: `When you’re ready you can Publish the import. Once published your fixtures will show up in boards where filters match the fixtures criteria. Let's publish now to continue the tour.`,
        tooltipClass: 'hide-introjs-tooltip-prev-button'
      }, {
        intro: 'Please wait while the fixtures are published...',
        tooltipClass: 'hide-introjs-tooltip-buttons',
        action: 'publishFixtures'
      }, {
        intro: `Now that your fixtures are published, click Done to learn how to create a Board!`,
        tooltipClass: 'hide-introjs-tooltip-prev-button',
        action: 'autoPublishFixtures'
      }]);
      // set options for this tour
      tour.setOptions({
        exitOnOverlayClick: false,
        exitOnEsc: false,
        disableInteraction: true,
        showBullets: false
      });
      // handle onchange events, add additional logic in this event
      tour.onchange(targetElement => {
        // get the current step
        const step = this.getCurrentStep(tour);
        // if step has an action handle execution
        if (step.hasOwnProperty('action')) {
          switch (step.action) {
            case 'publishFixtures':
              setTimeout(() => {
                controller.send('autoPublish', fixtureSet);
                tour.nextStep();
              }, 2600);
              break;
            case 'autoPublishFixtures':
              if (fixtureSet.status !== 'published') {
                setTimeout(() => {
                  controller.send('autoPublish', fixtureSet);
                  tour.nextStep();
                }, 600);
              }
              break;
          }
        }
      });
      // on complete transition to next section with tour
      tour.oncomplete(() => {
        // transition to fixtures view
        this.router.transitionTo('dashboard.boards.index');
      });
      // track this tour as completed
      tour.onexit(() => {
        this.completeTour('viewFixtures');
        this.deactivateTour();
      });
      // start the tour
      Ember.run.scheduleOnce('afterRender', this, () => {
        try {
          tour.start();
          this.activateTour('viewFixtures');
        } catch (error) {
          this.sentry.captureException(error);
        }
      });
    }

    /**
     * Tour for the boards index
     *
     * @void
     */
    async viewBoards(controller, model) {
      // check to make sure this tour is not already running
      if (this.activeTour === 'viewBoards') {
        return;
      }
      // check to see if a sample board exists, if not send to create board tutorial
      const hasSampleBoard = await this.fetch.get('actions/has-sample-board');
      // if no sample board send to create board tutorial
      if (!hasSampleBoard) {
        return this.createBoard(controller, model);
      }
      // define the tour
      const tour = (0, _intro.default)().addSteps([{
        intro: `This is your boards view, here you will be able to view all of your boards in one place.`
      }, {
        element: '.ff_page-filter__search',
        intro: `Use this text input to search your boards below.`
      }, {
        element: '.ff_page-filter__action',
        intro: `Here your tags will appear which will allow you to easily click a tag to filter the boards below.`
      }, {
        element: '#sortBoards',
        intro: `This button will allow you to drag and sort your boards in your preferred order, it will also allow you to add or remove tags from your boards.`
      }, {
        element: '#editLayout',
        intro: `This button will toggle the boards layout between a listed view or the "cards" view.`
      }]);
      // set options for this tour
      tour.setOptions({
        exitOnOverlayClick: false,
        exitOnEsc: false,
        disableInteraction: true,
        showBullets: false
      });
      // handle onchange events, add additional logic in this event
      tour.onchange(targetElement => {
        // get the current step
        const step = this.getCurrentStep(tour);
        // if step has an action handle execution
        if (step.hasOwnProperty('action')) {
          switch (step.action) {
            case 'publishFixtures':
              setTimeout(() => {
                if (tour._currentStep === 1) {
                  tour.nextStep();
                  setTimeout(() => {
                    controller.send('autoPublish', fixtureSet);
                  }, 600);
                }
              }, 5000);
              break;
            case 'autoPublishFixtures':
              if (fixtureSet.status !== 'published') {
                setTimeout(() => {
                  controller.send('autoPublish', fixtureSet);
                }, 600);
              }
              break;
          }
        }
      });
      // track this tour as completed
      tour.onexit(() => {
        this.completeTour('viewBoards');
        this.deactivateTour();
      });
      // start the tour
      Ember.run.scheduleOnce('afterRender', this, () => {
        try {
          tour.start();
          this.activateTour('viewBoards');
        } catch (error) {
          this.sentry.captureException(error);
        }
      });
    }

    /**
     * Tour for creating a board
     *
     * @void
     */
    createBoard(controller, model) {
      // check to make sure this tour is not already running
      if (this.activeTour === 'createBoard') {
        return;
      }
      // define the tour
      const preTour = (0, _intro.default)().addSteps([{
        intro: `Now that you have fixtures published, let’s create a Board.`
      }, {
        intro: `Boards allow you to prefilter your fixtures, any fixtures that match the filters will appear in the Board.`
      }, {
        element: 'button[title="New Board"]',
        intro: `Use this button when you want to create a new board. Click "Done" to continue.`,
        tooltipClass: 'hide-introjs-tooltip-direction-buttons',
        position: 'left'
      }]);
      // define the tour
      const tour = (0, _intro.default)().addSteps([{
        element: '#createBoard',
        intro: `This form contains all the filter options that can be applied to your board.`,
        highlightClass: 'introjs-highlight-dialog',
        position: 'top'
      }, {
        element: '#nameInputContainer',
        intro: `First thing you'll want to do is give your board a name. For this example we will call this board "My CPP".`,
        highlightClass: 'introjs-highlight-dialog-input',
        position: 'left',
        action: 'enterBoardName'
      }, {
        element: '#typeInputContainer',
        intro: `There are two types of boards. "Free Board" allows you to create a no limits spreadsheet for anything else you may need. "Fixtures Board" is a board that contains fixtures. For this example we will be creating a "Fixtures Board".`,
        highlightClass: 'introjs-highlight-dialog-input',
        position: 'left'
      }, {
        element: '#gradeInputContainer',
        intro: `For this example we will only be setting the Grade filter which will be CPP.`,
        highlightClass: 'introjs-highlight-dialog-input',
        position: 'left',
        action: 'enterGrade'
      }, {
        element: 'button[title="Confirm & Create"]',
        intro: `When you're done click this button to finalize.`,
        highlightClass: 'introjs-highlight-dialog-input',
        position: 'left'
      }, {
        intro: 'Please wait while the board is saved...',
        tooltipClass: 'hide-introjs-tooltip-buttons',
        action: 'saveBoard'
      }]);
      // define the tour
      const postTour = (0, _intro.default)().addSteps([{
        element: '#sortable-group .is-locked',
        intro: `Here is your newly created board!`,
        position: 'top'
      }, {
        element: '#sortable-group .is-locked',
        intro: `Next let's see what we can do with our board. Click the board title to continue.`,
        position: 'top'
      }]);
      // set options for this tour
      preTour.setOptions({
        exitOnOverlayClick: false,
        exitOnEsc: false,
        disableInteraction: true,
        showBullets: false
      });
      tour.setOptions({
        exitOnOverlayClick: false,
        exitOnEsc: false,
        disableInteraction: true,
        showBullets: false,
        overlayOpacity: 0.5
      });
      postTour.setOptions({
        exitOnOverlayClick: false,
        exitOnEsc: false,
        disableInteraction: true,
        showBullets: false,
        overlayOpacity: 0.5
      });
      // this function will trigger the create board dialog
      const startBoardCreation = () => {
        setTimeout(() => {
          if (document.getElementById('#createBoard') === null) {
            controller.send('create');
          }
          setTimeout(() => {
            tour.start();
          }, 100);
        }, 100);
      };
      // a function to maintain disabledInteraction over the dialog
      const maintainDisabledInteractionOverDialog = () => {
        const dialog = document.getElementById('createBoard');
        const disabledInteractionOverlay = document.querySelector('.introjs-disableInteraction');
        if (!dialog || !disabledInteractionOverlay) {
          return;
        }
        const dialogRect = dialog.getBoundingClientRect();
        disabledInteractionOverlay.style.left = dialogRect.left - 15;
        disabledInteractionOverlay.style.top = dialogRect.top - 45;
        disabledInteractionOverlay.style.width = `${dialogRect.width + 10}px`;
        disabledInteractionOverlay.style.height = `${dialogRect.height + 10}px`;
      };
      // handle events when the pretour is exited ot completed
      // handle onchange events, add additional logic in this event
      preTour.oncomplete(startBoardCreation);
      tour.onafterchange(maintainDisabledInteractionOverDialog);
      tour.onchange(targetElement => {
        // get the current step
        const step = this.getCurrentStep(tour);
        // if step has an action handle execution
        if (step.hasOwnProperty('action')) {
          switch (step.action) {
            case 'enterBoardName':
              setTimeout(() => {
                const boardName = 'My CPP';
                const boardNameInput = document.querySelector('input[name="boardNameInput"]');
                const boardModel = controller.presenter.contextObject;
                // clear board name input value first
                boardModel.name = ``;
                boardModel.is_sample = true;
                boardModel.order = 0;
                // "type" the sample board name
                for (let i = 0; i < boardName.length; i++) {
                  setTimeout(() => {
                    boardNameInput.value = boardNameInput.value + boardName.charAt(i);
                    if (i + 1 === boardName.length) {
                      boardModel.name = boardName;
                    }
                  }, 150 * (i + 1));
                }
              }, 800);
              break;
            case 'enterGrade':
              setTimeout(() => {
                const gradeSelectInput = document.getElementById('gradeSelector');
                const trigger = gradeSelectInput.querySelector('.ember-basic-dropdown-trigger');
                // trigger a mouse event
                this.simulateMouse(trigger, 'mousedown');
                // wait a few to get the selector api
                setTimeout(() => {
                  const gradeSelector = controller.gradeSelector;
                  // trigger the open from the api
                  // gradeSelector.actions.open();
                  // find the cpp model from store
                  const cppModel = this.store.peekAll('cargo-grade').find(grade => {
                    return grade.display_name === `CPP`;
                  });
                  // select the cpp model
                  gradeSelector.actions.select(cppModel);
                  gradeSelector.actions.close();
                }, 100);
              }, 500);
              break;
            case 'saveBoard':
              setTimeout(() => {
                controller.presenter.accept().finally(() => {
                  setTimeout(() => {
                    tour.exit();
                    controller.boards = controller.boards.sortBy('created_at').reverseObjects();
                    setTimeout(() => {
                      postTour.start();
                    }, 300);
                  }, 300);
                });
              }, 1600);
              break;
          }
        }
      });
      // track this tour as completed
      tour.onexit(() => {
        this.completeTour('createBoard');
        this.deactivateTour();
      });
      // start the tour
      Ember.run.scheduleOnce('afterRender', this, () => {
        try {
          preTour.start();
          this.activateTour('createBoard');
        } catch (error) {
          this.sentry.captureException(error);
        }
      });
    }

    /**
     * This tour gives the user an introduction to viewing a board
     *
     * @void
     */
    viewBoard(controller, model) {
      // console.log('Starting view board tour');
      // check to make sure this tour is not already running
      if (this.activeTour === 'viewBoard') {
        return;
      }
      // define the tour
      const tour = (0, _intro.default)().addSteps([{
        intro: `Inside the board you will be able to view all your pre-filtered fixtures and generate reports on the fly. Click "Next" for a quick overview.`
      }, {
        element: '#addFixturesBtn',
        intro: `This button allows you to import fixtures directly into the board. There are three ways you can add fixtures. You can use "Add a Fixture" to insert a single fixture, "Add Bulk Fixtures" to paste fixtures, or "Upload Fixtures from Excel" to upload a spreadsheet of fixtures.`
      }, {
        element: '#sortFixturesBtn',
        intro: `You are able to sort your fixtures by laycan, date created, and last updated. You may also sort by clicking on the column header you wish to sort by. You can sort by multiple columns by holding down the 'ctrl/ cmd' key depending on your OS.`
      }, {
        element: '#board-filters',
        intro: `You can filter the board further. Clicking here will show you all your filter options.`
      }, {
        element: '#geoFilterFixturesBtn',
        intro: `These are special geographic filters that allow you to draw layers over a map which will then be used to filter all fixtures with either load or discharge ports contained within your own drawn layer.`
      }, {
        element: '#exportFixturesBtn',
        intro: `This button will allow you to export all fixtures in this board in your chosen format.`
      }]);
      // set options for this tour
      tour.setOptions({
        exitOnOverlayClick: false,
        exitOnEsc: false,
        disableInteraction: true,
        showBullets: false
      });
      // track this tour as completed
      tour.onexit(() => {
        this.completeTour('viewBoard');
        this.deactivateTour();
        controller.isTouring = false;
      });
      // start the tour
      Ember.run.scheduleOnce('afterRender', this, () => {
        try {
          tour.start();
          this.activateTour('viewBoard');
          controller.isTouring = true;
        } catch (error) {
          this.sentry.captureException(error);
        }
      });
    }

    /**
     * This tour gives the user an introduction to viewing a board
     *
     * @void
     */
    viewReports(controller, model) {
      // check to make sure this tour is not already running
      if (this.activeTour === 'viewReports') {
        return;
      }
      // define the tour
      const tour = (0, _intro.default)().addSteps([{
        intro: `Welcome to Reports, here you can manage all your report types and templates.`
      }, {
        element: '#reportsLink',
        intro: `What is a report type? A report type is where you define the report itself. You can give your report a name and description, select which boards in which the report should pull fixtures from. You’re also able to define further filter criteria which will define what fixtures the report should pull as well as sorting options. Relative date filters are great for creating ongoing reports.`
      }, {
        element: '#reportTemplatesLink',
        intro: `What is a report template? A report template defines how your report will appear. There are options to include a custom header, select columns you’d like to display, and further options to format your fixtures.`
      }, {
        intro: `Once your template and report is defined you can quickly download it anytime you need it in your preferred format.`
      }]);
      // set options for this tour
      tour.setOptions({
        exitOnOverlayClick: false,
        exitOnEsc: false,
        disableInteraction: true,
        showBullets: false
      });
      // track this tour as completed
      tour.onexit(() => {
        this.completeTour('viewReports');
        this.deactivateTour();
      });
      // start the tour
      Ember.run.scheduleOnce('afterRender', this, () => {
        try {
          tour.start();
          this.activateTour('viewReports');
        } catch (error) {
          this.sentry.captureException(error);
        }
      });
    }
  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sentry", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TourService;
});