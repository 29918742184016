define("@fixflo/frontend/helpers/to-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function toArray([mixed]) {
    return Ember.tryInvoke(mixed, 'toArray') || [];
  });
  _exports.default = _default;
});