define("@fixflo/frontend/services/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    show: function (text, type = 'alert', layout = 'topCenter', theme = 'semanticui', onClick = null) {
      if (typeof layout === 'function') {
        onClick = layout;
        layout = 'topCenter';
      }
      return new Noty({
        type,
        layout,
        theme,
        text,
        timeout: 5000,
        progressBar: true,
        closeWith: ['click', 'button'],
        animation: {
          open: 'animate__animated animate__backInDown',
          close: 'animate__animated animate__backOutUp'
        },
        callback: {
          onCloseClick: onClick
        }
      }).show();
    },
    serverError: function (error, layout, theme, onClick) {
      if (error === undefined || error === null) {
        return;
      }
      return this.error(this.getErrorMessage(error), layout, theme, onClick);
    },
    error: function (text, layout, theme, onClick) {
      return this.show(text, 'error', layout, theme, onClick);
    },
    success: function (text, layout, theme, onClick) {
      return this.show(text, 'success', layout, theme, onClick);
    },
    info: function (text, layout, theme, onClick) {
      return this.show(text, 'info', layout, theme, onClick);
    },
    warning: function (text, layout, theme, onClick) {
      return this.show(text, 'warning', layout, theme, onClick);
    },
    getErrorMessage: function (error, message = 'Oops! Something went wrong') {
      if (error === undefined || error === null) {
        return message;
      }
      const payload = Ember.get(error, 'payload');
      const errorMessage = Ember.get(error, 'message');
      // get the error message depending on type
      if (payload && Ember.get(payload, 'errors') && Ember.isArray(Ember.get(payload, 'errors'))) {
        return Ember.get(payload, 'errors').join('\n');
      } else if (payload && Ember.get(payload, 'error') && typeof Ember.get(payload, 'error') === 'string') {
        return Ember.get(payload, 'error');
      } else if (payload && typeof payload === 'string') {
        return payload;
      } else if (errorMessage) {
        return errorMessage;
      }
      return message;
    }
  });
  _exports.default = _default;
});