define("@fixflo/frontend/serializers/vessel", ["exports", "@ember-data/serializer/rest", "@fixflo/frontend/helpers/generate-relation-object"], function (_exports, _rest, _generateRelationObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _rest.default.extend(_rest.EmbeddedRecordsMixin, {
    primaryKey: 'uuid',
    normalize: function (model, hash, prop) {
      // if one of the data objects is null set to default object
      if (Ember.get(hash, 'company') === null) {
        hash.company = Ember.get((0, _generateRelationObject.generateRelationObject)(['vessel']), 'company');
      }
      if (Ember.get(hash, 'measurement') === null) {
        hash.measurement = Ember.get((0, _generateRelationObject.generateRelationObject)(['vessel']), 'measurement');
      }
      if (Ember.get(hash, 'mechanical') === null) {
        hash.mechanical = Ember.get((0, _generateRelationObject.generateRelationObject)(['vessel']), 'mechanical');
      }
      if (Ember.get(hash, 'history') === null) {
        hash.history = Ember.get((0, _generateRelationObject.generateRelationObject)(['vessel']), 'history');
      }
      return this._super(...arguments);
    }
  });
  _exports.default = _default;
});