define("@fixflo/frontend/models/fixture", ["exports", "@ember-data/model", "@fixflo/frontend/helpers/n-a", "@fixflo/frontend/helpers/generate-relation-object", "@fixflo/frontend/utils/object-from-model", "moment"], function (_exports, _model, _nA, _generateRelationObject, _objectFromModel, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    uuid: (0, _model.attr)('string'),
    set_uuid: (0, _model.attr)('string'),
    vessel_uuid: (0, _model.attr)('string'),
    load_port_uuid: (0, _model.attr)('string'),
    discharge_port_uuid: (0, _model.attr)('string'),
    raw_text: (0, _model.attr)('string'),
    charterer: (0, _model.attr)('string'),
    charterer_uuid: (0, _model.attr)('string'),
    laycan: (0, _model.attr)('string'),
    laycanRangeStart: (0, _model.attr)('string'),
    laycanRangeEnd: (0, _model.attr)('string'),
    quantity: (0, _model.attr)('string'),
    grade: (0, _model.attr)('string'),
    grade_uuid: (0, _model.attr)('string'),
    rate: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    comments: (0, _model.attr)('string'),
    load: (0, _model.attr)('string'),
    discharge: (0, _model.attr)('string'),
    vessel: (0, _model.attr)('string'),
    cell_classname: (0, _model.attr)('string'),
    is_duplicate: (0, _model.attr)('boolean'),
    has_load_options: (0, _model.attr)('boolean'),
    has_discharge_options: (0, _model.attr)('boolean'),
    internal: (0, _model.attr)('boolean'),
    ignore_as_duplicate: (0, _model.attr)('boolean'),
    confidentiality: (0, _model.attr)('string'),
    load_options: (0, _model.attr)('raw'),
    discharge_options: (0, _model.attr)('raw'),
    duplicate_source_uuid: (0, _model.attr)('string'),
    duplicate_fixture_uuid: (0, _model.attr)('string'),
    duplicate_fixture: (0, _model.belongsTo)('fixture', {
      async: false
    }),
    duplicate_fixture_set: (0, _model.belongsTo)('fixture-set', {
      async: false
    }),
    values: (0, _model.hasMany)('fixture-value'),
    cell_comments: (0, _model.hasMany)('cell-comment', {
      async: false
    }),
    deleted_at: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('string'),
    updated_at: (0, _model.attr)('string'),
    created_by: (0, _model.attr)('string'),
    updated_by: (0, _model.attr)('string'),
    asTableData: Ember.computed('discharge_options.@each', 'load_options.@each', 'values.@each', function () {
      const fixture = {
        line: this.raw_text,
        as_line: this.asLine,
        cell_classname: this.cell_classname,
        internal: this.internal,
        confidentiality: this.confidentiality,
        created_at: this.createdAtShortFormat,
        updated_at: this.updatedAtShortFormat,
        updated_at_datetime: this.updatedAt,
        created_at_datetime: this.createdAt,
        updated_by: this.updated_by,
        created_by: this.created_by,
        has_load_options: this.has_load_options,
        has_discharge_options: this.has_discharge_options,
        load_options: this.load_options,
        discharge_options: this.discharge_options,
        fixture_uuid: this.id,
        cell_comments: this.cell_comments.toArray().map(c => c.serialize()),
        comments: {
          fixture_uuid: this.id,
          key: 'comments',
          label: 'Comments'
        },
        isChecked: false
      };
      this.values.forEach(fixtureValue => {
        const key = Ember.get(fixtureValue, 'key');
        if (key === 'isChecked' || !key) return;
        if (['load', 'discharge'].includes(key) && Ember.get(fixture, `${key}.relation`)) return;
        Ember.set(fixture, key, fixtureValue.serialize());
        if (!Ember.get(fixtureValue, 'relation')) {
          Ember.set(fixture, `${key}.relation`, (0, _generateRelationObject.generateRelationObject)([key]));
        }
      });
      return fixture;
    }),
    asLine: Ember.computed('values.@each', function () {
      return this.values.map(v => (0, _nA.nA)([Ember.get(v, 'entry'), '']).toUpperCase()).join(' ');
    }),
    updatedAgo: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).fromNow();
    }),
    updatedAt: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).format('YYYY-MM-DD HH:mm A');
    }),
    updatedAtShortFormat: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).format('DD-MMM');
    }),
    createdAgo: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).fromNow();
    }),
    createdAt: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).format('YYYY-MM-DD HH:mm A');
    }),
    createdAtShortFormat: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).format('DD-MMM');
    }),
    toJSON(options) {
      return this.serialize(options);
    },
    toObject() {
      return (0, _objectFromModel.default)(this, (prop, value) => {
        if (prop === 'values' || prop === 'cell_comments') {
          return value.toArray().map(m => m.serialize());
        }
        if (prop === 'duplicate_fixture' || prop === 'duplicate_fixture_set') {
          return (0, _objectFromModel.default)(value);
        }
        return value;
      }, ['asLine', 'asTableData']);
    }
  });
  _exports.default = _default;
});