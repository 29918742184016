define("@fixflo/frontend/templates/dialog/fixtures-geo-filter-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "l8M0XM0s",
    "block": "{\"symbols\":[\"layers\"],\"statements\":[[4,\"if\",[[24,[\"existingBoundary\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"p\",true],[10,\"class\",\"italic text-orange-600 font-semibold mb-2\"],[8],[0,\"Geo filter has already been applied \"],[1,[28,\"if\",[[24,[\"forDischarge\"]],\"for discharge\",\"for load\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[5,\"leaflet-map\",[],[[\"@lat\",\"@lng\",\"@zoom\",\"@drawControl\",\"@onLoad\"],[\"1.3521\",\"103.8198\",5,true,[28,\"action\",[[23,0,[]],\"setGeoFilterMapInstance\",[24,[\"board\"]],[24,[\"forDischarge\"]]],[[\"target\"],[[24,[\"controller\"]]]]]]],{\"statements\":[[0,\"\\n\\t\"],[6,[23,1,[\"tile\"]],[],[[\"@url\"],[\"https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png\"]]],[0,\"\\n\"],[4,\"unless\",[[28,\"and\",[[24,[\"isBoard\"]],[24,[\"existingBoundary\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\"],[5,\"leaflet-draw-control\",[],[[\"@draw\",\"@onDrawDrawstop\"],[[28,\"hash\",null,[[\"marker\",\"circlemarker\",\"circle\",\"polyline\"],[false,false,false,false]]],[28,\"action\",[[23,0,[]],\"setGeoFilter\",[24,[\"forDischarge\"]]],[[\"target\"],[[24,[\"controller\"]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/fixtures-geo-filter-form.hbs"
    }
  });
  _exports.default = _default;
});