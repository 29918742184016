define("@fixflo/frontend/templates/dialog/customize-columns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jo4eoC4N",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"columns\"]]],null,{\"statements\":[[4,\"unless\",[[28,\"eq\",[[23,1,[\"type\"]],\"checkbox\"],null]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"col-3\"],[8],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n                    \"],[5,\"fix-flo/check-box\",[],[[\"@type\",\"@placeholder\",\"@inputId\",\"@name\",\"@isChecked\",\"@onToggle\"],[\"checkbox\",[23,1,[\"label\"]],[28,\"underscore\",[[23,1,[\"label\"]]],null],[28,\"underscore\",[[23,1,[\"label\"]]],null],[28,\"contains\",[[23,1,[]],[24,[\"contextObject\",\"selected\"]]],null],[22,\"selectColumn\"]]]],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/customize-columns.hbs"
    }
  });
  _exports.default = _default;
});