define("@fixflo/frontend/mixins/manages-report-templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    actions: {
      /**
       * Create a new report type
       *
       * @void
       */
      createReportTemplate: async function () {
        const template = this.newTemplate;
        const columns = this.templateColumns;
        const cases = this.cases;
        const formats = this.templateFormats;
        // set dialog presenter to controller
        this.dialog.one('created', reportTemplatePresenter => this.setProperties({
          reportTemplatePresenter
        }));
        // render dialog
        this.dialog.open('report-template-form', null, {
          className: 'dialog-wider',
          title: 'Create a new report template',
          template,
          columns,
          cases,
          formats,
          acceptText: 'Save',
          controller: this,
          accept: () => {
            this.reportTemplatePresenter.startLoading();
            // save report type
            template.save().then(template => {
              this.notifications.success(`Your template ${template.name} was created successfully`);
              this.reportTemplatePresenter.stopLoading();
              this.reportTemplatePresenter._accept();
              this.resetNewTemplate();
            }).catch(error => {
              this.reportTemplatePresenter.stopLoading();
              this.notifications.serverError(error);
            });
          },
          decline: () => {
            template.destroyRecord();
            this.reportTemplatePresenter.stopLoading();
            this.reportTemplatePresenter._decline();
            this.resetNewTemplate();
          }
        });
      },
      /**
       * Delete a template
       */
      deleteReportTemplate: function (template) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Delete template',
          acceptText: 'Confirm and delete',
          message: Ember.String.htmlSafe(`Are you sure you want to delete this template <i>${Ember.get(template, 'name')}</i>?`),
          accept: () => {
            this.dialog.startLoading();
            template.destroyRecord().then(() => {
              this.notifications.info('Template deleted');
              this.dialog.stopLoading();
              this.dialog._close();
            }).catch(error => {
              this.dialog.stopLoading();
              this.notifications.serverError(error);
            });
          }
        });
      },
      /**
       * Toggle a selected column
       */
      toggleColumnSelected: function (column) {
        const selected = Ember.get(this, 'newTemplate.columns') || [];
        if (selected.includes(column)) {
          selected.removeObject(column);
        } else {
          selected.pushObject(column);
        }
      },
      /**
       * Reorder columns as dragged
       */
      reorderColumns: function ({
        sourceList,
        sourceIndex,
        targetList,
        targetIndex
      }) {
        if (sourceList === targetList && sourceIndex === targetIndex) return;
        const item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);
        Ember.set(this, 'newTemplate.columns', targetList);
      }
    },
    /**
     * New template record
     */
    newTemplate: Ember.computed(function () {
      return this.store.createRecord('report-template', {
        columns: [],
        case: 'default',
        format: 'tab',
        zone_slash: false
      });
    }),
    /**
     * Reset the newTemplate
     */
    resetNewTemplate: function () {
      this.set('newTemplate', this.store.createRecord('report-template', {
        columns: [],
        case: 'default',
        format: 'tab',
        zone_slash: false
      }));
    },
    /**
     * Column selection options
     */
    templateColumns: Ember.computed('newTemplate.columns.@each', function () {
      return ['charterer', 'laycan', 'quantity', 'grade', 'rate', 'load', 'discharge', 'vessel', 'status', 'comments'].filter(c => Ember.isArray(Ember.get(this, 'newTemplate.columns')) ? !Ember.get(this, 'newTemplate.columns').some(s => s === c) : true);
    }),
    /**
     * Case options for template format
     */
    cases: ['default', 'uppercase', 'lowercase', 'capitalized'],
    /**
     * Listing format
     */
    templateFormats: ['auto_spaced', 'space', '2x_spaces', '3x_spaces', 'tab', '2x_tab', 'table']
  });
  _exports.default = _default;
});