define("@fixflo/frontend/routes/dashboard/boards/view", ["exports", "ember-query-params-reset/mixins/query-params-reset-route", "@fixflo/frontend/mixins/track-reloads", "ember-concurrency", "@fixflo/frontend/utils/is-model"], function (_exports, _queryParamsResetRoute, _trackReloads, _emberConcurrency, _isModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const AUTO_REFRESH_MINUTES_INTERVAL = 10;
  var _default = Ember.Route.extend(_queryParamsResetRoute.default, _trackReloads.default, {
    /**
     * Template to use for this route
     *
     * @var string
     */
    templateName: 'dashboard.fixtures.view',
    /**
     * Inject the current user service
     *
     * @var {Service}
     */
    currentUser: Ember.inject.service(),
    /**
     * Inject the `tour` service
     *
     * @var {Service}
     */
    tour: Ember.inject.service(),
    /**
     * Inject the `sidebarTabs` service
     *
     * @var {Service}
     */
    sidebarTabs: Ember.inject.service(),
    /**
     * Query params the user can change for this route
     *
     * @var object
     */
    queryParams: {
      _filter: {
        refreshModel: false,
        replace: true
      },
      _tab: {
        refreshModel: false,
        replace: true
      },
      query: {
        refreshModel: true,
        replace: true
      },
      boundary: {
        refreshModel: true,
        replace: true
      },
      discharge_boundary: {
        refreshModel: true,
        replace: true
      },
      load: {
        refreshModel: true,
        replace: true
      },
      discharge: {
        refreshModel: true,
        replace: true
      },
      vessel: {
        refreshModel: true,
        replace: true
      },
      'quantity[min]': {
        refreshModel: true,
        replace: true
      },
      'quantity[max]': {
        refreshModel: true,
        replace: true
      },
      grade: {
        refreshModel: true,
        replace: true
      },
      charterer: {
        refreshModel: true,
        replace: true
      },
      rate: {
        refreshModel: true,
        replace: true
      },
      laycanRangeStart: {
        refreshModel: true,
        replace: true
      },
      laycanRangeEnd: {
        refreshModel: true,
        replace: true
      },
      before: {
        refreshModel: true,
        replace: true
      },
      after: {
        refreshModel: true,
        replace: true
      },
      createdBy: {
        refreshModel: true,
        replace: true
      },
      internal: {
        refreshModel: true,
        replace: true
      },
      confidentialityLevel: {
        refreshModel: true,
        replace: true
      },
      vesselFleet: {
        refreshModel: true,
        replace: true
      },
      'vesselDeadWeight[min]': {
        refreshModel: true,
        replace: true
      },
      'vesselDeadWeight[max]': {
        refreshModel: true,
        replace: true
      },
      'vesselYearBuilt[min]': {
        refreshModel: true,
        replace: true
      },
      'vesselYearBuilt[max]': {
        refreshModel: true,
        replace: true
      },
      'vesselCubicCapacity[min]': {
        refreshModel: true,
        replace: true
      },
      'vesselCubicCapacity[max]': {
        refreshModel: true,
        replace: true
      },
      'vesselLengthOverAll[min]': {
        refreshModel: true,
        replace: true
      },
      'vesselLengthOverAll[max]': {
        refreshModel: true,
        replace: true
      },
      page: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    // actions: {
    //     willTransition(transition) {
    //         this._super(transition);

    //         // reset board
    //         if (this.controller) {
    //             // update tab here // something like this
    //             // const board = this.controller.source;
    //             // const fixtures = this.controller.fixtures;
    //             // this.setAsTab(board, fixtures);
    //         }
    //     },
    // },

    /**
     * Concurrency task to refresh route every 10 minutes
     *
     * @void
     */
    autoRefresh: (0, _emberConcurrency.task)(function* () {
      while (true) {
        yield (0, _emberConcurrency.timeout)(AUTO_REFRESH_MINUTES_INTERVAL * 60000);
        return this.refresh();
      }
    }).cancelOn('deactivate'),
    model: function ({
      slug
    }) {
      return this.store.queryRecord('board', {
        slug,
        single: true
      }).catch(this.invalidBoard.bind(this));
    },
    serializeQueryParam(value, urlKey, defaultValueType) {
      if (value === null || value === undefined || defaultValueType === 'array') {
        return value;
      }
      return `${value}`;
    },
    deserializeQueryParam(value, urlKey, defaultValueType) {
      if (value === null || value === undefined) {
        return value;
      } else if (defaultValueType === 'boolean') {
        return value === 'true';
      } else if (defaultValueType === 'number') {
        return Number(value).valueOf();
      } else if (defaultValueType === 'array') {
        return Array.from(value);
      }
      return value;
    },
    async afterModel(board, transition) {
      this.controllerFor('dashboard.boards.view').set('isLoadingFilters', false);
      // if no board send user back
      if (!board) {
        return this.invalidBoard();
      }
      // if free board redirect to free board route
      if (Ember.get(board, 'type') === 'free') {
        return this.transitionTo('dashboard.boards.free', board);
      }
      // get params
      const params = Ember.get(transition, 'to.queryParams');
      // if no sort param
      if (!params.sort) {
        Ember.set(params, 'sort', '-laycan_range_start');
      }
      // set context name
      const contextName = params._filter ? params._filter : Ember.get(board, 'name');
      // is this a filter?
      const isFilter = !Ember.isEmpty(params._filter);
      const isTab = !Ember.isEmpty(params._tab);
      const startingPage = params.page;
      const perPage = params.limit;
      // delete slug
      delete params.slug;
      delete params._filter;
      delete params.page;
      delete params.limit;
      // get board params
      const boardParams = Ember.get(board, 'params');
      // console.log(paramsToDelete);
      if (Ember.get(boardParams, 'before') === null && Ember.get(boardParams, 'after') === null) {
        delete boardParams.before;
        delete boardParams.after;
      }
      const mergedParams = Ember.assign(boardParams, params);
      const infinityModelParams = _objectSpread(_objectSpread({}, mergedParams), {}, {
        published: 1,
        perPage,
        startingPage,
        perPageParam: 'limit',
        pageParam: 'page',
        totalPagesParam: 'meta.last_page',
        countParam: 'meta.total',
        _board: Ember.get(board, 'id')
      });
      // console.log('#infinityModelParams', infinityModelParams);
      const fixtures = await this.infinity.model('fixture', infinityModelParams);
      // let fixtures = [];

      // // get fixtures
      // if (isTab) {
      //     let tab = this.sidebarTabs.getTab(params._tab);
      //     fixtures = await this.sidebarTabs.restoreInfinityFixtureFromTab(tab, infinityModelParams, this.infinity);
      // } else {
      //     fixtures = await this.infinity.model('fixture', infinityModelParams);
      // }

      // set fixtures to board/model
      Ember.set(board, 'fixtures', fixtures);

      // get report types
      const reportTypesStorageKey = `${this.currentUser.id.replace(/\-/g, '_')}_reportTypes`;
      let quickReportTypes = this.currentUser.options.get(reportTypesStorageKey);
      if (!quickReportTypes || quickReportTypes.length === 0) {
        quickReportTypes = await this.store.findAll('report-type');
        // mapping to convert from iterable class to array
        this.currentUser.options.set(reportTypesStorageKey, quickReportTypes.map(rt => rt));
      }

      // set report types
      Ember.set(board, 'quickReportTypes', quickReportTypes);

      // check if collaborator
      const collaboratorCanEdit = await this.checkIfUserCanEditBoard(board);

      // create as tab or update tab data
      // this.setAsTab(board, fixtures);

      // set context name
      transition.finally(() => {
        if (!this.controller) {
          return;
        }
        const {
          controller
        } = this;
        controller.set('contextName', contextName);
        controller.set('source', board);
        controller.set('isBoard', true);
        controller.set('isFilter', isFilter);
        controller.set('collaboratorCanEdit', collaboratorCanEdit);
        controller.set('isLoadingFilters', false);
        // update adapter
        this.currentUser.options.set('viewingBoard', Ember.get(board, 'id'));
      });
    },
    /**
     * After loading completed
     *
     * @void
     */
    onRouteLoaded({
      controller
    }) {
      if (controller.filterFixturesDropdownMenu && controller.filterFixturesDropdownMenu.actions) {
        controller.filterFixturesDropdownMenu.actions.close();
      }
    },
    /**
     * Checks to see if current user has permissions to edit board
     *
     * @void
     */
    async checkIfUserCanEditBoard(board) {
      const storagekey = `${Ember.get(board, 'id')}_${this.currentUser.id}_collaboratorCanEdit`;
      // check local storage first
      if (this.currentUser.options.get(storagekey)) {
        return this.currentUser.options.get(storagekey);
      }
      // check on server
      const collaboratorCanEdit = await this.fetch.get('actions/verify-can-edit-board', {
        board: Ember.get(board, 'id')
      }).then(r => r.can_edit);
      // save to session
      this.currentUser.options.set(storagekey, collaboratorCanEdit);
      return collaboratorCanEdit;
    },
    /**
     * Continue loading the infinity model until end
     *
     * @void
     */
    continueLoading: (0, _emberConcurrency.task)(function* (infinityModel) {
      yield (0, _emberConcurrency.timeout)(1500); // 1.5 second to breathe
      if (Ember.get(infinityModel, 'loadingMore')) {
        return this.continueLoading.perform(infinityModel);
      }
      return this.infinity.infinityLoad(infinityModel).then(infinityModel => {
        if (Ember.get(infinityModel, 'reachedInfinity') === false) {
          return this.continueLoading.perform(infinityModel);
        }
      });
    }).cancelOn('deactivate'),
    /**
     * In controller setup, add a ember-concurrency task to refresh the route
     *
     * @void
     */
    setupController(controller, model) {
      this._super(controller, model);
      this.autoRefresh.perform();
      this.startIntroduction(controller, model);
    },
    async startIntroduction(controller, model) {
      return this.tour.runStack(['viewBoard'], controller, model);
    },
    /**
     * Clear this boards session on deactivate
     *
     * @void
     */
    deactivate() {
      this.currentUser.options.set('viewingBoard', null);
    },
    /**
     * No user or company found, end session
     *
     * @void
     */
    invalidBoard() {
      return this.transitionTo('dashboard.boards.index');
    },
    setAsTab(board, fixtures) {
      const {
        meta
      } = fixtures;

      // serialize fixtures
      fixtures = fixtures.toArray().map(fixture => {
        if ((0, _isModel.default)(fixture)) {
          return fixture;
        }
        return fixture.serialize();
      });
      const tab = {
        id: board.id,
        title: board.name,
        action: 'openBoard',
        route: 'board.view',
        data: {
          board,
          fixtures,
          meta
        }
      };
      this.sidebarTabs.newTab(tab);
    }
  });
  _exports.default = _default;
});