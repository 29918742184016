define("@fixflo/frontend/models/vessel", ["exports", "@ember-data/model", "@fixflo/frontend/helpers/generate-relation-object", "moment"], function (_exports, _model, _generateRelationObject, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    uuid: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    ex_name: (0, _model.attr)('string'),
    fleet: (0, _model.attr)('string'),
    imo: (0, _model.attr)('string'),
    mmsi: (0, _model.attr)('string'),
    call_sign: (0, _model.attr)('string'),
    hull: (0, _model.attr)('string'),
    age: (0, _model.attr)('string'),
    flag: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    record_type: (0, _model.attr)('string'),
    division: (0, _model.attr)('string'),
    kind: (0, _model.attr)('string'),
    category: (0, _model.attr)('string'),
    class: (0, _model.attr)('string'),
    company: (0, _model.attr)('raw'),
    measurement: (0, _model.attr)('raw'),
    mechanical: (0, _model.attr)('raw'),
    history: (0, _model.attr)('raw'),
    scrapped: (0, _model.attr)('boolean'),
    total_loss: (0, _model.attr)('boolean'),
    cancelled: (0, _model.attr)('boolean'),
    active: (0, _model.attr)('boolean'),
    delivery_date: (0, _model.attr)('string'),
    slug: (0, _model.attr)('string'),
    deleted_at: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('string'),
    updated_at: (0, _model.attr)('string'),
    updatedAgo: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).fromNow();
    }),
    updatedAt: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).format('YYYY-MM-DD HH:mm A');
    }),
    createdAgo: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).fromNow();
    }),
    createdAt: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).format('YYYY-MM-DD HH:mm A');
    })
  });
  _exports.default = _default;
});