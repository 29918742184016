define("@fixflo/frontend/controllers/admin/panel/charterers/index", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = Ember.Controller.extend((_dec = Ember._action, (_obj = {
    actions: {
      /**
       * Create a new charterer
       */
      create: function () {
        const charterer = this.store.createRecord('charterer', {
          name: null
        });
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // render dialog
        this.dialog.open('charterer-form', charterer, {
          title: 'Create new charterer',
          acceptText: 'Save',
          addAlias: tag => Ember.get(charterer, 'aliases').pushObject(tag),
          removeAlias: i => Ember.get(charterer, 'aliases').removeAt(i),
          controller: this,
          accept: () => {
            charterer.save().then(charterer => {
              this.notifications.success('New charterer added');
              this.presenter._accept();
            });
          },
          decline: () => {
            charterer.destroyRecord();
            this.presenter._decline();
          }
        });
      },
      /**
       * Edit a charterer
       */
      edit: function (charterer) {
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // render dialog
        this.dialog.open('charterer-form', charterer, {
          title: Ember.String.htmlSafe(`Edit charterer <i>${Ember.get(charterer, 'name')}</i>`),
          acceptText: 'Save Changes',
          addAlias: tag => Ember.get(charterer, 'aliases').pushObject(tag),
          removeAlias: i => Ember.get(charterer, 'aliases').removeAt(i),
          controller: this,
          accept: () => {
            charterer.save().then(charterer => {
              this.notifications.success('Changes to charterer saved');
              this.presenter._accept();
            });
          }
        });
      },
      /**
       * Deletes a charterer
       */
      delete: function (charterer) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Delete Charterer',
          acceptText: 'Confirm and delete',
          message: `Are you sure you want to delete this charterer (${charterer.get('name')})?`
        }).then(() => {
          charterer.destroyRecord().then(() => {
            this.notifications.info('Charterer deleted.');
          }).catch(error => {
            this.notifications.serverError(error);
          });
        });
      },
      /**
       * Performs the selected bulk action
       */
      performBulkAction: function () {
        // get selected action
        const action = this.bulkAction;
        if (!action) {
          return this.notifications.warning('No action selected');
        }
        // get selected items
        const selected = this.model.filter(i => Ember.get(i, 'checked'));
        if (!selected.length) {
          return this.notifications.warning('No items selected');
        }
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // display dialogs based on action
        switch (action) {
          case 'edit':
            // values to apply to models
            const values = {};
            // display edit dialog
            this.dialog.open('bulk-edit-form', null, {
              title: 'Bulk edit charterers',
              selected,
              editable: ['fleet'],
              values,
              acceptText: 'Apply Changes',
              controller: this,
              accept: () => {
                // apply values to all selected
                selected.forEach(i => Ember.setProperties(i, values));
                // save the changes
                return Ember.RSVP.all(selected.invoke('save')).then(() => {
                  this.notifications.info(`${(0, _emberInflector.pluralize)(selected.length, 'item')} saved`);
                  this.presenter._accept();
                });
              }
            });
            break;
          case 'delete':
            this.dialog.open('bulk-delete-form', null, {
              title: 'Bulk delete charterers',
              selected,
              acceptText: 'Confirm & Delete',
              controller: this,
              accept: () => {
                return Ember.RSVP.all(selected.invoke('destroyRecord')).then(() => {
                  this.notifications.info(`${(0, _emberInflector.pluralize)(selected.length, 'item')} deleted`);
                  this.presenter._accept();
                });
              }
            });
            break;
        }
      }
    },
    /**
     * Query parameters
     */
    queryParams: ['page', 'limit', 'sort', 'query'],
    /**
     * Current page
     */
    page: 1,
    /**
     * Default pagination limit
     */
    limit: 30,
    /**
     * Default sorting
     */
    sort: '-created_at',
    /**
     * The bulk action to perform
     */
    bulkAction: null,
    /**
     * Determines if all items are checked
     */
    checkAll: false,
    toggleSort(sort) {
      const currentSort = this.sort;
      if (currentSort && currentSort.includes(sort) && currentSort.startsWith('-')) {
        this.setProperties({
          sort: currentSort.substr(1)
        });
      } else {
        this.setProperties({
          sort: `-${sort}`
        });
      }
    },
    /**
     * All selected vessels
     */
    selectedCharterers: Ember.computed('model.@each.checked', function () {
      return this.model.filter(v => v.checked);
    }),
    /**
     * Togggles all checked
     */
    // eslint-disable-next-line ember/no-observers
    toggleCheckAll: Ember.observer('checkAll', function () {
      if (this.checkAll) {
        this.model.forEach(i => Ember.set(i, 'checked', true));
      } else {
        this.model.forEach(i => Ember.set(i, 'checked', false));
      }
    })
  }, (_applyDecoratedDescriptor(_obj, "toggleSort", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleSort"), _obj)), _obj)));
  _exports.default = _default;
});