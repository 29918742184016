define("@fixflo/frontend/utils/array-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = arrayRange;
  function arrayRange(size, startAt = 0) {
    return [...Array(size + 1).keys()].map(i => i + startAt);
  }
});