define("@fixflo/frontend/templates/admin/panel/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NOxCxKpP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h5\",true],[8],[0,\"\\n    Welcome, \"],[1,[24,[\"currentUser\",\"user\",\"name\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/admin/panel/index.hbs"
    }
  });
  _exports.default = _default;
});