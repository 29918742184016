define("@fixflo/frontend/models/report", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    uuid: (0, _model.attr)('string'),
    report_type_uuid: (0, _model.attr)('string'),
    before: (0, _model.attr)('string'),
    after: (0, _model.attr)('string'),
    quantity: (0, _model.attr)('raw'),
    sort: (0, _model.attr)('string'),
    deleted_at: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('string'),
    updated_at: (0, _model.attr)('string'),
    useRelativeDate: Ember.computed('before', 'after', function () {
      return typeof this.before === 'string' && ['+', '-', 't', 'l', 'n', 'f', 's'].includes(this.before.substr(0, 1)) || typeof this.after === 'string' && ['+', '-', 't', 'l', 'n', 'f', 's'].includes(this.after.substr(0, 1));
    }),
    updatedAgo: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).fromNow();
    }),
    updatedAt: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).format('YYYY-MM-DD HH:mm A');
    }),
    createdAgo: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).fromNow();
    }),
    createdAt: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).format('YYYY-MM-DD HH:mm A');
    })
  });
  _exports.default = _default;
});