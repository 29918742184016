define("@fixflo/frontend/routes/dashboard/settings/cargo-grades", ["exports", "ember-cli-pagination/remote/route-mixin", "@fixflo/frontend/mixins/track-reloads"], function (_exports, _routeMixin, _trackReloads) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var _default = Ember.Route.extend(_routeMixin.default, _trackReloads.default, {
    queryParams: {
      query: {
        refreshModel: true
      },
      perPage: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    model: function (params) {
      params.paramMapping = {
        perPage: 'limit',
        total_pages: 'last_page'
      };
      return this.findPaged('cargo-grade', _objectSpread(_objectSpread({}, params), {}, {
        internal: true
      }));
    }
  });
  _exports.default = _default;
});