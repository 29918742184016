define("@fixflo/frontend/helpers/confidentiality-level-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function confidentialityLevelDisplay([value]) {
    const confidentialityLevels = {
      public: 'Public',
      private: 'Private & Confidential',
      super_private: 'Super Private & Confidential'
    };
    if (typeof value !== 'string') {
      return '';
    }
    return Ember.get(confidentialityLevels, Ember.String.underscore(value)) || value;
  });
  _exports.default = _default;
});