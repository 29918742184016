define("@fixflo/frontend/initializers/inject-core-services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  function initialize(application) {
    const services = ['ajax', 'fetch', 'auth', 'dialog', 'can', 'notifications', 'layout', 'infinity', 'session', 'current-user', 'crud'];
    services.forEach(service => {
      application.inject('controller', service, `service:${service}`);
      application.inject('route', service, `service:${service}`);
    });
  }
  var _default = {
    initialize
  };
  _exports.default = _default;
});