define("@fixflo/frontend/controllers/dashboard/settings/roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      /**
       * Forwards and handles actions from table/action components
       */
      tableAction: function (action, model) {
        this.send(action, model);
      },
      /**
       * Sorts the current data listed
       */
      tableSort: function (sorts) {
        // TODO
      },
      /**
       * Create a new role
       */
      create: async function () {
        const role = this.store.createRecord('role', {
          company_uuid: this.currentUser.companyId,
          name: null
        });
        // load permissions
        const permissions = await this.store.findAll('permission');
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // render dialog
        this.dialog.open('role-form', role, {
          title: 'Create a new role',
          className: 'dialog-wider',
          acceptText: 'Save',
          controller: this,
          permissions,
          accept: () => {
            this.presenter.startLoading();
            role.save().then(role => {
              const rolePermissions = [];
              // next save permissions for role
              permissions.forEach(p => {
                rolePermissions.pushObject(this.store.createRecord('role-permission', {
                  role_uuid: Ember.get(role, 'id'),
                  permission_uuid: Ember.get(p, 'id'),
                  create: Ember.get(p, 'create'),
                  read: Ember.get(p, 'read'),
                  update: Ember.get(p, 'update'),
                  delete: Ember.get(p, 'delete'),
                  can: Ember.get(p, 'can')
                }));
              });
              // save role permissions
              return Ember.RSVP.all(rolePermissions.invoke('save')).then(() => {
                this.presenter.stopLoading();
                this.notifications.success('New role added');
                this.presenter._accept();
              }).catch(error => {
                this.presenter.stopLoading();
                this.notifications.serverError(error);
              });
            });
          },
          decline: () => {
            role.destroyRecord();
            this.presenter._decline();
          }
        });
      },
      /**
       * Edit a role
       */
      edit: async function (role) {
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // load permissions
        const rolePermissions = await this.store.query('role-permission', {
          role_uuid: Ember.get(role, 'id')
        });
        var permissions = rolePermissions.toArray();
        // add in role-permissions for missing permissions
        const sysPermissions = await this.store.findAll('permission');
        sysPermissions.forEach(systemPermission => {
          if (!permissions.some(p => Ember.get(p, 'permission_uuid') === Ember.get(systemPermission, 'id'))) {
            // add new role
            let rolePermission = this.store.createRecord('role-permission', {
              id: null,
              role_uuid: Ember.get(role, 'id'),
              permission_uuid: Ember.get(systemPermission, 'id'),
              subject: Ember.get(systemPermission, 'subject'),
              create: false,
              read: false,
              update: false,
              delete: false,
              can: false
            });
            // create rp and add
            permissions.pushObject(rolePermission);
          }
        });
        // render dialog
        this.dialog.open('role-form', role, {
          title: Ember.String.htmlSafe(`Edit <i>${Ember.get(role, 'name')}</i> role`),
          className: 'dialog-wider',
          acceptText: 'Save Changes',
          controller: this,
          permissions,
          accept: () => {
            this.presenter.startLoading();
            role.save().then(role => {
              // convert permissions back to reccords
              permissions = permissions.map(p => {
                if (Ember.get(p, 'id')) {
                  return this.store.push(this.store.normalize('role-permission', p));
                }
                return this.store.createRecord('role-permission', p);
              });
              // save role permissions
              return Ember.RSVP.all(permissions.invoke('save')).then(() => {
                this.presenter.stopLoading();
                this.notifications.success('Changes to role saved');
                this.presenter._accept();
              }).catch(error => {
                this.presenter.stopLoading();
                this.notifications.serverError(error);
              });
            });
          }
        });
      },
      /**
       * Delete a role
       */
      delete: function (role) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Delete role',
          acceptText: 'Confirm and delete',
          message: Ember.String.htmlSafe(`Are you sure you want to delete this role <i>${Ember.get(role, 'name')}</i>?`)
        }).then(() => {
          role.destroyRecord().then(() => {
            this.notifications.info('Role deleted');
          }).catch(error => {
            this.notifications.serverError(error);
          });
        });
      }
    },
    currentUser: Ember.inject.service(),
    /**
     * Query parameters
     */
    queryParams: ['page', 'limit', 'sort', 'query', 'role'],
    /**
     * Current page
     */
    page: 1,
    /**
     * Default pagination limit
     */
    limit: 10,
    /**
     * Default sorting
     */
    sort: 'created_at',
    /**
     * Columns to display in table
     * @type array
     */
    columns: Ember.A([{
      name: `Name`,
      valuePath: `name`
    }, {
      name: `Updated`,
      valuePath: `updatedAt`
    }, {
      name: `Created`,
      valuePath: `createdAt`
    }, {
      name: ``,
      menuItems: [{
        action: 'edit',
        icon: 'icon-content_edit',
        can: 'edit role',
        label: 'Edit Role'
      }, {
        action: 'delete',
        icon: 'icon-navigation_delete',
        can: 'delete role',
        label: 'Delete Role'
      }]
    }])
  });
  _exports.default = _default;
});