define("@fixflo/frontend/utils/boundary-to-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = boundaryToArray;
  function boundaryToArray(boundary) {
    if (!boundary || Ember.typeOf(boundary) !== 'string') {
      return false;
    }
    let coordinates = boundary.match(/\((.*?)\)/gim);
    return coordinates.map(c => {
      let coords = c.replace(/\(|\)/, '').split(',');
      return {
        lat: parseFloat(coords[0]),
        lng: parseFloat(coords[1])
      };
    });
  }
});