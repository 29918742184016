define("@fixflo/frontend/templates/dialog/fixtures-import-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "uxLPxA/i",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n\\t\"],[7,\"label\",true],[10,\"class\",\"col-form-label required\"],[8],[0,\"Name\"],[9],[0,\"\\n\\t\"],[5,\"fix-flo/input\",[[12,\"placeholder\",\"File Name\"]],[[\"@type\",\"@value\",\"@isInvalid\"],[\"text\",[24,[\"contextObject\",\"name\"]],[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[24,[\"contextObject\"]],\"validations\"],null],\"attrs\"],null],\"name\"],null],\"isInvalid\"],null]]]],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"invalid-feedback animated fadeIn\"],[8],[1,[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[24,[\"contextObject\"]],\"validations\"],null],\"attrs\"],null],\"name\"],null],\"message\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n\\t\"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"Status\"],[9],[0,\"\\n\\t\"],[5,\"fix-flo/input\",[[12,\"placeholder\",\"Status\"]],[[\"@type\",\"@value\",\"@isInvalid\"],[\"text\",[24,[\"contextObject\",\"status\"]],[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[24,[\"contextObject\"]],\"validations\"],null],\"attrs\"],null],\"status\"],null],\"isInvalid\"],null]]]],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"invalid-feedback animated fadeIn\"],[8],[1,[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[24,[\"contextObject\"]],\"validations\"],null],\"attrs\"],null],\"status\"],null],\"message\"],null],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/fixtures-import-form.hbs"
    }
  });
  _exports.default = _default;
});