define("@fixflo/frontend/templates/components/date-range-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "luzSNi0W",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/components/date-range-picker.hbs"
    }
  });
  _exports.default = _default;
});