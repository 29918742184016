define("@fixflo/frontend/templates/components/page-loading-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "f0NZw73u",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixflo-loading-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex items-center justify-center\"],[8],[0,\"\\n        \"],[5,\"fix-flo/spinner\",[[12,\"class\",\"blue-ring\"]],[[],[]]],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"fixflo-loading-message\"],[8],[0,\"\\n            \"],[1,[22,\"message\"],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/components/page-loading-indicator.hbs"
    }
  });
  _exports.default = _default;
});