define("@fixflo/frontend/components/fixtures-table", ["exports", "@fleetbase/fixture-extractor", "handsontable", "@fixflo/frontend/mixins/model-creator", "howler", "@fixflo/frontend/helpers/dot-underscore", "ember-concurrency", "ember-cli-string-helpers/helpers/humanize", "@fixflo/frontend/utils/array-range", "@fixflo/frontend/utils/create-report-filters-object", "@fixflo/frontend/utils/laycan-to-date", "@fixflo/frontend/utils/group-consecutive", "moment", "ember-get-config"], function (_exports, _fixtureExtractor, _handsontable, _modelCreator, _howler, _dotUnderscore, _emberConcurrency, _humanize, _arrayRange, _createReportFiltersObject, _laycanToDate, _groupConsecutive, _moment, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const getSelectedColumnHeader = handsontable => {
    let column = handsontable.getSelectedLast();
    let headers = handsontable.getColHeader();
    return headers[column[1]];
  };
  const notColumnOf = (handsontable, columns) => {
    let header = getSelectedColumnHeader(handsontable);
    return !columns.includes(header);
  };
  const isColumnOf = (handsontable, columns) => {
    let header = getSelectedColumnHeader(handsontable);
    return columns.includes(header);
  };
  const selectedRowHeader = handsontable => {
    const selectedRange = handsontable.getSelectedRange();
    return selectedRange[0].from.col < selectedRange[0].to.col;
  };
  const hasMultipleZoneOptions = handsontable => {
    const lastSelected = handsontable.getSelectedLast();
    const row = lastSelected[0];
    const col = lastSelected[1];
    const cellMeta = handsontable.getCellMeta(row, col);
    if (cellMeta.prop === 'discharge.value' && cellMeta.has_discharge_options || cellMeta.prop === 'load.value' && cellMeta.has_load_options) {
      return true;
    }
    return false;
  };
  const isDOMElement = obj => {
    try {
      //Using W3 DOM2 (works for FF, Opera and Chrome)
      return obj instanceof HTMLElement;
    } catch (e) {
      //Browsers not supporting W3 DOM2 don't have HTMLElement and
      //an exception is thrown and we end up here. Testing some
      //properties that all elements have (works on IE7)
      return typeof obj === 'object' && obj.nodeType === 1 && typeof obj.style === 'object' && typeof obj.ownerDocument === 'object';
    }
  };
  const cacheVersion = '20200717';
  var _default = Ember.Component.extend(_modelCreator.default, {
    actions: {
      /**
       * Allow an impoRt from raw text
       */
      importRawText: function () {
        const handsontable = this.handsontable;
        const input = document.querySelector('#fixtureInput[contenteditable]');
        const extractor = new _fixtureExtractor.default(input);
        // set dialog presenter
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // open the dialog presenterr
        this.dialog.open('fixtures-raw-text-form', null, {
          title: 'Import from raw text',
          acceptText: 'Process',
          acceptIcon: 'fa fa-magic',
          className: 'lg-w-dialog',
          accept: () => {
            // flag as extraction as running
            Ember.set(this, 'isExtracting', true);
            if (this.onExtractionStart) {
              this.onExtractionStart();
            }
            // start extraction
            // give extractor 100ms to flag states
            setTimeout(() => {
              extractor.parse().then(output => {
                handsontable.loadData(output);
                // notify done
                this.presenter._accept();
                this.notifications.success('(' + output.length + ') Fixtures inserted.');
                // set flags to completed
                Ember.set(this, 'isExtracting', false);
                if (this.onExtractionEnd) {
                  this.onExtractionEnd();
                }
              });
            }, 100);
          }
        });
      },
      /**
       * Manually process raw text
       */
      processRawText: function (input, callback = null) {
        const handsontable = this.handsontable;
        const extractor = new _fixtureExtractor.default(input);
        // flag extractor as running
        Ember.set(this, 'isExtracting', true);
        if (this.onExtractionStart) {
          this.onExtractionStart();
        }
        // start extraction
        // give 100ms to flag state
        setTimeout(() => {
          extractor.parse().then(output => {
            handsontable.loadData(output);
            // notify done
            this.notifications.success('(' + output.length + ') Fixtures inserted.');
            // set flags to completed
            Ember.set(this, 'isExtracting', false);
            if (typeof this.onExtractionEnd === 'function') {
              this.onExtractionEnd(output);
            }
            if (typeof callback === 'function') {
              callback(output);
            }
          });
        }, 100);
      },
      /**
       * Insert a row above
       */
      insertRow: function (direction) {
        const hot = this.handsontable;
        hot.alter('insert_row', hot.getData().length - (direction === 'above' ? 1 : 0));
      },
      /**
       * Insert a row above
       */
      insertRowTop: async function () {
        this.send('insertAtRow', 0);
      },
      /**
       * Insert a row above
       */
      insertAtRow: async function (row = 0) {
        this.set('isLoading', true);
        this.triggerAction('onLoadingStarted', 'insertRowTop');
        // const data = this.handsontable.getSourceData();
        const columnHeaders = this.handsontable.getColHeader();
        const checkboxIndex = columnHeaders.indexOf(c => c.includes('checkbox'));
        let setId = Ember.get(this, 'source._internalModel.modelName') === 'fixture-set' ? Ember.get(this, 'source.id') : null;
        let fixture = await this.fetch.post('actions/create-fixture', {
          data: {
            key: Ember.String.underscore(columnHeaders[1]),
            value: null,
            set_uuid: setId
          }
        });
        // insert row
        this.handsontable.alter('insert_row', row);
        // insert fixture into store
        fixture = this.store.push(this.store.normalize('fixture', fixture));
        // update row meta
        let data = this.data;
        data = data.replace(row, 1, [Ember.get(fixture, 'asTableData')]);
        // this.handsontable.setDataAtCell(0, checkboxIndex, 0, 'insertRowTop');
        this.handsontable.render();
        this.set('isLoading', false);
        this.triggerAction('onLoadingEnded', 'insertRowTop');
      },
      /**
       * Undo an action
       */
      undo: function () {
        if (this.handsontable.isUndoAvailable()) {
          this.handsontable.undo();
        }
      },
      /**
       * Redo an action
       */
      redo: function () {
        if (this.handsontable.isRedoAvailable()) {
          this.handsontable.redo();
        }
      },
      /**
       * Exports data in table as csv
       */
      exportAsCsv: function () {
        const exportPlugin = this.handsontable.getPlugin('exportFile');
        const delimiterOptions = [{
          label: 'Comma',
          value: ','
        }, {
          label: 'Tab',
          value: '\t'
        }, {
          label: 'Semicolon',
          value: ';'
        }, {
          label: 'Space',
          value: ' '
        }];
        const options = {
          filename: (0, _dotUnderscore.dotUnderscore)([Ember.get(this, 'source.name')]),
          columnHeaders: true,
          columnDelimiter: delimiterOptions[0]
        };
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        this.dialog.open('fixtures-export-form', options, {
          title: 'Export fixtures',
          acceptText: 'Export',
          acceptIcon: 'fa fa-check',
          delimiterOptions,
          accept: () => {
            this.presenter._accept();
            Ember.set(options, 'columnDelimiter', options.columnDelimiter.value);
            exportPlugin.downloadFile('csv', options);
          }
        });
      },
      /**
       * Set the handsontable instance
       */
      viewVessel: async function (fixture) {
        const id = Ember.get(fixture, 'vessel.relation_uuid') || Ember.get(fixture, 'vessel.relation.uuid');
        const name = Ember.get(fixture, 'vessel.display_entry') || Ember.get(fixture, 'vessel.entry');
        const vessel = id ? await this.store.findRecord('vessel', id) : await this.store.queryRecord('vessel', {
          name,
          single: true
        });
        // if still not found notify user
        if (!vessel) {
          return this.notifications.warning('No vessel info found');
        }
        // display vessel info
        return this.dialog.open('vessel-details', null, {
          title: Ember.get(vessel, 'name'),
          vessel,
          acceptText: 'Done'
        });
      },
      /**
       * Set zone boundary
       */
      setZoneBoundary: function (zone, {
        target
      }) {
        const map = target;
        // if zone has a boundary draw it to map
        if (Ember.get(zone, 'boundaries') && Ember.get(zone, 'type') === 'zone') {
          // display each boundary
          Ember.get(zone, 'boundaries').forEach(boundary => {
            const polygon = L.polygon(Ember.get(boundary, 'coordinates'), {
              color: '#48BB78'
            }).addTo(map);
            // set map to polygon
            map.fitBounds(polygon.getBounds());
          });
        }
      },
      /**
       * Set the handsontable instance
       */
      viewPort: async function (fxt, column) {
        column = column.toLowerCase();
        // get port id from load port on fxt
        let portId = Ember.get(fxt, `${column}.relation.uuid`);
        // if no port id load from server
        if (!portId) {
          // load the fixture fist
          const fixture = await this.store.findRecord('fixture', fxt[column].fixture_uuid);
          // load the port
          portId = fixture.get(`${column}_port_uuid`);
          // if no port id from loaded fixture check current fxt instance
          if (!portId) {
            portId = Ember.get(fxt, `${column}.relation_uuid`);
          }
        }
        // if absolutely no port id found return warning
        if (!portId) {
          return this.notifications.warning(`No ${column} port info found`);
        }
        const port = await this.store.findRecord('zone', portId);
        // display port info
        this.dialog.open('zone-details', null, {
          title: port.get('name'),
          controller: this,
          acceptText: 'Done',
          port
        });
      },
      /**
       * View a loaded zone
       */
      viewLoadedZone: async function (port) {
        const portId = Ember.get(port, 'relation_uuid');
        const zone = await this.store.findRecord('zone', portId);
        // display port info
        this.dialog.open('zone-details', null, {
          title: zone.get('name'),
          controller: this,
          acceptText: 'Done',
          port: zone,
          rate: Ember.get(port, 'rate')
        });
      },
      /**
       * View a loaded zone
       */
      addNewZone: function ({
        fixture_uuid
      }, property) {
        const handsontable = this.handsontable;
        const fixtureValue = this.store.createRecord('fixture-value', {
          fixture_uuid,
          key: property,
          label: (0, _humanize.humanize)([property]),
          rate: 'RNR'
        });
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        this.dialog.open('fixtures-add-zone-form', fixtureValue, {
          title: `Add a new ${property} port`,
          acceptText: 'Confirm & Save',
          accept: () => {
            // set loading state
            this.presenter.startLoading();
            // save fixture with new zone
            return fixtureValue.save().then(() => {
              // end loading state
              this.presenter.stopLoading();
              // close dialog
              this.presenter._accept();
              // reload table data
              this.sendAction('reloadData');
            });
          }
        });
      },
      // /**
      //  * View activity for a specific fixture
      //  */
      // viewFixtureActivity: async function(selection) {
      //     // get the fixture for the selected row
      //     const fixture = this.getFixtureFromRowIndex(selection[0].start.row);
      //     // load activity for the fixture
      //     const activities = await this.store.query('activity', {
      //         subject_id: get(fixture, 'id'),
      //         log_name: 'fixture',
      //     });
      //     // display activity
      //     this.dialog.open('view-fixture-activity', null, {
      //         title: 'Fixture activity',
      //         activities,
      //         acceptText: 'Done',
      //     });
      // },

      /**
       * Customize users default columns
       */
      customizeColumns: async function () {
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // get handsontable
        const handsontable = this.handsontable;
        // localstorage key
        const storageKey = `${Ember.get(this, 'source.id')}_${Ember.get(this, 'currentUser.id').replace(/\-/g, '_')}_boardColumns`;
        const storageKeyVersion = `${storageKey}_version`;
        // columns
        const columns = await this.fetch.request('actions/get-fixture-columns', {
          data: {
            all: true
          }
        }).then(columns => columns);
        // create context object
        const contextObject = {
          selected: columns.filter(column => this.columns.map(c => Ember.get(c, 'data')).includes(Ember.get(column, 'data')))
        };
        // console.log(columns);
        this.dialog.open('customize-columns', contextObject, {
          title: 'Customize Columns',
          className: 'dialog-xl',
          columns,
          selectColumn: (value, event) => {
            let columnId = Ember.get(event, 'originalEvent.toElement.id');
            let column = columns.find(c => Ember.get(c, 'label').underscore() === columnId);
            if (contextObject.selected.includes(column)) {
              contextObject.selected.removeObject(column);
            } else {
              contextObject.selected.addObject(column);
            }
          },
          acceptText: 'Done',
          accept: () => {
            // set loading state
            this.presenter.startLoading();
            // save column selection state
            return this.fetch.put('column-states', {
              data: {
                columnState: {
                  source_uuid: Ember.get(this, 'source.id'),
                  source: Ember.get(this, 'source._internalModel.modelName'),
                  state: contextObject.selected
                }
              },
              headers: {
                'X-Update-Option': 'UpdateOrCreate'
              }
            }).then(response => {
              // end loading state
              this.presenter.stopLoading();
              // get new column state
              const columnState = Ember.get(response, 'columnState.state');
              // update columns
              const columns = columnState.map(this.buildColumn.bind(this));
              const colHeaders = columns.map(c => Ember.get(c, 'label'));
              const viewportColumnRenderingOffset = colHeaders.length;
              // set to local storage
              this.currentUser.options.set(storageKey, columnState);
              this.currentUser.options.set(storageKeyVersion, cacheVersion);
              // set to controller
              Ember.setProperties(this, {
                columns,
                colHeaders,
                columnState
              });
              // update handsontable
              handsontable.updateSettings({
                columns,
                colHeaders,
                viewportColumnRenderingOffset
              });
              // done
              return this.presenter._accept();
            });
          }
        });
      },
      /**
       * Delete fixtures based on selection
       */
      deleteFixtures: function (selection) {
        // get hands on table instance
        const handsontable = this.handsontable;
        // delete queue
        const queue = [];
        // prompt dialog
        return this.dialog._confirm({
          title: 'Delete fixtures',
          acceptText: 'Confirm and delete',
          message: `Are you sure you want to delete these selected fixtures?`
        }).then(() => {
          selection.forEach(selected => {
            const rows = (0, _arrayRange.default)(selected.end.row - selected.start.row, selected.start.row);
            // iterate on each row index
            rows.forEach(row => {
              // get the fixture
              const fixture = this.getFixtureFromRowIndex(row);
              if (!fixture) {
                return;
              }
              // get fixture id
              const id = Ember.get(fixture, 'id');
              // save fixture to request queue
              queue.pushObject(id);
            });
            // now remove the rows via alter_table
            rows.forEach(row => {
              // remove row
              handsontable.alter('remove_row', row);
            });
          });
          // send bulk delete request
          return this.fetch.delete('actions/bulk-delete-fixtures', {
            data: {
              queue
            }
          });
        });
      },
      /**
       * Trigger a dialogg to edit the fixture rate/zone
       */
      rateZoneManager: async function (coords, column) {
        // dont aauto set cell meta
        Ember.set(this, 'skipSetCellMeta', true);
        // get required things
        var data = this.data;
        const fixture = data[coords.row];
        // console.log(fixture);
        const cellMeta = this.handsontable.getCellMeta(coords.row, coords.col);
        // get the actual load/discharge value
        const currentValue = Ember.get(fixture, column);
        const currentFixtureValue = this.store.push(this.store.normalize('fixture-value', currentValue));
        // get load options
        const load_options = Object.values(cellMeta.load_options);
        // get discharge_options
        const discharge_options = Object.values(cellMeta.discharge_options);
        // console.log(discharge_options);
        // bind options to object
        const allOptions = {
          discharge_options,
          load_options
        };
        //
        // MAKE SURE THE DISCHARGE/LOAD FIRST OPTIONS IS === TO THE VALUE - IF ONLY ONE OPTION
        //
        if (currentValue && Ember.get(allOptions, `${column}_options.length`) === 1 && Ember.get(allOptions, `${column}_options.0.relation_uuid`) !== Ember.get(currentValue, 'relation_uuid')) {
          // convert discharge option to fixture value and remove from array by setting length to 0
          const optionToRemove = this.store.push(this.store.normalize('fixture-value', Ember.get(allOptions, `${column}_options.0`)));
          optionToRemove.destroyRecord();
          Ember.set(allOptions, `${column}_options.length`, 0);
        }
        //
        // if rate column route to load or dicharge
        if (column === 'rate') {
          if (load_options.length > 1) {
            return this.send('rateZoneManager', coords, 'load');
          }
          return this.send('rateZoneManager', coords, 'discharge');
        }
        // let dialog know if options are being loaded
        let optionsLoading = false;
        // get initial entry
        const entry = Ember.get(fixture, `${column}.relation.system_display_name`) || Ember.get(fixture, `${column}.entry`) || Ember.get(fixture, `${column}.value`);
        // if no load options, create the first based on the fixture default load
        if (load_options.length === 0 && column === 'load' && entry) {
          optionsLoading = true;
          this.fetch.post('fixture-values', {
            data: {
              fixtureValue: {
                fixture_uuid: Ember.get(fixture, 'fixture_uuid'),
                relation_uuid: Ember.get(fixture, 'load.relation_uuid'),
                key: Ember.get(fixture, 'load.key'),
                label: (0, _humanize.humanize)([Ember.get(fixture, 'load.key')]),
                entry
              }
            }
          }).then(response => {
            load_options.pushObject(Ember.get(response, 'fixtureValue'));
            // update fixtue meta for discharge options
            this.setRowData(coords.row, 'load_options', load_options);
          });
        }
        // if no discharge options, create the first based on the fixture default discharge
        if (discharge_options.length === 0 && column === 'discharge' && entry) {
          optionsLoading = true;
          this.fetch.post('fixture-values', {
            data: {
              fixtureValue: {
                fixture_uuid: Ember.get(fixture, 'fixture_uuid'),
                relation_uuid: Ember.get(fixture, 'discharge.relation_uuid'),
                key: Ember.get(fixture, 'discharge.key'),
                label: (0, _humanize.humanize)([Ember.get(fixture, 'discharge.key')]),
                entry,
                rate: Ember.get(fixture, 'rate.entry')
              }
            }
          }).then(response => {
            discharge_options.pushObject(Ember.get(response, 'fixtureValue'));
            // update fixture meta for discharge options
            this.setRowData(coords.row, 'discharge_options', discharge_options);
          });
        }
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        this.dialog.open('fixtures-rate-zone-manager', null, {
          title: 'Edit rate & zones for fixture',
          acceptText: 'Done',
          className: 'dialog-xl',
          controller: this,
          coords,
          fixture,
          load_options,
          discharge_options,
          optionsLoading,
          column,
          accept: () => {
            // set loading state
            this.presenter.startLoading();
            // append load/discharge options to object
            const options = {
              load_options,
              discharge_options
            };
            // array to contain fixture values to save before saving set
            const saveQueue = [];
            //
            // IF ONLY 1 OPTION REMAIANING AND !== TO THE CURRENT VALUE, OVERWRITE CURRENT VALUE WITH LAST OPTION
            if (Ember.get(options, `${column}_options.length`) === 1 && Ember.get(options, `${column}_options.0.relation_uuid`) !== Ember.get(currentValue, 'relation_uuid')) {
              // console.log('THIS LAST OPTION SHOULD OVERWRITE THE CURRENT OPTION HERE!');
              // get the last option
              const lastOption = Ember.get(options, `${column}_options.0`);
              // update the fixture load_port_uuid to the last option
              this.fetch.put(`fixtures/${fixture.fixture_uuid}`, {
                data: {
                  [`${column}_port_uuid`]: Ember.get(lastOption, 'relation_uuid')
                }
              });
              // update the currentValue relation_uuid to last option
              currentFixtureValue.setProperties({
                relation_uuid: Ember.get(lastOption, 'relation_uuid'),
                entry: `${Ember.get(lastOption, 'relation.system_display_name') || Ember.get(lastOption, 'relation.name')}`
              });
              currentFixtureValue.save();
            }
            //
            // save relation rates if any
            // ['load_options', 'discharge_options'].forEach((prop) => {
            options[`${column}_options`].forEach((zone, index) => {
              if (Ember.get(zone, 'rate_binded_to') && index % 2 !== 0) {
                let bindedTo = options[`${column}_options`].find(o => Ember.get(o, 'uuid') === Ember.get(zone, 'rate_binded_to'));
                if (bindedTo) {
                  // set rate binded to
                  Ember.set(zone, 'rate', Ember.get(bindedTo, 'rate'));
                }
              }
              // convert zone to fixture value
              const fixtureValue = this.store.push(this.store.normalize('fixture-value', zone));
              // save the pos
              fixtureValue.set('pos', index);
              // push to saave queue
              saveQueue.pushObject(fixtureValue);
            });
            // });
            // if nothing to save still reload table
            if (saveQueue.length === 0) {
              this.presenter._accept();
            }
            // save fixture values pending
            return Ember.RSVP.all(saveQueue.invoke('save')).then(response => {
              // convert response into the display entry
              const displayEntry = Array.from(response).map(fixtureValue => {
                if (!Ember.get(fixtureValue, 'relation')) return null;
                return `${Ember.get(fixtureValue, 'relation.system_display_name') || Ember.get(fixtureValue, 'relation.name')}${Ember.get(fixtureValue, 'rate_bind_primary') ? '+' : '-'}`;
              }).join('').slice(0, -1);
              // convert response into the rate display entry
              const rateDisplayEntry = Array.from(response).map(fixtureValue => ({
                label: `${Ember.get(fixtureValue, 'rate')}${Ember.get(fixtureValue, 'rate_bind_primary') ? '+' : '-'}`.toUpperCase(),
                rate: Ember.get(fixtureValue, 'rate')
              })).uniqBy('rate').map(r => r.label).join('').slice(0, -1);
              // serialize options
              const options = Array.from(response).map(fixtureValue => fixtureValue.serialize());
              // console.log(options);
              // update data
              Ember.setProperties(fixture, {
                [`${column}.entry`]: displayEntry,
                [`${column}.display_entry`]: displayEntry,
                [`${column}.value`]: displayEntry,
                [`${column}.relation_uuid`]: Ember.get(currentFixtureValue, 'relation_uuid'),
                [`${column}.relation.uuid`]: Ember.get(currentFixtureValue, 'relation_uuid'),
                [`${column}_options`]: options,
                [`has_${column}_options`]: options.length > 1 ? true : false,
                [`rate.value`]: rateDisplayEntry
              });
              // console.log(fixture);
              // let data = get(this, 'data');
              data = data.replace(coords.row, 1, [fixture]);
              // render table
              this.handsontable.render();
              // end loading state
              this.presenter.stopLoading();
              // close dialog
              this.presenter._accept();
            });
          },
          decline: () => {
            this.presenter._decline();
          }
        });
      },
      /**
       * Bind the relation rate
       */
      bindRelationRate: function (fixture, zone, column, index, options, selected) {
        // console.log('[bindRelationRate]', fixture, zone, column, index, options, selected);
        // make sure the next value option exists
        if (options.length < index + 1) {
          return this.notifications.warning(`No other ${column} to join rates with`);
        }
        // get the next value from index
        const nextValue = options[index + 1];
        const nextValueIsLast = Ember.isBlank(options[index + 2]);
        const nextValueIsBinded = Ember.get(zone, 'uuid') === Ember.get(nextValue, 'rate_binded_to');
        const nextValueIsNotBinded = Ember.isBlank(Ember.get(nextValue, 'rate_binded_to'));
        // if next value is not set, notify with error
        if (!nextValue) {
          return this.notifications.warning(`Unable to join rates without setting the next ${column} option`);
        }
        // depending on option we either join or disjoin the rates
        switch (selected) {
          case '+':
            // join/bind the rates
            Ember.setProperties(zone, {
              rate_binded_to: Ember.get(nextValue, 'uuid'),
              rate_bind_primary: true
            });
            Ember.setProperties(nextValue, {
              rate_binded_to: Ember.get(zone, 'uuid'),
              rate: Ember.get(zone, 'rate'),
              rate_bind_primary: false
            });
            break;
          case '-':
            // console.log(zone, nextValue);
            // unbind both zones from each other
            // disjoin the rates
            Ember.setProperties(zone, {
              rate_binded_to: null,
              rate_bind_primary: false
            });
            // dont unbind the next value if the next value has a binding from another zone
            if (nextValueIsBinded || nextValueIsLast || nextValueIsNotBinded) {
              Ember.setProperties(nextValue, {
                rate_binded_to: null,
                rate_bind_primary: false
              });
            }
            break;
        }
      },
      /**
       * Create a new relation value
       */
      addRelation: function (fixture, column, options, coords) {
        // console.log('[createNewRelation]', fixture, column, options);
        // set dialog to custom variable
        this.dialog.one('created', presenter => {
          Ember.set(this, 'zone_finder_presenter', presenter);
        });
        // set the pos
        const pos = options.length + 1;
        // create a context
        const selected = {
          zone: null
        };
        // prompt for user to find zone to add as relation
        this.dialog.open('zone-finder-select', selected, {
          title: 'Find and select port',
          acceptText: 'Select port',
          controller: this,
          accept: () => {
            const {
              zone
            } = selected;
            // console.log('[selected port]', zone);
            // create a new fixture value for the property
            this.fetch.post('fixture-values', {
              data: {
                fixtureValue: {
                  fixture_uuid: Ember.get(fixture, 'fixture_uuid'),
                  relation_uuid: Ember.get(zone, 'uuid'),
                  key: column,
                  pos,
                  label: (0, _humanize.humanize)([column]),
                  entry: Ember.get(zone, 'system_display_name'),
                  rate: 'RNR'
                }
              }
            }).then(response => {
              // append new relation/fv to options
              options.pushObject(Ember.get(response, 'fixtureValue'));
              this.zone_finder_presenter._accept();
              // update meta data
              this.setRowData(coords.row, `${column}_options`, options);
            });
          }
        });
      },
      /**
       * Delete a relation value
       */
      deleteRelation: function (fixture, zone, column, options, coords) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Remove port',
          acceptText: 'Confirm and remove',
          message: `Are you sure you want to remove the '${Ember.get(zone, 'relation.system_display_name')}' port?`
        }).then(() => {
          const handsontable = this.handsontable;
          // convert zone to fixturee value model and destroy record
          const fixtureValue = this.store.push(this.store.normalize('fixture-value', zone));
          // start loading
          this.dialog.startLoading();
          // delete fixure value, then remove from options
          fixtureValue.destroyRecord().then(() => {
            options.removeObject(zone);
            if (options.length === 0) {
              this.dialog.stopLoading();
              Ember.set(this, 'skipSetCellMeta', false);
              this.presenter._decline();
              // reload table
              this.sendAction('reloadData');
            }
            this.setRowData(coords.row, `${column}_options`, options);
          }).catch(error => {
            this.dialog.stopLoading();
            this.notifications.serverError(error);
          });
        });
      },
      /**
       * Generate a report from selection
       */
      generateReport: function (selection) {
        const fixtures = [];
        // get all fixtures deom selection
        selection.forEach(selected => {
          const rows = (0, _arrayRange.default)(selected.end.row - selected.start.row, selected.start.row);
          // iterate on each row index
          rows.forEach(row => {
            // get the fixture
            const fixture = this.getFixtureFromRowIndex(row);
            if (!fixture) {
              return;
            }
            // save fixture to request queue
            fixtures.pushObject(fixture);
          });
        });
        // get ids from fixtures
        const ids = fixtures.map(fixture => Ember.get(fixture, 'id'));
        // prompt dialog to generate support
        this.dialog.one('created', presenter => {
          Ember.set(this, 'report_generator_presenter', presenter);
        });
        // create a context
        const report = this.store.createRecord('generated-report', {
          report_template_uuid: null,
          format: 'txt',
          fixtures: ids,
          saved: false
        });
        // prompt for user to find zone to add as relation
        this.dialog.open('generate-report-form', report, {
          title: 'Generate a report',
          acceptText: 'Confirm & Download',
          className: 'dialog-lg',
          user: this.currentUser.user,
          controller: this,
          accept: () => {
            // get sanctum token
            const sanctumToken = this.session.data.authenticated.token;
            // set loading state
            this.report_generator_presenter.startLoading();
            // save report
            return report.save().then(generatedReport => {
              this.report_generator_presenter.stopLoading();
              this.report_generator_presenter._accept();
              window.open(`${_emberGetConfig.default.api.host}/${_emberGetConfig.default.api.namespace}/generated-reports/download?get=${Ember.get(generatedReport, 'id')}&sanctum_token=${sanctumToken}`);
            });
          }
        });
      },
      /**
       * Export all fixtures as a report
       */
      exportAll: function (filters, sort = '-laycan_range_start') {
        // convert filters to report object
        filters = (0, _createReportFiltersObject.default)(filters);
        // prompt dialog to generate support
        this.dialog.one('created', presenter => {
          Ember.set(this, 'report_generator_presenter', presenter);
        });
        // create a context
        const report = this.store.createRecord('generated-report', {
          report_template_uuid: null,
          user_uuid: Ember.get(this, 'currentUser.id'),
          source_uuid: Ember.get(this, 'source.id'),
          source_type: Ember.get(this, 'source._internalModel.modelName'),
          format: 'txt',
          filters,
          sort,
          fixtures: [],
          saved: false
        });
        // prompt for user to find zone to add as relation
        this.dialog.open('generate-report-form', report, {
          title: 'Generate a report',
          className: 'dialog-lg',
          acceptText: 'Confirm & Download',
          user: this.currentUser,
          controller: this,
          isIframeLoading: true,
          actions: {
            onIframeLoaded: function () {
              this.set('isIframeLoading', false);
            }
          },
          accept: () => {
            // get sanctum token
            const sanctumToken = this.session.data.authenticated.token;
            // set loading state
            this.report_generator_presenter.startLoading();
            // save report
            return report.save().then(generatedReport => {
              this.report_generator_presenter.stopLoading();
              this.report_generator_presenter._accept();
              // console.log(generatedReport);
              // console.log(`${config.api.host}/${config.api.namespace}/generated-reports/download?get=${get(generatedReport, 'id')}`);
              window.open(`${_emberGetConfig.default.api.host}/${_emberGetConfig.default.api.namespace}/generated-reports/download?get=${Ember.get(generatedReport, 'id')}&sanctum_token=${sanctumToken}`);
            });
          }
        });
      },
      /**
       * Toggle all checkboxs
       * @void
       */
      toggleCheckAll: function (event, handsontable) {
        event.preventDefault();
        const {
          target
        } = event;
        const {
          checked
        } = target;
        const data = handsontable.getSourceData();

        // toggle
        data.forEach((f, row) => {
          Ember.set(data[row], 'isChecked', checked);
        });

        // flag _all_checked
        Ember.set(handsontable, '_all_checked', checked);

        // render
        handsontable.render();
      },
      /**
       * View activity for a specific fixture
       */
      viewFixtureActivity: async function (selectedFixtures) {
        const data = this.handsontable.getSourceData();
        const changes = selectedFixtures.map(fixture => {
          return {
            row: data.indexOf(fixture),
            id: Ember.get(fixture, 'fixture_uuid')
          };
        });
        const rows = changes.map(change => Ember.get(change, 'row'));
        const queue = changes.map(change => Ember.get(change, 'id'));
        // can only view one fixtures activity for now
        if (queue.length > 1) {
          return this.notifications.warning('You can only view a single fixtures activity at once');
        }
        // load activity for the fixture
        const activities = await this.store.query('activity', {
          subject_id: queue.firstObject,
          log_name: 'fixture'
        });
        // display activity
        this.dialog.open('view-fixture-activity', null, {
          title: 'Fixture activity',
          activities,
          acceptText: 'Done'
        });
      },
      /**
       * Sets a fixtures confidentiality level via meta
       */
      setBackdate: function (selectedFixtures) {
        const data = this.handsontable.getSourceData();
        const changes = selectedFixtures.map(fixture => {
          return {
            row: data.indexOf(fixture),
            id: Ember.get(fixture, 'fixture_uuid')
          };
        });
        const rows = changes.map(change => Ember.get(change, 'row'));
        const queue = changes.map(change => Ember.get(change, 'id'));
        // can only send action w/ queue
        if (!queue.length) {
          return this.notifications.warning('No fixtures selected');
        }
        this.dialog.one('created', setBackdatePresenter => this.setProperties({
          setBackdatePresenter
        }));
        // display prompt
        this.dialog.open('backdate-fixtures', null, {
          title: 'Backdate Fixtures',
          acceptText: 'Done',
          backdate: null,
          controller: this,
          accept: () => {
            const date = this.setBackdatePresenter.get('backdate');
            // set for each selection
            rows.forEach(row => {
              this.setRowData(row, 'created_at', (0, _moment.default)(date).format('DD-MMM-YYYY'));
              this.setRowData(row, 'created_at_datetime', date);
            });
            this.handsontable.render();
            this.setBackdatePresenter._accept();
            // execute requests handle responses
            return this.fetch.patch('actions/bulk-backdate-fixtures', {
              data: {
                queue,
                date
              }
            });
          }
        });
      },
      /**
       * Sets a fixtures confidentiality level via meta
       */
      setConfidentialityLevel: function (selectedFixtures, confidentiality) {
        const data = this.handsontable.getSourceData();
        const changes = selectedFixtures.map(fixture => {
          return {
            row: data.indexOf(fixture),
            id: Ember.get(fixture, 'fixture_uuid')
          };
        });
        const rows = changes.map(change => Ember.get(change, 'row'));
        const queue = changes.map(change => Ember.get(change, 'id'));
        // can only send action w/ queue
        if (!queue.length) {
          return this.notifications.warning('No fixtures selected');
        }
        // set for each selection
        rows.forEach(row => {
          this.setRowData(row, 'confidentiality', confidentiality);
        });
        this.handsontable.render();
        // execute requests handle responses
        return this.fetch.patch('actions/bulk-set-confidentiality', {
          data: {
            queue,
            confidentiality
          }
        });
      },
      /**
       * Toggle fixture to be internal or not internal
       */
      toggleInternal: function (selectedFixtures, internal = 1) {
        const data = this.handsontable.getSourceData();
        const changes = selectedFixtures.map(fixture => {
          return {
            row: data.indexOf(fixture),
            id: Ember.get(fixture, 'fixture_uuid')
          };
        });
        const rows = changes.map(change => Ember.get(change, 'row'));
        const queue = changes.map(change => Ember.get(change, 'id'));
        // can only send action w/ queue
        if (!queue.length) {
          return this.notifications.warning('No fixtures selected');
        }
        // set for each selection
        rows.forEach(row => {
          this.setRowData(row, 'internal', internal);
        });
        this.handsontable.render();
        // execute requests handle responses
        return this.fetch.patch('actions/bulk-set-internal', {
          data: {
            queue,
            internal
          }
        });
      },
      /**
       * Removes selected rows or fixtures
       */
      deleteSelected: function (selectedFixtures) {
        const data = this.handsontable.getSourceData();
        const changes = selectedFixtures.map(fixture => {
          return {
            row: data.indexOf(fixture),
            id: Ember.get(fixture, 'fixture_uuid')
          };
        });
        const rows = changes.map(change => Ember.get(change, 'row'));
        const queue = changes.map(change => Ember.get(change, 'id'));
        // can only send action w/ queue
        if (!queue.length) {
          return this.notifications.warning('No fixtures selected');
        }
        // group the row numbers by consecutive
        const groups = (0, _groupConsecutive.default)(rows).map(group => {
          return [group[0], group.length];
        });
        // deleted grouped
        this.handsontable.alter('remove_row', groups);
        this.notifyPropertyChange('selectedFixtures');
        this.triggerAction('onPropertyChange', 'selectedFixtures');
        // execute requests handle responses
        return this.fetch.delete('actions/bulk-delete-fixtures', {
          data: {
            queue
          }
        });
      },
      /**
       * Generate a "quick" report from selected report type
       */
      generateQuickReport: function (selectedFixtures, quickReportType) {
        const sanctumToken = this.session.data.authenticated.token;
        const baseUrl = `${_emberGetConfig.default.api.host}/${_emberGetConfig.default.api.namespace}/reports/download`;
        const authPart = `sanctum_token=${sanctumToken}`;
        const formatPart = `format=${quickReportType.format}`;
        const reportPart = `report=${quickReportType.uuid}`;
        const userPart = `user=${Ember.get(this, 'currentUser.id')}`;

        // multi sort
        const sortParts = quickReportType.type_sorts.map(sortItem => `sort[]=${sortItem.sort_by}`);
        const sortPart = sortParts.join('&');
        const reportUrl = `${baseUrl}?${authPart}&${formatPart}&${reportPart}&${userPart}&${sortPart}`;
        const request = new XMLHttpRequest();
        request.responseType = 'blob';
        request.withCredentials = true;
        request.open('GET', reportUrl, true);

        // notify owner quick report is being generated
        if (typeof this.onQuickReportGenerateStart === 'function') {
          this.onQuickReportGenerateStart(quickReportType);
        }
        request.addEventListener('readystatechange', () => {
          // notify owner quick report is done
          if (typeof this.onQuickReportGenerateDone === 'function') {
            this.onQuickReportGenerateDone(quickReportType, request);
          }
          if (request.readyState == 2 && request.status == 200) {
            this.set('isGenerating', false);
            this.set('isDownloading', true);
          } else if (request.readyState == 4) {
            this.set('downloadProgressPercentage', 100);
            this.set('isDownloading', false);
            let attachmentFilename = '';
            const contentDisposition = request.getResponseHeader('Content-Disposition');
            const defaultFilename = 'report-download.txt';
            if (typeof contentDisposition === 'undefined' || contentDisposition === null) {
              attachmentFilename = defaultFilename;
            } else {
              const parts = contentDisposition.split('filename=', 2);
              attachmentFilename = parts.length < 2 ? defaultFilename : parts[1];
            }
            const a = document.createElement('a');
            document.body.appendChild(a);
            const url = URL.createObjectURL(request.response);
            a.href = url;
            a.download = attachmentFilename;
            a.click();
            setTimeout(() => {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(a);
            }, 0);
          }
        });
        request.send();
      },
      /**
       * Generate a report from a selection of fixtres
       */
      generateReportFromSelected: function (selectedFixtures) {
        const data = this.handsontable.getSourceData();
        const changes = selectedFixtures.map(fixture => {
          return {
            row: data.indexOf(fixture),
            id: Ember.get(fixture, 'fixture_uuid')
          };
        });
        // const rows = changes.map((change) => get(change, 'row'));
        const queue = changes.map(change => Ember.get(change, 'id'));
        // can only send action w/ queue
        if (!queue.length) {
          return this.notifications.warning('No fixtures selected');
        }
        // prompt dialog to generate support
        this.dialog.one('created', presenter => {
          Ember.set(this, 'report_generator_presenter', presenter);
        });
        // create a context
        const report = this.store.createRecord('generated-report', {
          user_uuid: Ember.get(this, 'currentUser.id'),
          report_template_uuid: null,
          format: 'txt',
          fixtures: queue,
          saved: false
        });
        // prompt for user to find zone to add as relation
        this.dialog.open('generate-report-form', report, {
          title: 'Generate a report',
          acceptText: 'Confirm & Download',
          user: Ember.get(this, 'currentUser.user'),
          controller: this,
          isIframeLoading: true,
          actions: {
            onIframeLoaded: function () {
              this.set('isIframeLoading', false);
            }
          },
          accept: () => {
            // get sanctum token
            const sanctumToken = this.session.data.authenticated.token;
            // set loading state
            this.report_generator_presenter.startLoading();
            // save report
            return report.save().then(generatedReport => {
              this.report_generator_presenter.stopLoading();
              this.report_generator_presenter._accept();
              window.open(`${_emberGetConfig.default.api.host}/${_emberGetConfig.default.api.namespace}/generated-reports/download?&get=${Ember.get(generatedReport, 'id')}&sanctum_token=${sanctumToken}`);
            });
          }
        });
      }
    },
    /**
     * Classes that are binded to properties
     */
    // classNameBindings: ['isLoading'],

    /**
     * The api rest adapter service
     */
    store: Ember.inject.service(),
    /**
     * The session service
     */
    session: Ember.inject.service(),
    /**
     * The ajax service
     */
    ajax: Ember.inject.service(),
    /**
     * The ajax service
     */
    fetch: Ember.inject.service(),
    /**
     * The notificationss service
     *
     * @var {Service}
     */
    notifications: Ember.inject.service(),
    /**
     * The dialog service
     *
     * @var {Service}
     */
    dialog: Ember.inject.service(),
    /**
     * The socket service
      * @var {Service}
     */
    socket: Ember.inject.service(),
    /**
     * The current user service
     *
     * @var {Service}
     */
    currentUser: Ember.inject.service(),
    /**
     * The staged changes service
     */
    stagedChanges: Ember.inject.service(),
    /**
     * The can (permissions) service
     */
    can: Ember.inject.service(),
    /**
     * EDITING MODE
     *
     * If the fixtures table is not in editing mode,
     * auto complete, strict columns and saving changes
     * will be disabled. Also, context menu editing
     * options will be disabed.
     *
     * Defaults to true.
     */
    editingMode: true,
    /**
     * Table is in the state of saving
     */
    saving: false,
    /**
     * Determines if the tabe should be read only
     */
    readOnly: false,
    /**
     * Table is in the state of loading
     */
    isLoading: false,
    /**
     * Table is extracting data
     */
    isExtracting: false,
    /**
     * The loaded column state
     */
    columnState: {},
    /**
     * Get the selected fixtures
     */
    selectedFixtures: Ember.computed('data.@each.isChecked', function () {
      return this.data.filter(fixture => fixture.isChecked);
    }),
    /**
     * Data to use in table
     */
    data: [{}],
    /**
     * Quick Report report types
     */
    quickReportTypes: [],
    /**
     * The source of fixtures
     */
    source: null,
    /**
     * The handsontable instance
     */
    handsontable: null,
    /**
     * If table exists within goldenlayout, instance here
     */
    goldenlayout: null,
    /**
     * Default column headers
     */
    colHeaders: Ember.computed(function () {
      return ['Company', 'Laycan', 'Quantity', 'Grade', 'Rate', 'Load', 'Discharge', 'Vessel', 'Status'];
    }),
    /**
     * Default table height
     */
    height: undefined,
    /**
     * Optional confidentiality levels for fixtures
     */
    confidentialityLevels: Ember.computed(function () {
      return [{
        name: 'Public',
        key: 'public'
      }, {
        name: 'Private & Confidential',
        key: 'private'
      }, {
        name: 'Super Private & Confidential',
        key: 'super_private'
      }];
    }),
    /**
     * The current selected fixture
     */
    selectedFixture: null,
    /**
     * The current selected cell
     */
    selectedCell: null,
    /**
     * Determines if user is commenting
     */
    isCommenting: false,
    /**
     * Click event that triggered a comment box
     */
    commentToggleEvent: null,
    /**
     * The current pagination page
     */
    page: null,
    /**
     * The last page possible for this query
     */
    lastPage: null,
    /**
     * Determins whether or not to use a lightweight performance of `superRender``
     */
    useFastRender: false,
    /**
     * Trigger an action/event from component
     */
    triggerAction: function (actionName, ...params) {
      if (Ember.get(this, actionName) && typeof this[actionName] === 'function') {
        this[actionName](...params);
      }
    },
    /**
     * Safely set a variable
     */
    safeSet: function (key, value) {
      if (!this.isDestroyed || !this.isDestroying) {
        Ember.set(this, key, value);
      }
    },
    /**
     * Helper function to return the manual column sizes from local storage
     *
     * @return array or boolean
     */
    getManualColumnResize: function () {
      if (!Ember.get(this, 'source.id') || this.editingMode === false) {
        return true;
      }
      let manualColumnResize = window.localStorage.getItem(`manual_column_widths_${Ember.get(this, 'source.id').replace(/\-/g, '_')}_${Ember.get(this, 'currentUser.id').replace(/\-/g, '_')}`);
      return manualColumnResize ? JSON.parse(manualColumnResize) : true;
    },
    /**
     * Get the column headers
     */
    getColumns: async function () {
      const storageKey = `${Ember.get(this, 'source.id')}_${Ember.get(this, 'currentUser.id').replace(/\-/g, '_')}_boardColumns`;
      const storageKeyVersion = `${storageKey}_version`;
      // if cache version is outdated or not set clear cache key
      if (!this.currentUser.options.get(storageKeyVersion) || this.currentUser.options.get(storageKeyVersion) !== cacheVersion) {
        // this.currentUser.clearOptions();
        this.currentUser.options.set(storageKey, undefined);
        this.currentUser.options.set(storageKeyVersion, undefined);
      }
      if (this.currentUser.options.get(storageKey)) {
        const columns = this.currentUser.options.get(storageKey);
        const buildColumns = [...columns].map(this.buildColumn.bind(this)).filter(Boolean);
        this.safeSet('columnState', [...columns]);
        return buildColumns;
      }
      // check localstorage first
      return this.fetch.get('actions/get-fixture-columns', {
        data: {
          defaultOnly: true,
          source: Ember.get(this, 'source.id')
        }
      }).then(columns => {
        const buildColumns = [...columns].map(this.buildColumn.bind(this)).filter(Boolean);
        this.safeSet('columnState', [...columns]);
        this.currentUser.options.set(storageKey, columns);
        this.currentUser.options.set(storageKeyVersion, cacheVersion);
        return buildColumns;
      });
    },
    /**
     * Build columns into proper column objects
     */
    buildColumn: function (column) {
      let newColumn = Object.assign({}, column);
      // if not in editing mode dont include timestamp/and activity columns
      if (this.editingMode === false && ['updated_at', 'created_at', 'updated_by', 'created_by', 'isChecked'].includes(newColumn.data)) {
        return false;
      }
      // set date columsn
      if (['updated_at', 'created_at'].includes(newColumn.data)) {
        newColumn.type = 'date';
        newColumn.strict = false;
        newColumn.correctFormat = false;
        newColumn.dateFormat = 'DD-MMM';
      }
      // if has string source assign to source_model
      if (typeof newColumn.source === 'string') {
        Ember.set(newColumn, 'source_model', newColumn.source);
      }
      // for laycan only
      if (newColumn.data === 'laycan.value') {
        // laycan only since it's a date range
        newColumn.columnSorting = {
          indicator: true,
          headerAction: true,
          compareFunctionFactory: function compareFunctionFactory(sortOrder, columnMeta) {
            return function comparator(value, nextValue) {
              return (0, _laycanToDate.default)(value) - (0, _laycanToDate.default)(nextValue);
            };
          }
        };
      }
      // for grade remove strict
      if (newColumn.data === 'grade.value') {
        newColumn.strict = false;
      }
      // set readOnly permissions
      newColumn.readOnly = Ember.isArray(newColumn.readOnly) ? newColumn.readOnly.every(p => this.can.cannot(p)) : newColumn.readOnly;
      // set readOnly if its set
      if (this.readOnly === true) {
        newColumn.readOnly = true;
      }
      // if not in editing mode disable strict
      if (newColumn.strict && this.editingMode === false) {
        newColumn.strict = false;
      }
      // set default renderer
      newColumn.renderer = 'fixflo.text';
      // if checkbox use checkbox renderer
      if (newColumn.type === 'checkbox') {
        // return newColumn;
        newColumn.renderer = 'fixflo.checkbox';
      }
      // set source
      if ((newColumn.source || newColumn.source_model) && this.editingMode === true) {
        // set custom autocomplete renderer
        newColumn.renderer = 'fixflo.autocomplete';
        // newColumn.editor = 'fixflo.autocomplete';
        newColumn.source = this.queryForAutocomplete.bind(this, newColumn.source_model || newColumn.source);
        // never filter the autocomplete value
        newColumn.filter = false;
      }
      // return column
      return newColumn;
    },
    /**
     * Query data and process it for autocomplete
     */
    queryForAutocomplete: function (resource, query, process) {
      return this.performQueryForAutocomplete.perform(resource, query, process);
    },
    /**
     * Query for autocomplete task
     */
    performQueryForAutocomplete: (0, _emberConcurrency.task)(function* (subject, query, process) {
      // build the query params
      const response = yield this.fetch.get(`actions/quick-query`, {
        data: {
          subject,
          query
        }
      });
      // process this request
      localStorage.setItem('choicesList', JSON.stringify(response[Ember.String.camelize(subject)]));
      return process(response[Ember.String.camelize(subject)].map(r => (Ember.get(r, 'display_name') || Ember.get(r, 'system_display_name') || Ember.get(r, 'name') || 'NOT APPLICABLE').toUpperCase()));
    }).restartable(),
    /**
     * Set data to a row with a key/value as well as update the cell meta
     */
    setRowData: function (row, key, value) {
      // get the data
      const data = this.data;
      // set the data
      Ember.set(data[row], key, value);
      // update data
      Ember.setProperties(this, {
        data
      });
    },
    /**
     * Sets a fixtures confidentiality level via meta
     */
    setConfidentialityLevel: function (handsontable, selection, level) {
      // set confidentiality
      const confidentiality = level.replace('confidentiality:', '');
      // store confidentiality setting request
      const queue = [];
      // do for each selection
      selection.forEach(selected => {
        const rows = (0, _arrayRange.default)(selected.end.row - selected.start.row, selected.start.row);
        // iterate on each row index
        rows.forEach(row => {
          // get the fixture
          const fixture = this.getFixtureFromRowIndex(row);
          if (!fixture) {
            return;
          }
          // get fixture id
          const id = Ember.get(fixture, 'id');
          // set the fixture confidentiality level
          Ember.setProperties(fixture, {
            confidentiality
          });
          // update cell meta
          for (let columnIndex = 0; columnIndex <= selected.end.col; columnIndex++) {
            this.setRowData(row, 'confidentiality', confidentiality);
          }
          // save fixture to request queue
          queue.pushObject(id);
        });
      });
      // set updated data
      const data = this.data;
      handsontable.loadData(data);
      // execute requests handle responses
      return this.fetch.patch('actions/bulk-set-confidentiality', {
        data: {
          queue,
          confidentiality
        }
      });
    },
    /**
     * Toggle fixture to be internal or not internal
     */
    toggleInternal: function (handsontable, selection, internal = 1) {
      // queue
      const queue = [];
      // do for each selection
      selection.forEach(selected => {
        const rows = (0, _arrayRange.default)(selected.end.row - selected.start.row, selected.start.row);
        // iterate on each row index
        rows.forEach(row => {
          // get the fixture
          const fixture = this.getFixtureFromRowIndex(row);
          if (!fixture) {
            return;
          }
          // get fixture id
          const id = Ember.get(fixture, 'id');
          // set the fixture internal
          Ember.setProperties(fixture, {
            internal
          });
          // set cell meta on row
          for (let columnIndex = 0; columnIndex <= selected.end.col; columnIndex++) {
            this.setRowData(row, 'internal', Ember.get(fixture, 'internal'));
          }
          // save fixture to request queue
          queue.pushObject(id);
        });
      });
      // set updated data
      const data = this.data;
      handsontable.loadData(data);
      // execute requests handle responses
      return this.fetch.patch('actions/bulk-set-internal', {
        data: {
          queue,
          internal
        }
      });
    },
    /**
     * Returns a fixture record when supplied the row index
     */
    getFixtureFromRowIndex: function (rowIndex) {
      let fixture = this.data[rowIndex];
      let id = Ember.get(fixture, 'fixture_uuid');
      if (!id) {
        return false;
      }
      return this.store.peekRecord('fixture', id);
    },
    /**
     * Handle before cell changes
     */
    beforeChange: function (handsontable, changes, source) {
      // console.log('beforeChange', changes, source);
      if (source === 'paste.confirmed') return;
      if (changes.length && changes[0] && changes[0].length && changes[0].filter(value => Number.isNaN(value)).length) {
        return false;
      }
      if (changes.length > 7 && this.editingMode === true) {
        // console.log('[prompting confirmation of changes]');
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Confirm changes',
          acceptText: 'Confirm and continue',
          declineText: 'Undo changes',
          message: `Are you sure you wish to modify these columns?`
        }).then(() => {
          // get data to be updated
          const data = handsontable.getSourceData();
          // apply changes manually here
          changes.forEach(([row, columnHeader, currentValue, newValue]) => {
            Ember.set(data[row], columnHeader, newValue);
          });
        }, () => false);
        return false;
      }
    },
    /**
     * Before key down event
     */
    beforeKeyDown: function (handsontable, event) {
      const selected = handsontable.getSelectedRange();
      const {
        from,
        to
      } = selected.firstObject;
      // dis allow delete on large selection
      if ((event.keyCode === 46 || event.keyCode === 8) && (from.row !== to.row || from.col !== to.col)) {
        return event.stopImmediatePropagation();
      }
    },
    /**
     * Triggered after changes to a cell
     */
    afterChange: function (handsontable, changes, source) {
      // if no changes were made then skip
      if (Ember.isArray(changes) && Ember.get(changes, 'length') === 1 && Ember.get(changes, '0.2') === Ember.get(changes, '0.3')) return;
      // dont apply changes if conditions match
      if (this.editingMode === false || !changes || source === 'manualInsert' || source === 'insertRowTop' || source === 'checkboxToggled') return;
      // if observer changes only apply changes for main columns -- must contain value in prop
      if (source === 'ObserveChanges.change' && !changes.every(([row, prop]) => prop.includes('value'))) return;
      // handle changes
      // console.log(`afterChange`, changes, source);
      changes.forEach(([row, prop, oldValue, newValue]) => {
        const columnIndex = handsontable.getColHeader().findIndex(header => prop.includes(header.toLowerCase()));
        const meta = handsontable.getCellMeta(row, columnIndex);
        var changes = {};
        changes.entry = newValue;
        if (handsontable.getCellEditor(row, columnIndex).name == 'AutocompleteEditor') {
          const dropdownItems = JSON.parse(localStorage.getItem('choicesList'));
          ;
          var selectedItem = Ember.isArray(dropdownItems) ? dropdownItems[0] : null;
          if (selectedItem && 'uuid' in selectedItem) {
            changes.relation_uuid = selectedItem.uuid;
          }
        }

        // if no meta data just return, something is wrong
        if (typeof Ember.get(meta, 'data') !== 'object') return;

        // track changes
        this.trackChanges(row, prop, changes);
      });
      // commit changes
      return this.stagedChanges.commit().then(response => {
        let data = this.data;
        const updates = Object.values(Ember.get(response, 'results')).filter(u => u.prop);
        // update the values
        updates.forEach(update => {
          if (Number.isInteger(update.row)) {
            const fixture = data[update.row];
            const propKey = update.prop.replace('.value', '');
            // console.log(fixture);
            Ember.set(fixture, update.prop, update.entry);
            if (update.relation_uuid) {
              if (fixture[propKey].relation) {
                Ember.set(fixture, `${propKey}.relation.uuid`, update.relation_uuid);
              }
              if (fixture[propKey].relation_uuid) {
                Ember.set(fixture, `${propKey}.relation_uuid`, update.relation_uuid);
              }
            }
            data = data.replace(update.row, 1, [fixture]);
          }
        });
        handsontable.render();
      });
    },
    /**
     * Track changes made to staged changes service
     *
     * @void
     */
    trackChanges: function (row, prop, entry) {
      const columnIndex = this.handsontable.getColHeader().findIndex(header => prop.includes(header.toLowerCase()));
      const meta = this.handsontable.getCellMeta(row, columnIndex);

      // no meta found cancel
      if (typeof meta.data !== 'object') return;

      // get fixture-value attributes from meta.data
      const attributes = meta.data || {};

      // prepare fixture value data
      const data = _objectSpread(_objectSpread({}, attributes), {}, {
        entry: entry.entry
      });

      // make sure `fixture_uuid` is always plugged
      if (!data.fixture_uuid) {
        Ember.set(data, 'fixture_uuid', meta.fixture_uuid);
      }

      // make sure `key` is always plugged
      if (!data.key) {
        Ember.set(data, 'key', attributes.key);
      }

      // make sure key is set - this time use prop
      if (!data.key) {
        Ember.set(data, 'key', prop.replace('.value', ''));
      }

      // get change
      const changes = entry;

      // create fixture value from data
      const fixtureValue = this.store.normalize('fixture-value', data);

      // stage the fixture value
      this.stagedChanges.track(fixtureValue, changes, row, prop);
    },
    /**
     * Task to create or update a fixture value
     */
    updateOrCreateFixtureValue: (0, _emberConcurrency.task)(function* (handsontable, row, prop, entry) {
      // console.log('[updateOrCreateFixtureValue*task:init]', row, prop, entry);
      const columnIndex = handsontable.getColHeader().findIndex(header => prop.includes(header.toLowerCase()));
      const meta = handsontable.getCellMeta(row, columnIndex);
      // no meta found cancel
      if (typeof meta.data !== 'object') return;
      // console.log('[updateOrCreateFixtureValue*task:started]', new Date().toLocaleTimeString());
      // determine if value should be created or updated
      const isCreatingFixtureValue = !meta.data.uuid;
      // method to invole
      const method = isCreatingFixtureValue ? 'post' : 'put';
      const uri = isCreatingFixtureValue ? `fixture-values` : `fixture-values/${meta.data.uuid}`;
      // start building an object data/payload the the request to create or update
      let payload = {
        entry
      };
      // if creating a new value pass on the meta data
      if (isCreatingFixtureValue) {
        payload = Ember.assign(payload, meta.data);
      }
      // set the state to saving
      Ember.setProperties(this, {
        saving: true
      });
      // send request to create fixture value
      let fixtureValue = yield this.fetch[method](uri, {
        data: payload
      });
      // update saving state
      Ember.set(this, 'saving', false);
      handsontable.setCellMeta(row, columnIndex, 'isSaving', true);
      handsontable.render();
      // convert fixture value to ember model
      fixtureValue = this.store.push(this.store.normalize('fixture-value', Ember.get(fixtureValue, 'fixtureValue')));
      // get data for table
      const data = this.data;
      // iterate through data to find the fixture the value belongs to
      data.forEach((f, index) => {
        if (Ember.get(f, 'fixture_uuid') === Ember.get(fixtureValue, 'fixture_uuid')) {
          // update the value
          let fv = fixtureValue.serialize();
          // update the display entry, and value for the data
          Ember.setProperties(fv, {
            display_entry: Ember.get(fv, 'entry'),
            value: Ember.get(fv, 'entry')
          });
          // update the data by index
          data[index][Ember.get(fixtureValue, 'key')] = fv;
          // console.log('[fixtureValue.serialize()]', fv);
        }
      });
      // console.log('[updateOrCreateFixtureValue][data]', data);
      // load change in the new data
      Ember.setProperties(this, {
        data,
        saving: false
      });
      handsontable.setCellMeta(row, columnIndex, 'isSaving', false);
      handsontable.render();
    }).enqueue(),
    /**
     * Handle double click
     */
    onDoubleClick: function (handsontable, event, coords) {
      if (isColumnOf(handsontable, ['Load', 'Discharge', 'Rate'])) {
        const column = getSelectedColumnHeader(handsontable);
        this.send('rateZoneManager', coords, column.toLowerCase());
      } else if (isColumnOf(handsontable, ['Vessel']) && this.can.can('create vessel')) {
        this.send('createModel', 'vessel');
      }
    },
    /**
     * After click on cell, handle logic and doubleclick tracking
     */
    afterOnCellMouseDown: function (handsontable, event, coords, td) {
      const lastCellClick = this.lastCellClick;
      const clicked_at = new Date().getTime();
      // set click defaults
      Ember.set(this, 'isCommenting', false);
      Ember.set(this, 'selectedCell', coords);
      Ember.set(this, 'selectedFixture', this.data[coords.row]);
      // check if dbl-clicked within 1/5th of a second
      if (!(lastCellClick && clicked_at - Ember.get(lastCellClick, 'clicked_at') < 200)) {
        return Ember.set(this, 'lastCellClick', {
          clicked_at,
          event,
          coords,
          td
        });
      }
      if (this.can.cannot('update fixture') || this.readOnly) {
        return;
      }
      // send double click action
      this.onDoubleClick(handsontable, event, coords, td);
    },
    /**
     * After modifying the column with
     */
    afterColumnResize: function (handsontable) {
      // if its the import table dont persist
      if (this.editingMode === false) {
        return;
      }
      // get all column widths
      const manualColumnResize = this.colHeaders.map((header, index) => handsontable.getColWidth(index));
      // save column sizes to localstorage
      window.localStorage.setItem(`manual_column_widths_${Ember.get(this, 'source.id').replace(/\-/g, '_')}_${Ember.get(this, 'currentUser.id').replace(/\-/g, '_')}`, JSON.stringify(manualColumnResize));
    },
    /**
     * After moving a column
     */
    afterColumnMove: function (handsontable, columns = [], target = null) {
      // if its the import table dont persist
      if (this.editingMode === false) {
        return;
      }
      // do nothing for now
      const storageKey = `${Ember.get(this, 'source.id')}_${Ember.get(this, 'currentUser.id').replace(/\-/g, '_')}_boardColumns`;
      const storageKeyVersion = `${storageKey}_version`;
      const headers = handsontable.getColHeader();
      const columnState = this.columnState;
      // sort columns by new index
      columnState.sort((ca, cb) => headers.indexOf(ca.label) - headers.indexOf(cb.label));
      // console.log(columnState);
      // save column state
      return this.fetch.put('column-states', {
        data: {
          columnState: {
            source_uuid: Ember.get(this, 'source.id'),
            source: Ember.get(this, 'source._internalModel.modelName'),
            state: columnState
          }
        },
        headers: {
          'X-Update-Option': 'UpdateOrCreate'
        }
      }).then(response => {
        // get new column state
        const columnState = Ember.get(response, 'columnState.state');
        // update columns
        const columns = columnState.map(this.buildColumn.bind(this));
        const colHeaders = columns.map(c => Ember.get(c, 'label'));
        // set to local storage
        this.currentUser.options.set(storageKey, columnState);
        this.currentUser.options.set(storageKeyVersion, cacheVersion);
        // const viewportColumnRenderingOffset = colHeaders.length;
        // set properties to controller
        Ember.setProperties(this, {
          columns,
          colHeaders,
          columnState
        });
      });
    },
    /**
     * Handle mouse over cell event
     */
    afterOnCellMouseOver: function (handsontable, event, coord) {
      // get the column header
      const columnHeader = this.colHeaders[coord.col];
      // if td has comments render the comments
      if (isDOMElement(event.target) && event.target.classList.contains('cell-has-comments')) {
        Ember.setProperties(this, {
          isCommenting: true,
          commentToggleEvent: event
        });
      }
      // check if date column to display the whole date
      if (['Updated At', 'Created At'].includes(columnHeader) && coord.row >= 0) {
        // display a tooltip
        this.showToolTip(handsontable, event, coord, columnHeader);
      }
    },
    /**
     * Displays a tooltip where the cell was hovered depending on the data
     */
    showToolTip: function (handsontable, event, coord, columnHeader) {
      const cellMeta = handsontable.getCellMeta(coord.row, coord.col);
      // create the tooltip element
      const tooltip = document.createElement('div');
      tooltip.classList.add('fixtures-table-tooltip');
      tooltip.style.top = `${event.pageY}px`;
      tooltip.style.left = `${event.pageX}px`;
      switch (columnHeader) {
        case 'Updated At':
          tooltip.textContent = Ember.get(cellMeta, 'updated_at_datetime');
          break;
        case 'Created At':
          tooltip.textContent = Ember.get(cellMeta, 'created_at_datetime');
          break;
      }
      document.body.appendChild(tooltip);
      Ember.setProperties(this, {
        tooltip
      });
    },
    /**
     * Handle mouse over outcell event
     */
    afterOnCellMouseOut: function (handsontable, event, coord) {
      // get tooltip if any
      const tooltip = this.tooltip;
      // if td has comments clear comments render
      if (isDOMElement(event.target) && event.target.classList.contains('cell-has-comments')) {
        Ember.setProperties(this, {
          isCommenting: false
        });
      }
      // if there is a tooltip destroy it
      if (tooltip) {
        tooltip.remove();
        Ember.set(this, 'tooltip', null);
      }
    },
    /**
     * After the table loads more data
     */
    afterLoadData: function (handsontable) {
      // console.log('[afterLoadData]', new Date().toLocaleTimeString());
      // wrap in timeout of 600ms
      setTimeout(() => {
        handsontable.updateSettings({
          viewportRowRenderingOffset: Ember.get(this, 'data.length'),
          viewportColumnRenderingOffset: Ember.get(this, 'colHeaders.length')
        });
        handsontable.getPlugin('contextMenu').updatePlugin();
        // handsontable.getPlugin('filters').filter();
      }, 600);
    },
    /**
     * The instance of the notification ping
     */
    notificationPing: Ember.computed(function () {
      return new _howler.Howl({
        src: ['/sounds/noty.mp3']
      });
    }),
    /**
     * Custom cell click tracker
     */
    lastCellClick: {
      clicked_at: null
    },
    /**
     * Returns current cell coordinates
     */
    getCellCoords: function () {
      const lastSelected = this.handsontable.getSelectedLast();
      const selected = Ember.isArray(lastSelected) && lastSelected.length > 1 ? lastSelected.slice(0, 2) : [0, 0];
      const cell = this.handsontable.getCell(...selected);
      const coords = this.handsontable.getCoords(cell);
      return coords;
    },
    /**
     * The context menu configuration
     */
    contextMenu: Ember.computed('handsontable', 'data.@each.fixture_uuid', 'quickReportTypes.@each.id', function () {
      const handsontable = this.handsontable;
      return {
        items: {
          // quick_report: {
          //     name: 'Quick Report',
          //     hidden: () => {
          //         return this.editingMode === false || (this.quickReportTypes || []).length <= 0;
          //     },
          //     submenu: {
          //         items: (this.quickReportTypes || []).map((quickReportType, index, qrts) => ({
          //             key: `quick_report:${quickReportType.uuid}`,
          //             name: quickReportType.name,
          //             callback: () => this.send('generateQuickReport', this.selectedFixtures, qrts[index]),
          //         })),
          //     },
          // },
          generate_report: {
            name: 'Generate Report',
            hidden: () => {
              return this.editingMode === false;
            },
            callback: (key, selection, clickEvent) => {
              this.send('generateReportFromSelected', this.selectedFixtures);
            }
          },
          confidentiality: {
            name: 'Set Confidentiality',
            hidden: () => {
              return this.editingMode === false || this.can.cannot('update fixture') || this.readOnly;
            },
            submenu: {
              items: this.confidentialityLevels.map(level => ({
                key: `confidentiality:${Ember.get(level, 'key')}`,
                name: Ember.get(level, 'name'),
                callback: (key, selection) => {
                  return this.send('setConfidentialityLevel', this.selectedFixtures, level.key);
                }
              }))
            }
          },
          set_internal: {
            name: 'Make Internal',
            hidden: () => {
              return this.editingMode === false || this.can.cannot('update fixture') || this.readOnly;
            },
            callback: (key, selection) => {
              return this.send('toggleInternal', this.selectedFixtures, 1);
            }
          },
          unset_internal: {
            name: 'Undo Internal',
            hidden: () => {
              return this.editingMode === false || this.can.cannot('update fixture') || this.readOnly;
            },
            callback: (key, selection) => {
              return this.send('toggleInternal', this.selectedFixtures, 0);
            }
          },
          backdate: {
            name: 'Backdate Fixtures',
            hidden: () => {
              return this.editingMode === false || this.can.cannot('update fixture') || this.readOnly;
            },
            callback: (key, selection) => {
              return this.send('setBackdate', this.selectedFixtures);
            }
          },
          fixture_activity: {
            name: 'View fixture activity',
            hidden: () => {
              return this.editingMode === false || this.selectedFixtures.length !== 1;
            },
            callback: (key, selection, clickEvent) => {
              this.send('viewFixtureActivity', this.selectedFixtures);
            }
          },
          seperator_0: {
            name: '---------'
          },
          port_info: {
            name: 'View port info',
            hidden: () => {
              return hasMultipleZoneOptions(handsontable) || notColumnOf(handsontable, ['Load', 'Discharge']) || this.can.cannot('view fixture-assosciation') || this.editingMode === false;
            },
            callback: (key, selection, clickEvent) => {
              // render port info
              let rowIndex = selection[0].start.row;
              let column = getSelectedColumnHeader(handsontable);
              let fixture = this.data[rowIndex];
              // console.log('[contextMenu][port_info][fixture]', fixture);
              // send action
              this.send('viewPort', fixture, column);
            }
          },
          multi_port_info: {
            name: 'View ports',
            hidden: () => {
              return !hasMultipleZoneOptions(handsontable) || this.can.cannot('view fixture-assosciation') || this.editingMode === false;
            },
            submenu: {
              items: [0, 1, 2, 3, 4, 5, 6, 7].map(zone => {
                return {
                  key: `multi_port_info:zone_${zone}`,
                  name: zone,
                  callback: (key, selection) => {
                    // get last selected & row/col
                    const lastSelected = handsontable.getSelectedLast();
                    if (!Ember.isArray(lastSelected)) {
                      return;
                    }
                    const selectedRow = lastSelected[0];
                    const selectedCol = lastSelected[1];
                    // get the cell meta
                    const cellMeta = handsontable.getCellMeta(selectedRow, selectedCol);
                    // get the option
                    const options = Object.values(Ember.get(cellMeta, `${cellMeta.prop.replace('.value', '_options')}`)); //.filter(z => get(z, 'rate'));
                    // get the zone
                    const port = options[zone];
                    // send action
                    this.send('viewLoadedZone', port);
                  },
                  renderer: (hot, wrapper, row, col, prop, itemValue) => {
                    // get last selected & row/col
                    const lastSelected = handsontable.getSelectedLast();
                    if (!Ember.isArray(lastSelected)) {
                      wrapper.parentNode.style.display = 'none';
                      return label;
                    }
                    const selectedRow = lastSelected[0];
                    const selectedCol = lastSelected[1];
                    // get the cell meta
                    const cellMeta = handsontable.getCellMeta(selectedRow, selectedCol);
                    // initialize label
                    const label = document.createElement('div');
                    // get the option
                    const options = Object.values(Ember.get(cellMeta, `${cellMeta.prop.replace('.value', '_options')}`)); //.filter(z => get(z, 'rate')); -- cant remember zones without rate was filtered
                    // if no further option do not display
                    if (itemValue > options.length - 1) {
                      wrapper.parentNode.style.display = 'none';
                      return label;
                    } else {
                      // set wrapper
                      wrapper.parentNode.className = 'htContextMenuWrapper';
                    }
                    // set label
                    label.className = 'hot-submenu-item';
                    // return final label
                    label.innerHTML = `<span class="inline-block" style="width: 12px; height: 12px;"></span> ${Ember.get(options[itemValue], 'relation.system_display_name') || Ember.get(options[itemValue], 'entry')}`;
                    return label;
                  }
                  // submenu: {
                  //     items: [
                  //         {
                  //             key: 'multi_port_info:delete_zone',
                  //             name: 'Remove'
                  //         },
                  //         {
                  //             key: 'multi_port_info:edit_rate',
                  //             name: 'Edit Rate'
                  //         }
                  //     ]
                  // }
                };
              })
            },

            callback: (key, selection, clickEvent) => {
              // render port info
              let rowIndex = selection[0].start.row;
              let column = getSelectedColumnHeader(handsontable);
              let fixture = this.data[rowIndex];
              // send action
              this.send('viewPort', fixture, column);
            }
          },
          vessel_info: {
            name: 'View vessel info',
            hidden: () => {
              return notColumnOf(handsontable, ['Vessel']) || this.can.cannot('view fixture-assosciation') || this.editingMode === false;
            },
            callback: (key, selection, clickEvent) => {
              // render vessel info
              let rowIndex = selection[0].start.row;
              let column = getSelectedColumnHeader(handsontable);
              let fixture = this.data[rowIndex];
              // send action
              this.send('viewVessel', fixture, column);
            }
          },
          seperator_1: {
            name: '---------',
            hidden: () => {
              return notColumnOf(handsontable, ['Load', 'Discharge', 'Vessel']);
            }
          },
          row_above: {
            name: 'Insert row above',
            hidden: () => {
              return this.editingMode === true;
            }
          },
          row_below: {
            name: 'Insert row below',
            hidden: () => {
              return this.editingMode === true;
            }
          },
          fixture_above: {
            name: 'Insert fixture above',
            hidden: () => {
              return !this.editingMode;
            },
            callback: (key, selection, clickEvent) => {
              const rowAbove = selection.firstObject.start.row > 1 ? selection.firstObject.start.row - 1 : 0;
              this.send('insertAtRow', rowAbove);
            }
          },
          fixture_below: {
            name: 'Insert fixture below',
            hidden: () => {
              return !this.editingMode;
            },
            callback: (key, selection, clickEvent) => {
              const rowBelow = selection.firstObject.start.row + 1;
              this.send('insertAtRow', rowBelow);
            }
          },
          seperator_2: {
            name: '---------'
          },
          remove_row: {
            name: 'Remove row(s)',
            hidden: () => {
              return this.editingMode === true;
            }
          },
          remove_fixture: {
            name: 'Remove fixture(s)',
            hidden: () => {
              return this.editingMode === false || this.can.cannot('delete fixture') || this.readOnly;
            },
            callback: (key, selection, clickEvent) => {
              this.send('deleteSelected', this.selectedFixtures);
            }
          },
          // comment: {
          //     name: htmlSafe('<i class="far fa-comment mr-1"></i> Comment'),
          //     hidden: () => {
          //         return get(this, 'editingMode') === false || get(this, 'can').cannot('edit fixture');
          //     },
          //     callback: (key, selection, clickEvent) => {
          //         const commentToggleEvent = get(this, 'lastCellClick.event');
          //         setProperties(this, {
          //             isCommenting: true,
          //             commentToggleEvent,
          //         });
          //     },
          // },
          seperator_3: {
            name: '---------'
          },
          undo: {
            name: 'Undo'
          },
          redo: {
            name: 'Redo'
          },
          seperator_4: {
            name: '---------'
          },
          seperator_5: {
            name: '---------'
          },
          alignment: {
            name: 'Alignment'
          },
          seperator_6: {
            name: '---------'
          },
          copy: {
            name: 'Copy'
          },
          cut: {
            name: 'Cut'
          }
        }
      };
    }),
    /**
     * Register table hotkeys
     */
    registerHotKeys: function (handsontable) {
      document.addEventListener('keydown', event => {
        if (event.ctrlKey) {
          switch (event.keyCode) {
            case 49:
              // 1 pressed - if row is highlighted set confidentiality to public
              if (selectedRowHeader(handsontable)) {
                const selection = handsontable.getSelectedRange().map(cellRange => {
                  return {
                    start: cellRange.from,
                    end: cellRange.to
                  };
                });
                this.setConfidentialityLevel(handsontable, selection, `confidentiality:public}`);
              }
              break;
            case 50:
              // 2 pressed - if row is highlighted set confidentiality to private
              if (selectedRowHeader(handsontable)) {
                const selection = handsontable.getSelectedRange().map(cellRange => {
                  return {
                    start: cellRange.from,
                    end: cellRange.to
                  };
                });
                this.setConfidentialityLevel(handsontable, selection, `confidentiality:private`);
              }
              break;
            case 51:
              // 3 pressed - if row is highlighted set confidentiality to super private
              if (selectedRowHeader(handsontable)) {
                const selection = handsontable.getSelectedRange().map(cellRange => {
                  return {
                    start: cellRange.from,
                    end: cellRange.to
                  };
                });
                this.setConfidentialityLevel(handsontable, selection, `confidentiality:super_private}`);
              }
              break;
            case 73:
              // i pressed - if row is highlighted set to internal
              if (selectedRowHeader(handsontable)) {
                const selection = handsontable.getSelectedRange().map(cellRange => {
                  return {
                    start: cellRange.from,
                    end: cellRange.to
                  };
                });
                this.toggleInternal(handsontable, selection, 1);
              }
              break;
            case 187:
              // get last selected & row/col
              const lastSelected = handsontable.getSelectedLast();
              if (!Ember.isArray(lastSelected) || !hasMultipleZoneOptions(handsontable)) {
                return;
              }
              const selectedRow = lastSelected[0];
              const selectedCol = lastSelected[1];
              // get the cell meta
              const cellMeta = handsontable.getCellMeta(selectedRow, selectedCol);
              // prompt to add new zone
              this.send('addNewZone', cellMeta.data, cellMeta.prop.replace('.value', ''));
              break;
          }
        }
      });
    },
    /**
     * Commits staged changes when user clicks outside fixture table
     *
     * @void
     */
    handleBulkCommitOnOutsideClickEvent: function (event) {
      event.stopImmediatePropagation();
      // if click is outside this element then attempt to commit staged commits
      const {
        target
      } = event;
      // outside click then board is not focused
      if (this.element && !this.element.contains(target)) {
        this.stagedChanges.commit();
      }
    },
    /**
     * Commits staged changes when user clicks outside fixture table
     *
     * @void
     */
    handleBulkCommitOnHoverEvent: function (event) {
      event.stopImmediatePropagation();
      // if click is outside this element then attempt to commit staged commits
      const {
        target
      } = event;
      // outside click then board is not focused
      if (target && (target.classList.contains('fixflo-page-title') || target.classList.contains('fixflo-side-nav') || target.classList.contains('board-filter-actions'))) {
        this.stagedChanges.commit();
      }
    },
    /**
     * Handle double click events anywhere
     *
     * @void
     */
    handleDoubleClickEvent: function (event) {
      if (Array.from(event.target.classList).some(c => c.includes('handsontable'))) {
        const coords = this.getCellCoords();
        return this.onDoubleClick(this.handsontable, event, coords);
      }
    },
    /**
     * Instantiate the handsontable and initialize fixtures table
     */
    didInsertElement: async function () {
      // get manual column resize if set
      const manualColumnResize = this.getManualColumnResize();
      // get the default cell meta
      const cellMeta = this.cellMeta;
      // get default table height
      const height = this.height;
      // load and build column headers first
      let columns = await this.getColumns();
      let colHeaders = columns.map(c => Ember.get(c, 'label'));
      let data = this.data;
      // initialize handsontable
      let handsontable = new _handsontable.default(this.element, {
        data,
        colHeaders,
        columns,
        cellMeta,
        height,
        stretchH: 'all',
        stretchW: 'all',
        autoColumnSize: true,
        filters: true,
        dropdownMenu: false,
        allowRemoveRow: true,
        allowInsertRow: true,
        rowHeaders: false,
        bindRowsWithHeaders: true,
        manualColumnMove: true,
        manualRowMove: true,
        manualColumnResize,
        persistentState: false,
        manualRowResize: true,
        viewportRowRenderingOffset: 30,
        viewportColumnRenderingOffset: colHeaders.length,
        multiColumnSorting: true,
        undo: true,
        search: true,
        observeChanges: false,
        enterBeginsEditing: true,
        enterMoves: {
          row: 0,
          col: 1
        },
        readOnly: this.can.cannot('update fixture') || this.readOnly,
        licenseKey: 'non-commercial-and-evaluation'
      });
      // trigger event actions
      this.inserted(handsontable, this);
      // set properties
      Ember.setProperties(this, {
        handsontable,
        colHeaders,
        columns
      });
      // update settings with initialized
      handsontable.updateSettings({
        afterChange: this.afterChange.bind(this, handsontable),
        afterOnCellMouseDown: this.afterOnCellMouseDown.bind(this, handsontable),
        afterOnCellMouseOver: this.afterOnCellMouseOver.bind(this, handsontable),
        afterOnCellMouseOut: this.afterOnCellMouseOut.bind(this, handsontable),
        afterLoadData: this.afterLoadData.bind(this, handsontable),
        // afterRender: get(this, 'afterRender').bind(this, handsontable),
        afterColumnMove: this.afterColumnMove.bind(this, handsontable),
        // afterCreateRow: get(this, 'justRender').bind(this, handsontable),
        afterScrollVertically: this.afterScrollVertically,
        afterColumnResize: this.afterColumnResize.bind(this, handsontable),
        beforeChange: this.beforeChange.bind(this, handsontable),
        beforeKeyDown: this.beforeKeyDown.bind(this, handsontable),
        contextMenu: this.contextMenu,
        afterRender: () => {
          // handle checkAll
          const checkAll = document.querySelector('.ht_clone_top.handsontable .wtHolder .wtSpreader table.htCore thead tr th input[type="checkbox"][name="checkAll"]');
          if (checkAll) {
            checkAll.addEventListener('change', event => {
              this.send('toggleCheckAll', event, handsontable);
            });
            // afterRender if the all checkbox columns is true, this should be true
            if (handsontable.getSourceData().every(d => d['isChecked'] === true)) {
              // checkAll.checked = true;
              Ember.set(checkAll, 'checked', true);
            }
          }
          // add double click event on all table cells
          document.addEventListener('dblclick', this.handleDoubleClickEvent.bind(this));
        }
      });
      // registerr hotkeys
      this.registerHotKeys(handsontable);
    },
    /**
     * Remove event listeners
     */
    willDestroyElement: function () {
      // remove handler handleDoubleClickEvent()
      document.removeEventListener('dblclick', this.handleDoubleClickEvent);
      // // destory handsontable
      // if(this.handsontable) {
      //     this.handsontable.destroy();
      // }
    }
  });
  _exports.default = _default;
});