define("@fixflo/frontend/templates/ui/type-suggestion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mQpAv+lO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"typeahead-suggestion\"],[8],[1,[24,[\"model\",\"text\"]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/ui/type-suggestion.hbs"
    }
  });
  _exports.default = _default;
});