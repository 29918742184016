define("@fixflo/frontend/models/user", ["exports", "@ember-data/model", "ember-cp-validations", "moment"], function (_exports, _model, _emberCpValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })]
  });
  var _default = _model.default.extend(Validations, {
    uuid: (0, _model.attr)('string'),
    company_uuid: (0, _model.attr)('string'),
    role_uuid: (0, _model.attr)('string'),
    role_name: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    phone_number: (0, _model.attr)('string'),
    avatar_url: (0, _model.attr)('string'),
    company_name: (0, _model.attr)('string'),
    timezone: (0, _model.attr)('string'),
    password: (0, _model.attr)('string'),
    permissions: (0, _model.hasMany)('role-permission', {
      async: false
    }),
    confidentiality_settings: (0, _model.hasMany)('user-confidentiality-setting', {
      async: false
    }),
    last_login: (0, _model.attr)('string'),
    last_online: (0, _model.attr)('string'),
    is_online: (0, _model.attr)('boolean'),
    layout_state: (0, _model.attr)('raw'),
    type: (0, _model.attr)('string'),
    slug: (0, _model.attr)('string'),
    deleted_at: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('string'),
    updated_at: (0, _model.attr)('string'),
    initials: Ember.computed('name', function () {
      let name = this.name;
      if (name.includes(' ')) {
        name = name.split(' ');
        return `${name.firstObject[0]}${name.lastObject[0]}`;
      }
      return name.substr(0, 2);
    }),
    isAdmin: Ember.computed('type', function () {
      return this.type === 'admin';
    }),
    isSuperUser: Ember.computed('type', function () {
      return this.type === 'admin' || this.type === 'owner';
    }),
    lastSeenOnline: Ember.computed('last_online', function () {
      return this.last_online === '0' || this.last_online === 0 ? this.lastLoggedIn : (0, _moment.default)(this.last_online).fromNow();
    }),
    lastLoggedIn: Ember.computed('last_login', function () {
      return this.last_login ? (0, _moment.default)(this.last_login).fromNow() : 'Never';
    }),
    updatedAgo: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).fromNow();
    }),
    updatedAt: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).format('YYYY-MM-DD HH:mm A');
    }),
    createdAgo: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).fromNow();
    }),
    createdAt: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).format('YYYY-MM-DD HH:mm A');
    })
  });
  _exports.default = _default;
});