define("@fixflo/frontend/components/free-table", ["exports", "handsontable"], function (_exports, _handsontable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      /**
       * Prompt dialog for user to set and manage board columns
       */
      manageColumns: function () {
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // get hot instance
        const handsontable = this.handsontable;
        // get the baord
        const board = this.source;
        // load all columns
        const columns = this.store.peekAll('free-board-column');
        // open the dialog
        this.dialog.open('free-board-columns', null, {
          title: 'Manage Columns',
          columns,
          board,
          addNewColumn: () => {
            const order = columns.map(c => Ember.get(c, 'board_uuid') === Ember.get(this, 'source.id')).length + 1;
            let newColumn = this.store.createRecord('free-board-column', {
              board_uuid: Ember.get(this, 'source.id'),
              read_only: false,
              order
            });
          },
          removeColumn: column => {
            column.destroyRecord();
          },
          isBoardColumn: column => {
            return Ember.get(column, 'board_uuid') === Ember.get(this, 'source.id');
          },
          acceptText: 'Done',
          accept: () => {
            // get columns belonging to this board
            const boardColumns = columns.filter(c => Ember.get(c, 'board_uuid') === Ember.get(this, 'source.id'));
            // save columns
            Ember.RSVP.all(boardColumns.invoke('save')).then(response => {
              // update table with new columns
              const updatedColumns = boardColumns.map(this.buildColumn.bind(this));
              const colHeaders = updatedColumns.map(c => Ember.get(c, 'label'));
              // update to table and render and component
              handsontable.updateSettings({
                columns: updatedColumns,
                colHeaders
              });
              setTimeout(() => {
                handsontable.render();
              }, 1000);
              // set to component
              Ember.setProperties(this, {
                columns: updatedColumns,
                colHeaders
              });
              // done
              this.presenter._accept();
            });
          }
        });
      }
    },
    /**
     * Inject the ajax adapter service
     */
    ajax: Ember.inject.service(),
    /**
     * Inject the store adapter service
     */
    store: Ember.inject.service(),
    /**
     * Inject the dialog service
     */
    dialog: Ember.inject.service(),
    /**
     * Initial data
     */
    data: Ember.A([]),
    /**
     * Working data that has already been transformed
     */
    workingData: Ember.A([]),
    /**
     * Columns in the table
     */
    columns: Ember.A([]),
    /**
     * The column headers
     */
    colHeaders: Ember.A([]),
    /**
     * The columns original models
     */
    columnModels: Ember.A([]),
    /**
     * Initiall number of rows to start with
     */
    startRows: 1,
    /**
     * Initiall number of columns to start with
     */
    startCols: 10,
    /**
     * Save free board changes
     */
    afterChange: function (handsontable, changes, source) {
      // console.log('[all changes]', changes);
      const requests = [];
      const board_uuid = Ember.get(this, 'source.id');
      const values = [];
      const massPush = changes.length > 5;
      changes.forEach(([row, column, oldValue, value]) => {
        // get the real row if applicable
        const meta = handsontable.getCellMetaAtRow(row);
        if (meta.firstObject && meta.firstObject.realRow) {
          row = meta.firstObject.realRow;
        }
        // set insert data
        let data = {
          board_uuid,
          value,
          row,
          key: column
        };
        values.pushObject(data);
        // create request
        if (!massPush) {
          let request = this.fetch.post(`free-board-inputs/set-value`, {
            data
          });
          requests.pushObject(request);
        }
      });
      // if requests over 5, send all inserts in mass
      if (massPush) {
        return this.fetch.post(`free-board-inputs/set-multiple-values`, {
          data: {
            values
          }
        }).then(res => res);
      }
      // save
      return Ember.RSVP.all(requests).then(response => {
        // send callback action if exists
        if (this.saved) {
          this.saved(response, [row, column, oldValue, value]);
        }
      });
    },
    /**
     * After a row is removed delete all the data for that row
     */
    afterRemoveRow: function (handsontable, index, amount, rows, source) {
      const board_uuid = Ember.get(this, 'source.id');
      // get the real rows
      rows = rows.map(row => {
        const meta = handsontable.getCellMetaAtRow(row);
        return meta.firstObject.realRow;
      });
      return this.fetch.post(`free-board-inputs/remove-rows`, {
        data: {
          board_uuid,
          rows
        }
      });
    },
    /**
     * After moving a row
     */
    afterRowMove: function (handsontable, rows, target) {
      // wip
      // const board_uuid = get(this, 'source.id');
      // const movements = [];
      // rows.forEach(row => {
      //     let newRow = (row + target) - ((row === 0) ? 1 : 2);
      //     movements.pushObject({
      //         from: row,
      //         to: newRow
      //     });
      // });
      // return get(this, 'ajax').post(`free-board-inputs/move-rows`, {
      //     data: {
      //         movements
      //     }
      // });
    },
    /**
     * After moving a column
     */
    afterColumnMove: function (handsontable, columns, target) {
      // do nothing for now
      const headers = handsontable.getColHeader();
      const columnModels = this.columnModels;
      // console.log(columnModels);
      // sort columns by new index
      columnModels.forEach(c => {
        const position = headers.indexOf(Ember.get(c, 'label'));
        console.log(`${Ember.get(c, 'label')} moved to ${position}`);
        Ember.set(c, 'order', position);
      });
      return Ember.RSVP.all(columnModels.invoke('save')).then(response => response);
    },
    /**
     * Build the initial data
     */
    getData: function () {
      if (!this.data) {
        return [];
      }
      const d = [];
      this.data.forEach(datum => {
        if (!d[Ember.get(datum, 'row')]) {
          d[Ember.get(datum, 'row')] = {
            rowId: Ember.get(datum, 'row')
          };
        }
        d[Ember.get(datum, 'row')][Ember.get(datum, 'key')] = Ember.get(datum, 'value');
      });
      const workingData = d.filter(Boolean);
      Ember.setProperties(this, {
        workingData
      });
      return workingData;
    },
    /**
     * Get the column headers
     */
    getColumns: function () {
      return this.store.query('free-board-column', {
        board_uuid: Ember.get(this, 'source.id')
      }).then(columns => {
        Ember.setProperties(this, {
          columnModels: columns
        });
        return columns.map(this.buildColumn.bind(this)).sortBy('order');
      });
    },
    /**
     * Build columns into proper column objects
     */
    buildColumn: function (column) {
      let newColumn = {};
      // set label
      newColumn.label = column.label;
      // set readOnly permissions
      newColumn.readOnly = column.read_only;
      // set data key from label
      newColumn.data = column.key;
      // set the column order
      newColumn.order = column.order;
      // set readOnly if its set
      if (this.readOnly === true) {
        newColumn.readOnly = true;
      }
      return newColumn;
    },
    /**
     * Iterate and create default cell meta
     */
    setCellMeta: function (handsontable) {
      const data = this.workingData;
      const columns = this.colHeaders;
      if (!data) return;
      data.forEach((datum, row) => {
        columns.forEach((column, col) => {
          handsontable.setCellMeta(row, col, 'realRow', Ember.get(datum, 'rowId'));
        });
      });
    },
    /**
     * Instantiate the handsontable and initialize free table
     */
    didInsertElement: async function () {
      // load and build column headers first
      const columns = await this.getColumns();
      const colHeaders = columns.map(c => Ember.get(c, 'label'));
      const data = this.getData();
      // instantiate
      let handsontable = new _handsontable.default(this.element, {
        data,
        colHeaders,
        columns,
        minSpareRows: 1,
        stretchH: 'all',
        stretchW: 'all',
        autoColumnSize: true,
        rowHeaders: true,
        filters: true,
        search: true,
        dropdownMenu: true,
        contextMenu: true,
        bindRowsWithHeaders: true,
        manualColumnMove: true,
        manualRowMove: true,
        manualColumnResize: true,
        manualRowResize: true,
        persistentState: true,
        licenseKey: 'non-commercial-and-evaluation'
      });
      // set cell meta
      this.setCellMeta(handsontable);
      // update settings with initialized
      handsontable.updateSettings({
        afterChange: this.afterChange.bind(this, handsontable),
        afterRemoveRow: this.afterRemoveRow.bind(this, handsontable),
        beforeRender: this.setCellMeta.bind(this, handsontable),
        afterRowMove: this.afterRowMove.bind(this, handsontable),
        afterColumnMove: this.afterColumnMove.bind(this, handsontable)
      });
      // set properties
      // setProperties(this, {
      this.columns = columns;
      this.colHeaders = colHeaders;
      this.handsontable = handsontable;
      this.data = data;
      // });
      // trigger event actions
      if (this.inserted) {
        this.inserted(handsontable, this);
      }
      // double render table after 500ms
      setTimeout(() => {
        handsontable.render();
      }, 500);
    }
  });
  _exports.default = _default;
});