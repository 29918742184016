define("@fixflo/frontend/controllers/dashboard/settings/groups/manage-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      /**
       * Add a member to the group
       */
      addMember: function () {
        // create user to be created
        const member = this.store.createRecord('group-member', {
          group_uuid: this.get('model.id')
        });
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          this.set('presenter', presenter);
        });
        // render dialog
        this.dialog.open('add-user-to-group', member, {
          title: 'Add member to group',
          acceptText: 'Confirm',
          controller: this,
          accept: () => {
            this.presenter.startLoading();
            member.save().then(() => {
              this.presenter.stopLoading();
              this.notifications.success('User added to group');
              this.presenter._accept();
            }).catch(error => {
              this.presenter.stopLoading();
              this.notifications.serverError(error);
            });
          }
        });
      },
      /**
       * Remove a meber from the group
       */
      removeMember: function (member) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Remove member',
          acceptText: `Remove ${member.get('user.name')}`,
          message: `Are you sure you want to remove ${member.get('user.name')} from this group?`
        }).then(() => {
          this.dialog.presenter.startLoading();
          member.destroyRecord().then(member => {
            this.dialog.presenter.stopLoading();
            this.notifications.info(`${member.get('user.name')} removed`);
          }).catch(error => {
            this.dialog.presenter.stopLoading();
            this.notifications.serverError(error);
          });
        });
      }
    },
    /**
     * All group memers in cache
     */
    allGroupMembers: Ember.computed(function () {
      return this.store.peekAll('group-member');
    }),
    /**
     * This groups members
     */
    groupMembers: Ember.computed('allGroupMembers.@each', function () {
      return this.allGroupMembers.filter(gm => !gm.get('isNew') && gm.get('group_uuid') === this.get('model.id'));
    })
  });
  _exports.default = _default;
});