define("@fixflo/frontend/utils/is-model-or-proxy", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isModelOrProxy;
  function isModelOrProxy(subject) {
    return subject instanceof _model.default || subject instanceof Ember.ObjectProxy;
  }
});