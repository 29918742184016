define("@fixflo/frontend/routes/dashboard/settings/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    /**
     * Inject the current user service
     *
     * @var {Service}
     */
    currentUser: Ember.inject.service(),
    model: function () {
      return Ember.RSVP.hash({
        user: this.currentUser.user,
        userConfidentialitySettings: this.store.findAll('user-confidentiality-setting')
      });
    }
  });
  _exports.default = _default;
});