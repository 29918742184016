define("@fixflo/frontend/routes/dashboard/settings/index/confidentiality-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function () {
      return this.modelFor('dashboard.settings.index');
    }
  });
  _exports.default = _default;
});