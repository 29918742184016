define("@fixflo/frontend/controllers/admin/panel/companies/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      /**
       * Create a new company
       */
      create: async function () {
        const company = this.store.createRecord('company', {
          name: null
        });
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // render dialog
        this.dialog.open('company-form', company, {
          title: 'Create new company',
          acceptText: 'Save',
          controller: this,
          accept: () => {
            company.save().then(company => {
              this.notifications.success('New company added');
              this.presenter._accept();
            });
          },
          decline: () => {
            company.destroyRecord();
            this.presenter._decline();
          }
        });
      },
      /**
       * Edit a company
       */
      edit: async function (company) {
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // render dialog
        this.dialog.open('company-form', company, {
          title: Ember.String.htmlSafe(`Edit company <i>${Ember.get(company, 'name')}</i>`),
          acceptText: 'Save Changes',
          controller: this,
          accept: () => {
            company.save().then(company => {
              this.notifications.success('Changes to company saved');
              this.presenter._accept();
            });
          }
        });
      },
      /**
       * Delete a company
       */
      delete: function (company) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Delete company',
          acceptText: 'Confirm and delete',
          message: Ember.String.htmlSafe(`Are you sure you want to delete this company <i>${Ember.get(company, 'name')}</i>?`)
        }).then(() => {
          company.destroyRecord().then(() => {
            this.notifications.info('User deleted');
          }).catch(error => {
            this.notifications.serverError(error);
          });
        });
      }
    },
    /**
     * Query parameters
     */
    queryParams: ['page', 'limit', 'sort', 'query'],
    /**
     * Current page
     */
    page: 1,
    /**
     * Default pagination limit
     */
    limit: 10,
    /**
     * Default sorting
     */
    sort: '-created_at'
  });
  _exports.default = _default;
});