define("@fixflo/frontend/templates/dialog/view-board-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fxLnwD+I",
    "block": "{\"symbols\":[\"layers\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"table table-bordered\"],[8],[0,\"\\n\\t\"],[7,\"thead\",true],[8],[0,\"\\n\\t\\t\"],[7,\"tr\",true],[10,\"class\",\"bg-body\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"th\",true],[10,\"colspan\",\"2\"],[8],[0,\"Active Filters\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"tbody\",true],[8],[0,\"\\n\\t\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\\t\"],[7,\"td\",true],[10,\"colspan\",\"2\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"pre\",true],[10,\"class\",\"pre-like-code\"],[10,\"style\",\"overflow-x: scroll; width: 745px;\"],[8],[1,[28,\"json-pretty-print\",[[24,[\"params\"]]],null],false],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"hasGeoFilter\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"thead\",true],[8],[0,\"\\n\\t\\t\\t\"],[7,\"tr\",true],[10,\"class\",\"bg-body\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"th\",true],[10,\"colspan\",\"2\"],[8],[0,\"Geographical Filter\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"tbody\",true],[8],[0,\"\\n\\t\\t\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"td\",true],[10,\"colspan\",\"2\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[5,\"leaflet-map\",[],[[\"@lat\",\"@lng\",\"@zoom\",\"@onLoad\"],[\"1.3521\",\"103.8198\",6,[28,\"action\",[[23,0,[]],\"setGeoFilterMapInstance\",[24,[\"board\"]],[24,[\"isDischarge\"]]],[[\"target\"],[[24,[\"controller\"]]]]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[6,[23,1,[\"tile\"]],[],[[\"@url\"],[\"https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png\"]]],[0,\"\\n\\t\\t\\t\\t\\t\"]],\"parameters\":[1]}],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/view-board-params.hbs"
    }
  });
  _exports.default = _default;
});