define("@fixflo/frontend/utils/clean-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = cleanObject;
  function cleanObject(obj) {
    let result = {},
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key) && obj[key]) {
        result[key] = obj[key];
      }
    }
    return result;
  }
});