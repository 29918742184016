define("@fixflo/frontend/components/leaflet-draw-control", ["exports", "ember-leaflet/components/base-layer"], function (_exports, _baseLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var _default = _baseLayer.default.extend({
    enableDeleting: true,
    // Default value
    enableEditing: true,
    // Default value
    showDrawingLayer: true,
    // Default value

    leafletEvents: [L.Draw.Event.CREATED, L.Draw.Event.EDITED, L.Draw.Event.EDITMOVE, L.Draw.Event.EDITRESIZE, L.Draw.Event.EDITSTART, L.Draw.Event.EDITSTOP, L.Draw.Event.EDITVERTEX, L.Draw.Event.DELETED, L.Draw.Event.DELETESTART, L.Draw.Event.DELETESTOP, L.Draw.Event.DRAWSTART, L.Draw.Event.DRAWSTOP, L.Draw.Event.DRAWVERTEX],
    leafletOptions: ['draw', 'edit', 'enableEditing', 'position', 'showDrawingLayer'],
    editableLayers: [],
    usedLeafletEvents: Ember.computed('leafletEvents', function () {
      return this.leafletEvents.filter(eventName => {
        eventName = Ember.String.camelize(eventName.replace(':', ' '));
        const methodName = '_' + eventName;
        const actionName = 'on' + Ember.String.classify(eventName);
        return Ember.get(this, methodName) !== undefined || Ember.get(this, actionName) !== undefined;
      });
    }),
    addToContainer() {
      if (this._layer) {
        this.parentComponent._layer.addLayer(this._layer);
      }
    },
    createLayer() {
      const editableLayers = this.editableLayers;
      let drawingLayerGroup;
      if (this.showDrawingLayer) {
        drawingLayerGroup = new this.L.FeatureGroup(editableLayers);
        const map = Ember.get(this, 'parentComponent._layer');
        drawingLayerGroup.addTo(map);
        // trigger action/event draw control created
        if (typeof this.onDrawFeatureGroupCreated === 'function') {
          this.onDrawFeatureGroupCreated(drawingLayerGroup, map);
        }
      }
      return drawingLayerGroup;
    },
    didCreateLayer() {
      const map = Ember.get(this, 'parentComponent._layer');
      if (map) {
        let options = Ember.getProperties(this, 'position', 'draw', 'edit');
        if (!options.position) {
          options.position = 'topleft';
        }
        if (this._layer) {
          options.edit = _objectSpread({
            featureGroup: this._layer
          }, options.edit);
          if (!this.enableEditing && !options.edit.edit) {
            options.edit.edit = false;
          }
          if (!this.enableDeleting && !options.edit.remove) {
            options.edit.remove = false;
          }

          // Extend the default draw object with options overrides
          options.draw = _objectSpread(_objectSpread({}, this.L.drawLocal.draw), options.draw);
          // create draw control
          const drawControl = new this.L.Control.Draw(options);
          // trigger action/event draw control created
          if (typeof this.onDrawControlCreated === 'function') {
            this.onDrawControlCreated(drawControl, map);
          }
          // Add the draw control to the map
          map.addControl(drawControl);
          // trigger action/event draw control added to map
          if (typeof this.onDrawControlAddedToMap === 'function') {
            this.onDrawControlAddedToMap(drawControl, map);
          }

          // If showDrawingLayer, add new layer to the layerGroup
          if (this.showDrawingLayer) {
            map.on(this.L.Draw.Event.CREATED, e => {
              const layer = e.layer;
              this._layer.addLayer(layer);
            });
          }
        }
      }
    },
    _addEventListeners() {
      this._eventHandlers = {};
      this.usedLeafletEvents.forEach(eventName => {
        const originalEventName = eventName;
        const map = Ember.get(this, 'parentComponent._layer');
        // Cleanup the Leaflet Draw event names that have colons, ex:'draw:created'
        eventName = Ember.String.camelize(eventName.replace(':', ' '));
        const actionName = 'on' + Ember.String.classify(eventName);
        const methodName = '_' + eventName;
        // Create an event handler that runs the function inside an event loop.
        this._eventHandlers[originalEventName] = function (e) {
          Ember.run(() => {
            // Try to invoke/send an action for this event
            this.invokeAction(actionName, e, this._layer, map);
            // Allow classes to add custom logic on events as well
            if (typeof this[methodName] === 'function') {
              Ember.run(this, this[methodName], e, this._layer, map);
            }
          });
        };

        // The events for Leaflet Draw are on the map object, not the layer
        map.addEventListener(originalEventName, this._eventHandlers[originalEventName], this);
      });
    },
    _removeEventListeners() {
      if (this._eventHandlers) {
        this.usedLeafletEvents.forEach(eventName => {
          const map = Ember.get(this, 'parentComponent._layer');
          // The events for Leaflet Draw are on the map object, not the layer
          map.removeEventListener(eventName, this._eventHandlers[eventName], this);
          delete this._eventHandlers[eventName];
        });
      }
    }
  });
  _exports.default = _default;
});