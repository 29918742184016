define("@fixflo/frontend/abilities/duplicate-fixture", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberCan.Ability.extend({
    /**
     * Inject the current user service
     */
    currentUser: Ember.inject.service(),
    /**
     * Inject the current user
     */
    user: Ember.computed.readOnly('currentUser.user'),
    /**
     * Get the permission for this subject
     */
    permission: Ember.computed('user.isSuperUser', 'permission.share', function () {
      return Ember.get(this, 'user.permissions').find(p => Ember.get(p, 'subject') === 'duplicate-fixture');
    }),
    /**
     * Can create duplicate-fixtures
     */
    canCreate: Ember.computed('user.isSuperUser', 'permission.create', function () {
      if (Ember.get(this, 'user.isSuperUser')) {
        return true;
      } else if (!this.permission) {
        return false;
      }
      return Ember.get(this, 'permission.create');
    }),
    /**
     * Can view duplicate-fixtures
     */
    canView: Ember.computed('user.isSuperUser', 'permission.read', function () {
      if (Ember.get(this, 'user.isSuperUser')) {
        return true;
      } else if (!this.permission) {
        return false;
      }
      return Ember.get(this, 'permission.read');
    }),
    /**
     * Can edit duplicate-fixtures
     */
    canEdit: Ember.computed('user.isSuperUser', 'permission.update', function () {
      if (Ember.get(this, 'user.isSuperUser')) {
        return true;
      } else if (!this.permission) {
        return false;
      }
      return Ember.get(this, 'permission.update');
    }),
    /**
     * Can delete duplicate-fixtures
     */
    canDelete: Ember.computed('user.isSuperUser', 'permission.delete', function () {
      if (Ember.get(this, 'user.isSuperUser')) {
        return true;
      } else if (!this.permission) {
        return false;
      }
      return Ember.get(this, 'permission.delete');
    }),
    /**
     * Can import duplicate-fixtures
     */
    canImport: Ember.computed('user.isSuperUser', 'permission.import', function () {
      if (Ember.get(this, 'user.isSuperUser')) {
        return true;
      } else if (!this.permission) {
        return false;
      }
      return Ember.get(this, 'permission.import');
    }),
    /**
     * Can export duplicate-fixtures
     */
    canExport: Ember.computed('user.isSuperUser', 'permission.export', function () {
      if (Ember.get(this, 'user.isSuperUser')) {
        return true;
      } else if (!this.permission) {
        return false;
      }
      return Ember.get(this, 'permission.export');
    }),
    /**
     * Can share duplicate-fixtures
     */
    canShare: Ember.computed('user.isSuperUser', 'permission.share', function () {
      if (Ember.get(this, 'user.isSuperUser')) {
        return true;
      } else if (!this.permission) {
        return false;
      }
      return Ember.get(this, 'permission.share');
    })
  });
  _exports.default = _default;
});