define("@fixflo/frontend/templates/dialog/add-user-to-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "k0DHIQdB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Select a user to add to group\\n    \"],[9],[0,\"\\n    \"],[5,\"model-select\",[],[[\"@placeholder\",\"@selectedModel\",\"@modelName\",\"@allowClear\",\"@searchEnabled\",\"@infiniteScroll\",\"@renderInPlace\",\"@onChange\"],[\"Select a user to add to group\",[24,[\"contextObject\",\"user_uuid\"]],\"user\",true,true,false,true,[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"contextObject\",\"user_uuid\"]]],null]],null]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/add-user-to-group.hbs"
    }
  });
  _exports.default = _default;
});