define("@fixflo/frontend/components/placeholder/board-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Default class names applied to Component
     *
     * @type Array
     */
    classNames: ['board-card', 'is-loading'],
    /**
     * Class names binded by property states
     *
     * @type Array
     */
    classNameBindings: ['isLoading', 'isAnimated'],
    /**
     * The loading state of the component
     *
     * @type {Boolean}
     */
    isAnimated: true
  });
  _exports.default = _default;
});