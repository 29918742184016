define("@fixflo/frontend/templates/components/file-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6OepNxBE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,\"s3-upload\",[],[[\"@multiple\",\"@key\",\"@keyId\",\"@file\",\"@fileId\",\"@progress\",\"@fileUrl\",\"@fileType\",\"@autoSaveTo\",\"@onDidUpload\",\"@onUploadStart\",\"@saveProperty\"],[[22,\"multiple\"],[22,\"key\"],[22,\"keyId\"],[22,\"file\"],[22,\"fileId\"],[22,\"progress\"],[22,\"fileUrl\"],[22,\"fileType\"],[22,\"autoSaveTo\"],[22,\"onDidUpload\"],[22,\"onUploadStart\"],[22,\"saveProperty\"]]]],[0,\"\\n\"],[14,1,[[24,[\"fileUrl\"]],[24,[\"progress\"]],[24,[\"file\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/components/file-upload.hbs"
    }
  });
  _exports.default = _default;
});