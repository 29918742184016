define("@fixflo/frontend/mixins/manages-report-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    actions: {
      /**
       * Create a new report type
       *
       * @void
       */
      createReportType: function () {
        const reportType = this.store.createRecord('report-type', {
          filters: {
            confidentialityLevel: []
          },
          format: 'docx',
          name: null
        });
        reportType.type_sorts.pushObject(this.store.createRecord('report-type-sort', {
          report_type_uuid: reportType.get('id'),
          sort_by: 'created_at',
          sort_order: 'asc',
          order: Ember.get(reportType, 'type_sorts.length') || 0
        }));
        const confidentialityLevels = this.confidentialityLevels;
        const formats = this.formats;
        // set dialog presenter to controller
        this.dialog.one('created', createReportTypePresenter => this.setProperties({
          createReportTypePresenter
        }));
        // render dialog
        this.dialog.open('report-type-form', reportType, {
          title: 'Create a new report type',
          acceptText: 'Save',
          className: 'dialog-xl',
          isAddingSource: false,
          isSavingSource: false,
          // sortDirection: 'asc',
          confidentialityLevels,
          formats,
          toggleSortAscDesc: this.toggleSortAscDesc.bind(this),
          user: this.currentUser.user,
          controller: this,
          accept: () => {
            // make sure type has sources
            if (Ember.isArray(reportType.type_sources) && reportType.type_sources.length === 0) {
              return this.notifications.warning('Cannot save report type without sources');
            }
            // make sure at least one sort order is defined
            if (Ember.isArray(reportType.type_sorts) && reportType.type_sorts.length === 0) {
              return this.notifications.warning('Specify at least one sort field');
            }
            // save report type
            this.createReportTypePresenter.startLoading();
            return reportType.save().then(reportType => {
              // refresh refresh route in bg
              Ember.tryInvoke(this, 'refreshRoute');
              // notify user and close dialog

              // intercept has many relationships to remove duplicates
              reportType.set('type_sorts', reportType.type_sorts.filter(item => !item.isNew));
              reportType.set('type_sources', reportType.type_sources.filter(item => !item.isNew));
              this.notifications.success(`New report ${reportType.name} created`);
              this.createReportTypePresenter.stopLoading();
              this.createReportTypePresenter._accept();

              // delete cached report types
              const reportTypesStorageKey = `report_types_${this.currentUser.id.replace(/\-/g, '_')}`;
              this.currentUser.options.set(reportTypesStorageKey, []);
            }).catch(error => {
              this.createReportTypePresenter.stopLoading();
              this.notifications.serverError(error);
            });
          },
          decline: () => {
            reportType.destroyRecord();
            this.createReportTypePresenter._decline();
          }
        });
      },
      /**
       * Edit a report type
       *
       * @void
       */
      editReportType: function (reportType) {
        const confidentialityLevels = this.confidentialityLevels;
        const formats = this.formats;
        // set dialog presenter to controller
        this.dialog.one('created', editReportTypePresenter => this.setProperties({
          editReportTypePresenter
        }));
        // render dialog
        this.dialog.open('report-type-form', reportType, {
          title: Ember.String.htmlSafe(`Edit <i>${Ember.get(reportType, 'name')}</i> report type`),
          acceptText: 'Save Changes',
          className: 'dialog-xl',
          isAddingSource: false,
          isSavingSource: false,
          // sortDirection: reportType.get('sort_by').startsWith('-') ? 'desc' : 'asc',
          confidentialityLevels,
          formats,
          toggleSortAscDesc: this.toggleSortAscDesc.bind(this),
          controller: this,
          accept: () => {
            // make sure type has sources
            if (Ember.isArray(reportType.type_sources) && reportType.type_sources.length === 0) {
              return this.notifications.warning('Cannot save report type without sources');
            }
            // make sure at least one sort order is defined
            if (Ember.isArray(reportType.type_sorts) && reportType.type_sorts.length === 0) {
              return this.notifications.warning('Specify at least one sort field');
            }
            // save changes
            this.editReportTypePresenter.startLoading();
            return reportType.save().then(reportType => {
              this.notifications.info(`Changes to report type ${reportType.get('name')} saved`);
              this.editReportTypePresenter.stopLoading();
              this.editReportTypePresenter._accept();

              // delete cached report types
              const reportTypesStorageKey = `report_types_${this.currentUser.id.replace(/\-/g, '_')}`;
              this.currentUser.options.set(reportTypesStorageKey, []);
            }).catch(error => {
              this.editReportTypePresenter.stopLoading();
              this.notifications.serverError(error);
            });
          }
        });
      },
      /**
       * Reorders the type sources
       */
      reorderSources: function ({
        sourceList,
        sourceIndex,
        targetList,
        targetIndex
      }) {
        if (sourceList === targetList && sourceIndex === targetIndex) return;
        const item = sourceList.objectAt(sourceIndex);
        item.set('order', targetIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);
        targetList.forEach((typeSource, order) => {
          typeSource.setProperties({
            order
          });
        });
        Ember.set(this, 'sources', targetList);
      },
      /**
       * Remove a source from report type
       */
      removeSource: function (reportType, source) {
        reportType.type_sources.removeObject(source);
        source.destroyRecord();
      },
      /**
       * Prompt dialog to add report type source
       */
      quickAddSource: function (reportType, board) {
        const source = this.store.createRecord('report-type-source', {
          report_type_uuid: reportType.get('id'),
          board_uuid: board.get('id'),
          board,
          order: Ember.get(reportType, 'type_sources.length') || 0
        });
        // make sure it isnt already a source
        if (reportType.type_sources.includes(source)) {
          return this.notifications.warning('This source has already been added');
        }
        // if the report type is new just queue the source and save after type
        if (reportType.get('isNew')) {
          source.set('board', board);
          return reportType.type_sources.pushObject(source);
        }
        // set the edit or create presenter to saving state for source
        if (this.editPresenter) {
          this.editPresenter.set('isSavingSource', true);
        } else if (this.createPresenter) {
          this.createPresenter.set('isSavingSource', true);
        }
        // save report type
        return source.save().then(source => {
          // add to types then save
          reportType.type_sources.pushObject(source);
          // set the edit or create presenter to saving state for source
          if (this.editPresenter) {
            this.editPresenter.set('isSavingSource', false);
          } else if (this.createPresenter) {
            this.createPresenter.set('isSavingSource', false);
          }
        }).catch(error => {
          this.notifications.serverError(error);
        });
      },
      /**
       * Prompt dialog to add report type source
       */
      addSource: function (reportType) {
        const source = this.store.createRecord('report-type-source', {
          report_type_uuid: reportType.get('id'),
          board_uuid: null,
          order: Ember.get(reportType, 'type_sources.length') || 0
        });
        // set dialog presenter to controller
        this.dialog.one('created', addSourcePresenter => this.setProperties({
          addSourcePresenter
        }));
        // render dialog
        this.dialog.open('report-type-source-form', source, {
          title: 'Select board to source report',
          acceptText: 'Add Source',
          removeSource: source => source.destroyRecord(),
          controller: this,
          accept: () => {
            // make sure it isnt already a source
            if (reportType.type_sources.includes(source)) {
              return this.notifications.warning('This source has already been added');
            }
            this.addSourcePresenter.startLoading();
            // save report type
            source.save().then(source => {
              reportType.type_sources.pushObject(source);
              this.addSourcePresenter.stopLoading();
              this.addSourcePresenter._accept();
            }).catch(error => {
              this.addSourcePresenter.stopLoading();
              this.notifications.serverError(error);
            });
          },
          decline: () => {
            source.destroyRecord();
            this.addSourcePresenter._decline();
          }
        });
      },
      /**
       * Remove a sort order key from the report type
       */
      removeSortOrderKey: function (reportType, sortItem) {
        reportType.type_sorts.removeObject(sortItem);
        sortItem.destroyRecord();
      },
      /**
       * Add sort order key
       */
      addSortOrderKey: function (reportType) {
        const sortOrderKey = this.store.createRecord('report-type-sort', {
          report_type_uuid: reportType.get('id'),
          sort_by: 'laycan_range_start',
          sort_order: 'asc',
          order: Ember.get(reportType, 'type_sorts.length') || 0
        });
        // if the report type is new just queue the source and save after type
        if (reportType.get('isNew')) {
          return reportType.type_sorts.pushObject(sortOrderKey);
        }
        // set the edit or create presenter to saving state for source
        if (this.editPresenter) {
          this.editPresenter.set('isSavingSource', true);
        } else if (this.createPresenter) {
          this.createPresenter.set('isSavingSource', true);
        }
        // save report type
        return sortOrderKey.save().then(sortOrderKey => {
          // add to types then save
          reportType.type_sorts.pushObject(sortOrderKey);
          // set the edit or create presenter to saving state for source
          if (this.editPresenter) {
            this.editPresenter.set('isSavingSource', false);
          } else if (this.createPresenter) {
            this.createPresenter.set('isSavingSource', false);
          }
        }).catch(error => {
          this.notifications.serverError(error);
        });
      },
      /**
       * Switch order/direction of a sort item
       */
      changeSortOrder: function (reportType, sortItem, value) {
        const cleanSortBy = sortItem.sort_by.startsWith('-') ? sortItem.sort_by.substring(1) : sortItem.sort_by;
        sortItem.set('sort_order', value);
        if (value === 'asc') {
          sortItem.set('sort_by', cleanSortBy);
        }
        if (value === 'desc') {
          sortItem.set('sort_by', `-${cleanSortBy}`);
        }
      },
      /**
       * Reorders the sort options
       */
      reorderSorts: function ({
        sourceList,
        sourceIndex,
        targetList,
        targetIndex
      }) {
        if (sourceList === targetList && sourceIndex === targetIndex) return;
        const item = sourceList.objectAt(sourceIndex);
        item.set('order', targetIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);
        targetList.forEach((typeSortItem, order) => typeSortItem.set('order', order));
        Ember.set(this, 'type_sorts', targetList);
      },
      /**
       * Delete a report type
       *
       * @void
       */
      deleteReportType: function (reportType) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Delete report type',
          acceptText: 'Confirm and delete',
          message: Ember.String.htmlSafe(`Are you sure you want to delete this report type <i>${Ember.get(reportType, 'name')}</i>?`),
          accept: () => {
            this.dialog.startLoading();
            reportType.destroyRecord().then(() => {
              return this.refreshRoute().then(() => {
                this.dialog.stopLoading();
                this.dialog._close();
                this.notifications.info('Report type deleted');

                // delete cached report types
                const reportTypesStorageKey = `report_types_${this.currentUser.id.replace(/\-/g, '_')}`;
                this.currentUser.options.set(reportTypesStorageKey, []);
              });
            }).catch(error => {
              this.dialog.stopLoading();
              this.notifications.serverError(error);
            });
          }
        });
      },
      /**
       * Toggle a selected column
       *
       * @void
       */
      toggleConfidentialitySelected: function (reportType, level) {
        if (!Ember.isArray(Ember.get(reportType, 'filters.confidentialityLevel'))) {
          Ember.set(reportType, 'filters.confidentialityLevel', []);
        }
        let selected = Ember.get(reportType, 'filters.confidentialityLevel');
        if (selected.includes(level)) {
          selected.removeObject(level);
        } else {
          selected.pushObject(level);
        }
      }
    },
    /**
     * Toggle the sort asc/desc
     *
     * @void
     */
    toggleSortAscDesc: function (reportType, sortDirection) {
      const sort = Ember.get(reportType, 'sort_by');
      if (this.createReportTypePresenter) {
        Ember.setProperties(this.createReportTypePresenter, {
          sortDirection
        });
      } else if (this.editReportTypePresenter) {
        Ember.setProperties(this.editReportTypePresenter, {
          sortDirection
        });
      }
      if (sortDirection === 'asc') {
        Ember.setProperties(reportType, {
          sort_by: sort.substr(1)
        });
      } else {
        Ember.setProperties(reportType, {
          sort_by: `-${sort}`
        });
      }
    },
    /**
     * Formats
     * @type {Array}
     */
    formats: ['txt', 'docx', 'pdf', 'xlsx', 'csv', 'tsv', 'ods', 'xls', 'html'],
    /**
     * Optional confidentiality levels for fixtures
     */
    confidentialityLevels: Ember.A([{
      name: 'Public',
      key: 'public'
    }, {
      name: 'Private & Confidential',
      key: 'private'
    }, {
      name: 'Super Private & Confidential',
      key: 'super_private'
    }])
  });
  _exports.default = _default;
});