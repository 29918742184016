define("@fixflo/frontend/components/highlight-text", ["exports", "mark.js"], function (_exports, _mark) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * The text to highlight
     */
    context: '',
    /**
     * Query '' or queries [] to highlight
     */
    query: '',
    /**
     * On element insert instantiate mark.js
     */
    didInsertElement: function () {
      const instance = new _mark.default(this.element);
      instance.mark(this.query, {
        separateWordSearch: false
      });
    }
  });
  _exports.default = _default;
});