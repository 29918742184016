define("@fixflo/frontend/controllers/dashboard/boards/free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj, _init;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = Ember.Controller.extend((_dec = Ember._tracked, (_obj = {
    actions: {
      /**
       * Prompt dialog for user to set and manage board columns
       */
      manageColumns: function () {
        this.freeTable.send('manageColumns');
      },
      /**
       * Set the handsontable instance
       */
      setInstance: function (handsontable, freeTable) {
        Ember.setProperties(this, {
          handsontable,
          freeTable
        });
      },
      /**
       * Display dialog to delete this board
       */
      deleteBoard: function () {
        const board = Ember.get(this, 'model.board');
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Delete board',
          acceptText: 'Confirm and delete',
          message: `Are you sure you want to delete this board?`,
          accept: () => {
            this.dialog.startLoading();
            board.destroyRecord().then(() => {
              this.dialog.stopLoading();
              this.notifications.info(`Board ${board.get('name')} was deleted.`);
              this.dialog._close();
              return this.transitionToRoute('dashboard.boards');
            }).catch(error => {
              this.dialog.stopLoading();
              this.notifications.serverError(error);
            });
          }
        });
      },
      /**
       * Share the board with other sers or groups
       */
      shareBoard: function () {
        const query = query => {
          let request = [];
          request.push(this.store.query('user', {
            without: ['permissions'],
            query
          }));
          request.push(this.store.query('group', {
            without: ['members'],
            query
          }));
          return Ember.RSVP.all(request).then(response => [...response[0].toArray(), ...response[1].toArray()].filter(u => Ember.get(u, 'id') !== Ember.get(this, 'source.owner_uuid')));
        };
        const contextObject = {
          selected: [],
          canEdit: false
        };
        this.dialog.one('created', presenter => {
          this.set('presenter', presenter);
        });
        this.dialog.open('share-board-form', contextObject, {
          title: 'Share with others',
          query,
          acceptText: 'Save',
          accept: () => {
            const groups = contextObject.selected.filter(s => Ember.get(s, '_internalModel.modelName') === 'group').map(s => Ember.get(s, 'uuid'));
            const users = contextObject.selected.filter(s => Ember.get(s, '_internalModel.modelName') === 'user').map(s => Ember.get(s, 'uuid'));
            if (users.length || groups.length) {
              return this.fetch.post('actions/share-board', {
                data: {
                  board: Ember.get(this, 'model.board.id'),
                  groups,
                  users,
                  canEdit: contextObject.canEdit
                }
              }).then(response => {
                this.notifications.success('Board shared');
                this.presenter._accept();
              }).catch(error => {
                this.notifications.serverError(error);
                this.presenter._accept();
              });
            }
            this.presenter._accept();
          }
        });
      },
      /**
       * View the board collaborators
       */
      viewBoardCollaborators: async function () {
        const {
          collaborators,
          owner
        } = await this.fetch.get('actions/get-board-collaborators', {
          data: {
            board: Ember.get(this, 'model.board.id')
          }
        });
        // check if current user is board owner
        const isBoardOwner = Ember.get(owner, 'uuid') === this.currentUser.id;
        // set dialog presenter
        this.dialog.one('created', presenter => {
          this.set('presenter', presenter);
        });
        // triger dialog
        this.dialog.open('board-collaborators', null, {
          title: 'View Collaborators',
          collaborators,
          owner,
          isBoardOwner,
          controller: this,
          acceptText: 'Done',
          accept: () => {
            // convert board groups and board members to models
            const boardMembers = collaborators.users.map(member => {
              return this.store.push(this.store.normalize('board-member', member));
            });
            // convert board groups to models
            const boardGroups = collaborators.groups.map(group => {
              return this.store.push(this.store.normalize('board-group', group));
            });
            // save collaborators and close dialog
            Ember.RSVP.all([...boardMembers, ...boardGroups].invoke('save')).then(response => {
              this.presenter._accept();
            });
          }
        });
      },
      /**
       * Removes a board collaborator
       */
      removeCollaborator: function (collaborator) {
        // check collaborator type
        const isGroup = Ember.get(collaborator, 'user_uuid') === undefined;
        // convert to model
        if (isGroup) {
          collaborator = this.store.push(this.store.normalize('board-group', collaborator));
        } else {
          collaborator = this.store.push(this.store.normalize('board-member', collaborator));
        }
        this.dialog._confirm({
          title: 'Remove collaborator',
          acceptText: 'Confirm and remove',
          message: `Are you sure you want to remove this collaborator (${isGroup ? Ember.get(collaborator, 'group.name') : Ember.get(collaborator, 'user.name')})?`
        }).then(() => {
          collaborator.destroyRecord().then(collaborator => {
            document.getElementById(`collaborator-${Ember.get(collaborator, 'id')}`).remove();
          });
        });
      }
    },
    currentUser: Ember.inject.service(),
    /**
     * Handsontable instance
     */
    handsontable: null,
    /**
     * Free table component instance
     */
    freeTable: null,
    data: Ember.A([]),
    /**
     * Current search query
     */
    query: null,
    /**
     * Search table
     */
    // eslint-disable-next-line ember/no-observers
    search: Ember.observer('query', function () {
      if (this.handsontable && this.query) {
        const search = this.handsontable.getPlugin('search');
        search.query(this.query);
        this.handsontable.render();
      }
    })
  }, (_applyDecoratedDescriptor(_obj, "data", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "data"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init;
    }
  }), _obj)), _obj)));
  _exports.default = _default;
});