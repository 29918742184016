define("@fixflo/frontend/serializers/fixture", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _rest.default.extend(_rest.EmbeddedRecordsMixin, {
    primaryKey: 'uuid',
    attrs: {
      values: {
        embedded: 'always'
      },
      cell_comments: {
        embedded: 'always'
      },
      duplicate_fixture: {
        embedded: 'always'
      },
      duplicate_fixture_set: {
        embedded: 'always'
      }
    },
    serialize: function (snapshot, options) {
      return this._super(snapshot, options);
    }
  });
  _exports.default = _default;
});