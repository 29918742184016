define("@fixflo/frontend/templates/dashboard/reports/index-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "K2/oqr7P",
    "block": "{\"symbols\":[],\"statements\":[[5,\"page-loading-indicator\",[],[[\"@message\"],[\"Loading your reports...\"]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dashboard/reports/index-loading.hbs"
    }
  });
  _exports.default = _default;
});