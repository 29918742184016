define("@fixflo/frontend/helpers/object-to-uri", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.objectToUri = objectToUri;
  _exports.default = void 0;
  function objectToUri(object, prefix, asObject = false) {
    const serializeQuery = (object, prefix) => {
      const query = Object.keys(object).map(key => {
        const value = object[key];
        if (object.constructor === Array) key = `${prefix}[]`;else if (object.constructor === Object) key = prefix ? `${prefix}[${key}]` : key;
        if (typeof value === 'object') return serializeQuery(value, key);else return `${key}=${value}`;
      });
      return [].concat.apply([], query).join('&');
    };
    const queryString = serializeQuery(object, prefix);
    console.log('[objectToUri:string]', queryString);
    if (asObject) {
      let queryObject = {};
      let properties = queryString.split('&');
      properties.forEach(query => {
        let keyAndValue = query.split('=');
        if (keyAndValue.length === 2) {
          queryObject[keyAndValue[0]] = keyAndValue[1];
        }
      });
      console.log('[objectToUri:object]', queryObject);
      return queryObject;
    }
    return queryString;
  }
  var _default = Ember.Helper.helper(objectToUri);
  _exports.default = _default;
});