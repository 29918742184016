define("@fixflo/frontend/controllers/dashboard/settings/cargo-grades", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      /**
       * Forwards and handles actions from table/action components
       */
      tableAction: function (action, model) {
        this.send(action, model);
      },
      /**
       * Sorts the current data listed
       */
      tableSort: function (sorts) {
        // TODO
      },
      /**
       * Create a new cargo Ggrade
       */
      create: function () {
        const cargoGrade = this.store.createRecord('cargo-grade', {
          company_uuid: this.currentUser.companyId,
          name: null,
          display_name: null,
          aliases: [],
          pendingChildren: []
        });
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // render dialog
        this.dialog.open('cargo-grade-form', cargoGrade, {
          title: 'Create new cargo grade',
          acceptText: 'Save',
          controller: this,
          accept: () => {
            this.presenter.startLoading();
            cargoGrade.save().then(cargoGrade => {
              this.presenter.stopLoading();
              this.notifications.success('New cargo grade added');
              this.presenter._accept();
            }).catch(error => {
              this.presenter.stopLoading();
              this.notifications.serverError(error);
            });
          },
          decline: () => {
            cargoGrade.destroyRecord();
            this.presenter._decline();
          }
        });
      },
      /**
       * Edit a cargo grade
       */
      edit: async function (cargoGrade) {
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // render dialog
        this.dialog.open('cargo-grade-form', cargoGrade, {
          title: Ember.String.htmlSafe(`Edit cargo grade <i>${Ember.get(cargoGrade, 'name')}</i>`),
          acceptText: 'Save Changes',
          controller: this,
          accept: () => {
            this.presenter.startLoading();
            cargoGrade.save().then(cargoGrade => {
              this.presenter.stopLoading();
              this.notifications.success('Changes to cargo grade saved');
              this.presenter._accept();
            }).catch(error => {
              this.presenter.stopLoading();
              this.notifications.serverError(error);
            });
          }
        });
      },
      /**
       * Delete a cargoGrade
       */
      delete: function (cargoGrade) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Delete cargo grade',
          acceptText: 'Confirm and delete',
          message: Ember.String.htmlSafe(`Are you sure you want to delete this cargo grade <i>${Ember.get(cargoGrade, 'name')}</i>?`)
        }).then(() => {
          cargoGrade.destroyRecord().then(() => {
            this.notifications.info('Cargo grade deleted');
          }).catch(error => {
            this.notifications.serverError(error);
          });
        });
      }
    },
    currentUser: Ember.inject.service(),
    /**
     * Query parameters
     */
    queryParams: ['page', 'limit', 'sort', 'query'],
    /**
     * Current page
     */
    page: 1,
    /**
     * Default pagination limit
     */
    limit: 10,
    /**
     * Default sorting
     */
    sort: 'created_at',
    /**
     * Columns to display in table
     * @type array
     */
    columns: Ember.A([{
      name: `Name`,
      valuePath: `name`
    }, {
      name: `Display Name`,
      valuePath: `display_name`
    }, {
      name: `Aliases`,
      valuePath: `aliasesList`
    }, {
      name: `Sub Grades`,
      valuePath: `subGradesList`
    }, {
      name: `Created`,
      valuePath: `createdAt`
    }, {
      name: `Modified`,
      valuePath: `updatedAt`
    }, {
      name: ``,
      menuItems: [{
        action: 'edit',
        icon: 'icon-content_edit',
        can: 'edit cargo-grade',
        label: 'Edit Cargo Grade'
      }, {
        action: 'delete',
        icon: 'icon-navigation_delete',
        can: 'delete cargo-grade',
        label: 'Delete Cargo Grade'
      }]
    }])
  });
  _exports.default = _default;
});