define("@fixflo/frontend/models/cargo-grade", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    uuid: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    company_uuid: (0, _model.attr)('string'),
    display_name: (0, _model.attr)('string'),
    system_display_name: (0, _model.attr)('string'),
    aliases: (0, _model.attr)('raw'),
    children: (0, _model.hasMany)('sub-cargo-grade'),
    sub_grades: (0, _model.attr)('raw'),
    slug: (0, _model.attr)('string'),
    deleted_at: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('string'),
    updated_at: (0, _model.attr)('string'),
    aliasesList: Ember.computed('aliases', function () {
      return this.aliases.join(', ');
    }),
    subGradesList: Ember.computed('sub_grades_array', function () {
      return this.sub_grades_array.join(', ');
    }),
    sub_grades_array: Ember.computed('sub_grades.@each', function () {
      return Ember.isArray(this.sub_grades) ? this.sub_grades.map(sub => Ember.get(sub, 'system_display_name') || '') : [];
    }),
    updatedAgo: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).fromNow();
    }),
    updatedAt: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).format('YYYY-MM-DD HH:mm A');
    }),
    createdAgo: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).fromNow();
    }),
    createdAt: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).format('YYYY-MM-DD HH:mm A');
    })
  });
  _exports.default = _default;
});