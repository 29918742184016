define("@fixflo/frontend/models/widget", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    uuid: (0, _model.attr)('string'),
    company_uuid: (0, _model.attr)('string'),
    owner_uuid: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    source: (0, _model.belongsTo)('board', {
      async: false
    }),
    source_uuid: (0, _model.attr)('string'),
    source_type: (0, _model.attr)('string'),
    x_axis: (0, _model.attr)('string'),
    y_axis: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    shared_with_company: (0, _model.attr)('boolean'),
    slug: (0, _model.attr)('string'),
    deleted_at: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('string'),
    updated_at: (0, _model.attr)('string'),
    // determines if widget is active on the dashboard
    isActive: false,
    componentName: Ember.computed(function () {
      return `${Ember.String.camelize(this.slug)}Component`;
    }),
    updatedAgo: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).fromNow();
    }),
    updatedAt: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).format('YYYY-MM-DD HH:mm A');
    }),
    createdAgo: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).fromNow();
    }),
    createdAt: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).format('YYYY-MM-DD HH:mm A');
    })
  });
  _exports.default = _default;
});