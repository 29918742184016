define("@fixflo/frontend/templates/dialog/bulk-edit-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vujF6KNW",
    "block": "{\"symbols\":[\"prop\",\"item\"],\"statements\":[[7,\"p\",true],[8],[0,\"\\n\\tYou have selected \"],[1,[28,\"pluralize\",[[24,[\"selected\",\"length\"]],\"item\"],null],false],[0,\" to be edited.\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-6\"],[8],[0,\"\\n\\t\\t\"],[7,\"ul\",true],[10,\"class\",\"list-scroll-box\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"filter-by\",[\"checked\",true,[24,[\"selected\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"li\",true],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"span\",true],[8],[1,[23,2,[\"name\"]],false],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"a\",false],[12,\"href\",\"javascript:;\"],[12,\"class\",\"mr-3 text-gray-light\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[23,2,[\"checked\"]]],null],false],[[\"on\"],[\"click\"]]],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-times-circle\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-6\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"editable\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n\\t\\t\\t    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[1,[28,\"humanize\",[[23,1,[]]],null],false],[9],[0,\"\\n\\t\\t\\t    \"],[5,\"fix-flo/input\",[[12,\"placeholder\",[28,\"concat\",[\"Edit \",[23,1,[]]],null]]],[[\"@type\",\"@value\"],[\"text\",[28,\"mut\",[[28,\"get\",[[24,[\"values\"]],[23,1,[]]],null]],null]]]],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/bulk-edit-form.hbs"
    }
  });
  _exports.default = _default;
});