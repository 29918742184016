define("@fixflo/frontend/templates/dialog/fixtures-export-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cLttapxf",
    "block": "{\"symbols\":[\"delimiter\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"col-form-label required\"],[8],[0,\"\\n        File Name\\n    \"],[9],[0,\"\\n    \"],[5,\"fix-flo/input\",[[12,\"placeholder\",\"File Name\"]],[[\"@type\",\"@value\"],[\"text\",[24,[\"contextObject\",\"filename\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"col-form-label required\"],[8],[0,\"\\n        Select column delimiter\\n    \"],[9],[0,\"\\n    \"],[5,\"power-select\",[],[[\"@selected\",\"@options\",\"@onChange\",\"@placeholder\"],[[24,[\"contextObject\",\"columnDelimiter\"]],[22,\"delimiterOptions\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"contextObject\",\"columnDelimiter\"]]],null]],null],\"Select delimiter\"]],{\"statements\":[[0,\"\\n        \"],[1,[23,1,[\"label\"]],false],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/fixtures-export-form.hbs"
    }
  });
  _exports.default = _default;
});