define("@fixflo/frontend/templates/dialog/backdate-fixtures", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0fTequZV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Select a backdate\\n    \"],[9],[0,\"\\n    \"],[5,\"pikaday-input\",[[12,\"placeholder\",\"Select a backdate\"],[12,\"class\",\"fixflo-input\"]],[[\"@onSelection\",\"@format\",\"@yearRange\",\"@maxDate\",\"@useUTC\"],[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"backdate\"]]],null]],null],\"DD-MMM-YYYY\",\"2000,currentYear\",[28,\"to-js-date\",[[28,\"now\",null,null]],null],true]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/backdate-fixtures.hbs"
    }
  });
  _exports.default = _default;
});