define("@fixflo/frontend/components/object-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Style classes
     */
    classNames: ['object-form'],
    /**
     * Style classes binded to variables
     */
    classNameBindings: ['isForm', 'currentDepth'],
    /**
     * Object to render as form
     */
    object: null,
    /**
     * Sort the object values
     */
    sortedObject: Ember.computed('object', function () {
      const object = this.object;
      const newObject = {};
      Object.keys(object).sort((a, b) => {
        if (Ember.typeOf(object[a]) === 'string') {
          return -1;
        } else {
          return 1;
        }
      }).forEach(key => {
        newObject[key] = object[key];
      });
      return newObject;
    }),
    /**
     * The depth of iteration
     */
    depth: 1,
    /**
     * Current depth as a class
     */
    currentDepth: Ember.computed('depth', function () {
      return `object-form-depth depth-${this.depth}`;
    }),
    /**
     * Determines if iterating over a form
     */
    isForm: Ember.computed('object', function () {
      if (Ember.typeOf(Object.values(this.object)[0]) === 'array' && Object.keys(Object.values(this.object)[0][0]).length === 2 && Object.keys(Object.values(this.object)[0][0]).includes('name', 'value')) {
        return 'form-group form-row';
      }
      return Ember.typeOf(Object.values(this.object)[0]) === 'string' || Object.values(this.object)[0] === null ? 'form-group form-row' : '';
    })
  });
  _exports.default = _default;
});