define("@fixflo/frontend/templates/dashboard/settings/team-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Ox6yEzpk",
    "block": "{\"symbols\":[],\"statements\":[[5,\"fix-flo/section-header\",[],[[\"@title\"],[\"Users\"]]],[0,\"\\n\"],[5,\"page-loading-indicator\",[],[[\"@message\"],[\"Loading your team...\"]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dashboard/settings/team-loading.hbs"
    }
  });
  _exports.default = _default;
});