define("@fixflo/frontend/helpers/initialize-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function initializeName([name]) {
    if (!name) return;
    if (name.includes(' ')) {
      name = name.split(' ');
      return `${name.firstObject[0]}${name.lastObject[0]}`;
    }
    return name.substr(0, 2);
  });
  _exports.default = _default;
});