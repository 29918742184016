define("@fixflo/frontend/templates/dashboard/fixtures/index-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5kDRkgh+",
    "block": "{\"symbols\":[],\"statements\":[[5,\"fix-flo/page-title\",[],[[\"@title\"],[\"Workspace\"]]],[0,\"\\n\"],[5,\"page-loading-indicator\",[],[[\"@message\"],[\"Loading your workspace...\"]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dashboard/fixtures/index-loading.hbs"
    }
  });
  _exports.default = _default;
});