define("@fixflo/frontend/components/report-downloader", ["exports", "@glimmer/component", "ember-get-config"], function (_exports, _component, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ReportDownloaderComponent = (_dec = Ember._action, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed('args.buttonSize'), _dec6 = Ember.computed('args.buttonScheme'), _dec7 = Ember.computed('args.buttonTitle'), _dec8 = Ember.computed.or('isGenerating', 'isDownloading'), _dec9 = Ember.computed.not('isWorking'), _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember.computed('downloadProgressPercentage'), (_class = class ReportDownloaderComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "notifications", _descriptor2, this);
      _initializerDefineProperty(this, "session", _descriptor3, this);
      _initializerDefineProperty(this, "isWorking", _descriptor4, this);
      _initializerDefineProperty(this, "isIdle", _descriptor5, this);
      _initializerDefineProperty(this, "isGenerating", _descriptor6, this);
      _initializerDefineProperty(this, "isDownloading", _descriptor7, this);
      _initializerDefineProperty(this, "downloadProgressPercentage", _descriptor8, this);
    }
    /**
     * Request the report type from the server and stream it
     *
     * @void
     */
    startDownload() {
      this.isGenerating = true;
      const reportUrl = this.getUrl();
      const request = new XMLHttpRequest();
      request.responseType = 'blob';
      request.withCredentials = true;
      request.open('GET', reportUrl, true);
      request.addEventListener('progress', e => {
        this.downloadProgressPercentage = parseInt(e.loaded / e.total * 100, 10);
      });
      request.addEventListener('readystatechange', () => {
        if (request.readyState === 2 && request.status === 200) {
          this.downloadProgressPercentage = 0;
          this.isGenerating = false;
          this.isDownloading = true;
        } else if (request.readyState === 4 && request.status === 200) {
          this.downloadProgressPercentage = 100;
          this.isDownloading = false;
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = URL.createObjectURL(request.response);
          a.href = url;
          a.download = this.getFilenameFromContentDisposition(request.getResponseHeader('Content-Disposition'));
          a.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }, 0);
        } else if (request.readyState === 4 && request.status !== 200) {
          this.downloadProgressPercentage = 0;
          this.isGenerating = false;
          this.isDownloading = false;
          const reader = new FileReader();
          reader.onload = () => this.notifications.error(Ember.get(reader, 'result') || 'Report failed to download!');
          reader.readAsText(request.response);
        }
      });
      request.addEventListener('error', e => {
        this.downloadProgressPercentage = 0;
        this.isGenerating = false;
        this.isDownloading = false;
        this.notifications.error('A connection error occurred while downloading the report!');
      });
      request.send();
    }

    /**
     * Inject the current user service
     *
     * @var {Service}
     */

    /**
     * Inject notification service
     *
     * @var {Service}
     */

    /**
     * Inject session service
     *
     * @var {Service}
     */

    /**
     * Default button size
     *
     * @var {String}
     */
    get buttonSize() {
      return this.args.buttonSize || 'xs';
    }

    /**
     * Default button scheme
     *
     * @var {String}
     */
    get buttonScheme() {
      return this.args.buttonScheme || 'light';
    }

    /**
     * Default button title
     *
     * @var {String}
     */
    get buttonTitle() {
      return this.args.buttonTitle || 'Export';
    }

    /**
     * Is report downloader working
     *
     * @var {Boolean}
     */

    /**
     * Is report downloader NOT working
     *
     * @var {Boolean}
     */

    /**
     * Status flag: true if waiting for server to finish generating the report
     *
     * @var {Boolean}
     */

    /**
     * Status flag: true if report is being streamed
     *
     * @var {Boolean}
     */

    /**
     * Report download progress in percent (0-100)
     *
     * @var {Integer}
     */

    /**
     * Report download progress in percent (0-100)
     *
     * @var {Integer}
     */
    get downloadProgressText() {
      return `${this.downloadProgressPercentage ? this.downloadProgressPercentage + '% ' : ''}Downloading...`;
    }

    /**
     * Get download URL of report this component's report type
     *
     * @return {String} url
     */
    getUrl() {
      const sanctumToken = this.session.data.authenticated.token;
      const baseUrl = `${_emberGetConfig.default.api.host}/${_emberGetConfig.default.api.namespace}/reports/download`;
      const authPart = `sanctum_token=${sanctumToken}`;
      const formatPart = `format=${this.args.reportType.get('format')}`;
      const reportPart = `report=${this.args.reportType.get('id')}`;
      const userPart = `user=${Ember.get(this, 'currentUser.id')}`;

      // multi sort
      const sortParts = this.args.reportType.get('type_sorts').map(sortItem => `sort[]=${sortItem.get('sort_by')}`);
      const sortPart = sortParts.join('&');

      // full url
      const url = `${baseUrl}?${authPart}&${formatPart}&${reportPart}&${userPart}&${sortPart}`;
      return url;
    }

    /**
     * Get filename from content disposition response header
     *
     * @param  {String} contentDisposition
     * @return {String} fileName
     */
    getFilenameFromContentDisposition(contentDisposition) {
      const fileNameMatches = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/gim);
      const fileNameString = fileNameMatches.length ? fileNameMatches[0] : `report-download.${this.args.reportType.get('format') || 'txt'}`;
      const fileName = fileNameString.replace(/\"|filename=/gim, '');
      return fileName;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "startDownload", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "startDownload"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buttonSize", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "buttonSize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buttonScheme", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "buttonScheme"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buttonTitle", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "buttonTitle"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isWorking", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isIdle", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isGenerating", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isDownloading", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "downloadProgressPercentage", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "downloadProgressText", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "downloadProgressText"), _class.prototype)), _class));
  _exports.default = ReportDownloaderComponent;
});