define("@fixflo/frontend/handsontable/editors/autocomplete-editor", ["exports", "handsontable"], function (_exports, _handsontable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AutocompleteEditor extends _handsontable.default.editors.BaseEditor {
    init() {
      // create input for datalist
      this.input = this.hot.rootDocument.createElement('input');
      this.input.setAttribute('list', 'options');
      this.input.className = 'fixfloInputForDataList';
      this.input.setAttribute('data-hot-input', '');
      this.input.style.width = 0;
      this.input.style.height = 0;

      // create datalist for input
      this.datalist = this.hot.rootDocument.createElement('datalist');
      this.datalist.setAttribute('id', 'options');

      // Attach node to DOM, by appending it to the container holding the table
      this.hot.rootElement.appendChild(this.input);
      this.hot.rootElement.appendChild(this.datalist);
    }
    open() {
      super.open();
      this.showEditableElement();
      this.focus();
      this.hot._registerTimeout(() => {
        this.runQuery(this.input.value);
      });
    }

    /**
     * Closes the editor.
     */
    close() {
      this.removeHooksByKey('beforeKeyDown');
      super.close();
    }
    runQuery() {}
  }
  window.__CLASSIC_OWN_CLASSES__.set(AutocompleteEditor, true);
  var _default = AutocompleteEditor;
  _exports.default = _default;
});