define("@fixflo/frontend/utils/create-report-filters-uri", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createReportFiltersUri;
  const isNullVesselMetric = (key, value) => {
    return key.includes('vessel') && value === 0;
  };
  const encodeFilterProperty = property => {
    if (property.includes('[')) {
      const bracketValue = property.match(/\[([a-z]+)\]/i).firstObject;
      return encodeURIComponent(`filters[${property.replace(bracketValue, '')}]${bracketValue}`);
    }
    return encodeURIComponent(`filters[${property}]`);
  };
  function createReportFiltersUri(obj) {
    const str = [];
    for (var p in obj) {
      if (obj.hasOwnProperty(p) && obj[p] !== null && obj[p] !== undefined && !isNullVesselMetric(p, obj[p])) {
        if (['quantity', 'vesselDeadWeight', 'vesselLengthOverAll', 'vesselCubicCapacity', 'vesselYearBuilt'].includes(p) && typeof obj[p] === 'object') {
          for (let range in obj[p]) {
            str.push(`filters[${p}][${range}]=${encodeURIComponent(obj[p][range])}`);
          }
          continue;
        }
        str.push(`${encodeFilterProperty(p)}=${encodeURIComponent(obj[p])}`);
      }
    }
    return str.length ? str.join('&') : '';
  }
});