define("@fixflo/frontend/models/generated-report", ["exports", "@ember-data/model", "@fixflo/frontend/utils/create-report-filters-uri", "moment", "ember-get-config"], function (_exports, _model, _createReportFiltersUri, _moment, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const arrayToUri = (key, arr = [], limit = null) => {
    const uri = arr.map(e => `${key}[]=${e}&`);
    if (Number.isInteger(limit) && limit > -1) {
      uri.length = limit;
    }
    return uri.join('').slice(0, -1);
  };
  var _default = _model.default.extend({
    uuid: (0, _model.attr)('string'),
    user_uuid: (0, _model.attr)('string'),
    report_template_uuid: (0, _model.attr)('string'),
    source_uuid: (0, _model.attr)('string'),
    source_type: (0, _model.attr)('string'),
    format: (0, _model.attr)('string'),
    sort: (0, _model.attr)('string'),
    fixtures: (0, _model.attr)('raw'),
    filters: (0, _model.attr)('raw'),
    saved: (0, _model.attr)('boolean'),
    deleted_at: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('string'),
    updated_at: (0, _model.attr)('string'),
    /**
     * conver to preview url
     */
    previewUrl: Ember.computed('user_uuid', 'template_id', 'source_uuid', 'source_type', 'format', 'fixtures.@each', 'filters', function () {
      const owner = Ember.getOwner(this);
      const session = owner.lookup('service:session');
      const sanctumToken = session.data.authenticated.token;
      return `${_emberGetConfig.default.api.host}/${_emberGetConfig.default.api.namespace}/generated-reports/preview?sanctum_token=${sanctumToken}&user=${this.user_uuid}&template=${this.template_id}&source=${this.source_uuid}&source_type=${this.source_type}&${Ember.isArray(this.fixtures) ? arrayToUri('fixtures', this.fixtures, 10) : ''}&format=${this.format}&sort=${this.sort || '-laycan_range_start'}&${(0, _createReportFiltersUri.default)(this.filters)}`;
    }),
    /**
     * Get the template id even if object
     */
    template_id: Ember.computed('report_template_uuid', function () {
      // if user model set for member, set the id
      if (this.report_template_uuid instanceof Object) {
        return Ember.get(this, 'report_template_uuid.id');
      }
      return this.report_template_uuid;
    }),
    updatedAgo: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).fromNow();
    }),
    updatedAt: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).format('YYYY-MM-DD HH:mm A');
    }),
    createdAgo: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).fromNow();
    }),
    createdAt: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).format('YYYY-MM-DD HH:mm A');
    })
  });
  _exports.default = _default;
});