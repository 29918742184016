define("@fixflo/frontend/routes/admin/panel/vessels/index", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_routeMixin.default, {
    queryParams: {
      query: {
        refreshModel: true
      },
      type: {
        refreshModel: true
      },
      fleet: {
        refreshModel: true
      },
      perPage: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    model: function (params) {
      params.paramMapping = {
        perPage: 'limit',
        total_pages: 'last_page'
      };
      return this.findPaged('vessel', params);
    },
    setupController: async function (controller, model) {
      this._super(controller, model);
      // only if empty load fleets
      if (Ember.isEmpty(Ember.get(controller, 'fleets'))) {
        // get all fleets
        const fleets = await this.store.query('vessel', {
          columns: ['fleet'],
          distinct: true,
          limit: 9999999
        });
        // set to model
        Ember.setProperties(controller, {
          fleets
        });
      }
    }
  });
  _exports.default = _default;
});