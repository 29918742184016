define("@fixflo/frontend/controllers/dashboard/fixtures/index", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj, _init, _init2, _init3;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = Ember.Controller.extend((_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, (_obj = {
    actions: {
      /**
       * Forwards and handles actions from table/action components
       */
      tableAction: function (action, model) {
        this.send(action, model);
      },
      /**
       * Sorts the current data listed
       */
      tableSort: function (sorts) {
        // TODO
      },
      /**
       * Edit a fixture set
       */
      edit: async function (fixtureSet) {
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // render dialog
        this.dialog.open('fixtures-form', fixtureSet, {
          title: Ember.String.htmlSafe(`Edit fixture set <i>${Ember.get(fixtureSet, 'name')}</i>`),
          statusOptions: ['draft', 'published'],
          acceptText: 'Save Changes',
          controller: this,
          accept: () => {
            this.presenter.startLoading();
            fixtureSet.set('bypass_duplicate_check', this.bypassDuplicateCheck);
            fixtureSet.save().then(fixtureSet => {
              this.presenter.stopLoading();
              this.notifications.success('Changes to fixture set saved');
              this.presenter._accept();
            }).catch(error => {
              this.presenter.stopLoading();
              // rollback changes to fixture set
              fixtureSet.rollbackAttributes();
              // handle duplicates via dialog
              if (error.code === 'duplicates_found') {
                Ember.set(this, 'bypassDuplicateCheck', true);
                return this.send('handleDuplicates', error.duplicates, fixtureSet);
              }
              // display error
              this.notifications.serverError(error);
            });
          }
        });
      },
      /**
       * Delete a fixture set
       */
      delete: function (fixtureSet) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Delete fixture set',
          acceptText: 'Confirm and delete',
          message: Ember.String.htmlSafe(`Are you sure you want to delete this fixture set <i>${Ember.get(fixtureSet, 'name')}</i>?`),
          accept: () => {
            this.dialog.startLoading();
            fixtureSet.destroyRecord().then(() => {
              this.refreshRoute().then(() => {
                this.dialog.stopLoading();
                this.dialog._close();
                this.notifications.info('Fixture set deleted');
              });
            }).catch(error => {
              this.dialog.stopLoading();
              this.notifications.serverError(error);
            });
          }
        });
      },
      /**
       * Publish a fixture set
       */
      publish: function (fixtureSet) {
        this.dialog._confirm({
          title: `Publish fixtures from ${Ember.get(fixtureSet, 'name')}`,
          acceptText: 'Confirm & Publish',
          message: `Are you sure you want to publish these fixtures?`,
          accept: () => {
            this.dialog.startLoading();
            fixtureSet.set('status', 'published');
            fixtureSet.set('bypass_duplicate_check', this.bypassDuplicateCheck);
            fixtureSet.save().then(() => {
              this.dialog.stopLoading();
              this.notifications.success('Fixtures successfully published');
            }).catch(error => {
              this.dialog.stopLoading();
              // rollback changes to fixture set
              fixtureSet.rollbackAttributes();
              // handle duplicates via dialog
              if (error.code === 'duplicates_found') {
                Ember.set(this, 'bypassDuplicateCheck', true);
                return this.send('handleDuplicates', error.duplicates, fixtureSet);
              }
              // display error
              this.notifications.serverError(error);
            });
          }
        });
      },
      /**
       * Performs the selected bulk action
       */
      performBulkAction: function () {
        // get selected action
        const action = this.bulkAction;
        if (!action) {
          return this.notifications.warning('No action selected');
        }
        // get selected items
        const selected = this.selectedFixtureSets;
        if (!selected.length) {
          return this.notifications.warning('No items selected');
        }
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'bulk_dialog_presenter', presenter);
        });
        // display dialogs based on action
        switch (action) {
          case 'delete':
            this.dialog.open('bulk-delete-form', null, {
              title: 'Bulk delete fixture sets',
              selected,
              acceptText: 'Confirm & Delete',
              acceptButtonScheme: 'danger',
              controller: this,
              accept: () => {
                this.bulk_dialog_presenter.startLoading();
                return Ember.RSVP.all(selected.invoke('destroyRecord')).then(() => {
                  Ember.setProperties(this, {
                    checkAllPublished: false,
                    checkAllDrafts: false
                  });
                  this.refreshRoute();
                  this.notifications.info(`${(0, _emberInflector.pluralize)(selected.length, 'item')} deleted`);
                  this.bulk_dialog_presenter.stopLoading();
                  this.bulk_dialog_presenter._accept();
                });
              }
            });
            break;
          case 'publish':
            this.dialog.open('bulk-publish-form', null, {
              title: 'Bulk publish fixture sets',
              selected,
              action: 'publish',
              acceptText: 'Confirm & Publish',
              controller: this,
              accept: () => {
                this.bulk_dialog_presenter.startLoading();
                // set all to published
                selected.forEach(s => Ember.set(s, 'status', 'published'));
                // save changes
                return Ember.RSVP.all(selected.invoke('save')).then(fixtureSet => {
                  Ember.setProperties(this, {
                    checkAllPublished: false,
                    checkAllDrafts: false
                  });
                  this.notifications.info(`${(0, _emberInflector.pluralize)(selected.length, 'item')} published`);
                  this.bulk_dialog_presenter.stopLoading();
                  this.bulk_dialog_presenter._accept();
                }).catch(error => {
                  this.bulk_dialog_presenter.stopLoading();
                  // handle duplicates via dialog
                  if (error.code === 'duplicates_found') {
                    // get fixture set of duplicate
                    const fixtureSet = this.store.peekRecord('fixture-set', error.set);
                    // rollback changes to fixture set
                    fixtureSet.rollbackAttributes();
                    // handle dupes
                    return this.send('handleDuplicates', error.duplicates, fixtureSet);
                  }
                  // display error
                  this.notifications.serverError(error);
                });
              }
            });
            break;
          case 'unpublish':
            this.dialog.open('bulk-publish-form', null, {
              title: 'Bulk un-publish fixture sets',
              selected,
              action: 'unpublish',
              acceptText: 'Confirm & Change to Draft',
              controller: this,
              accept: () => {
                this.bulk_dialog_presenter.startLoading();
                // set all to published
                selected.forEach(s => Ember.set(s, 'status', 'draft'));
                // save changes
                return Ember.RSVP.all(selected.invoke('save')).then(fixtureSet => {
                  Ember.setProperties(this, {
                    checkAllPublished: false,
                    checkAllDrafts: false
                  });
                  this.notifications.info(`${(0, _emberInflector.pluralize)(selected.length, 'item')} set to draft`);
                  this.bulk_dialog_presenter.startLoading();
                  this.bulk_dialog_presenter._accept();
                }).catch(error => {
                  // display error
                  // get(this, 'bulk_dialog_presenter').startLoading();
                  this.notifications.serverError(error);
                });
              }
            });
            break;
        }
      },
      /**
       * Prompt dialog for user to handle duplicate fixtures
       */
      handleDuplicates: function (duplicates, fixtureSet) {
        // set dialog to controller
        this.dialog.one('created', presenter => {
          this.set('handle_dupes_presenter', presenter);
        });
        // prompt user to handle dialogs
        return this.dialog.open('duplicates-on-publish', null, {
          title: 'Duplicates found, you must resolve before publishing',
          duplicates,
          acceptText: 'Confirm',
          acceptIcon: 'fa fa-save',
          controller: this,
          accept: () => {
            this.handle_dupes_presenter._accept();
            this.bulk_dialog_presenter._accept();
            return this.send('publish', fixtureSet);
          }
        });
      },
      /**
       * Use Me, deletes the duplicate and unflags the other
       */
      deleteDuplicate: function (useMe, deleteMe, duplicates, index) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Remove Duplicate',
          acceptText: 'Confirm and delete',
          message: `Are you sure you want to use this fixture?`
        }).then(() => {
          this.handle_dupes_presenter.startLoading();
          return this.fetch.post('actions/select-from-duplicates', {
            data: {
              fixtureToUse: Ember.get(useMe, 'uuid'),
              fixtureToDelete: Ember.get(deleteMe, 'uuid')
            }
          }).then(() => {
            this.handle_dupes_presenter.stopLoading();
            // delete from index
            duplicates.removeAt(index);
            // send notification
            this.notifications.success('Duplicate removed');
          }).catch(error => {
            this.handle_dupes_presenter.stopLoading();
            this.notifications.serverError(error);
          });
        });
      },
      /**
       * Removed feeback from suggestions
       */
      ignoreDuplicate: function (duplicate, duplicates, index) {
        console.log(duplicate);
        // set duplicate to be ignored
        this.fetch.put(`fixtures/${Ember.get(duplicate, 'uuid')}`, {
          data: {
            ignore_as_duplicate: 1
          }
        });
        // remove from duplicates payload
        duplicates.removeAt(index);
      },
      /**
       * Select all fixture sets
       */
      toggleSelectAll: function (isChecked) {
        this.model.forEach(s => s.set('isChecked', isChecked));
      },
      /**
       * Toggle table sorting
       */
      sortTable: function (property) {
        const sortingBy = this.sortProperty.firstObject;
        if (sortingBy.includes('desc') && sortingBy.includes(property)) {
          this.set('sortProperty', [`${property}:asc`]);
        } else if (sortingBy.includes('asc') && sortingBy.includes(property)) {
          this.set('sortProperty', [`${property}:desc`]);
        } else {
          this.set('sortProperty', [`${property}:desc`]);
        }
      },
      /**
       * Toggle the sort asc/desc
       *
       * @void
       */
      toggleSortAscDesc: function (sortDirection) {
        const sort = this.sort;
        if (sortDirection === 'asc') {
          Ember.setProperties(this, {
            sort: sort.substr(1),
            sortDirection
          });
        } else {
          Ember.setProperties(this, {
            sort: `-${sort}`,
            sortDirection
          });
        }
      },
      /**
       * Transition to a board
       */
      transitionToFixtureSet: function (fixtureSet) {
        // set route title
        // this.loader.set('routeTitle', fixtureSet.get('name'));
        // continue to board view
        this.transitionToRoute('dashboard.fixtures.view', fixtureSet.get('slug'));
      },
      changePage(page) {
        console.log(page);
      }
    },
    /**
     * Query parameters
     */
    queryParams: ['page', 'limit', 'sort', 'query', 'status'],
    page: 1,
    limit: 10,
    sort: '-created_at',
    /**
     * The default sort direction
     */
    sortDirection: Ember.computed('sort', function () {
      return this.sort.startsWith('-') ? 'desc' : 'asc';
    }),
    /**
     * Columns to display in table
     * @type array
     */
    get columns() {
      return [{
        name: ``,
        checkbox: true,
        valuePath: `isChecked`
      }, {
        name: `Name`,
        valuePath: `name`,
        action: 'transitionToFixtureSet'
      }, {
        name: `Fixtures`,
        valuePath: `fixtures_count`
      }, {
        name: `Created`,
        valuePath: `createdAt`
      }, {
        name: `Modified`,
        valuePath: `updatedAt`
      }, {
        name: `Status`,
        valuePath: `status`
      }, {
        name: ``,
        menuItems: [{
          action: 'edit',
          icon: 'icon-content_edit',
          can: 'edit fixture-set',
          label: 'Edit Fixtures'
        }, {
          action: 'delete',
          icon: 'icon-navigation_delete',
          can: 'delete fixture-set',
          label: 'Delete Fixtures'
        }]
      }];
    },
    /**
     * All fixture with query filters sets
     */
    sortedFixtureSets: Ember.computed.sort('model', 'sortProperty'),
    /**
     * All the selected fixture sets
     */
    selectedFixtureSets: Ember.computed.filter('model', ['isAllChecked', 'model.@each.isChecked'], function (fixtureSet) {
      return fixtureSet.isChecked;
    }),
    /**
     * The property of fixture set to sort by
     */
    sortProperty: Ember.A(['updated_at:desc']),
    /**
     * Does user have fixtures
     */
    hasFixtures: Ember.computed.alias('model.meta.to'),
    /**
     * The bulk action to perform
     */
    bulkAction: null,
    /**
     * Determines if all items are checked
     */
    isAllChecked: false,
    /**
     * Determines if system needs to perform dupe check before publish
     */
    bypassDuplicateCheck: false
  }, (_applyDecoratedDescriptor(_obj, "page", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "page"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "limit", [_dec2], (_init2 = Object.getOwnPropertyDescriptor(_obj, "limit"), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init2;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "sort", [_dec3], (_init3 = Object.getOwnPropertyDescriptor(_obj, "sort"), _init3 = _init3 ? _init3.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init3;
    }
  }), _obj)), _obj)));
  _exports.default = _default;
});