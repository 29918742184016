define("@fixflo/frontend/models/boundary", ["exports", "@ember-data/model", "moment", "@fixflo/frontend/utils/get-boundary-center"], function (_exports, _model, _moment, _getBoundaryCenter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    uuid: (0, _model.attr)('string'),
    zone_uuid: (0, _model.attr)('string'),
    latitude: (0, _model.attr)('string'),
    longitude: (0, _model.attr)('string'),
    boundary: (0, _model.attr)('raw'),
    deleted_at: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('string'),
    updated_at: (0, _model.attr)('string'),
    getCenter: function () {
      const coordinates = this.coordinates;
      return (0, _getBoundaryCenter.default)(coordinates);
    },
    // longitude first coordinates
    coordinates: Ember.computed('boundary', function () {
      return this.boundary.map(coord => Ember.get(coord, 'lng') ? [Ember.get(coord, 'lng'), Ember.get(coord, 'lat')] : coord);
    }),
    // latitude first coordinates
    latFirstCoordinates: Ember.computed('boundary', function () {
      return this.boundary.map(coord => Ember.get(coord, 'lat') ? [Ember.get(coord, 'lat'), Ember.get(coord, 'lng')] : coord.reverse());
    }),
    center: Ember.computed('boundary', function () {
      return this.getCenter();
    }),
    lat: Ember.computed('boundary', function () {
      return this.center[1];
    }),
    lng: Ember.computed('boundary', function () {
      return this.center[0];
    }),
    updatedAgo: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).fromNow();
    }),
    updatedAt: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).format('YYYY-MM-DD HH:mm A');
    }),
    createdAgo: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).fromNow();
    }),
    createdAt: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).format('YYYY-MM-DD HH:mm A');
    })
  });
  _exports.default = _default;
});