define("@fixflo/frontend/controllers/admin/panel/zones/index", ["exports", "ember-inflector", "country-data", "@fixflo/frontend/utils/get-boundary-center", "@fixflo/frontend/utils/geojson-to-coords"], function (_exports, _emberInflector, _countryData, _getBoundaryCenter, _geojsonToCoords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = Ember.Controller.extend((_dec = Ember._action, (_obj = {
    actions: {
      /**
       * Delete layers selected to be deleted
       *
       * @void
       */
      deleteBoundaries: function ({
        layers
      }) {
        layers.getLayers().forEach(layer => {
          // get the boundary from id reference
          const boundary = this.store.peekRecord('boundary', Ember.get(layer, 'boundary_id'));
          if (boundary) {
            boundary.destroyRecord();
          }
        });
      },
      /**
       * Delete layers selected to be deleted
       *
       * @void
       */
      saveBoundaryChanges: function ({
        target
      }) {
        const layers = Object.values(Ember.get(target, '_layers'));
        layers.forEach(layer => {
          const boundary_id = Ember.get(layer, 'boundary_id');
          if (!boundary_id) {
            return;
          }
          const boundary = (0, _geojsonToCoords.default)(layer, true, true);
          this.fetch.put(`boundaries/${boundary_id}`, {
            data: {
              boundary
            }
          });
        });
      },
      /**
       * Set the active drawing control to the controllers
       *
       * @void
       */
      setFeatureGroup: function (activeFeatureGroup, map) {
        Ember.setProperties(this, {
          activeFeatureGroup
        });
      },
      /**
       * Add a new boundary to be saved to the zone being edited
       *
       * @void
       */
      queueZoneBoundary: function (event, layer) {
        const boundary = (0, _geojsonToCoords.default)(layer, true, true);
        if (isEmpty(boundary)) {
          return;
        }
        const {
          latitude,
          longitude
        } = (0, _getBoundaryCenter.default)(boundary, true);
        const zoneBoundary = this.store.createRecord('boundary', {
          boundary,
          latitude,
          longitude
        });
        this.pendingBoundaries.push(zoneBoundary);
      },
      /**
       * Add an existing layer to editableLayers
       *
       * @void
       */
      makeLayerEditable: function (boundary, {
        target
      }) {
        const activeFeatureGroup = this.activeFeatureGroup;
        const editableLayers = this.editableLayers;
        if (editableLayers.includes(target)) {
          return;
        }
        // set metadata for boundary id
        Ember.set(target, 'boundary_id', Ember.get(boundary, 'id'));
        // push to editable layers & feature group
        editableLayers.pushObject(target);
        activeFeatureGroup.addLayer(target);
      },
      /**
       * Creates a zone
       */
      create: function () {
        // create zone to be created
        const zone = this.store.createRecord('zone', {
          type: 'zone',
          aliases: []
        });
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          this.set('presenter', presenter);
        });
        // render dialog
        this.dialog.open('zone-form', zone, {
          title: 'Create a new zone',
          acceptText: 'Save',
          controller: this,
          countries: _countryData.countries.all.map(c => c.name),
          addAlias: tag => zone.get('aliases').pushObject(tag),
          removeAlias: i => zone.get('aliases').removeAt(i),
          accept: () => {
            const firstBoundary = Ember.get(this, 'pendingBoundaries.firstObject');
            if (firstBoundary && !Ember.get(zone, 'longitude')) {
              // set the zone latitude and longitude from boundary
              Ember.setProperties(zone, {
                longitude: Ember.get(firstBoundary, 'longitude'),
                latitude: Ember.get(firstBoundary, 'latitude')
              });
            }
            // start loading indicator
            this.presenter.startLoading();
            // save the zone
            return zone.save().then(zone => {
              // set zone to pending boundaries
              const boundaries = this.pendingBoundaries.map(b => {
                b.set('zone_uuid', zone.get('id'));
                return b;
              });
              // save boundaries
              return Ember.RSVP.all(boundaries.invoke('save')).then(boundaries => {
                // clear pending zones
                this.resetZoneEditor();
                // set boundaries to zones
                zone.get('boundaries').pushObjects(boundaries);
                // save boundaries
                this.notifications.success('New zone added');
                this.presenter.stopLoading();
                this.presenter._accept();
              }).catch(error => {
                this.presenter.stopLoading();
                this.notifications.serverError(error);
              });
            });
          },
          decline: () => {
            zone.destroyRecord();
            this.resetZoneEditor();
            this.presenter._decline();
          }
        });
      },
      /**
       * Edit a zone
       */
      edit: function (zone) {
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          this.set('presenter', presenter);
        });
        // render dialog
        this.dialog.open('zone-form', zone, {
          title: `Edit ${zone.get('name')} zone`,
          acceptText: 'Save Changes',
          controller: this,
          editableLayers: this.editableLayers,
          // countries: countries.map(c => c.name),
          addAlias: tag => zone.get('aliases').pushObject(tag),
          removeAlias: i => zone.get('aliases').removeAt(i),
          accept: () => {
            const editableLayers = this.editableLayers;
            const firstBoundary = Ember.get(this, 'pendingBoundaries.firstObject');
            if (firstBoundary && !Ember.get(zone, 'longitude')) {
              // set the zone latitude and longitude from boundary
              Ember.setProperties(zone, {
                longitude: Ember.get(firstBoundary, 'longitude'),
                latitude: Ember.get(firstBoundary, 'latitude')
              });
            }
            // start loading indicator
            this.presenter.startLoading();
            // save the zone
            return zone.save().then(zone => {
              // set zone to pending boundaries
              const boundaries = this.pendingBoundaries.map(b => {
                b.set('zone_uuid', zone.get('id'));
                return b;
              });
              // save boundaries
              return Ember.RSVP.all(boundaries.invoke('save')).then(boundaries => {
                // set boundaries to zones
                zone.get('boundaries').pushObjects(boundaries);
                // clear pending zones
                this.resetZoneEditor();
                // refresh content
                this.notifications.success('Zone saved.');
                this.presenter.stopLoading();
                this.presenter._accept();
              }).catch(error => {
                this.presenter.stopLoading();
                this.notifications.serverError(error);
              });
            });
          },
          decline: () => {
            this.resetZoneEditor();
            this.presenter._decline();
          }
        });
      },
      /**
       * Deletes a zone
       */
      delete: function (zone) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Delete Zone',
          acceptText: 'Confirm and delete',
          message: `Are you sure you want to delete this zone (${Ember.get(zone, 'name')})?`
        }).then(() => {
          zone.destroyRecord().then(() => {
            this.notifications.info('Zone deleted.');
          }).catch(error => {
            this.notifications.serverError(error);
          });
        });
      },
      /**
       * Performs the selected bulk action
       */
      performBulkAction: function () {
        // get selected action
        const action = this.bulkAction;
        if (!action) {
          return this.notifications.warning('No action selected');
        }
        // get selected items
        const selected = this.model.filter(i => Ember.get(i, 'checked'));
        if (!selected.length) {
          return this.notifications.warning('No items selected');
        }
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // display dialogs based on action
        switch (action) {
          case 'delete':
            this.dialog.open('bulk-delete-form', null, {
              title: 'Bulk delete zones',
              selected,
              acceptText: 'Confirm & Delete',
              controller: this,
              accept: () => {
                return Ember.RSVP.all(selected.invoke('destroyRecord')).then(() => {
                  this.notifications.info(`${(0, _emberInflector.pluralize)(selected.length, 'item')} deleted`);
                  this.presenter._accept();
                });
              }
            });
            break;
        }
      }
    },
    /**
     * Reset controller properties when dialog closes
     *
     * @void
     */
    resetZoneEditor: function () {
      Ember.setProperties(this, {
        pendingBoundaries: [],
        editableLayers: [],
        activeFeatureGroup: null
      });
    },
    /**
     * Query parameters
     */
    queryParams: ['page', 'limit', 'sort', 'query', 'type'],
    /**
     * Current page
     */
    page: 1,
    /**
     * Default pagination limit
     */
    limit: 30,
    /**
     * Default sorting
     */
    sort: '-created_at',
    /**
     * Only query zones with alises only
     */
    aliasOnly: false,
    toggleSort(sort) {
      const currentSort = this.sort;
      if (currentSort && currentSort.includes(sort) && currentSort.startsWith('-')) {
        this.setProperties({
          sort: currentSort.substr(1)
        });
      } else {
        this.setProperties({
          sort: `-${sort}`
        });
      }
    },
    /**
     * Array of bounndaries pending creation
     */
    pendingBoundaries: Ember.A([]),
    /**
     * Array of editable layers
     */
    editableLayers: Ember.A([]),
    /**
     * The current active feature group
     */
    activeFeatureGroup: null,
    /**
     * The bulk action to perform
     */
    bulkAction: null,
    /**
     * Determines if all items are checked
     */
    checkAll: false,
    /**
     * All selected vessels
     */
    selectedZones: Ember.computed('model.@each.checked', function () {
      return this.model.filter(v => v.checked);
    }),
    /**
     * Togggles all checked
     */
    toggleCheckAll: Ember.observer('checkAll', function () {
      if (this.checkAll) {
        this.model.forEach(i => Ember.set(i, 'checked', true));
      } else {
        this.model.forEach(i => Ember.set(i, 'checked', false));
      }
    })
  }, (_applyDecoratedDescriptor(_obj, "toggleSort", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleSort"), _obj)), _obj)));
  _exports.default = _default;
});