define("@fixflo/frontend/models/file", ["exports", "@ember-data/model", "moment", "ember-get-config"], function (_exports, _model, _moment, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    uuid: (0, _model.attr)('string'),
    uploader_uuid: (0, _model.attr)('string'),
    company_uuid: (0, _model.attr)('string'),
    key_uuid: (0, _model.attr)('string'),
    caption: (0, _model.attr)('string'),
    s3url: (0, _model.attr)('string'),
    key: (0, _model.attr)('string'),
    bucket: (0, _model.attr)('string'),
    folder: (0, _model.attr)('string'),
    etag: (0, _model.attr)('string'),
    original_filename: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    content_type: (0, _model.attr)('string'),
    file_size: (0, _model.attr)('number'),
    slug: (0, _model.attr)('string'),
    permalink: (0, _model.attr)('string'),
    deleted_at: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('string'),
    updated_at: (0, _model.attr)('string'),
    /**
     * Download the file
     *
     * @return Promise
     */
    download: function () {
      window.open(_emberGetConfig.default.api.host + '/' + _emberGetConfig.default.api.namespace + '/files/download?file=' + this.id, '_self');
    },
    updatedAgo: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).fromNow();
    }),
    updatedAt: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).format('MMM Do YYYY');
    }),
    createdAgo: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).fromNow();
    }),
    createdAt: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).format('MMM Do YYYY');
    })
  });
  _exports.default = _default;
});