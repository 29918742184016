define("@fixflo/frontend/components/note-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      /**
       * Post a note to the company scope
       */
      postNote: function () {
        const content = this.newNote;
        const note = this.store.createRecord('note', {
          scope: 'company',
          content
        });
        note.save().then(() => {
          Ember.setProperties(this, {
            newNote: null
          });
        });
      }
    },
    /**
     * Class styles appended to component
     */
    classNames: ['note-box'],
    /**
     * Inject the current user service
     *
     * @var {Servie}
     */
    currentUser: Ember.inject.service(),
    /**
     * Inject the api adapter
     */
    store: Ember.inject.service(),
    /**
     * Inject the ajax api adapter
     */
    ajax: Ember.inject.service(),
    /**
     * Initially loaded notes
     */
    notes: Ember.A([]),
    /**
     * The note message being written
     */
    newNote: null,
    /**
     * All notes in the store
     */
    allNotes: Ember.computed(function () {
      return this.store.peekAll('note');
    }),
    /**
     * All current company notes
     */
    companyNotes: Ember.computed('allNotes.@each', function () {
      return this.allNotes.filter(n => Ember.get(n, 'scope') === 'company');
    }),
    /**
     * On dom insert display all company members and the users groups
     */
    didInsertElement: async function () {
      // get groups
      const notes = await this.store.query('note', {
        scope: 'company'
      }).then(notes => notes);
      // set to component
      Ember.setProperties(this, {
        notes
      });
    }
  });
  _exports.default = _default;
});