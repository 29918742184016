define("@fixflo/frontend/helpers/dot-underscore", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dotUnderscore = dotUnderscore;
  _exports.default = void 0;
  function dotUnderscore([string]) {
    console.log(string);
    return Ember.String.underscore(string.replace(/\./gim, '_'));
  }
  var _default = Ember.Helper.helper(dotUnderscore);
  _exports.default = _default;
});