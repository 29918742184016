define("@fixflo/frontend/services/dialog", ["exports", "ember-dialog/services/dialog"], function (_exports, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _dialog.default.extend({
    _confirm: function (options) {
      return this.show('dialog/layouts/app-confirm', null, null, options);
    },
    conf: function (options) {
      return this.show('dialog/layouts/app-confirm', null, null, options);
    },
    _show: function (template, context, options) {
      return this.show('dialog/layouts/app-confirm', template, context, options);
    },
    open: function (template, context, options) {
      return this.show('dialog/layouts/app-confirm', `dialog/${template}`, context, options);
    },
    startLoading: function (presenter) {
      presenter = presenter || this.presenter;
      if (presenter && (!presenter.isDestroyed || !presenter.isDestroying)) {
        presenter.set('isLoading', true);
      }
    },
    stopLoading: function (presenter) {
      presenter = presenter || this.presenter;
      if (presenter && (!presenter.isDestroyed || !presenter.isDestroying)) {
        presenter.set('isLoading', false);
      }
    },
    disableAcceptButton: function (presenter) {
      presenter = presenter || this.presenter;
      if (presenter && (!presenter.isDestroyed || !presenter.isDestroying)) {
        presenter.set('isAcceptDisabled', true);
      }
    },
    enableAcceptButton: function (presenter) {
      presenter = presenter || this.presenter;
      if (presenter && (!presenter.isDestroyed || !presenter.isDestroying)) {
        presenter.set('isAcceptDisabled', false);
      }
    },
    setAcceptButtonText: function (text, presenter) {
      presenter = presenter || this.presenter;
      if (presenter && (!presenter.isDestroyed || !presenter.isDestroying)) {
        presenter.set('acceptText', text);
      }
    },
    _close: function () {
      if (this.presenter && (!this.presenter.isDestroyed || !this.presenter.isDestroying)) {
        this.presenter._accept();
      }
    },
    created: function (presenter) {
      presenter.set('isLoading', false);
      presenter.setProperties({
        startLoading: this.startLoading.bind(this, presenter),
        stopLoading: this.stopLoading.bind(this, presenter),
        disableAcceptButton: this.disableAcceptButton.bind(this, presenter),
        enableAcceptButton: this.enableAcceptButton.bind(this, presenter),
        setAcceptButtonText: this.setAcceptButtonText.bind(this, presenter)
      });
      this.set('presenter', presenter);
      this._super(presenter);
    }
  });
  _exports.default = _default;
});