define("@fixflo/frontend/controllers/dashboard/settings/team", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      /**
       * Forwards and handles actions from table/action components
       */
      tableAction: function (action, model) {
        this.send(action, model);
      },
      /**
       * Sorts the current data listed
       */
      tableSort: function (sorts) {
        // TODO
      },
      /**
       * Create a new user
       */
      create: async function () {
        const user = this.store.createRecord('user', {
          name: null,
          type: 'user'
        });
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // render dialog
        this.dialog.open('user-form', user, {
          title: 'Create new user',
          acceptText: 'Save',
          controller: this,
          accept: () => {
            this.dialog.startLoading();
            user.save().then(user => {
              this.refreshRoute().then(() => {
                this.notifications.success('New user added');
                this.dialog.stopLoading();
                this.presenter._accept();
              });
            }).catch(error => {
              this.dialog.stopLoading();
              this.notifications.serverError(error);
            });
          },
          decline: () => {
            user.destroyRecord();
            this.presenter._decline();
          }
        });
      },
      /**
       * Edit a user
       */
      edit: async function (user) {
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // render dialog
        this.dialog.open('user-form', user, {
          title: Ember.String.htmlSafe(`Edit user <i>${Ember.get(user, 'name')}</i>`),
          acceptText: 'Save Changes',
          controller: this,
          accept: () => {
            this.dialog.startLoading();
            // save changes
            user.save().then(user => {
              this.dialog.stopLoading();
              this.notifications.info(`Changes to user ${user.get('name')} saved.`);
              this.dialog._close();
            }).catch(error => {
              this.dialog.stopLoading();
              this.notifications.serverError(error);
            });
          }
        });
      },
      /**
       * Delete a user
       */
      delete: function (user) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Delete user',
          acceptText: 'Confirm and delete',
          message: Ember.String.htmlSafe(`Are you sure you want to delete this user <i>${Ember.get(user, 'name')}</i>?<br><div class="text-orange-400 mt-2">Once this user is deleted, all of their fixtures will be transferred to the organization owner account.</div>`),
          accept: () => {
            this.dialog.startLoading();
            user.destroyRecord().then(user => {
              this.refreshRoute().then(() => {
                this.notifications.info(`User ${user.get('name')} was deleted.`);
                this.dialog.stopLoading();
                this.dialog._close();
              });
            }).catch(error => {
              this.dialog.stopLoading();
              this.notifications.serverError(error);
            });
          }
        });
      }
    },
    /**
     * Query parameters
     */
    queryParams: ['page', 'limit', 'sort', 'query', 'role'],
    /**
     * Current page
     */
    page: 1,
    /**
     * Default pagination limit
     */
    limit: 10,
    /**
     * Default sorting
     */
    sort: 'created_at',
    /**
     * Columns to display in table
     * @type array
     */
    columns: [{
      name: `Name`,
      valuePath: `name`
    }, {
      name: `Email`,
      valuePath: `email`
    }, {
      name: `Role`,
      valuePath: `role_name`
    }, {
      name: `Created`,
      valuePath: `createdAt`
    }, {
      name: `Last Seen`,
      valuePath: `lastSeenOnline`
    }, {
      name: ``,
      menuItems: [{
        action: 'edit',
        icon: 'icon-content_edit',
        can: 'edit user',
        label: 'Edit User'
      }, {
        action: 'delete',
        icon: 'icon-navigation_delete',
        can: 'delete user',
        label: 'Delete User'
      }]
    }]
  });
  _exports.default = _default;
});