define("@fixflo/frontend/models/activity", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    uuid: (0, _model.attr)('string'),
    log_name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    details: (0, _model.attr)('string'),
    company_id: (0, _model.attr)('string'),
    subject_id: (0, _model.attr)('string'),
    subject_type: (0, _model.attr)('string'),
    subject_name: (0, _model.attr)('string'),
    subject: (0, _model.attr)('raw'),
    causer_id: (0, _model.attr)('string'),
    causer_type: (0, _model.attr)('string'),
    causer_name: (0, _model.attr)('string'),
    causer_avatar_url: (0, _model.attr)('string'),
    properties: (0, _model.attr)('raw'),
    deleted_at: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('string'),
    updated_at: (0, _model.attr)('string'),
    /**
     * Array of changes properties as string
     */
    changes: Ember.computed('properties', function () {
      let changes = [];
      for (let attribute in Ember.get(this, 'properties.attributes')) {
        if (!['updated_at'].includes(attribute)) {
          changes.push(`'${attribute}' from '${Ember.get(this, `properties.old.${attribute}`)}' to '${Ember.get(this, `properties.attributes.${attribute}`)}'`);
        }
      }
      return changes;
    }),
    // /**
    //  * The model the activity was on
    //  */
    // subject_model: computed(function() {
    //     return decamelize(get(this, 'subject_type').split('\\').reverse()[0]);
    // }),

    // /**
    //  * Detailed description of the activity
    //  */
    //    details: computed('log_name', 'description', 'properties', 'causer_name', 'subject_name', 'created_at', 'changes', function() {
    //        switch(get(this, 'description')) {
    //            case 'deleted':
    //                if(get(this, 'subject_model') === 'fixture_set_group') {
    //                    return `${get(this, 'causer_name')} removed <i>${get(this, 'subject.set_name')} fixtures</i> from the <i>${get(this, 'subject.group_name')} board</i>.`;
    //                }
    //            default:
    //                break;
    //        }
    //        let changes = get(this, 'changes');
    //        return `${get(this, 'causer_name')} ${get(this, 'description')} ${changes.join(', ')} on ${get(this, 'subject_name')}`;
    //    }),

    updatedAgo: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).fromNow();
    }),
    updatedAt: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).format('MMM Do YYYY');
    }),
    createdAgo: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).fromNow();
    }),
    createdAt: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).format('MMM Do YYYY');
    })
  });
  _exports.default = _default;
});