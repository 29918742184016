define("@fixflo/frontend/templates/components/min-max-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qt8+xLOJ",
    "block": "{\"symbols\":[],\"statements\":[[5,\"input\",[[12,\"class\",\"form-control d-inline-block w-32 mr-4\"]],[[\"@type\",\"@value\"],[\"number\",[24,[\"values\",\"min\"]]]]],[0,\"\\n\"],[5,\"input\",[[12,\"class\",\"form-control d-inline-block w-32 mr-4\"]],[[\"@type\",\"@value\"],[\"number\",[24,[\"values\",\"max\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/components/min-max-input.hbs"
    }
  });
  _exports.default = _default;
});