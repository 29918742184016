define("@fixflo/frontend/components/select-2", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Should be text input
     *
     * @var string
     */
    tagName: 'select',
    /**
     * Attribute names
     *
     * @var array
     */
    attributeBindings: ['multiple'],
    /**
     * Default input classes
     *
     * @var array
     */
    classNames: ['form-control'],
    /**
     * Default input classes
     *
     * @var array
     */
    selectClass: '',
    /**
     * Determines if the field is invalid
     *
     * @var string
     */
    isInvalid: false,
    /**
     * If input is invalid toggle class is-invalid to parent container
     *
     * @var Ember.Object.Observer
     */
    bindIsInvalidClass: Ember.observer('isInvalid', function () {
      return this.checkIsInvalid();
    }),
    /**
     * Function to check if innput is valid
     *
     * @void
     */
    checkIsInvalid: function () {
      const select = (0, _jquery.default)(this.element);
      if (this.isInvalid) {
        select.addClass('is-invalid');
      } else {
        select.removeClass('is-invalid');
      }
    },
    /**
     * The selected value
     *
     * @var mixed
     */
    value: null,
    /**
     * The options
     *
     * @var array
     */
    options: [],
    /**
     * If list of object properties
     * provide a property for value
     *
     * @return string
     */
    optionValue: null,
    /**
     * If list of object properties
     * provide a property for the label
     *
     * @return string
     */
    optionLabel: null,
    /**
     * Multiple option selector
     *
     * @var integer
     */
    multiple: false,
    /**
     * Set the multiple value as array
     *
     * @var integer
     */
    multipleAsArray: false,
    /**
     * Multiple option selector
     *
     * @var integer
     */
    allowClear: false,
    /**
     * Whether or not to yield the option to the component
     *
     * @var integer
     */
    noYield: false,
    /**
     * Width of selector
     *
     * @var integer
     */
    width: '100%',
    /**
     * Placeholder
     *
     * @var string
     */
    placeholder: '',
    /**
     * Is it a country dropdown? show flags :)
     *
     * @var boolean
     */
    showFlags: false,
    /**
     * Remote ajax source
     *
     * @var object
     */
    ajax: null,
    /**
     * Initialize the input
     *
     * @void
     */
    didInsertElement: function () {
      const component = this;
      const element = window.jQuery(this.element);
      const showFlags = this.showFlags;
      const options = {
        width: '100%',
        placeholder: component.get('placeholder'),
        allowClear: component.get('allowClear'),
        multiple: component.get('multiple'),
        ajax: component.get('ajax'),
        containerCssClass: component.get('selectClass'),
        dropdownCssClass: component.get('selectClass')
      };
      if (showFlags) {
        options.templateResult = country => {
          if (!country.id) {
            return country.text;
          }
          var $country = (0, _jquery.default)('<span class="mr-2 flag-icon flag-icon-' + country.id.toLowerCase() + '"></span>' + '<span class="flag-text">' + country.text + '</span>');
          return $country;
        };
      }
      const select2 = element.select2(options);
      // console.log(component.get('value'), component.get('multiple'));
      if (component.get('value') && component.get('multiple')) {
        setTimeout(function () {
          element.val(values).trigger('change');
          var values = component.get('value');
          values = component.cleanArray(values);
          element.val(values).trigger('change');
          // console.log(element, values);
        }, 600);
      }
      // on select set the value
      select2.on('select2:select', function (e) {
        if (component.get('ajax') !== null) {
          // make it appear selection was input
          element.next().find('.select2-selection__placeholder').text(e.params.data.text);
        } else if (component.get('multiple') == true) {
          if (!component.get('multipleAsArray')) {
            component.set('value', component.get('value') + ',' + e.params.data.id);
          } else {
            let v = component.get('value');
            v.push(e.params.data.id);
            component.set('value', v);
          }
        } else {
          component.set('value', e.params.data.id);
        }
        component.sendAction('onSelect', e.params.data.id);
      });
      // handle de-selection for multiple
      select2.on('select2:unselect', function (e) {
        if (component.get('multiple') == true) {
          // var data = element.select2('data');
          let value = component.get('value');
          let removed = e.params.data.id;
          value.removeObject(removed);
          // console.log(e, e.params, value);
          // // var value = e.params.data.map(function(selection) {
          // //     return selection.id;
          // // });
          if (!value.length) {
            return component.set('value', '');
          }
          component.set('value', value);
        } else {
          component.set('value', null);
          element.select2('close');
        }
      });
      // check if iis valid
      this.checkIsInvalid();
    },
    /**
     * Cleans an array
     *
     * @return array
     */
    cleanArray: function (actual) {
      var newArray = new Array();
      for (var i = 0; i < actual.length; i++) {
        if (actual[i]) {
          newArray.push(actual[i]);
        }
      }
      return newArray;
    }
  });
  _exports.default = _default;
});