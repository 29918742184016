define("@fixflo/frontend/utils/cp/rounded-class", ["exports", "@fixflo/ui/utils/cp/rounded-class"], function (_exports, _roundedClass) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _roundedClass.default;
    }
  });
});