define("@fixflo/frontend/templates/dialog/upload-fixtures", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "LG4C4Wpm",
    "block": "{\"symbols\":[\"file\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n\\t\"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"Upload excel documents to be processed\"],[9],[0,\"\\n\\t\"],[5,\"file-upload\",[],[[\"@key\",\"@onUploadStart\",\"@onDidUpload\",\"@multiple\"],[\"excel-imports\",[28,\"fn\",[[24,[\"uploadStarted\"]]],null],[28,\"fn\",[[24,[\"addFile\"]]],null],true]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"uploads\",\"length\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"ul\",true],[10,\"class\",\"uploads-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"uploads\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"li\",true],[10,\"class\",\"flex flex-row justify-between items-center\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"span\",true],[8],[1,[23,1,[\"original_filename\"]],false],[0,\" - \"],[1,[28,\"format-bytes\",[[23,1,[\"file_size\"]]],null],false],[9],[0,\"\\n\\t\\t\\t\\t\"],[5,\"fix-flo/button\",[],[[\"@scheme\",\"@size\",\"@icon\",\"@onClick\"],[\"danger\",\"xs\",\"fa fa-times\",[28,\"fn\",[[24,[\"removeFile\"]],[23,1,[]]],null]]]],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"import-progress\"],[10,\"class\",\"d-none my-3\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"progress\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"progress-bar progress-bar-striped progress-bar-animated bg-info\"],[10,\"role\",\"progressbar\"],[10,\"style\",\"width: 0%;\"],[10,\"aria-valuenow\",\"0\"],[10,\"aria-valuemin\",\"0\"],[10,\"aria-valuemax\",\"100\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"font-size-sm font-w600\"],[8],[0,\"0%\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"progress-message my-2\"],[8],[0,\"Starting import...\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/upload-fixtures.hbs"
    }
  });
  _exports.default = _default;
});