define("@fixflo/frontend/templates/components/placeholder/page-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "a6L5o0KI",
    "block": "{\"symbols\":[],\"statements\":[[5,\"content-loader\",[],[[\"@height\"],[9]],{\"statements\":[[0,\"\\n\\t\"],[7,\"rect\",true],[11,\"x\",0],[11,\"y\",0],[11,\"rx\",3],[11,\"ry\",3],[11,\"width\",100],[11,\"height\",9],[8],[9],[0,\"\\n\\t\"],[7,\"rect\",true],[11,\"x\",105],[11,\"y\",0],[11,\"rx\",3],[11,\"ry\",3],[11,\"width\",50],[11,\"height\",9],[8],[9],[0,\"\\n\\t\"],[7,\"rect\",true],[11,\"x\",160],[11,\"y\",0],[11,\"rx\",3],[11,\"ry\",3],[11,\"width\",30],[11,\"height\",9],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/components/placeholder/page-filters.hbs"
    }
  });
  _exports.default = _default;
});