define("@fixflo/frontend/routes/dashboard", ["exports", "@fixflo/frontend/mixins/manages-reports", "@fixflo/frontend/helpers/object-to-uri", "ember-query-params-reset/utils/reset-query-params", "moment"], function (_exports, _managesReports, _objectToUri, _resetQueryParams, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let DashboardRoute = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember.inject.service, _dec13 = Ember.inject.service, _dec14 = Ember.inject.service, (_class = (_temp = class DashboardRoute extends Ember.Route.extend(_managesReports.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "socket", _descriptor, this);
      _initializerDefineProperty(this, "logrocket", _descriptor2, this);
      _initializerDefineProperty(this, "tour", _descriptor3, this);
      _initializerDefineProperty(this, "sentry", _descriptor4, this);
      _initializerDefineProperty(this, "zendesk", _descriptor5, this);
      _initializerDefineProperty(this, "analytics", _descriptor6, this);
      _initializerDefineProperty(this, "currentUser", _descriptor7, this);
      _initializerDefineProperty(this, "fetch", _descriptor8, this);
    }
    /**
     * Handle the loading substate
     */
    loading(transition) {
      const controller = this.controllerFor('dashboard');
      controller.set('isLoading', true);
      transition.promise.finally(() => {
        controller.set('isLoading', false);
      });
      return true;
    }

    /**
     * Invalidates session and logs the user out
     *
     * @void
     */
    invalidateSession() {
      this.layout.createLoadingOverlay('Logging out...');
      return this.session.invalidate();
    }

    /**
     * Just use the browser to go back
     */
    goBack() {
      window.history.back();
    }

    /**
     * Creates a new widget
     */
    createWidget() {
      const widget = this.store.createRecord('widget', {
        name: null,
        source_uuid: null,
        type: null
      });
      this.dialog.one('created', presenter => {
        this.set('presenter', presenter);
      });
      this.dialog.open('widget-form', widget, {
        title: 'Create a new widget',
        acceptText: 'Create',
        acceptIcon: 'fa fa-check',
        widgetTypes: ['board', 'metric'],
        accept: () => {
          // set source
          Ember.set(widget, 'source_uuid', Ember.get(widget, 'source_uuid.id'));
          // save widget
          widget.save().then(() => {
            this.notifications.success('New widget created');
            this.presenter._accept();
          }).catch(error => {
            this.notifications.serverError(error);
          });
        },
        decline: () => {
          widget.destroyRecord();
          this.presenter._decline();
        }
      });
    }

    /**
     * Transition to a board
     */
    transitionToBoard(board) {
      // set route title
      // this.loader.set('routeTitle', board.get('name'));
      // if free board route to free board route
      if (board.get('type') === 'free') {
        return this.transitionTo('dashboard.boards.free', board);
      }
      // continue to board view
      this.transitionTo('dashboard.boards.view', board).then(state => {
        const route = Ember.get(state, 'routeInfos.lastObject._route');
        if (route) {
          (0, _resetQueryParams.default)(route);
        }
      });
    }

    /**
     * Open a saved filter
     */
    openSavedFilter(savedFilter) {
      const queryParams = Ember.get(savedFilter, 'params');
      // set filter name as param
      queryParams._filter = Ember.get(savedFilter, 'name');
      // handle switch
      switch (Ember.get(savedFilter, 'for')) {
        case 'company-board':
        case 'fixture-set':
        case 'board':
          {
            let route = 'dashboard.fixtures.view';
            const defaultParams = ['query', 'load', 'discharge', 'vessel', 'boundary', 'discharge_boundary', 'quantity', 'quantity[min]', 'quantity[max]', 'grade', 'charterer', 'rate', 'query', 'laycanRangeStart', 'laycanRangeEnd', 'before', 'after', 'internal', 'confidentialityLevel', 'vesselFleet', 'vesselDeadWeight', 'vesselDeadWeight[min]', 'vesselDeadWeight[max]', 'vesselYearBuilt', 'vesselYearBuilt[min]', 'vesselYearBuilt[max]', 'vesselCubicCapacity', 'vesselCubicCapacity[min]', 'vesselCubicCapacity[max]', 'vesselLengthOverAll', 'vesselLengthOverAll[min]', 'vesselLengthOverAll[max]'];
            defaultParams.forEach(p => {
              if (Ember.typeOf(queryParams[p]) === 'object') {
                // conver to uri array and spread into query params
                let objectParam = (0, _objectToUri.objectToUri)(queryParams[p], p, true);
                // update queryParams
                delete queryParams[p];
                Object.assign(queryParams, objectParam);
              }
              queryParams[p] = !queryParams[p] ? null : queryParams[p];
            });
            // console.log('[savedFilter:queryParams]', queryParams);
            // get the route
            if (Ember.get(savedFilter, 'for') === 'board') {
              route = 'dashboard.boards.view';
            } else if (Ember.get(savedFilter, 'for') === 'company-board') {
              // company board
              return this.transitionTo('dashboard.boards.company', {
                queryParams
              });
            }
            return this.transitionTo(route, Ember.get(savedFilter, 'subject_slug'), {
              queryParams
            });
          }
      }
    }

    /**
     * Inject the socket service
     *
     * @var {Service}
     */

    /**
     * Inject the logrocket service
     *
     * @var {Service}
     */

    /**
     * Inject the tour service
     *
     * @var {Service}
     */

    /**
     * Inject the sentry service
     *
     * @var {Service}
     */

    /**
     * Inject the zendesk service
     *
     * @var {Service}
     */

    /**
     * Inject the analytics service
     *
     * @var {Service}
     */

    /**
     * Inject the current user service
     *
     * @var {Service}
     */

    /**
     * Inject the fetch service
     *
     * @var {Service}
     */

    /**
     * Check and only allow authenticated users in dashboard
     *
     * @void
     */
    async beforeModel(transition) {
      this.session.requireAuthentication(transition, 'auth.login');
      return this.session.loadCurrentUser(transition).then(() => this.identify());
    }

    /**
     * Identify the authenticated user to services
     *
     * @void
     */
    identify() {
      // start socket listening for user events
      this.socket.startWatching();
      // track every page view for current user
      this.analytics.trackPageViews();
      // identify session for sentry
      this.sentry.identify();
      // identify session for logrocket
      this.logrocket.identify();
      // identify session for zendesk
      this.zendesk.identify();
    }

    /**
     * Remind the user when their session is expiring
     *
     * @void
     */
    remindUserSessionEnding() {
      const sessionExpiresAt = this.session.get('data.authenticated.expires_at');
      const momentExpires = _moment.default.unix(sessionExpiresAt);
      const alertUserAt = momentExpires.subtract(5, 'minutes');
      // if not valid
      if (!alertUserAt.isValid()) {
        return;
      }
      let duration = alertUserAt.diff((0, _moment.default)());
      const durationMinutes = Math.round(duration / (60 * 1000));
      duration = durationMinutes <= 5 ? 0 : duration;
      // console.log(alertUserAt, duration, durationMinutes);
      setTimeout(() => {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Your session is about to expire!',
          declineText: 'Continue',
          acceptText: 'Extend Session & Continue',
          message: `Your session is going to expire ${momentExpires.fromNow()}, at approximately ${momentExpires.format('LT')}, you can extend by clicking extend or let it expire.`
        }).then(() => {
          return this.fetch.post('auth/extend-session');
        });
      }, duration);
    }

    /**
     * Handles the loading of the current user via session
     *
     * @void
     */
    async loadUser() {
      await this.session.loadCurrentUser();
      this.identify();
      this.remindUserSessionEnding();
    }
  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "loading", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "invalidateSession", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "invalidateSession"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goBack", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "goBack"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createWidget", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "createWidget"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToBoard", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToBoard"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openSavedFilter", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "openSavedFilter"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "socket", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "logrocket", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tour", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sentry", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "zendesk", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DashboardRoute;
});