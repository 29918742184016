define("@fixflo/frontend/helpers/hash", ["exports", "ember-dialog/helpers/hash"], function (_exports, _hash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _hash.default;
    }
  });
});