define("@fixflo/frontend/components/x-toggle", ["exports", "ember-toggle/components/x-toggle/component", "@fixflo/frontend/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const config = _environment.default['ember-toggle'] || {};
  var _default = _component.default.extend({
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    theme: config.defaultTheme || 'default',
    defaultOffLabel: config.defaultOffLabel || 'Off::off',
    defaultOnLabel: config.defaultOnLabel || 'On::on',
    showLabels: config.defaultShowLabels || false,
    size: config.defaultSize || 'medium'
  });
  _exports.default = _default;
});