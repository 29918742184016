define("@fixflo/frontend/mixins/manages-reports", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    actions: {
      /**
       * Initiates a request to download a report
       *
       * @void
       */
      downloadReport: function (savedReport) {
        const sanctumToken = this.session.data.authenticated.token;
        const host = `${_emberGetConfig.default.api.host}/${_emberGetConfig.default.api.namespace}`;
        const format = Ember.get(savedReport, 'format');
        const report = Ember.get(savedReport, 'report_type_uuid');
        const user = this.currentUser.id;
        const sort = Ember.get(savedReport, 'sort');
        const quantity = !Ember.get(savedReport, 'quantity') ? '' : `&quantity[min]=${Ember.get(savedReport, 'quantity.min')}&quantity[max]=${Ember.get(savedReport, 'quantity.max')}`;
        return window.open(`${host}/reports/download?sanctum_token=${sanctumToken}&format=${format}&report=${report}&user=${user}&sort=${sort}${quantity}`);
      },
      /**
       * Starts a dialog to delete a report
       *
       * @void
       */
      deleteReport: function (report) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Delete report',
          acceptText: 'Confirm and delete',
          message: Ember.String.htmlSafe(`Are you sure you want to delete this report <i>${Ember.get(report, 'name')}</i>?`),
          accept: () => {
            this.dialog.startLoading();
            report.destroyRecord().then(() => {
              this.dialog.stopLoading();
              this.notifications.info('Report deleted');
            }).catch(error => {
              this.dialog.stopLoading();
              this.notifications.serverError(error);
            });
          }
        });
      },
      /**
       * View a report params
       *
       * @void
       */
      viewReportParams: function (report) {
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // render dialog
        this.dialog.open('saved-report-params', report, {
          title: Ember.String.htmlSafe(`Viewing <i>${Ember.get(report, 'name')}</i> report parameters`),
          acceptText: 'Done'
        });
      },
      /**
       * Saves a new report based on options selected
       *
       * @void
       */
      saveReport: function (reportOptions) {
        if (Ember.isEmpty(reportOptions)) {
          return this.notifications.warning('Select a report type first.');
        }
        const savedReport = this.store.createRecord('saved-report', {
          report_type_uuid: Ember.get(reportOptions, 'report_type_uuid.id'),
          sort: Ember.get(reportOptions, 'sort'),
          quantity: Ember.get(reportOptions, 'quantity'),
          format: 'txt'
        });
        this.dialog.one('created', presenter => {
          this.set('presenter', presenter);
        });
        this.dialog.open('save-report-form', savedReport, {
          title: 'Save current report',
          acceptText: 'Save',
          accept: () => {
            this.dialog.startLoading();
            savedReport.save().then(filter => {
              this.notifications.success('Report saved');
              this.dialog.stopLoading();
              this.presenter._accept();
            });
          },
          decline: () => {
            savedReport.destroyRecord();
            this.dialog.stopLoading();
            this.presenter._decline();
          }
        });
      },
      /**
       * Saves a new report based on options selected
       *
       * @void
       */
      editSavedReport: function (savedReport) {
        console.log('[editSavedReport]', savedReport);
        this.dialog.one('created', presenter => {
          this.set('presenter', presenter);
        });
        this.dialog.open('save-report-form', savedReport, {
          title: 'Edit saved report',
          acceptText: 'Save Changes',
          accept: () => {
            this.dialog.startLoading();
            savedReport.save().then(filter => {
              this.notifications.success('Report saved');
              this.dialog.stopLoading();
              this.presenter._accept();
            });
          },
          decline: () => {
            this.dialog.stopLoading();
            this.presenter._decline();
          }
        });
      }
    }
  });
  _exports.default = _default;
});