define("@fixflo/frontend/controllers/dashboard/settings/index/edit-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      /**
       * Save user settings
       */
      saveProfile: function () {
        this.set('savingProfile', true);
        // eslint-disable-next-line no-unused-vars
        this.model.user.save().then(user => {
          this.set('savingProfile', false);
          this.notifications.success('Profile changes saved.');
        });
      }
    }
  });
  _exports.default = _default;
});