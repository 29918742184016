define("@fixflo/frontend/utils/is-empty-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isEmptyObject;
  function isEmptyObject(obj) {
    return typeof obj === 'object' && Object.values(obj).length === 0;
  }
});