define("@fixflo/frontend/models/fixture-set-group", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    uuid: (0, _model.attr)('string'),
    group_uuid: (0, _model.attr)('string'),
    set_uuid: (0, _model.attr)('string'),
    set_name: (0, _model.attr)('string'),
    group_name: (0, _model.attr)('string'),
    deleted_at: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('string'),
    updated_at: (0, _model.attr)('string'),
    updatedAgo: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).fromNow();
    }),
    updatedAt: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).format('YYYY-MM-DD HH:mm A');
    }),
    createdAgo: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).fromNow();
    }),
    createdAt: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).format('YYYY-MM-DD HH:mm A');
    }),
    /**
     * Converts fixtures to table data
     * 
     * @return array
     */
    toTableData: function () {
      return this.fixtures.map(fixture => {
        let newFixture = {
          line: fixture.get('raw_text')
        };
        fixture.get('values').forEach(fv => {
          newFixture[fv.get('key')] = fv.serialize();
        });
        return newFixture;
      });
    }
  });
  _exports.default = _default;
});