define("@fixflo/frontend/components/date-range-picker", ["exports", "@fixflo/frontend/utils/start-of-decade", "@fixflo/frontend/utils/end-of-decade", "moment", "jquery"], function (_exports, _startOfDecade, _endOfDecade, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Component element type
     */
    tagName: 'input',
    /**
     * Classes to bind to element
     */
    classNameBindings: ['disabled'],
    /**
     * Bind these attributes
     */
    attributeBindings: ['disabled', 'autocomplete'],
    /**
     * The start date selected
     */
    startDate: null,
    /**
     * The start date selected
     */
    endDate: null,
    /**
     * The clear button element
     */
    clearButton: null,
    /**
     * If input should be disabled
     */
    disabled: false,
    /**
     * The placeholder message
     */
    placeholder: 'Select a date range',
    /**
     * Disable autocomplete on date picker fields
     */
    autocomplete: 'new-password',
    /**
     * Convert the date supplied to a moment
     */
    strToMoment: function (str) {
      // no time just today
      if (!str) {
        return (0, _moment.default)();
      }
      // handle a relative time
      if (str.startsWith('+') || str.startsWith('-')) {
        let direction = str.substr(0, 1).trim();
        let count = str.substr(1, str.indexOf(' ')).trim();
        let unit = str.substr(str.indexOf(' '), str.length).trim();
        return direction === '+' ? (0, _moment.default)().add(count, unit) : (0, _moment.default)().subtract(count, unit);
      } else if (str === 'today') {
        return (0, _moment.default)();
      } else if (str === 'yesterday') {
        return (0, _moment.default)().subtract(1, 'day');
      } else if (str === 'last month') {
        return (0, _moment.default)().substract(1, 'month').startOf('month');
      } else if (str === 'start of decade') {
        return (0, _moment.default)((0, _startOfDecade.default)());
      } else if (str === 'end of decade') {
        return (0, _moment.default)((0, _endOfDecade.default)());
      } else if (str === 'first day of this month') {
        return (0, _moment.default)().startOf('month');
      } else if (str === 'first day of quarter') {
        return (0, _moment.default)().startOf('quarter');
      } else if (str === 'last day of quarter') {
        return (0, _moment.default)().endOf('quarter');
      } else if (str === 'tomorrow') {
        return (0, _moment.default)().add(1, 'day');
      } else if (str === 'next week') {
        return (0, _moment.default)().add(1, 'week').endOf('week');
      } else if (str === 'next month') {
        return (0, _moment.default)().add(1, 'month').endOf('month');
      } else if (str === 'next year') {
        return (0, _moment.default)().add(1, 'year').endOf('year');
      }
      // return str to moment
      return (0, _moment.default)(str);
    },
    /**
     * Clear value to show placeholder
     */
    clearValueIfNull() {
      const startDate = this.startDate;
      const endDate = this.endDate;
      if (!startDate || !endDate) {
        (0, _jquery.default)(this.element).val('');
      }
    },
    /**
     * Initialize date-range-picker
     */
    didInsertElement: function () {
      const startDate = this.startDate;
      const endDate = this.endDate;
      const placeholder = this.placeholder;
      const datePicker = (0, _jquery.default)(this.element).daterangepicker({
        startDate: this.strToMoment(startDate),
        endDate: this.strToMoment(endDate),
        disabled: this.disabled || false,
        parentEl: this.parentEl || 'body'
      });
      (0, _jquery.default)(this.element).attr('placeholder', placeholder);
      this.clearValueIfNull();

      // handle apply
      datePicker.on('apply.daterangepicker', (ev, picker) => {
        const {
          startDate,
          endDate
        } = picker;
        Ember.setProperties(this, {
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD')
        });
        datePicker.data('daterangepicker').setStartDate(startDate);
        datePicker.data('daterangepicker').setEndDate(endDate);
      });

      // handle cancel
      datePicker.on('cancel.daterangepicker', (ev, picker) => {
        Ember.setProperties(this, {
          startDate: null,
          endDate: null
        });
        this.clearValueIfNull();
      });

      // handle blur
      datePicker.on('hide.daterangepicker', (ev, picker) => {
        this.clearValueIfNull();
      });
    }
  });
  _exports.default = _default;
});