define("@fixflo/frontend/templates/dialog/bulk-publish-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "HP2QXG5y",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"p\",true],[8],[0,\"\\n\\tYou have selected \"],[1,[28,\"pluralize\",[[24,[\"selected\",\"length\"]],\"fixture set\"],null],false],[0,\" to be \"],[4,\"if\",[[28,\"eq\",[[24,[\"action\"]],\"publish\"],null]],null,{\"statements\":[[0,\"published\"]],\"parameters\":[]},{\"statements\":[[0,\"set to draft\"]],\"parameters\":[]}],[0,\".\\n\"],[9],[0,\"\\n\"],[7,\"ul\",true],[10,\"class\",\"list-scroll-box\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"filter-by\",[\"isChecked\",true,[24,[\"selected\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"li\",true],[8],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\\t\\t\\t\"],[7,\"a\",false],[12,\"href\",\"javascript:;\"],[12,\"class\",\"mr-3 text-gray-light\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[23,1,[\"isChecked\"]]],null],false],[[\"on\"],[\"click\"]]],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-times-circle\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/bulk-publish-form.hbs"
    }
  });
  _exports.default = _default;
});