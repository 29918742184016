define("@fixflo/frontend/controllers/dashboard/boards/company", ["exports", "@fixflo/frontend/controllers/dashboard/fixtures/view"], function (_exports, _view) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _view.default.extend({
    /**
     * Is board
     */
    isBoard: true,
    /**
     * Page limit
     */
    limit: 30,
    /**
     * Default sorting
     */
    sort: '-laycan_range_start',
    /**
     * Current page
     */
    page: 1
  });
  _exports.default = _default;
});