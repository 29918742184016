define("@fixflo/frontend/controllers/dashboard/reports/saved-reports", ["exports", "@fixflo/frontend/mixins/manages-reports"], function (_exports, _managesReports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend(_managesReports.default, {
    actions: {
      /**
       * Forwards and handles actions from table/action components
       */
      tableAction: function (action, model) {
        this.send(action, model);
      },
      /**
       * Sorts the current data listed
       */
      tableSort: function (sorts) {
        // TODO
      },
      /**
       * set the type filter
       */
      setTypeFilter: function (typeModel) {
        this.set('type', typeModel ? typeModel.get('id') : null);
      }
    },
    /**
     * Query parameters
     */
    queryParams: ['page', 'limit', 'sort', 'query', 'format', 'type'],
    /**
     * Current page
     */
    page: 1,
    /**
     * Default pagination limit
     */
    limit: 10,
    /**
     * Default sorting
     */
    sort: 'created_at',
    /**
     * Formats
     * @type {Array}
     */
    formats: ['txt', 'docx', 'pdf', 'xlsx', 'csv', 'tsv', 'ods', 'xls', 'html'],
    /**
     * Columns to display in table
     * @type array
     */
    columns: [{
      name: `Name`,
      valuePath: `name`
    }, {
      name: `Sorted By`,
      valuePath: `sortedBy`
    }, {
      name: `Date Range`,
      valuePath: `filteredDateRange`
    }, {
      name: `Created by`,
      valuePath: `created_by`
    }, {
      name: `Shared`,
      valuePath: `shared_with_company`
    }, {
      name: `Format`,
      valuePath: `format`
    }, {
      name: ``,
      menuItems: true
    }]
  });
  _exports.default = _default;
});