define("@fixflo/frontend/components/sortable-group", ["exports", "@shopify/draggable/lib/plugins/swap-animation", "@shopify/draggable"], function (_exports, _swapAnimation, _draggable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Initialize sortable group
     */
    didInsertElement: function () {
      const sortable = new _draggable.Sortable(this.element, {
        draggable: '.draggable',
        delay: 400,
        swapAnimation: {
          duration: 200,
          easingFunction: 'ease-in-out',
          horizontal: true
        },
        plugins: [_swapAnimation.default]
      });
      // bind events to component actions
      sortable.on('sortable:start', () => {
        if (Ember.typeOf(this.onSortStart) === 'function') {
          return this.onSortStart();
        }
      });
      sortable.on('sortable:sort', () => {
        if (Ember.typeOf(this.onSort) === 'function') {
          return this.onSort();
        }
      });
      sortable.on('sortable:sorted', () => {
        if (Ember.typeOf(this.onSorted) === 'function') {
          return this.onSorted();
        }
      });
      sortable.on('sortable:stop', () => {
        if (Ember.typeOf(this.onSortEnd) === 'function') {
          return this.onSortEnd();
        }
      });
    }
  });
  _exports.default = _default;
});