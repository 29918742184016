define("@fixflo/frontend/serializers/report-type", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _rest.default.extend(_rest.EmbeddedRecordsMixin, {
    primaryKey: 'uuid',
    attrs: {
      type_sources: {
        embedded: 'always'
      },
      type_sorts: {
        embedded: 'always'
      }
    },
    serialize: function (snapshot, options) {
      var json = this._super(snapshot, options);

      // if report template model set for role, set the id
      if (snapshot.attr('report_template_uuid') instanceof Object) {
        json.report_template_uuid = snapshot.attr('report_template_uuid').get('id');
      }

      // fix filters before saving
      if (snapshot.attr('filters') instanceof Object) {
        const filters = snapshot.attr('filters');
        Object.keys(filters).map(f => {
          Ember.set(filters, f, Ember.typeOf(filters[f]) === 'instance' ? filters[f].get('id') : filters[f]);
          if (Ember.typeOf(filters[f]) === 'array' && filters[f].every(m => Ember.typeOf(m) === 'instance')) {
            Ember.set(filters, f, filters[f].map(m => Ember.get(m, 'id')));
          }
          // if(typeOf(filters[f]) === 'array' && f === 'confidentialityLevel') {
          //     console.log('[serializing confidentiality level input]', f, filters[f]);
          //     set(filters, f, filters[f].map(m => get(m, 'key')));
          // }
        });

        json.filters = filters;
      }
      return json;
    }
  });
  _exports.default = _default;
});