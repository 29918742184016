define("@fixflo/frontend/routes/dashboard/boards/index", ["exports", "@fixflo/frontend/mixins/track-reloads"], function (_exports, _trackReloads) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_trackReloads.default, {
    /**
     * Inject the current user service
     *
     * @var {Service}
     */
    currentUser: Ember.inject.service(),
    /**
     * Inject the tour service
     *
     * @var {Service}
     */
    tour: Ember.inject.service(),
    model: function () {
      return this.store.findAll('board');
    },
    setupController: function (controller, model) {
      this._super(...arguments);
      this.canUserViewCompanyBoard(controller);
      this.startIntroduction(controller, model);
    },
    canUserViewCompanyBoard: function (controller) {
      const user = this.currentUser.user;
      const permission = user.permissions.find(p => p.subject === 'company-board');
      const canUserViewCompanyBoard = user.isSuperUser ? true : permission.read;
      // set permission result to controller
      controller.canUserViewCompanyBoard = canUserViewCompanyBoard;
      // return permission check result
      return canUserViewCompanyBoard;
    },
    async startIntroduction(controller, model) {
      return this.tour.runStack(['overview', 'viewBoards'], controller, model);
    }
  });
  _exports.default = _default;
});