define("@fixflo/frontend/router", ["exports", "@fixflo/frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "location", _environment.default.locationType);
      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }
  }
  _exports.default = Router;
  window.__CLASSIC_OWN_CLASSES__.set(Router, true);
  Router.map(function () {
    this.route('dashboard', function () {
      this.route('home');
      this.route('fixtures', {
        path: '/workspace'
      }, function () {
        this.route('new');
        this.route('view', {
          path: '/~/:slug'
        });
      });
      this.route('settings', function () {
        this.route('team', {
          path: '/users'
        });
        this.route('zones');
        this.route('cargo-grades');
        this.route('groups', function () {
          this.route('manage-group', {
            path: '/~/:slug'
          });
        });
        this.route('diff');
        this.route('roles');
        this.route('index', {
          path: '/'
        }, function () {
          this.route('edit-profile', {
            path: '/'
          });
          this.route('confidentiality-settings');
        });
        this.route('vessels');
      });
      this.route('boards', {
        path: '/'
      }, function () {
        this.route('view', {
          path: '/~/:slug'
        });
        this.route('company');
        this.route('free', {
          path: '/free/:slug'
        });
      });
      this.route('widgets', function () {});
      this.route('reports', function () {
        this.route('templates');
        this.route('types');
        this.route('new-template');
        this.route('edit-template', {
          path: '/:slug'
        });
        this.route('saved-reports');
      });
      this.route('views', function () {});
    });
    this.route('auth', {
      path: '/'
    }, function () {
      this.route('login', {
        path: '/'
      });
      this.route('sign-up');
      this.route('forgot-password');
      this.route('reset-password');
    });
    this.route('admin', {
      path: '~hq'
    }, function () {
      this.route('login', {
        path: '/'
      });
      this.route('panel', function () {
        this.route('zones', function () {});
        this.route('vessels', function () {
          this.route('edit', {
            path: '/:slug'
          });
          this.route('create');
        });
        this.route('users', function () {});
        this.route('companies', function () {});
        this.route('charterers', function () {});
        this.route('cargo-grades', function () {});
      });
      this.route('vessels', function () {});
      this.route('charterers');
    });
  });
});