define("@fixflo/frontend/templates/dashboard/boards/view-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "z8f/ZYIR",
    "block": "{\"symbols\":[],\"statements\":[[5,\"fix-flo/page-title\",[],[[\"@title\",\"@backRoute\"],[[28,\"or\",[[23,0,[\"loader\",\"routeTitle\"]],\"Loading...\"],null],[28,\"if\",[[24,[\"isBoard\"]],\"dashboard.boards.index\",\"dashboard.fixtures.index\"],null]]]],[0,\"\\n\"],[5,\"page-loading-indicator\",[],[[],[]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dashboard/boards/view-loading.hbs"
    }
  });
  _exports.default = _default;
});