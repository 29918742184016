define("@fixflo/frontend/components/chat-box", ["exports", "howler"], function (_exports, _howler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      /**
       * Send a new message to the current session
       */
      sendMesage: function () {
        const content = this.newMessage;
        const session = this.currentSession;
        const chatInputElement = this.element.querySelector('#chatInputBox');
        if (!content) {
          return;
        }
        // disable input
        chatInputElement.disabled = true;
        // send
        const message = this.store.createRecord('chat-message', {
          chat_session_uuid: session.get('id'),
          content
        });
        // keep scroll up to date
        this.handleScroll();
        // send message
        message.save().then(() => {
          // enable chat input
          chatInputElement.disabled = false;
          // clear message
          Ember.setProperties(this, {
            newMessage: null
          });
          // reset focus
          chatInputElement.focus();
        });
      },
      /**
       * Chat with a session
       */
      startChat: function (session) {
        this.send(Ember.get(session, '_internalModel.modelName') === 'user' ? 'startChatWithUser' : 'startChatWithGroup', session);
      },
      /**
       * Start a PM chat with another user
       */
      startChatWithUser: function (user) {
        // load chat session if not found create one
        this.fetch.request('chat-sessions/lookup', {
          data: {
            participants: [this.currentUser.id, user.get('id')]
          }
        }).then(session => {
          const currentSession = this.store.push(this.store.normalize('chat-session', session));
          Ember.setProperties(this, {
            currentSession,
            chatting: true
          });
          this.send('watchSession', currentSession);
        });
      },
      /**
       * Enter a group chat
       */
      startChatWithGroup: function (group) {
        // load chat session if not found create one
        this.fetch.request('chat-sessions/lookup', {
          data: {
            group: group.get('id')
          }
        }).then(session => {
          const currentSession = this.store.push(this.store.normalize('chat-session', session));
          Ember.setProperties(this, {
            currentSession,
            chatting: true
          });
          this.send('watchSession', currentSession);
        });
      },
      /**
       * Exit a chat session
       */
      exitSession: function () {
        Ember.setProperties(this, {
          chatting: false,
          currentSession: null,
          newMessage: null
        });
      },
      /**
       * Watch a session via the socket
       */
      watchSession: function (session) {
        const sessionChannel = this.socket.subscribe(session.get('id'));
        sessionChannel.watch(message => {
          // add message to store, unless sender sent
          if (Ember.get(message, 'recipients').includes(this.currentUser.id)) {
            // add chat to store
            this.store.push(this.store.normalize('chat-message', message));
            // update scroll timeout
            Ember.set(this, 'userScrolled', false);
          }
          // keep scroll up to date
          this.handleScroll();
        });
        // handle the shift/enter key submission for textarea
        const chatInputElement = this.element.querySelector('#chatInputBox');
        chatInputElement.addEventListener('keypress', event => {
          if (event.which === 13 && !event.shiftKey) {
            chatInputElement.disabled = true;
            this.send('sendMesage');
            event.preventDefault();
          }
        });
      }
    },
    /**
     * Class styles appended to component
     */
    classNames: ['chat-box'],
    /**
     * Class styles binded to component properties
     */
    classNameBindings: ['chatting'],
    /**
     * Inject the auth service
     */
    auth: Ember.inject.service(),
    /**
     * Inject the api adapter
     */
    store: Ember.inject.service(),
    /**
     * Inject the ajax api adapter
     */
    ajax: Ember.inject.service(),
    /**
     * Inject the socket service
     */
    socket: Ember.inject.service(),
    /**
     * Get groups the user is in
     */
    groups: [],
    /**
     * Get other users in the company
     */
    users: [],
    /**
     * Users query for chat sessions
     */
    searchQuery: null,
    /**
     * Filtered chat sessions
     */
    sessions: Ember.computed('groups.@each', 'users.@each', 'searchQuery', function () {
      const sessions = [...this.groups.toArray(), ...this.users.toArray()];
      if (!this.searchQuery) {
        return sessions;
      }
      return sessions.filter(s => Ember.get(s, 'name').toLowerCase().includes(this.searchQuery.toLowerCase()));
    }),
    /**
     * Is the user in the chat interface
     */
    chatting: false,
    /**
     * The current chat session
     */
    currentSession: null,
    /**
     * The chat message being written
     */
    newMessage: null,
    /**
     * Has user scrolled the chat box?
     */
    userScrolled: false,
    /**
     * The current user id
     */
    currentUserId: Ember.computed(function () {
      return this.currentUser.id;
    }),
    /**
     * The instance of the chat notification ping
     */
    chatPing: Ember.computed(function () {
      return new _howler.Howl({
        src: ['/sounds/noty.mp3']
      });
    }),
    /**
     * Handle scroll functionality, and keep scroll to bottom by default
     */
    handleScroll: function () {
      const messagesBox = document.getElementById('messages-box');
      messagesBox.scrollTop = messagesBox.scrollHeight;
      messagesBox.onscroll = () => {
        Ember.set(this, 'userScrolled', true);
      };
      const updateScroll = () => {
        if (this.userScrolled === true) return;
        messagesBox.scrollTop = messagesBox.scrollHeight;
      };
      setInterval(updateScroll, 1000);
    },
    /**
     * The name of the chat session
     */
    sessionName: Ember.computed('currentSession', 'currentSession.type', 'currentSession.id', function () {
      const session = this.currentSession;
      if (!session) return;
      switch (Ember.get(session, 'type')) {
        case 'private':
          return session ? Ember.get(session, 'users').find(user => {
            return user.get('id') !== this.currentUser.id;
          }).get('name') : null;
        case 'group':
          return Ember.get(session, 'group.name');
        default:
          return;
      }
    }),
    /**
     * Currently chatting with
     */
    chattingWith: Ember.computed('currentSession', 'currentSession.id', function () {
      const session = this.currentSession;
      return session ? Ember.get(session, 'users').find(user => {
        return user.get('id') !== this.currentUser.id;
      }) : null;
    }),
    /**
     * All messages in the store
     */
    allMessages: Ember.computed(function () {
      return this.store.peekAll('chat-message');
    }),
    /**
     * All current session messages
     */
    sessionMessages: Ember.computed('allMessages.@each', 'currentSession.id', function () {
      return this.allMessages.filter(m => Ember.get(m, 'chat_session_uuid') === Ember.get(this, 'currentSession.id'));
    }),
    /**
     * On dom insert display all company members and the users groups
     */
    didInsertElement: async function () {
      // get groups
      const groups = await this.store.query('group', {
        with_member: this.currentUser.id
      }).then(groups => groups);
      // get users
      const users = await this.store.findAll('user').then(users => users.filter(user => user.get('id') !== this.currentUser.id));
      // set to component
      Ember.setProperties(this, {
        groups,
        users
      });
      // fix chat interface scroll to the bottom
      this.handleScroll();
    }
  });
  _exports.default = _default;
});