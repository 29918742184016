define("@fixflo/frontend/helpers/is-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isArray = isArray;
  _exports.default = void 0;
  function isArray([arr]) {
    return Ember.isArray(arr);
  }
  var _default = Ember.Helper.helper(isArray);
  _exports.default = _default;
});