define("@fixflo/frontend/services/sidebar-tabs", ["exports", "ember-infinity/lib/infinity-model", "ember-infinity/utils", "ember-local-storage", "@fixflo/frontend/utils/is-model"], function (_exports, _infinityModel, _utils, _emberLocalStorage, _isModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let SidebarTabsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _emberLocalStorage.storageFor)('board-cache'), _dec4 = (0, _emberLocalStorage.storageFor)('tabs'), _dec5 = Ember.computed('currentUser.id'), _dec6 = Ember._tracked, (_class = (_temp = class SidebarTabsService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "boardCache", _descriptor3, this);
      _initializerDefineProperty(this, "tabs", _descriptor4, this);
      _initializerDefineProperty(this, "_activeTabs", _descriptor5, this);
    }
    /**
     * Inject the `currentUser` service
     *
     * @var {Service}
     */
    /**
     * Inject the `store` service
     *
     * @var {Service}
     */
    /**
     * Inject the `boardCache` storage
     *
     * @var {StorageObject}
     */
    /**
     * Inject the `tabs` storage
     *
     * @var {StorageObject}
     */
    /**
     * The cache key for board records
     *
     * @var {String}
     */
    get cacheKey() {
      return `tabs:${this.currentUser.id}`;
    }

    /**
     * The tracked mutable active tabs.
     * 
     * @var {Array}
     */

    /**
     * The currently active tabs.
     * 
     * @var {Array}
     */
    get activeTabs() {
      if (Ember.isArray(this._activeTabs) && this._activeTabs.length) {
        return this._activeTabs;
      }
      return this.getTabs();
    }

    /**
     * Returns all tabs in storage.
     *
     * @var {Array}
     */
    getTabs() {
      return Ember.A(Array.from(this.tabs.get(this.cacheKey) ?? []));
    }

    /**
     * Retrieve a tab by id.
     * 
     * @param {String} id 
     */
    getTab(id) {
      const tabs = this.getTabs();
      return tabs.findBy('id', id);
    }

    /**
     * Retrieve a tab index by id.
     * 
     * @param {String} id 
     */
    getTabIndex(id) {
      const tabs = this.getTabs();
      return tabs.findIndex(t => t.id === id);
    }
    setTabProperty(id, prop, value) {
      const tab = this.getTab(id);
      Ember.setProperties(tab, {
        [prop]: value
      });
    }

    /**
     * Inserts a new tab.
     *
     * @void
     */
    newTab(tab = {}) {
      const tabs = this.getTabs();

      // check if tab exists
      const existingIndex = tabs.findIndex(t => t.id === tab.id);
      if (existingIndex > -1) {
        tabs.replace(existingIndex, 1, [tab]);
      } else {
        tabs.pushObject(tab);
      }
      this.tabs.set(this.cacheKey, tabs);
      // this.activeTabs = tabs;
      this.notifyPropertyChange('activeTabs');
      return tab;
    }

    /**
     * Removes a tab.
     *
     * @void
     */
    removeTab(index) {
      const tabs = this.getTabs();
      if (tabs.objectAt(index)) {
        tabs.removeAt(index);
        this.tabs.set(this.cacheKey, tabs);
        // this.activeTabs = tabs;
        this.notifyPropertyChange('activeTabs');
        return true;
      }
      return false;
    }
    restoreInfinityFixtureFromTab(tab, options, infinityModelService) {
      let {
        fixtures,
        meta
      } = tab.data;

      // serialize fixtures to models
      fixtures = fixtures.map(fixture => {
        if ((0, _isModel.default)(fixture)) {
          return fixture;
        }
        return this.store.push(this.store.normalize('fixture', fixture));
      });

      // modelName will always be `fixture`
      const modelName = 'fixture';
      // default is to start at 0, request next page and increment
      const currentPage = options.startingPage === undefined ? 0 : options.startingPage - 1;
      // sets first page when route is loaded
      const firstPage = currentPage === 0 ? 1 : currentPage + 1;
      // chunk requests by indicated perPage param
      const perPage = options.perPage || 25;

      // store service methods (defaults to ember-data if nothing passed)
      const store = options.store || this.store;
      const storeFindMethod = options.storeFindMethod || 'query';
      let infinityModel = _infinityModel.default.create();

      // check if user passed in param w/ infinityModel, else default
      const perPageParam = (0, _utils.paramsCheck)('perPageParam', options, infinityModel);
      const pageParam = (0, _utils.paramsCheck)('pageParam', options, infinityModel);
      const totalPagesParam = (0, _utils.paramsCheck)('totalPagesParam', options, infinityModel);
      const countParam = (0, _utils.paramsCheck)('countParam', options, infinityModel);
      // const infinityCache = paramsCheck('infinityCache', options, infinityModel);

      // create identifier for use in storing unique cached infinity model
      // let identifier = stringifyObjectValues(options);

      delete options.startingPage;
      delete options.perPage;
      delete options.perPageParam;
      delete options.pageParam;
      delete options.totalPagesParam;
      delete options.countParam;
      delete options.infinityCache;
      delete options.store;
      delete options.storeFindMethod;
      let initParams = {
        meta,
        container: Ember.getOwner(infinityModelService),
        currentPage,
        firstPage,
        perPage,
        perPageParam,
        pageParam,
        totalPagesParam,
        countParam,
        extraParams: options,
        _infinityModelName: modelName,
        store,
        storeFindMethod,
        content: Ember.A(fixtures)
      };
      Ember.setProperties(infinityModel, initParams);
      return infinityModel;
    }
  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "boardCache", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tabs", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "cacheKey", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "cacheKey"), _class.prototype), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_activeTabs", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  })), _class));
  _exports.default = SidebarTabsService;
});