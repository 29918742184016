define("@fixflo/frontend/models/free-board-column", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    uuid: (0, _model.attr)('string'),
    board_uuid: (0, _model.attr)('string'),
    label: (0, _model.attr)('string'),
    key: (0, _model.attr)('string'),
    read_only: (0, _model.attr)('boolean'),
    order: (0, _model.attr)('number'),
    deleted_at: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('string'),
    updated_at: (0, _model.attr)('string'),
    updatedAgo: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).fromNow();
    }),
    updatedAt: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).format('YYYY-MM-DD HH:mm A');
    }),
    createdAgo: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).fromNow();
    }),
    createdAt: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).format('YYYY-MM-DD HH:mm A');
    })
  });
  _exports.default = _default;
});