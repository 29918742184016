define("@fixflo/frontend/mixins/query-param-serializer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    serializeQueryParam(value, urlKey, defaultValueType) {
      return this._super(value, urlKey, defaultValueType);
    },
    deserializeQueryParam(value, urlKey, defaultValueType) {
      return this._super(value, urlKey, defaultValueType);
    }
  });
  _exports.default = _default;
});