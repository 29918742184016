define("@fixflo/frontend/models/board", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    uuid: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    owner_name: (0, _model.attr)('string'),
    is_owner: (0, _model.attr)('boolean'),
    is_shared: (0, _model.attr)('boolean'),
    params: (0, _model.attr)('raw'),
    participants: (0, _model.attr)('array'),
    cargo_grade: (0, _model.hasMany)('cargo-grade'),
    order: (0, _model.attr)('number'),
    is_sample: (0, _model.attr)('boolean'),
    tags: (0, _model.attr)('array'),
    type: (0, _model.attr)('string'),
    slug: (0, _model.attr)('string'),
    deleted_at: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('string'),
    updated_at: (0, _model.attr)('string'),
    dateStrStartChars: ['+', '-', 't', 'l', 'n', 'f', 's'],
    participantUsers: Ember.computed('participants.@each', function () {
      return Array.from(this.participants).filter(p => Ember.get(p, 'type') === 'user');
    }),
    participantGroups: Ember.computed('participants.@each', function () {
      return Array.from(this.participants).filter(p => Ember.get(p, 'type') === 'group');
    }),
    useRelativeLaycan: Ember.computed('laycanRangeStart', 'laycanRangeEnd', function () {
      const laycanStartFirstChar = Ember.typeOf(this.get('params.laycanRangeStart')) === 'string' ? this.get('params.laycanRangeStart').substring(0, 1) : false;
      const laycenEndFirstChar = Ember.typeOf(this.get('params.laycanRangeEnd')) === 'string' ? this.get('params.laycanRangeEnd').substring(0, 1) : false;
      return laycanStartFirstChar && this.dateStrStartChars.includes(laycanStartFirstChar) || laycenEndFirstChar && this.dateStrStartChars.includes(laycenEndFirstChar);
    }),
    useRelativeDate: Ember.computed('before', 'after', function () {
      const beforeFirstChar = Ember.typeOf(this.get('params.before')) === 'string' ? this.get('params.before').substring(0, 1) : false;
      const afterFirstChar = Ember.typeOf(this.get('params.after')) === 'string' ? this.get('params.after').substring(0, 1) : false;
      return beforeFirstChar && this.dateStrStartChars.includes(beforeFirstChar) || afterFirstChar && this.dateStrStartChars.includes(afterFirstChar);
    }),
    hasParams: function (params = []) {
      if (Ember.isArray(params)) {
        return typeof this.params === 'object' && params.every(p => this.params.hasOwnProperty(p));
      } else if (typeof params === 'string') {
        return typeof this.params === 'object' && this.params.hasOwnProperty(params);
      }
    },
    tagged: Ember.computed('tags.@each', function () {
      const tags = this.tags || [];
      const typeTag = this.type === 'free' ? ['free'] : [];
      const gradeTag = this.get('cargo_grade.system_display_name') ? [this.get('cargo_grade.system_display_name')] : [];
      return [...typeTag, ...gradeTag, ...tags];
    }),
    updatedAgo: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).fromNow();
    }),
    updatedAt: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).format('YYYY-MM-DD HH:mm A');
    }),
    createdAgo: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).fromNow();
    }),
    createdAt: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).format('DD MMM YYYY');
    }),
    toJSON(options) {
      return this.serialize();
    }
  });
  _exports.default = _default;
});