define("@fixflo/frontend/templates/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Z7Ssxt7f",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ff_layout\"],[8],[0,\"\\n    \"],[5,\"fix-flo/side-nav\",[],[[\"@logo\",\"@user\",\"@navItems\",\"@tabs\",\"@onSignOut\",\"@onTabClick\",\"@onCloseTab\"],[\"/images/logo-horizontal.png\",[23,0,[\"user\"]],[23,0,[\"navItems\"]],[23,0,[\"activeTabs\"]],[28,\"route-action\",[\"invalidateSession\"],null],[28,\"fn\",[[23,0,[\"transitionToTab\"]]],null],[28,\"fn\",[[23,0,[\"closeTab\"]]],null]]]],[0,\"\\n    \"],[7,\"main\",true],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"scrollable-area\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"minHeight\"],[8],[0,\"\\n                \"],[1,[22,\"outlet\"],false],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dashboard.hbs"
    }
  });
  _exports.default = _default;
});