define("@fixflo/frontend/controllers/dashboard/boards/index", ["exports", "@fixflo/frontend/utils/boundary-to-array", "@fixflo/frontend/utils/geo-json-to-boundary", "ember-get-config"], function (_exports, _boundaryToArray, _geoJsonToBoundary, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      /**
       * Create a new board
       */
      create: function () {
        // create a board instance
        const board = this.store.createRecord('board', {
          params: {
            quantity: {
              min: null,
              max: null
            }
          },
          type: 'fixtures'
        });
        // set presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // open board dialog
        this.dialog.open('board-creator', board, {
          dialogId: 'createBoard',
          title: `Create a new board`,
          acceptText: 'Confirm & Create',
          isCreatingNewBoard: true,
          type: 'fixtures',
          showMoreFilters: false,
          confidentialityLevels: this.confidentialityLevels,
          toggleInternal: onlyInternal => {
            board.set('params.internal', onlyInternal || null);
          },
          setGradeSelectorInstance: api => {
            this.gradeSelector = api;
          },
          useRelativeLaycan: Ember.typeOf(Ember.get(board, 'params.laycanRangeStart')) === 'string' && ['+', '-'].includes(Ember.get(board, 'params.laycanRangeStart').substr(0, 1)),
          useRelativeDate: Ember.typeOf(Ember.get(board, 'params.before')) === 'string' && ['+', '-'].includes(Ember.get(board, 'params.before').substr(0, 1)),
          controller: this,
          accept: () => {
            this.dialog.startLoading();
            Object.keys(Ember.get(board, 'params')).map(f => {
              Ember.set(board, `params.${f}`, Ember.typeOf(Ember.get(board, `params.${f}`)) === 'instance' ? Ember.get(board, `params.${f}.id`) : Ember.get(board, `params.${f}`));
              if (Ember.typeOf(Ember.get(board, `params.${f}`)) === 'array' && Ember.get(board, `params.${f}`).every(m => Ember.typeOf(m) === 'instance')) {
                Ember.set(board, `params.${f}`, Ember.get(board, `params.${f}`).map(m => Ember.get(m, 'id')));
              }
            });
            return board.save().then(() => {
              this.dialog.stopLoading();
              this.presenter._accept();
              this.refreshRoute().then(() => this.notifications.success('New board created.'));
            }).catch(error => {
              this.dialog.stopLoading();
              this.notifications.serverError(error);
            });
          }
        });
      },
      /**
       * Display dialog to edit this board
       */
      edit: function (board) {
        // hacky hotfix for setting quantity filters on board params
        if (!Ember.get(board, 'params.quantity')) {
          board.set('params.quantity', {
            min: null,
            max: null
          });
        }
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        this.dialog.open('board-creator', board, {
          title: `Edit ${Ember.get(board, 'name')} board`,
          acceptText: 'Save Changes',
          showMoreFilters: false,
          confidentialityLevels: this.confidentialityLevels,
          controller: this,
          toggleInternal: onlyInternal => {
            board.set('params.internal', onlyInternal || null);
          },
          setGradeSelectorInstance: () => {},
          accept: () => {
            this.dialog.startLoading();
            Object.keys(Ember.get(board, 'params')).map(f => {
              Ember.set(board, `params.${f}`, Ember.typeOf(Ember.get(board, `params.${f}`)) === 'instance' ? Ember.get(board, `params.${f}.id`) : Ember.get(board, `params.${f}`));
              if (Ember.typeOf(Ember.get(board, `params.${f}`)) === 'array' && Ember.get(board, `params.${f}`).every(m => Ember.typeOf(m) === 'instance')) {
                Ember.set(board, `params.${f}`, Ember.get(board, `params.${f}`).map(m => Ember.get(m, 'id')));
              }
            });
            board.save().then(() => {
              this.notifications.success('Changes to board saved.');
              this.dialog.stopLoading();
              this.presenter._accept();
            }).catch(error => {
              this.dialog.stopLoading();
              this.notifications.serverError(error);
            });
          }
        });
      },
      /**
       * Display dialog to delete this board
       */
      delete: function (board) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: `Delete ${Ember.get(board, 'name')} board`,
          acceptText: 'Confirm and delete',
          message: `Are you sure you want to delete ${Ember.get(board, 'name')} board?`,
          accept: () => {
            this.dialog.startLoading();
            board.destroyRecord().then(board => {
              this.dialog.stopLoading();
              this.notifications.info(`Board ${board.get('name')} was deleted.`);
              this.dialog._close();
            }).catch(error => {
              this.dialog.stopLoading();
              this.notifications.serverError(error);
            });
          }
        });
      },
      /**
       * Share the board with other users or groups
       */
      share: function (board) {
        const query = query => {
          return this.fetch.request('actions/search-collaborators', {
            data: {
              query
            }
          }).then(collaborators => collaborators.filter(u => Ember.get(u, 'uuid') !== Ember.get(this, 'source.owner_uuid')));
        };
        this.dialog.one('created', shareBoardPresenter => this.setProperties({
          shareBoardPresenter
        }));
        this.dialog.open('share-board-form', null, {
          title: `Share "${board.name}" Board`,
          query,
          board,
          selected: [],
          canEdit: false,
          acceptText: 'Share',
          accept: () => {
            const groups = this.shareBoardPresenter.selected.filter(s => Ember.get(s, 'owner_uuid')).map(s => Ember.get(s, 'uuid'));
            const users = this.shareBoardPresenter.selected.filter(s => Ember.get(s, 'email')).map(s => Ember.get(s, 'uuid'));
            if (users.length || groups.length) {
              this.shareBoardPresenter.startLoading();
              return this.fetch.post('actions/share-board', {
                data: {
                  board: Ember.get(board, 'id'),
                  groups,
                  users,
                  canEdit: this.shareBoardPresenter.canEdit
                }
              }).then(response => {
                this.shareBoardPresenter.stopLoading();
                this.notifications.success('Board shared');
                this.shareBoardPresenter._accept();
              }).catch(error => {
                this.shareBoardPresenter.stopLoading();
                this.notifications.serverError(error);
                this.shareBoardPresenter._accept();
              });
            }
            this.shareBoardPresenter._accept();
          }
        });
      },
      /**
       * Export all fixtures as a report
       */
      exportAll: function (board, sort = '-laycan_range_start') {
        // prompt dialog to generate support
        this.dialog.one('created', presenter => {
          Ember.set(this, 'report_generator_presenter', presenter);
        });
        // create a context
        const report = this.store.createRecord('generated-report', {
          report_template_uuid: null,
          source_uuid: Ember.get(board, 'id'),
          source_type: Ember.get(board, '_internalModel.modelName'),
          format: 'txt',
          sort,
          fixtures: [],
          saved: false
        });
        // prompt for user to find zone to add as relation
        this.dialog.open('generate-report-form', report, {
          title: 'Generate a report',
          className: 'dialog-lg',
          acceptText: 'Confirm & Download',
          user: this.currentUser.user,
          controller: this,
          accept: () => {
            // get sanctum token
            const sanctumToken = this.session.data.authenticated.token;
            // set loading state
            this.report_generator_presenter.startLoading();
            // save report
            return report.save().then(generatedReport => {
              this.report_generator_presenter.stopLoading();
              this.report_generator_presenter._accept();
              window.open(`${_emberGetConfig.default.api.host}/${_emberGetConfig.default.api.namespace}/generated-reports/download?get=${Ember.get(generatedReport, 'id')}&sanctum_token=${sanctumToken}`);
            });
          }
        });
      },
      /**
       * Save boards order after drag and drop
       */
      saveBoardsOrder: function () {
        console.log('[saveBoardsOrder]');
        // get sortable group element
        const sortableGroupElement = document.getElementById('sortable-group');
        // get array of all the boards
        const boardElements = sortableGroupElement.querySelectorAll('.draggable');
        // convert node list to array
        const boardElementsArray = Array.from(boardElements);
        // update sort order for boards and return models
        const sorted = boardElementsArray.map((el, order) => {
          const {
            id
          } = el;
          return {
            id,
            order
          };
        });
        // save to server
        this.fetch.post('boards/sort', {
          data: {
            sorted
          }
        });
      },
      /**
       * Toggle layout personalization by making tags editable and board sortable by dragging
       */
      togglePersonalizeLayout: function () {
        if (this.isBoardsLocked) {
          this.set('isBoardsLocked', false);
        } else {
          this.set('isBoardsLocked', true);
          this.send('saveBoardsOrder');
        }
      },
      /**
       * Adds a new tag to the filtered boards list
       */
      addTagFilter: function (tag) {
        if (this.selectedTags.includes(tag)) {
          return this.selectedTags.removeObject(tag);
        }
        return this.selectedTags.pushObject(tag);
      },
      /**
       * Set the geo filter map instance
       */
      setGeoFilterMapInstance: function (board, forDischarge, {
        target
      }) {
        // console.log('[setGeoFilterMapInstance]', forDischarge, target);
        // target to map
        const map = target;
        // get the param applying boundary to
        const boundaryParam = forDischarge ? 'discharge_boundary' : 'boundary';
        // is the current filter being used on a board
        const isBoard = true;
        // get the existing boundary if any
        const existingBoundary = (0, _boundaryToArray.default)(Ember.get(board, `params.${boundaryParam}`));
        // display existing boundary on map if exists
        if (isBoard && existingBoundary) {
          // create a polygon from existing boundary
          const polygon = L.polygon(existingBoundary, {
            color: '#48BB78'
          }).addTo(map);
          // set map to polygon
          map.fitBounds(polygon.getBounds());
        }
      },
      /**
       * Applies the boundary from the user drawn layer to queryparams
       *
       * @void
       */
      setGeoFilter: function (board, forDischarge, event, layer) {
        const boundaryParam = forDischarge ? 'discharge_boundary' : 'boundary';
        const boundary = (0, _geoJsonToBoundary.default)(layer);
        Ember.set(board, `params.${boundaryParam}`, boundary);
      },
      /**
       * The official geo filter
       */
      addGeoFilter: function (board, forDischarge = false) {
        // get the boundary param
        const boundaryParam = forDischarge ? 'discharge_boundary' : 'boundary';
        // get filters
        const filters = Ember.get(board, 'params');
        // is the current filter being used on a board
        const isBoard = true;
        // get the existing boundary if any
        const existingBoundary = (0, _boundaryToArray.default)(Ember.get(board, `params.${boundaryParam}`));
        // set presenter to controller
        this.dialog.one('created', geoFilterPresenter => this.setProperties({
          geoFilterPresenter
        }));
        // open presenter dialog
        this.dialog.open('board-creator-geo-filter-form', null, {
          title: `Filter ${forDischarge ? 'discharge' : 'load'} geographically`,
          acceptText: 'Done',
          board,
          forDischarge,
          isBoard,
          existingBoundary,
          controller: this,
          accept: () => {
            Ember.setProperties(board, {
              params: filters
            });
            this.geoFilterPresenter._accept();
          }
        });
      },
      /**
       * View the board collaborators
       */
      viewBoardCollaborators: async function (board) {
        const {
          collaborators,
          owner
        } = await this.fetch.get('actions/get-board-collaborators', {
          data: {
            board: Ember.get(board, 'id')
          }
        });
        // check if current user is board owner
        const isBoardOwner = Ember.get(owner, 'uuid') === this.currentUser.id;
        // set dialog presenter
        this.dialog.one('created', viewBoardCollaboratorsPresenter => this.setProperties({
          viewBoardCollaboratorsPresenter
        }));
        // triger dialog
        this.dialog.open('board-collaborators', null, {
          title: 'View Collaborators',
          collaborators,
          owner,
          board,
          isBoardOwner,
          canShare: true,
          controller: this,
          acceptText: 'Done',
          accept: () => {
            // convert board groups and board members to models
            const boardMembers = collaborators.users.map(member => {
              return this.store.push(this.store.normalize('board-member', member));
            });
            // convert board groups to models
            const boardGroups = collaborators.groups.map(group => {
              return this.store.push(this.store.normalize('board-group', group));
            });
            // save collaborators and close dialog
            Ember.RSVP.all([...boardMembers, ...boardGroups].invoke('save')).then(response => {
              this.viewBoardCollaboratorsPresenter._accept();
            });
          }
        });
      },
      /**
       * Removes a board collaborator
       */
      removeCollaborator: function (collaborator) {
        // check collaborator type
        const isGroup = Ember.get(collaborator, 'user_uuid') === undefined;
        // convert to model
        if (isGroup) {
          collaborator = this.store.push(this.store.normalize('board-group', collaborator));
        } else {
          collaborator = this.store.push(this.store.normalize('board-member', collaborator));
        }
        this.dialog._confirm({
          title: 'Remove collaborator',
          acceptText: 'Confirm and remove',
          message: `Are you sure you want to remove this collaborator (${isGroup ? Ember.get(collaborator, 'group.name') : Ember.get(collaborator, 'user.name')})?`
        }).then(() => {
          collaborator.destroyRecord().then(collaborator => {
            document.getElementById(`collaborator-${Ember.get(collaborator, 'id')}`).remove();
          });
        });
      },
      /**
       * Toggles UI between BoardCard and BoardRow components
       */
      toggleLayout: function () {
        const nextLayout = this.uiLayout === 'cards' ? 'rows' : 'cards';
        this.currentUser.options.setProperties({
          boardsLayout: nextLayout
        });
        this.setProperties({
          uiLayout: nextLayout
        });
      }
    },
    /**
     * The current layout
     *
     * @type {String}
     */
    uiLayout: Ember.computed('currentUser.options.boardsLayout', function () {
      return this.currentUser.options.get('boardsLayout') || 'cards';
    }),
    /**
     * The current layout
     *
     * @type {Boolean}
     */
    isCardLayout: Ember.computed.equal('uiLayout', 'cards'),
    /**
     * All available confidentiality levels
     * @type {Array}
     */
    confidentialityLevels: Ember.A(['public', 'private', 'super_private']),
    /**
     * Determines if customizing boards layout is enabled or disabled
     *
     * @type Boolean
     */
    isBoardsLocked: true,
    /**
     * All the boards loaded from store
     *
     * @type {Array}
     */
    allBoards: Ember.computed(function () {
      return this.store.peekAll('board');
    }),
    /**
     * All the boards with applied filter
     *
     * @type {Array}
     */
    boards: Ember.computed('model.@each.id', 'query', 'selectedTags.@each', function () {
      return this.model.filter(board => {
        // no newly created boards
        if (board.get('isNew')) {
          return false;
        }
        // filter by search query
        if (!Ember.isEmpty(this.query) && !board.name.toLowerCase().includes(this.query)) {
          return false;
        }
        // filter by selected tags
        if (this.selectedTags.length && !board.tagged.some(t => this.selectedTags.includes(t))) {
          return false;
        }
        return board;
      }).sortBy('order');
    }),
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    /**
     * All distinct tags from boards loaded
     *
     * @var {Array}
     */
    tags: Ember.computed('allBoards.@each.{id,tags}', function () {
      return this.allBoards.map(b => Ember.get(b, 'tagged')).flat().uniq();
    }),
    /**
     * All the tags selected by user to filter with
     *
     * @var {Array}
     */
    selectedTags: Ember.A([]),
    /**
     * Query to filter the boards with
     *
     * @var {String}
     */
    query: null,
    /**
     * See if the current user can view/access the company board
     *
     * @var {Boolean}
     */
    canViewCompanyBoard: false
  });
  _exports.default = _default;
});