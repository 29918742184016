define("@fixflo/frontend/services/analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  // eslint-disable-next-line no-undef
  const GoogleAnalytics = window.gtag || gtag;
  let AnalyticsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = (_temp = class AnalyticsService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "currentUser", _descriptor3, this);
    }
    /**
     * Inject the router service
     *
     * @var {Service}
     */
    /**
     * Inject the session service
     *
     * @var {Service}
     */
    /**
     * Inject the current user service
     *
     * @var {Service}
     */
    /**
     * Tracks all page views
     *
     * @void
     */
    trackPageViews() {
      // identify user first if applicable
      this.identify();
      // track each page view by route transition
      this.router.on('routeDidChange', transition => {
        this.trackPage(transition.to);
      });
    }

    /*
     * Send the current page URL to
     * the analytics engine.
     *
     * @method trackPage
     *
     * @param {Object} options
     *   Options to send the analytics engine.
     */
    trackPage(options = {}) {
      const sendEvent = {
        hitType: 'pageview'
      };
      const event = Ember.assign({}, sendEvent, options);
      GoogleAnalytics('config', 'GA_MEASUREMENT_ID', event);
    }

    /*
     * Send an arbitrary event to the
     * analytics engine.
     *
     * @method trackEvent
     *
     * @param {Object} options
     *   Options to send the analytics engine.
     */
    trackEvent(eventName, options = {}) {
      GoogleAnalytics('event', eventName, options);
    }

    /*
     * Identify an anonymous user with a
     * unique ID. This is useful when a
     * user returns to the application
     * an we wish to further track them.
     *
     * This should not be called in
     * conjunction with alias.
     *
     * @method identity
     *
     * @param {Object} options
     *   Options to send the analytics engine.
     */
    identify(options = {}) {
      if (Ember.isBlank(options)) {
        options = this.currentUser.user;
      }
      const {
        id
      } = options;
      GoogleAnalytics('config', 'GA_MEASUREMENT_ID', {
        cookie_prefix: 'fixflo_session',
        cookie_domain: '*.fixflo.',
        cookie_expires: this.session.getSessionSecondsRemaining(),
        user_id: id
      });
    }
  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AnalyticsService;
});