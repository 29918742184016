define("@fixflo/frontend/models/zone", ["exports", "@ember-data/model", "moment", "@fixflo/frontend/utils/get-boundary-center"], function (_exports, _model, _moment, _getBoundaryCenter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    uuid: (0, _model.attr)('string'),
    company_uuid: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    display_name: (0, _model.attr)('string'),
    system_display_name: (0, _model.attr)('string'),
    country: (0, _model.attr)('string'),
    location: (0, _model.attr)('string'),
    latitude: (0, _model.attr)('string'),
    longitude: (0, _model.attr)('string'),
    aliases: (0, _model.attr)('raw'),
    boundaries: (0, _model.hasMany)('boundary', {
      async: false
    }),
    type: (0, _model.attr)('string'),
    slug: (0, _model.attr)('string'),
    deleted_at: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('string'),
    updated_at: (0, _model.attr)('string'),
    aliasesList: Ember.computed('aliases', function () {
      return this.aliases.join(', ');
    }),
    boundary: Ember.computed('boundaries.@each', function () {
      return Ember.get(this, 'boundaries.firstObject');
    }),
    // longitude first coordinates
    coordinates: Ember.computed('boundary', function () {
      const boundary = Ember.get(this, 'boundary.boundary');
      if (!boundary) {
        return [];
      }
      return boundary.map(coord => Ember.get(coord, 'lng') ? [Ember.get(coord, 'lng'), Ember.get(coord, 'lat')] : coord);
    }),
    // latitude first coordinates
    latFirstCoordinates: Ember.computed('boundary', function () {
      const boundary = Ember.get(this, 'boundary.boundary');
      if (!boundary) {
        return [];
      }
      return boundary.map(coord => Ember.get(coord, 'lat') ? [Ember.get(coord, 'lat'), Ember.get(coord, 'lng')] : coord.reverse());
    }),
    center: Ember.computed('boundary', function () {
      const longitude = this.longitude;
      const latitude = this.latitude;
      if (longitude && latitude) {
        return [latitude, longitude];
      }
      return this.getCenter();
    }),
    lat: Ember.computed('center', function () {
      const latitude = this.latitude;
      if (latitude) {
        return latitude;
      }
      return this.center[1]; // latitude 2nd index
    }),

    lng: Ember.computed('center', function () {
      const longitude = this.longitude;
      if (longitude) {
        return longitude;
      }
      return this.center[0]; // longitude 1st index
    }),

    getCenter: function () {
      const coordinates = this.coordinates;
      return (0, _getBoundaryCenter.default)(coordinates);
    },
    updatedAgo: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).fromNow();
    }),
    updatedAt: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).format('YYYY-MM-DD HH:mm A');
    }),
    createdAgo: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).fromNow();
    }),
    createdAt: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).format('YYYY-MM-DD HH:mm A');
    })
  });
  _exports.default = _default;
});