define("@fixflo/frontend/serializers/report-type-sort", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _rest.default.extend(_rest.EmbeddedRecordsMixin, {
    primaryKey: 'uuid',
    serialize: function (snapshot, options) {
      // no serialization is done, can we remove this?
      // it seems, NOT removing `sort_order` silently goes through
      // to the server without errors/side-effects
      return this._super(snapshot, options);
    },
    normalize: function (model, hash, prop) {
      hash.sort_order = hash.sort_by.startsWith('-') ? 'desc' : 'asc';
      return this._super(model, hash, prop);
    }
  });
  _exports.default = _default;
});