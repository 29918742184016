define("@fixflo/frontend/adapters/application", ["exports", "@ember-data/adapter/rest", "ember-local-storage", "ember-inflector", "@fixflo/frontend/config/environment"], function (_exports, _rest, _emberLocalStorage, _emberInflector, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ApplicationAdapter = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _emberLocalStorage.storageFor)('user-options'), _dec4 = Ember._tracked, (_class = class ApplicationAdapter extends _rest.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "userOptions", _descriptor3, this);
      _defineProperty(this, "credentials", 'include');
      _initializerDefineProperty(this, "_headers", _descriptor4, this);
    }
    /**
     * Inject the `session` service
     *
     * @var {Service}
     */
    /**
     * Inject the `currentUser` service
     *
     * @var {Service}
     */
    /**
     * User options for setting specific headers
     *
     * @var StorageObject
     */
    /**
     * The default namespace for the adapter
     *
     * @var {String}
     */
    get host() {
      return Ember.get(_environment.default, 'api.host');
    }

    /**
     * The default namespace for adapter
     *
     * @var {String}
     */
    get namespace() {
      return Ember.get(_environment.default, 'api.namespace');
    }

    /**
     * Credentials
     *
     * @var {String}
     */

    /**
     * Mutable headers property.
     *
     * @var {Array}
     */

    /**
     * The headers to send with request.
     *
     * @var {Object}
     */
    get headers() {
      if (this._headers) {
        return this._headers;
      }
      return this.getHeaders();
    }

    /**
     * Setter fucntion to overwrite headers.
     */
    set headers(headers) {
      this._headers = headers;
    }

    /**
     * Gets headers that should be sent with request.
     *
     * @return {Object}
     */
    getHeaders() {
      const headers = {};

      // check if user is authenticated
      const isAuthenticated = this.session.isAuthenticated;
      const isViewingBoard = this.currentUser.options.get('viewingBoard');
      headers['Content-Type'] = 'application/json';
      if (isAuthenticated) {
        headers['Authorization'] = `Bearer ${this.session.data.authenticated.token}`;
      }
      if (isViewingBoard) {
        headers['X-FIXFLO-BOARD'] = isViewingBoard;
      }
      return headers;
    }

    /**
     * Gets fresh headers and sets them.
     *
     * @return {Object}
     */
    refreshHeaders() {
      const headers = this.getHeaders(true);
      this.headers = headers;
      return headers;
    }

    /**
     * Configure AJAX options for request, return as options hash
     *
     * @param {String} url
     * @param {String} type The request type GET, POST, PUT, DELETE etc.
     * @param {Object} options
     *
     * @return {Object}
     */
    ajaxOptions(url, type, options) {
      this.refreshHeaders();
      const ajaxOptions = super.ajaxOptions(url, type, options);
      ajaxOptions.credentials = this.credentials;
      return ajaxOptions;
    }

    /**
     * Dasherize the path for type
     *
     * @param {Object} type
     */
    pathForType(type) {
      return Ember.String.dasherize((0, _emberInflector.pluralize)(type));
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "userOptions", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_headers", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ApplicationAdapter;
});