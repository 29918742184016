define("@fixflo/frontend/instance-initializers/fixtures-table-renderer", ["exports", "handsontable"], function (_exports, _handsontable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  function initialize(application) {
    // lookup the current user service
    const currentUser = application.lookup('service:current-user');
    const store = application.lookup('service:store');
    // creating binding context
    const context = {
      application,
      currentUser,
      store
    };
    // create a default render function which applies to all cells
    const FixFloBaseRenderer = function (hotInstance, td, row, column, prop, value, cellProperties) {
      // set the current user
      const user = this.currentUser.user;
      // get visual cell details
      const visualRowIndex = hotInstance.toVisualRow(row);
      // const visualColIndex = hotInstance.toVisualColumn(column);
      // get source data
      const data = hotInstance.getSourceData();
      // get this rows data
      const rowData = data[row];
      const visualRowData = data[visualRowIndex];
      // console.log(row, rowData);
      // console.log(visualRowIndex, visualRowData);
      // get the column header
      const columnHeader = typeof prop === 'string' && prop.includes('.value') ? prop.replace('.value', '') : prop;
      // determine if row is checked
      const isChecked = Ember.get(rowData, 'isChecked');
      const isCheckbox = prop === 'isChecked';
      const isSaving = Ember.get(cellProperties, 'isSaving') || false;
      const isCreating = Ember.get(cellProperties, 'isCreating') || false;

      // set cell meta
      if (columnHeader) {
        hotInstance.setCellMetaObject(row, column, {
          data: Ember.get(rowData, columnHeader)
        });
      }
      hotInstance.setCellMeta(row, column, 'fixture_uuid', Ember.get(rowData, 'fixture_uuid'));
      hotInstance.setCellMeta(row, column, 'as_line', Ember.get(rowData, 'as_line'));
      hotInstance.setCellMeta(row, column, 'className', Ember.get(rowData, 'cell_classname'));
      hotInstance.setCellMeta(row, column, 'confidentiality', Ember.get(rowData, 'confidentiality'));
      hotInstance.setCellMeta(row, column, 'internal', Ember.get(rowData, 'internal'));
      hotInstance.setCellMeta(row, column, 'has_load_options', Ember.get(rowData, 'has_load_options'));
      hotInstance.setCellMeta(row, column, 'has_discharge_options', Ember.get(rowData, 'has_discharge_options'));
      hotInstance.setCellMeta(row, column, 'load_options', Ember.get(rowData, 'load_options'));
      hotInstance.setCellMeta(row, column, 'discharge_options', Ember.get(rowData, 'discharge_options'));
      hotInstance.setCellMeta(row, column, 'created_at', Ember.get(rowData, 'created_at'));
      hotInstance.setCellMeta(row, column, 'updated_at', Ember.get(rowData, 'updated_at'));
      hotInstance.setCellMeta(row, column, 'created_by', Ember.get(rowData, 'created_by'));
      hotInstance.setCellMeta(row, column, 'updated_by', Ember.get(rowData, 'updated_by'));
      hotInstance.setCellMeta(row, column, 'updated_at_datetime', Ember.get(rowData, 'updated_at_datetime'));
      hotInstance.setCellMeta(row, column, 'created_at_datetime', Ember.get(rowData, 'created_at_datetime'));
      // hotfix: get cell comments first, check if exists
      const cellComments = Ember.get(rowData, 'cell_comments');
      hotInstance.setCellMeta(row, column, 'has_comments', Ember.isArray(cellComments) ? cellComments.some(c => Ember.get(c, 'cell.col') === column) : false);
      const cellMeta = hotInstance.getCellMeta(row, column);
      // apply confidenntiality settings
      if (Ember.get(user, 'confidentiality_settings') && visualRowData && Ember.get(visualRowData, 'confidentiality')) {
        const confidentiality = Ember.get(visualRowData, 'confidentiality');
        const confidentialitySetting = Ember.get(user, 'confidentiality_settings').find(s => Ember.get(s, 'level') === confidentiality);
        // console.log(confidentiality, confidentialitySetting);
        if (confidentialitySetting && Ember.get(confidentialitySetting, 'font_bold')) {
          td.style.fontWeight = 'bold';
        }
        if (confidentialitySetting && Ember.get(confidentialitySetting, 'font_italic')) {
          td.style.fontStyle = 'italic';
        }
        if (confidentialitySetting && Ember.get(confidentialitySetting, 'font_color')) {
          td.style.color = Ember.get(confidentialitySetting, 'font_color');
        }
        if (confidentialitySetting && Ember.get(confidentialitySetting, 'background_color')) {
          td.style.backgroundColor = Ember.get(confidentialitySetting, 'background_color');
        }
      }
      // if cell has comments
      if (Ember.get(cellMeta, 'has_comments')) {
        td.classList.add('cell-has-comments');
      }
      // if is checked ass is-checked class
      if (isChecked) {
        td.classList.add('is-checked');
      } else {
        td.classList.remove('is-checked');
      }
      // it it's a checkbox ass the class
      if (isCheckbox) {
        td.classList.add('is-checkbox');
      }
      // handle column loading states
      if (isCreating) {
        td.classList.add('is-creating');
      } else {
        td.classList.remove('is-creating');
      }
      if (isSaving) {
        td.classList.add('is-saving');
      } else {
        td.classList.remove('is-saving');
      }
      // // IF the fixture is just being created, this whole row will become readOnly
      // if (isCreating) {
      //     if (td.parentNode) {
      //         td.parentNode.classList.add('is-creating');
      //     }
      //     cellProperties.readOnly = true;
      // } else {
      //     if (td.parentNode) {
      //         td.parentNode.classList.remove('is-creating');
      //     }
      //     cellProperties.readOnly = false;
      // }
      // // for subsequent cells
      // if (td.parentNode && td.parentNode.classList.contains('is-creating')) {
      //     cellProperties.readOnly = true;
      // } else {
      //     cellProperties.readOnly = false;
      // }
      // if fixture is older than 7 days
      // if (get(cellMeta, 'updated_at')) {
      //     const isOlderThan7Days = moment(get(cellMeta, 'updated_at')).isBefore(moment().subtract(7, 'days'));
      //     if (isOlderThan7Days) {
      //         td.classList.add('old-fixture');
      //     }
      // }
      // if no fixture add class is-new
      if (!Ember.get(cellMeta, 'fixture_uuid')) {
        td.classList.add('is-new');
      }
      // if cell has discharge options disable inline editing
      if (prop === 'discharge.value' && rowData.has_discharge_options === true) {
        cellProperties.readOnly = true;
      }
      // if cell has discharge options disable inline editing
      if (prop === 'load.value' && rowData.has_load_options === true) {
        cellProperties.readOnly = true;
      }
      // internal border
      if (cellMeta && Ember.get(cellMeta, 'internal')) {
        if (td.parentNode) {
          td.parentNode.classList.add('is-internal-fixture');
        }
      } else if (cellMeta && !Ember.get(cellMeta, 'internal')) {
        if (td.parentNode) {
          td.parentNode.classList.remove('is-internal-fixture');
        }
      }
      return td;
    }.bind(context);
    // create the default text renderer
    const FixFloTextRenderer = function (hotInstance, td, row, column, prop, value, cellProperties) {
      _handsontable.default.renderers.TextRenderer.apply(this, arguments);
      td = FixFloBaseRenderer(hotInstance, td, row, column, prop, value, cellProperties);
      return td;
    };
    // create the default checkbox renderer
    const FixFloCheckboxRenderer = function (hotInstance, td, row, column, prop, value, cellProperties) {
      _handsontable.default.renderers.CheckboxRenderer.apply(this, arguments);
      const checkbox = td.querySelector('input[type=checkbox]');
      const sourceData = hotInstance.getSourceData();
      // -- overwrite checkbox events --
      checkbox.onchange = event => event.stopPropagation();
      checkbox.onclick = event => {
        event.stopPropagation();
        const target = event.target;
        // set(target, 'checked', isChecked);
        Ember.set(sourceData[row], 'isChecked', target.checked);
        // before renderer/ probably?
        const siblings = td.parentNode.childNodes;
        for (let i = 0; i < siblings.length; i++) {
          if (target.checked) {
            siblings[i].classList.add('is-checked');
          } else {
            siblings[i].classList.remove('is-checked');
          }
        }
      };
      // -- overwrite onchange --
      return FixFloBaseRenderer(hotInstance, td, row, column, prop, value, cellProperties);
    };
    // create the default autocomplete renderer
    const FixFloAutocompleteRenderer = function (hotInstance, td, row, column, prop, value, cellProperties) {
      _handsontable.default.renderers.AutocompleteRenderer.apply(this, arguments);
      // Handsontable.renderers.TextRenderer.apply(this, arguments);
      td = FixFloBaseRenderer(hotInstance, td, row, column, prop, value, cellProperties);
      return td;
    };
    // register renderer
    _handsontable.default.renderers.registerRenderer('fixflo.text', FixFloTextRenderer);
    _handsontable.default.renderers.registerRenderer('fixflo.checkbox', FixFloCheckboxRenderer);
    _handsontable.default.renderers.registerRenderer('fixflo.autocomplete', FixFloAutocompleteRenderer);
  }
  var _default = {
    initialize
  };
  _exports.default = _default;
});