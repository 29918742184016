define("@fixflo/frontend/routes/dashboard/fixtures/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _temp;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let DashboardFixturesNewRoute = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = (_temp = class DashboardFixturesNewRoute extends Ember.Route {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "tour", _descriptor, this);
    }
    /**
     * Inject the tour service
     *
     * @var {Service}
     */
    /**
     * Before exit transition clear the handsontable data
     *
     * @void
     */
    willTransition(transition) {
      const handsontable = Ember.get(this.controller, 'handsontable');
      if (handsontable) {
        // clear all data from handsontable
        handsontable.clear();
      }
    }

    /**
     * During controller setup define hotkeys
     *
     * @void
     */
    setupController(controller, model) {
      super.setupController(controller, model);
      // define hotkeys
      document.addEventListener('keydown', event => {
        if (event.ctrlKey) {
          switch (event.keyCode) {
            case 13:
              // Enter key pressed - save the fixtures
              controller.send('save');
              break;
          }
        }
      });
      // start tour if should for the current user
      this.startIntroduction(controller, model);
    }
    async startIntroduction(controller, model) {
      return this.tour.runStack(['addFixtures'], controller, model);
    }
  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tour", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "willTransition", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype)), _class));
  _exports.default = DashboardFixturesNewRoute;
});