define("@fixflo/frontend/templates/dashboard/boards/index-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JCUeygLE",
    "block": "{\"symbols\":[\"number\"],\"statements\":[[5,\"fix-flo/page-title\",[],[[\"@title\"],[\"Boards\"]]],[0,\"\\n\\n\"],[5,\"placeholder/page-filters\",[],[[],[]]],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ff_container boards-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ff_row\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"can\",[\"view company-board\"],null]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"ff_col-xs-3\"],[8],[0,\"\\n                \"],[5,\"link-to\",[[12,\"class\",\"board-card company-board\"],[12,\"alt\",\"Company Board\"],[12,\"title\",\"Company Board\"]],[[\"@tagName\",\"@route\"],[\"div\",\"dashboard.boards.company\"]],{\"statements\":[[0,\"\\n                    \"],[7,\"div\",true],[10,\"class\",\"board-card--header\"],[8],[0,\"\\n                        \"],[7,\"span\",true],[10,\"class\",\"board-card--header-title\"],[8],[0,\"\\n                            Company Board\\n                        \"],[9],[0,\"\\n                    \"],[9],[0,\"\\n                \"]],\"parameters\":[]}],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[28,\"range\",[0,5],null]],null,{\"statements\":[[0,\"            \"],[5,\"placeholder/board-card\",[[12,\"class\",\"ff_col-xs-3\"]],[[],[]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dashboard/boards/index-loading.hbs"
    }
  });
  _exports.default = _default;
});