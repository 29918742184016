define("@fixflo/frontend/templates/components/fixtures-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0vmgHEr4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[5,\"cell-comment-box\",[],[[\"@user\",\"@cell\",\"@subject\",\"@isCommenting\",\"@toggleBoxEvent\"],[[22,\"currentUser\"],[22,\"selectedCell\"],[22,\"selectedFixture\"],[22,\"isCommenting\"],[22,\"commentToggleEvent\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/components/fixtures-table.hbs"
    }
  });
  _exports.default = _default;
});