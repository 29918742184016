define("@fixflo/frontend/tailwind/config", [], function () {
  "use strict";

  const defaultTheme = require("tailwindcss/defaultTheme");
  module.exports = {
    theme: {
      extend: {
        fontFamily: {
          sans: ['Inter var', ...defaultTheme.fontFamily.sans]
        }
      }
    },
    variants: {},
    plugins: [require("@tailwindcss/ui")({
      layout: 'sidebar'
    })]
  };
});