define("@fixflo/frontend/controllers/auth/login", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Email address',
      message: '{description} cannot be empty.',
      disabled: Ember.computed.not('model.isValidating')
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      description: 'Email address',
      message: '{description} entered is not valid.',
      disabled: Ember.computed.not('model.isValidating')
    })],
    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Password',
      message: '{description} cannot be empty.',
      disabled: Ember.computed.not('model.isValidating')
    })]
  });
  var _default = Ember.Controller.extend(Validations, {
    actions: {
      /**
       * Sends authentication request to server
       *
       * @void
       */
      async login() {
        // set default login properties
        this.setProperties({
          isProcessing: true,
          isValidating: true
        });

        // get user credentials
        const credentials = this.getProperties('email', 'password');
        const remember = this.remember;

        // validate login
        const validator = await this.validate();

        // if invalid form inputs
        if (validator.validations.get('isInvalid')) {
          return this.reset('fail');
        }

        // set timeout trigger
        this.set('timeout', setTimeout(this.slowConnection.bind(this), 12000));

        // start login request
        try {
          await this.session.authenticate('authenticator:fixflo', credentials, remember);
        } catch (error) {
          return this.failure(error);
        }

        // if login was successful
        if (this.session.isAuthenticated) {
          this.success();
        }
      }
    },
    /**
     * After successful authentication, redirect to where
     *
     * @var string
     */
    redirectTo: 'dashboard',
    /**
     * Login is validating user input
     *
     * @var boolean
     */
    isValidating: false,
    /**
     * Login is processing
     *
     * @var boolean
     */
    isProcessing: false,
    /**
     * If the connection or requesst it taking too long
     *
     * @var boolean
     */
    isSlowConnection: false,
    /**
     * Interval to determine when to timeout the request
     *
     * @var integer
     */
    timeout: null,
    /**
     * Handles the authentication success
     *
     * @void
     */
    success: function () {
      this.reset('success');
    },
    /**
     * Handles the authentication failure
     *
     * @void
     */
    failure: function (error) {
      this.notifications.serverError(error);
      this.reset('error');
      this.set('loginFailed', true);
    },
    /**
     * Handles the request slow connection
     *
     * @void
     */
    slowConnection: function () {
      this.notifications.error('Experiencing connectivity issues.');
    },
    /**
     * Reset the login form
     *
     * @void
     */
    reset: function (type) {
      if (type === 'success') {
        this.setProperties({
          identity: null,
          password: null,
          isValidating: false
        });
      } else if (type === 'error' || type === 'fail') {
        this.set('password', null);
      }
      clearTimeout(this.timeout);
      this.setProperties({
        isProcessing: false,
        isSlowConnection: false
      });
    }
  });
  _exports.default = _default;
});