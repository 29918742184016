define("@fixflo/frontend/models/saved-report", ["exports", "@ember-data/model", "ember-cli-string-helpers/helpers/humanize", "moment"], function (_exports, _model, _humanize, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    uuid: (0, _model.attr)('string'),
    user_uuid: (0, _model.attr)('string'),
    created_by: (0, _model.attr)('string'),
    company_uuid: (0, _model.attr)('string'),
    report_type_uuid: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    format: (0, _model.attr)('string'),
    quantity: (0, _model.attr)('raw'),
    report_filters: (0, _model.attr)('raw'),
    sort: (0, _model.attr)('string'),
    shared_with_company: (0, _model.attr)('boolean'),
    slug: (0, _model.attr)('string'),
    deleted_at: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('string'),
    updated_at: (0, _model.attr)('string'),
    filteredDateRange: Ember.computed('report_filters.before', 'report_filters.after', function () {
      if (Ember.isEmpty(this.get('report_filters.before')) && Ember.isEmpty(this.get('report_filters.after'))) {
        return `None`;
      }
      // @todo for exact date range
      return `Between ${this.get('report_filters.after').substr(1)} before now and ${this.get('report_filters.before').substr(1)} after`;
    }),
    sortedBy: Ember.computed('sort', function () {
      let sortedBy = this.sort;
      if (!sortedBy) {
        return 'N/A';
      }
      if (sortedBy.includes('_range_start')) {
        sortedBy = sortedBy.replace('_range_start', '');
      }
      if (typeof sortedBy === 'string' && sortedBy.startsWith('-')) {
        return `${(0, _humanize.humanize)([sortedBy.substr(1)])} Descending`;
      }
      return `${(0, _humanize.humanize)([sortedBy])} Ascending`;
    }),
    updatedAgo: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).fromNow();
    }),
    updatedAt: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).format('YYYY-MM-DD HH:mm A');
    }),
    createdAgo: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).fromNow();
    }),
    createdAt: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).format('YYYY-MM-DD HH:mm A');
    })
  });
  _exports.default = _default;
});