define("@fixflo/frontend/controllers/auth/sign-up", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Full name',
      message: '{description} cannot be empty.',
      disabled: Ember.computed.not('model.isValidating')
    })],
    company_name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Company name',
      message: '{description} cannot be empty.',
      disabled: Ember.computed.not('model.isValidating')
    })],
    email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Email address',
      message: '{description} cannot be empty.',
      disabled: Ember.computed.not('model.isValidating')
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      description: 'Email address',
      message: '{description} entered is not valid.',
      disabled: Ember.computed.not('model.isValidating')
    })],
    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Password',
      message: '{description} cannot be empty.',
      disabled: Ember.computed.not('model.isValidating')
    })],
    password_confirmation: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Password confirmation',
      message: '{description} cannot be empty.',
      disabled: Ember.computed.not('model.isValidating')
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: 'Passwords must be the same.',
      disabled: Ember.computed.not('model.isValidating')
    })]
  });
  var _default = Ember.Controller.extend(Validations, {
    actions: {
      /**
       * Send registration details to the server
       *
       * @void
       */
      signUp: async function () {
        // set state/processing properties
        this.setProperties({
          isProcessing: true,
          isValidating: true
        });

        // validate values
        const validator = await this.validate();
        if (!validator.validations.get('isValid')) {
          return this.reset('fail');
        }

        // set timeout trigger
        this.set('timeout', setTimeout(this.slowConnection.bind(this), 12000));

        // prep form data
        const user = this.getProperties('name', 'email', 'password', 'password_confirmation');
        const company = {
          name: this.company_name
        };

        // start sign-up request
        return this.signUp(user, company).then(this.success.bind(this)).catch(this.failure.bind(this));
      }
    },
    /**
     * Where to redirect the user after signup is completed
     *
     * @var {String}
     */
    redirectTo: 'dashboard',
    /**
     * Where the state of this controller is validating
     *
     * @var {Boolean}
     */
    isValidating: false,
    /**
     * Where the state of this controller is processing
     *
     * @var {Boolean}
     */
    isProcessing: false,
    /**
     * Where the state of this controller is experiencing slow connection from user
     *
     * @var {Boolean}
     */
    isSlowConnection: false,
    /**
     * The current settimeout instance
     *
     * @var {Boolean}
     */
    timeout: null,
    /**
     * Sends a sign-up request
     *
     * @void
     */
    signUp: function (user, company, path = 'auth/sign-up') {
      return this.fetch.post(path, {
        data: {
          user,
          company
        }
      });
    },
    /**
     * Handles the sign-up success
     *
     * @void
     */
    success: function () {
      return this.transitionToRoute(this.redirectTo).then(() => {
        return this.reset('success');
      });
    },
    /**
     * Handles the sign-up failure
     *
     * @void
     */
    failure: function (error) {
      this.notifications.serverError(error);
      this.reset('error');
      this.set('signUpFailed', true);
    },
    /**
     * Handles request timeout
     *
     * @void
     */
    slowConnection: function () {
      this.notifications.error('Experiencing connectivity issues.');
    },
    /**
     * Reset the sign-up form
     *
     * @void
     */
    reset: function (type) {
      if (type === 'success') {
        this.setProperties({
          name: null,
          company_name: null,
          email: null,
          password: null,
          password_confirmation: null,
          isValidating: false
        });
      }
      clearTimeout(this.timeout);
      this.setProperties({
        isProcessing: false,
        isSlowConnection: false
      });
    }
  });
  _exports.default = _default;
});