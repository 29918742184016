define("@fixflo/frontend/mixins/can-make-fixture-suggestions", ["exports", "@fixflo/frontend/mixins/model-creator", "ember-inflector", "@fixflo/frontend/helpers/dot-underscore", "ember-cli-string-helpers/helpers/humanize"], function (_exports, _modelCreator, _emberInflector, _dotUnderscore, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var _default = Ember.Mixin.create(_modelCreator.default, {
    actions: {
      /**
       * Change suggestion value
       *
       * @void
       */
      changeSuggestionValue: function (data, property) {
        let fixture = data.fixture;
        // set the fixture value based on property
        switch (property) {
          case 'discharge':
          case 'load':
            Ember.set(fixture, `${property}_port_uuid`, null);
            break;
          case 'company':
            Ember.set(fixture, `charterer_uuid`, null);
            break;
          case 'vessel':
            Ember.set(fixture, `${property}_uuid`, null);
            break;
        }
        // set display name
        Ember.set(fixture, `${property}_display_name`, null);
        // save fixture
        this.fetch.put(`fixtures/${fixture.uuid}`, {
          data: {
            fixture: {
              load_port_uuid: fixture['load_port_uuid'],
              discharge_port_uuid: fixture['discharge_port_uuid'],
              vessel_uuid: fixture['vessel_uuid']
            }
          }
        });
      },
      /**
       * Adds a new relation (load/discharge) to a fixture
       *
       * @void
       */
      createNewRelation: function (suggestion, property, value, model) {
        // console.log('[createNewRelation]', suggestion, property, value, model);
        // find position of relation
        const siblings = Ember.get(suggestion, `suggestions.${property}.all_multiple_relations`);
        const pos = siblings.indexOf(value);
        // console.log(siblings, pos);
        // create a new fixture value for the property
        this.fetch.post('fixture-values', {
          data: {
            fixtureValue: {
              fixture_uuid: Ember.get(suggestion, 'fixture.uuid'),
              relation_uuid: Ember.get(model, 'uuid'),
              key: property,
              label: (0, _humanize.humanize)([property]),
              entry: value,
              //get(model, 'name'),
              pos,
              rate: 'RNR'
            }
          }
        }).then(response => {
          const fixtureValue = this.store.push(this.store.normalize('fixture-value', response.fixtureValue));
          // append to fixture for deletion later
          // console.log('[setting...]', `${property}_options.${dotUnderscore([value])}`);
          Ember.set(suggestion, `${property}_options.${(0, _dotUnderscore.dotUnderscore)([value])}`, fixtureValue);
        });
      },
      /**
       * Delete a relation previously made
       *
       * @void
       */
      deleteRelation: function (suggestion, property, value) {
        // get the fixture value from the options object
        const fixtureValue = Ember.get(suggestion, `${property}_options.${(0, _dotUnderscore.dotUnderscore)([value])}`);
        // delete relation from options object
        Ember.set(suggestion, `${property}_options.${(0, _dotUnderscore.dotUnderscore)([value])}`, null);
        // delete the actual record
        fixtureValue.destroyRecord();
      },
      /**
       * Binds the relation rate to the next index
       *
       * @void
       */
      bindRelationRate: function (suggestion, query, property, value, index, option) {
        // console.log('[bindRelationRate]', suggestion, query, property, value, index);
        // console.log(`${property}_options.${dotUnderscore([value])}`);
        // get the currennt fixture value to bind with
        const fixtureValue = Ember.get(suggestion, `${property}_options.${(0, _dotUnderscore.dotUnderscore)([value])}`);
        // make sure the next value option exists
        if (Ember.get(query, 'multiple_relations.length') < index + 1) {
          return this.notifications.warning(`No other ${property} to join rates with`);
        }
        // get the next value from index
        const nextValue = Ember.get(query, 'all_multiple_relations')[index + 1];
        // get the next fixture value
        const nextFixtureValue = Ember.get(suggestion, `${property}_options.${(0, _dotUnderscore.dotUnderscore)([nextValue])}`);
        // if next value is not set, notify with error
        if (!nextFixtureValue) {
          return this.notifications.warning(`Unable to join rates without setting the next ${property} option`);
        }
        // depending on option we either join or disjoin the rates
        switch (option) {
          case '+':
            // if the fixture value is already binded only bind the next
            if (Ember.get(fixtureValue, 'rate_binded_to')) {
              Ember.setProperties(nextFixtureValue, {
                rate_binded_to: Ember.get(fixtureValue, 'id'),
                rate_bind_primary: false
              });
            } else {
              // join/bind the rates
              Ember.setProperties(fixtureValue, {
                rate_binded_to: Ember.get(nextFixtureValue, 'id'),
                rate_bind_primary: true
              });
              Ember.setProperties(nextFixtureValue, {
                rate_binded_to: Ember.get(fixtureValue, 'id'),
                rate_bind_primary: false
              });
            }
            break;
          case '-':
            // if this rate is already binded just unbind the next
            if (Ember.get(fixtureValue, 'rate_binded_to')) {
              Ember.setProperties(nextFixtureValue, {
                rate_binded_to: null,
                rate_bind_primary: false
              });
            } else {
              // disjoin the rates
              Ember.setProperties(fixtureValue, {
                rate_binded_to: null,
                rate_bind_primary: false
              });
              Ember.setProperties(nextFixtureValue, {
                rate_binded_to: null,
                rate_bind_primary: false
              });
            }
            break;
        }
      },
      /**
       * Sets a suggestion value to a fixture
       *
       * @void
       */
      setSuggestionValue: function ({
        fixture
      }, property, unrecognized, suggestion) {
        // console.log('[setSuggestionValue]', fixture, property, unrecognized, suggestion);
        // set the id of the updated property
        let id;
        // set the fixture value based on property
        switch (property) {
          case 'discharge':
          case 'load':
            Ember.set(fixture, `${property}_port_uuid`, suggestion.uuid);
            id = suggestion.uuid;
            break;
          case 'company':
            Ember.set(fixture, `charterer_uuid`, suggestion.uuid);
            id = suggestion.uuid;
            break;
          case 'vessel':
            Ember.set(fixture, `${property}_uuid`, suggestion.uuid);
            id = suggestion.uuid;
            break;
          case 'grade':
            Ember.set(fixture, `${property}_uuid`, suggestion.uuid);
            id = suggestion.uuid;
            break;
        }
        // save fixture
        this.fetch.put(`fixtures/${fixture.uuid}`, {
          data: {
            fixture: {
              load_port_uuid: fixture['load_port_uuid'],
              discharge_port_uuid: fixture['discharge_port_uuid'],
              vessel_uuid: fixture['vessel_uuid'],
              grade_uuid: fixture['grade_uuid']
            }
          }
        }).then(response => {
          // helper to set unrecognized
          this.fetch.post(`actions/tag-alias`, {
            data: {
              unrecognized,
              property,
              id
            }
          });
          // set display name
          Ember.set(fixture, `${property}_display_name`, suggestion.name);
        });
      },
      /**
       * Use Me, deletes the duplicate and unflags the other
       */
      deleteDuplicate: function (useMe, deleteMe, suggestions, index) {
        // if a fallback duplicate deletion check
        if (index !== undefined) {
          return this.send('deleteDuplicateFallback', useMe, deleteMe, suggestions, index);
        }
        // prompt for deletion
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Remove Duplicate',
          acceptText: 'Confirm and delete',
          message: `Are you sure you want to use this fixture?`
        }).then(() => {
          Ember.set(this, 'isLoading', true);
          // send request
          return this.fetch.post('actions/select-from-duplicates', {
            data: {
              fixtureToUse: Ember.get(useMe, 'uuid'),
              fixtureToDelete: Ember.get(deleteMe, 'uuid')
            }
          }).then(() => {
            Ember.set(this, 'isLoading', false);
            // determine if the original fixtue was deleted
            var originalFixtureDeleted = feedback = suggestions.find(f => Ember.get(f, 'duplicate') === deleteMe);
            if (originalFixtureDeleted) {
              // if the og fixture deleted was the duplicated, update the feedback by removing the duplicate and is_duplicate = false
              Ember.setProperties(feedback, {
                is_duplicate: false,
                duplicate: null
              });
            } else {
              // if suggestions is supplied were removing the containing feedback
              var feedback = suggestions.find(f => Ember.get(f, 'duplicate') === useMe);
              suggestions.removeObject(feedback);
            }
            this.notifications.success('Duplicate removed');
          }).catch(error => {
            Ember.set(this, 'isLoading', false);
            this.notifications.serverError(error);
          });
        });
      },
      /**
       * Removed feeback from suggestions
       */
      ignoreDuplicate: function (duplicate, suggestions, index) {
        // console.log(duplicate);
        // set duplicate to be ignored
        this.fetch.put(`fixtures/${Ember.get(duplicate, 'uuid')}`, {
          data: {
            ignore_as_duplicate: 1
          }
        });
        // remove this feedback object from suggestions
        const feedback = suggestions.find(f => Ember.get(f, 'duplicate') === duplicate);
        suggestions.removeObject(feedback);
      },
      /**
       * Prompt dialog for user to handle duplicate fixtures
       */
      handleDuplicates: function (duplicates, fixtureSet) {
        // set dialog to controller
        this.dialog.one('created', presenter => {
          this.set('presenter', presenter);
        });
        // prompt user to handle dialogs
        return this.dialog.open('duplicates-on-publish', null, {
          title: 'Duplicates found, you must resolve before publishing',
          duplicates,
          className: 'dialog-xl',
          acceptText: 'Confirm',
          acceptIcon: 'fa fa-save',
          controller: this,
          accept: () => {
            this.presenter._accept();
            this.send('publish', fixtureSet);
          }
        });
      },
      /**
       * Use Me, deletes the duplicate and unflags the other
       */
      deleteDuplicateFallback: function (useMe, deleteMe, duplicates, index) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Remove Duplicate',
          acceptText: 'Confirm and delete',
          message: `Are you sure you want to use this fixture?`
        }).then(() => {
          return this.fetch.post('actions/select-from-duplicates', {
            data: {
              fixtureToUse: Ember.get(useMe, 'uuid'),
              fixtureToDelete: Ember.get(deleteMe, 'uuid')
            }
          }).then(() => {
            // delete from index
            duplicates.removeAt(index);
            // send notification
            this.notifications.success('Duplicate removed');
          }).catch(error => {
            this.notifications.serverError(error);
          });
        });
      },
      /**
       * Query api for a prop as model
       *
       * @void
       */
      queryApi: function (property, query) {
        property = ['load', 'discharge'].includes(property) ? 'zone' : property;
        property = property === 'grade' ? 'cargo-grade' : property;
        return this.fetch.request((0, _emberInflector.pluralize)(property), {
          data: {
            query
          }
        }).then(response => response[Ember.String.camelize((0, _emberInflector.pluralize)(property))]);
      }
    },
    /**
     * Parse suggestions for user to use
     */
    fixtureSuggestions: function (importResults) {
      return Object.values(importResults.manual).filter(feedback => {
        return Ember.typeOf(feedback) === 'object';
      }).map(feedback => {
        return _objectSpread(_objectSpread({}, feedback), {}, {
          extracted: feedback.has_suggestions ? Object.values(feedback.suggestions).map(s => Ember.get(s, 'current_value')) : [],
          discharge_options: feedback.discharge_options || {},
          load_options: feedback.load_options || {}
        });
      });
    },
    /**
     * Prompt user to make suggestions on fixtures imported
     */
    makeSuggestions: function (importResults) {
      const suggestions = this.fixtureSuggestions(importResults);
      // console.log('[suggestions]', suggestions);
      this.dialog.one('created', presenter => {
        this.set('presenter', presenter);
      });
      return this.dialog.open('fixtures-import-suggestions', importResults.set, {
        title: 'We have suggestions for your fixtures',
        suggestions,
        acceptText: 'Confirm',
        acceptIcon: 'fa fa-save',
        className: 'dialog-xl',
        controller: this,
        accept: () => {
          this.presenter.startLoading();
          // array to contain fixture values to save before saving set
          const pendingValues = [];
          // save relation rates if any
          suggestions.forEach(feedback => {
            ['discharge', 'load'].forEach(property => {
              if (Ember.get(feedback, `suggestions.${property}.multiple_relations.length`)) {
                // set binded rates for each fixture value options, then add to array to save
                let fixtureValues = Object.values(Ember.get(feedback, `${property}_options`)).map((option, index, options) => {
                  // console.log(get(option, 'entry'), get(option, 'rate'), index % 2 !== 0);
                  if (Ember.get(option, 'rate_binded_to') && index % 2 !== 0) {
                    let bindedTo = options.find(o => Ember.get(o, 'id') === Ember.get(option, 'rate_binded_to'));
                    if (bindedTo) {
                      // set rate binded to
                      Ember.set(option, 'rate', Ember.get(bindedTo, 'rate'));
                    }
                  }
                  return option.hasOwnProperty('serialize') ? option.serialize() : option;
                });
                if (fixtureValues.length) {
                  pendingValues.pushObjects(fixtureValues);
                }
              }
            });
          });
          return this.fetch.put('fixture-values/bulk', {
            data: {
              fixtureValues: pendingValues
            }
          }).then(response => {
            this.presenter.stopLoading();
            this.presenter._accept();
            return this.finallySave(importResults);
          });
        },
        decline: () => {
          if (this.get('target.currentRouteName') === 'dashboard.fixtures.new') {
            const fixtureSet = this.store.push(this.store.normalize('fixture-set', importResults.set));
            fixtureSet.destroyRecord();
            this.resetForm();
          }
          return this.presenter._decline();
        }
      });
    }
  });
  _exports.default = _default;
});