define("@fixflo/frontend/controllers/dashboard/reports/new-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      /**
       * Create new template
       */
      save: function (template) {
        this.set('isSaving', true);
        template.save().then(response => {
          return this.transitionToRoute('dashboard.reports.templates').then(() => {
            this.resetForm();
            this.notifications.success('Report template saved.');
          });
        });
      },
      /**
       * Toggle a selected column
       */
      toggleColumnSelected: function (column) {
        const selected = Ember.get(this, 'template.columns') || [];
        if (selected.includes(column)) {
          selected.removeObject(column);
        } else {
          selected.pushObject(column);
        }
      },
      /**
       * Reorder columns as dragged
       */
      reorderColumns: function ({
        sourceList,
        sourceIndex,
        targetList,
        targetIndex
      }) {
        if (sourceList === targetList && sourceIndex === targetIndex) return;
        const item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);
        Ember.set(this, 'template.columns', targetList);
      }
    },
    /**
     * Reset form after save
     */
    resetForm: function () {
      this.setProperties({
        template: this.store.createRecord('report-template', {
          columns: [],
          case: 'default',
          format: 'tab',
          zone_slash: false
        }),
        selectedColumns: [],
        isSaving: false
      });
    },
    /**
     * Saving state of form
     */
    isSaving: false,
    /**
     * The template to be created
     */
    template: Ember.computed(function () {
      return this.store.createRecord('report-template', {
        columns: [],
        case: 'default',
        format: 'tab',
        zone_slash: false
      });
    }),
    /**
     * Column selection options
     */
    columns: Ember.computed('template', 'template.columns.@each', function () {
      return ['charterer', 'laycan', 'quantity', 'grade', 'rate', 'load', 'discharge', 'vessel', 'status', 'comments'].filter(c => Ember.isArray(Ember.get(this, 'template.columns')) ? !Ember.get(this, 'template.columns').some(s => s === c) : true);
    }),
    /**
     * Case options for template format
     */
    cases: ['default', 'uppercase', 'lowercase', 'capitalized'],
    /**
     * Listing format
     */
    formats: ['auto_spaced', 'space', '2x_spaces', '3x_spaces', 'tab', '2x_tab', 'table']
  });
  _exports.default = _default;
});