define("@fixflo/frontend/components/relative-date-input", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Element type
     */
    tagName: 'form',
    /**
     * Default classes
     */
    classNames: ['form-inline', 'relative-date-input'],
    /**
     * Classes to bind to element
     */
    classNameBindings: ['disabled'],
    /**
     * Bind these attributes
     */
    attributeBindings: ['from', 'to'],
    /**
     * Is this input disabled
     */
    disabled: false,
    /**
     * Determines if component is initialized
     */
    didInit: false,
    /**
     * The date from
     */
    from: null,
    /**
     * The date to
     */
    to: null,
    /**
     * The date from direction
     */
    dateFromDirection: 'before',
    /**
     * The date from count
     */
    dateFromCount: 1,
    /**
     * The date from unit
     */
    dateFromUnit: 'month',
    /**
     * The date from direction
     */
    dateToDirection: 'after',
    /**
     * The date from count
     */
    dateToCount: 1,
    /**
     * The date from unit
     */
    dateToUnit: 'day',
    /**
     * Allowed start time intervals for relative date
     */
    fromIntervals: [{
      label: 'Today',
      value: 'today'
    }, {
      label: 'Last 7 Days',
      value: '-7 days'
    }, {
      label: 'Last 4 Weeks',
      value: '-4 weeks'
    }, {
      label: 'Last Month',
      value: 'last month'
    }, {
      label: 'Last 3 Months',
      value: '-3 months'
    }, {
      label: 'Last 12 Months',
      value: '-12 months'
    }, {
      label: 'Last Decade',
      value: 'start of decade'
    }, {
      label: 'Last 2 Years',
      value: '-2 years'
    }, {
      label: 'Last 4 Years',
      value: '-4 years'
    }, {
      label: 'Last 6 Years',
      value: '-6 years'
    }, {
      label: 'Month to Date',
      value: 'first day of this month'
    }, {
      label: 'Quarter to Date',
      value: 'first day of quarter'
    }, {
      label: 'All Time',
      value: null
    }],
    /**
     * Allowed ending time intervals for relative date (until)
     */
    toIntervals: [{
      label: 'Today',
      value: null
    }, {
      label: 'Tomorrow',
      value: 'tomorrow'
    }, {
      label: 'Next Week',
      value: 'next week'
    }, {
      label: 'Next Month',
      value: 'next month'
    }, {
      label: 'Next Quarter',
      value: 'last day of quarter'
    }, {
      label: 'Next Year',
      value: 'next year'
    }, {
      label: 'Next 3 Months',
      value: '+3 months'
    }, {
      label: 'Next 12 Months',
      value: '+12 months'
    }, {
      label: 'Next 2 Years',
      value: '+2 years'
    }, {
      label: 'Next 4 Years',
      value: '+4 years'
    }, {
      label: 'Next 10 Years',
      value: '+10 years'
    }, {
      label: 'Next Decade',
      value: 'end of decade'
    }, {
      label: 'End of Time',
      value: null
    }],
    /**
     * All direction options
     */
    directions: ['before', 'after'],
    /**
     * All optional units
     */
    units: ['minute', 'hour', 'day', 'week', 'month', 'year'],
    /**
     * The total value from, ex: - 22 days
     */
    setFrom: Ember.observer('didInit', 'dateFromDirection', 'dateFromCount', 'dateFromUnit', function () {
      Ember.set(this, 'dateFromCount', parseInt(this.dateFromCount));
      Ember.set(this, 'from', `${this.dateFromDirection === 'before' ? '-' : '+'}${this.dateFromCount} ${(0, _emberInflector.pluralize)(this.dateFromCount, this.dateFromUnit, {
        withoutCount: true
      })}`);
    }),
    /**
     * The total value to, ex: + 22 days
     */
    setTo: Ember.observer('didInit', 'dateToDirection', 'dateToCount', 'dateToUnit', function () {
      Ember.set(this, 'dateToCount', parseInt(this.dateToCount));
      Ember.set(this, 'to', `${this.dateToDirection === 'before' ? '-' : '+'}${this.dateToCount} ${(0, _emberInflector.pluralize)(this.dateToCount, this.dateToUnit, {
        withoutCount: true
      })}`);
    }),
    /**
     * The clear button element
     */
    clearButton: null,
    /**
     * DOM element to cear input on click
     */
    createClearButton: function () {
      const clearButton = document.createElement('div');
      clearButton.innerHTML = '×';
      clearButton.className = 'input-clear';
      clearButton.addEventListener('click', () => {
        Ember.setProperties(this, {
          from: null,
          to: null
        });
      });
      this.element.parentNode.appendChild(clearButton);
      Ember.setProperties(this, {
        clearButton
      });
    },
    /**
     * If from/to not null parse back into the dates value
     */
    didInsertElement: function () {
      ['from', 'to'].forEach(date => {
        const dateString = Ember.get(this, date);
        // console.log('[dateString]', dateString);
        if (dateString && (dateString.startsWith('+') || dateString.startsWith('-'))) {
          let direction = dateString.startsWith('-') ? 'before' : 'after';
          let count = dateString.substr(1, dateString.indexOf(' ')).trim();
          let unit = (0, _emberInflector.singularize)(dateString.substr(dateString.indexOf(' '), dateString.length).trim());
          // console.log(`[date${capitalize(date)}Direction]`, direction);
          // console.log(`[date${capitalize(date)}Count]`, count);
          // console.log(`[date${capitalize(date)}Unit]`, unit);
          Ember.set(this, `date${Ember.String.capitalize(date)}Direction`, direction);
          Ember.set(this, `date${Ember.String.capitalize(date)}Count`, count);
          Ember.set(this, `date${Ember.String.capitalize(date)}Unit`, unit);
        }
      });
      // component initialized
      Ember.set(this, 'didInit', true);
      // create a clear button
      this.createClearButton();
    },
    /**
     * Remove event listeners
     */
    willDestroyElement: function () {
      this.clearButton.remove();
    }
  });
  _exports.default = _default;
});