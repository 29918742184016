define("@fixflo/frontend/templates/dialog/group-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "H0XbHht7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Name\\n    \"],[9],[0,\"\\n    \"],[5,\"fix-flo/input\",[],[[\"@type\",\"@value\",\"@placeholder\"],[\"text\",[24,[\"contextObject\",\"name\"]],\"Enter a name for your group\"]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Owner\\n    \"],[9],[0,\"\\n    \"],[5,\"model-select\",[],[[\"@modelName\",\"@selectedModel\",\"@onChange\",\"@placeholder\"],[\"user\",[24,[\"contextObject\",\"owner_uuid\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"contextObject\",\"owner_uuid\"]]],null]],null],\"Select owner of the group\"]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/group-form.hbs"
    }
  });
  _exports.default = _default;
});