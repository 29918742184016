define("@fixflo/frontend/templates/dashboard/reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6TeLOA9q",
    "block": "{\"symbols\":[],\"statements\":[[5,\"fix-flo/page-title\",[],[[\"@title\"],[\"Reports\"]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"sm:hidden\"],[8],[0,\"\\n            \"],[7,\"select\",true],[10,\"class\",\"block w-full form-select\"],[8],[0,\"\\n                \"],[7,\"option\",true],[8],[0,\"\\n                    Reports\\n                \"],[9],[0,\"\\n                \"],[7,\"option\",true],[8],[0,\"\\n                    Report Templates\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"hidden sm:block\"],[8],[0,\"\\n            \"],[7,\"nav\",true],[10,\"class\",\"flex\"],[8],[0,\"\\n                \"],[5,\"link-to\",[[12,\"id\",\"reportsLink\"],[12,\"class\",\"branded-tab\"]],[[\"@route\"],[\"dashboard.reports.index\"]],{\"statements\":[[0,\"\\n                    Reports\\n                \"]],\"parameters\":[]}],[0,\"\\n                \"],[5,\"link-to\",[[12,\"id\",\"reportTemplatesLink\"],[12,\"class\",\"branded-tab\"]],[[\"@route\"],[\"dashboard.reports.templates\"]],{\"statements\":[[0,\"\\n                    Report Templates\\n                \"]],\"parameters\":[]}],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ff_container\"],[8],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dashboard/reports.hbs"
    }
  });
  _exports.default = _default;
});