define("@fixflo/frontend/mixins/lazy-load-fixtures", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var _default = Ember.Mixin.create({
    setupController: function (controller, model) {
      this._super(controller, model);
      this.loadRemainingFixtures(controller, model);
    },
    loadRemainingFixtures: async function (controller, model) {
      if (!Ember.get(model, 'fixtures')) return;
      const params = this.paramsFor(this.routeName);
      // delete slug
      delete params.slug;
      delete params._filter;
      // prepare requests
      const currentPage = Ember.get(model, 'fixtures.meta.current_page');
      const lastPage = Ember.get(model, 'fixtures.meta.last_page');
      const requests = [];
      for (var i = currentPage; i < lastPage; i++) {
        params.page = i + 1;
        requests.pushObject(this.store.query('fixture', _objectSpread(_objectSpread({}, params), {}, {
          published: 1
        })));
      }
      Ember.set(controller, 'isLoading', true);
      for (var i = 0; i < requests.length; i++) {
        await requests[i].then(fixtures => {
          controller.insertFixtures(fixtures);
        });
      }
      Ember.set(controller, 'isLoading', false);
    }
  });
  _exports.default = _default;
});