define("@fixflo/frontend/helpers/humanize-pascal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.humanizePascal = humanizePascal;
  _exports.default = void 0;
  function humanizePascal([string]) {
    return (string.charAt(0).toLowerCase() + string.slice(1)).replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
  }
  var _default = Ember.Helper.helper(humanizePascal);
  _exports.default = _default;
});