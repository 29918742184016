define("@fixflo/frontend/models/fixture-group", ["exports", "@ember-data/model", "ember-cp-validations", "moment"], function (_exports, _model, _emberCpValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true)],
    status: [(0, _emberCpValidations.validator)('presence', true)]
  });
  var _default = _model.default.extend(Validations, {
    uuid: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    fixtures: (0, _model.hasMany)('fixture'),
    slug: (0, _model.attr)('string'),
    shared_with_company: (0, _model.attr)('boolean'),
    status: (0, _model.attr)('string'),
    deleted_at: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('string'),
    updated_at: (0, _model.attr)('string'),
    /**
     * Converts fixtures to table data
     *
     * @return array
     */
    toTableData: function () {
      return this.fixtures.map(fixture => {
        let newFixture = {
          line: fixture.get('raw_text')
        };
        fixture.get('values').forEach(fv => {
          newFixture[fv.get('key')] = fv.serialize();
        });
        return newFixture;
      });
    },
    updatedAgo: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).fromNow();
    }),
    updatedAt: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).format('YYYY-MM-DD HH:mm A');
    }),
    createdAgo: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).fromNow();
    }),
    createdAt: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).format('YYYY-MM-DD HH:mm A');
    })
  });
  _exports.default = _default;
});