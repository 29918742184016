define("@fixflo/frontend/templates/dialog/user-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NVwmEpa6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Name\\n    \"],[9],[0,\"\\n    \"],[5,\"fix-flo/input\",[],[[\"@placeholder\",\"@type\",\"@value\"],[\"Enter name for user\",\"text\",[24,[\"contextObject\",\"name\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Email\\n    \"],[9],[0,\"\\n    \"],[5,\"fix-flo/input\",[],[[\"@placeholder\",\"@type\",\"@value\"],[\"Enter email for user\",\"email\",[24,[\"contextObject\",\"email\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Phone Number\\n    \"],[9],[0,\"\\n    \"],[5,\"fix-flo/input\",[],[[\"@placeholder\",\"@type\",\"@value\"],[\"Enter phone number for user\",\"tel\",[24,[\"contextObject\",\"phone_number\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Role\\n    \"],[9],[0,\"\\n    \"],[5,\"model-select\",[],[[\"@placeholder\",\"@selectedModel\",\"@modelName\",\"@onChange\"],[\"Select a role for user\",[24,[\"contextObject\",\"role_uuid\"]],\"role\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"contextObject\",\"role_uuid\"]]],null]],null]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Password\\n    \"],[9],[0,\"\\n    \"],[5,\"fix-flo/input\",[],[[\"@placeholder\",\"@type\",\"@value\"],[\"Enter a password for user\",\"password\",[24,[\"contextObject\",\"password\"]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/user-form.hbs"
    }
  });
  _exports.default = _default;
});