define("@fixflo/frontend/routes/dashboard/fixtures/view", ["exports", "ember-query-params-reset/mixins/query-params-reset-route", "@fixflo/frontend/mixins/track-reloads"], function (_exports, _queryParamsResetRoute, _trackReloads) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const buildParams = params => {
    params.set_slug = params.slug;
    delete params.slug;
    delete params._filter;
    delete params.page;
    delete params.limit;
    return params;
  };
  var _default = Ember.Route.extend(_queryParamsResetRoute.default, _trackReloads.default, {
    /**
     * Inject the current user service
     *
     * @var {Service}
     */
    currentUser: Ember.inject.service(),
    /**
     * Inject the tour service
     *
     * @var {Service}
     */
    tour: Ember.inject.service(),
    /**
     * Query params the user can change for this route
     *
     * @var object
     */
    queryParams: {
      _filter: {
        refreshModel: false,
        replace: true
      },
      query: {
        refreshModel: true
      },
      'within[latitude]': {
        refreshModel: true,
        replace: true
      },
      'within[longitude]': {
        refreshModel: true,
        replace: true
      },
      'within[radius]': {
        refreshModel: true,
        replace: true
      },
      boundary: {
        refreshModel: true,
        replace: true
      },
      discharge_boundary: {
        refreshModel: true,
        replace: true
      },
      load: {
        refreshModel: true,
        replace: true
      },
      discharge: {
        refreshModel: true,
        replace: true
      },
      vessel: {
        refreshModel: true,
        replace: true
      },
      'quantity[min]': {
        refreshModel: true,
        replace: true
      },
      'quantity[max]': {
        refreshModel: true,
        replace: true
      },
      grade: {
        refreshModel: true,
        replace: true
      },
      charterer: {
        refreshModel: true,
        replace: true
      },
      rate: {
        refreshModel: true,
        replace: true
      },
      laycanRangeStart: {
        refreshModel: true,
        replace: true
      },
      laycanRangeEnd: {
        refreshModel: true,
        replace: true
      },
      before: {
        refreshModel: true,
        replace: true
      },
      after: {
        refreshModel: true,
        replace: true
      },
      createdBy: {
        refreshModel: true,
        replace: true
      },
      internal: {
        refreshModel: true,
        replace: true
      },
      confidentialityLevel: {
        refreshModel: true,
        replace: true
      },
      vesselFleet: {
        refreshModel: true,
        replace: true
      },
      'vesselDeadWeight[min]': {
        refreshModel: true,
        replace: true
      },
      'vesselDeadWeight[max]': {
        refreshModel: true,
        replace: true
      },
      'vesselYearBuilt[min]': {
        refreshModel: true,
        replace: true
      },
      'vesselYearBuilt[max]': {
        refreshModel: true,
        replace: true
      },
      'vesselCubicCapacity[min]': {
        refreshModel: true,
        replace: true
      },
      'vesselCubicCapacity[max]': {
        refreshModel: true,
        replace: true
      },
      'vesselLengthOverAll[min]': {
        refreshModel: true,
        replace: true
      },
      'vesselLengthOverAll[max]': {
        refreshModel: true,
        replace: true
      },
      page: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    model: function (params) {
      const startingPage = params.page;
      const perPage = params.limit;
      const slug = params.slug;
      params = buildParams(params);
      return Ember.RSVP.hash({
        fixtureSet: this.store.queryRecord('fixture-set', {
          single: true,
          slug,
          without: ['fixtures']
        }),
        fixtures: this.infinity.model('fixture', _objectSpread(_objectSpread({}, params), {}, {
          perPage,
          startingPage,
          perPageParam: 'limit',
          pageParam: 'page',
          totalPagesParam: 'meta.last_page',
          countParam: 'meta.total'
        }))
      });
    },
    /**
     * After loading completed
     *
     * @void
     */
    onRouteLoaded: function ({
      controller
    }) {
      if (controller.filtersMenu) {
        controller.filtersMenu.close();
      }
    },
    afterModel: function (model, transition) {
      this.controllerFor('dashboard.fixtures.view').set('isLoadingFilters', false);
      this.incrementProperty('_trackedReloads');
      const params = this.paramsFor(this.routeName);
      transition.finally(() => {
        const {
          controller
        } = this;
        controller.set('contextName', params._filter ? params._filter : Ember.get(model, 'fixtureSet.name'));
        controller.set('source', model.fixtureSet);
        controller.set('collaboratorCanEdit', true);
        controller.set('isLoadingFilters', false);
        // start introduction tour
        this.startIntroduction(controller, model);
      });
    },
    async startIntroduction(controller, model) {
      if (model.fixtureSet.status === 'published') {
        return;
      }
      return this.tour.runStack(['viewFixtures'], controller, model);
    }
  });
  _exports.default = _default;
});