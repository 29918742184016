define("@fixflo/frontend/components/placeholder/table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Columns to help render placeholder data
     *
     * @type Array
     */
    columns: []
  });
  _exports.default = _default;
});