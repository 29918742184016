define("@fixflo/frontend/utils/get-boundary-center", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getBoundaryCenter;
  function getBoundaryCenter(coordinates = [], asLatLngObject = false) {
    var x = coordinates.map(x => x[0]);
    var y = coordinates.map(x => x[1]);
    var cx = (Math.min(...x) + Math.max(...x)) / 2;
    var cy = (Math.min(...y) + Math.max(...y)) / 2;
    if (asLatLngObject) {
      return {
        longitude: cx,
        latitude: cy
      };
    }
    return [cx, cy];
  }
});