define("@fixflo/frontend/utils/laycan-to-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = laycanToDate;
  function laycanToDate(laycan) {
    const currentYear = new Date().getFullYear();
    const isDateRange = !Ember.isEmpty(laycan.match(/[0-9]{2}-[0-9]{2}\/[A-Z]{3}/i));
    const isDayMonth = !Ember.isEmpty(laycan.match(/[0-9]{2} [A-Z]{3}/i));
    const isDayMonthYear = !Ember.isEmpty(laycan.match(/[0-9]{2} [A-Z]{3} [0-9]{4}/i));
    // convert date to moment
    if (isDateRange) {
      const dateRangeMonth = laycan.match(/[A-Z]{3}/i).firstObject;
      const dateRangeStart = laycan.match(/[[0-9]{2}/i).firstObject;
      const dateRangeNumericMonth = new Date(Date.parse(`${dateRangeMonth} 1, 2012`)).getMonth() + 1;
      return new Date(currentYear, dateRangeNumericMonth, dateRangeStart);
    } else if (isDayMonth) {
      const abbreviatedMonth = laycan.match(/[A-Z]{3}/i).firstObject;
      const numericMonth = new Date(Date.parse(`${abbreviatedMonth} 1, 2012`)).getMonth() + 1;
      const day = laycan.match(/[0-9]{2}/i).firstObject;
      return new Date(currentYear, numericMonth, day);
    } else if (isDayMonthYear) {
      const day = laycan.match(/[0-9]{2}/i).firstObject;
      const abbreviatedMonth = laycan.match(/[A-Z]{3}/i).firstObject;
      const month = new Date(Date.parse(`${abbreviatedMonth} 1, 2012`)).getMonth() + 1;
      const year = laycan.match(/[0-9]{4}/i).firstObject;
      return new Date(year, month, day);
    }
    return new Date(Date.parse(laycan));
  }
});