define("@fixflo/frontend/helpers/is-key-value-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isKeyValueObject = isKeyValueObject;
  _exports.default = void 0;
  function isKeyValueObject([object]) {
    return Object.keys(object).length === 2 && Object.keys(object).includes('name', 'value');
  }
  var _default = Ember.Helper.helper(isKeyValueObject);
  _exports.default = _default;
});