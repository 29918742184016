define("@fixflo/frontend/helpers/uri-to-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.uriToObject = uriToObject;
  _exports.default = void 0;
  function uriToObject(uri, getKey) {
    if (!uri) {
      uri = decodeURI(window.location.search.substring(1));
    }
    if (uri.indexOf('?') === 0) {
      uri = uri.substring(1);
    }

    // console.log('[uriToObject]', uri);

    var strArr = String(uri).replace(/^&/, '').replace(/&$/, '').split('&');
    var sal = strArr.length;
    var i;
    var j;
    var ct;
    var p;
    var lastObj;
    var obj;
    var chr;
    var tmp;
    var key;
    var value;
    var postLeftBracketPos;
    var keys;
    var keysLen;
    const fixStr = str => decodeURIComponent(str.replace(/\+/g, '%20'));
    const result = {};
    for (i = 0; i < sal; i++) {
      tmp = strArr[i].split('=');
      key = fixStr(tmp[0]);
      value = tmp.length < 2 ? '' : fixStr(tmp[1]);
      while (key.charAt(0) === ' ') {
        key = key.slice(1);
      }
      if (key.indexOf('\x00') > -1) {
        key = key.slice(0, key.indexOf('\x00'));
      }
      if (key && key.charAt(0) !== '[') {
        keys = [];
        postLeftBracketPos = 0;
        for (j = 0; j < key.length; j++) {
          if (key.charAt(j) === '[' && !postLeftBracketPos) {
            postLeftBracketPos = j + 1;
          } else if (key.charAt(j) === ']') {
            if (postLeftBracketPos) {
              if (!keys.length) {
                keys.push(key.slice(0, postLeftBracketPos - 1));
              }
              keys.push(key.substr(postLeftBracketPos, j - postLeftBracketPos));
              postLeftBracketPos = 0;
              if (key.charAt(j + 1) !== '[') {
                break;
              }
            }
          }
        }
        if (!keys.length) {
          keys = [key];
        }
        for (j = 0; j < keys[0].length; j++) {
          chr = keys[0].charAt(j);
          if (chr === ' ' || chr === '.' || chr === '[') {
            keys[0] = keys[0].substr(0, j) + '_' + keys[0].substr(j + 1);
          }
          if (chr === '[') {
            break;
          }
        }
        obj = result;
        for (j = 0, keysLen = keys.length; j < keysLen; j++) {
          key = keys[j].replace(/^['"]/, '').replace(/['"]$/, '');
          lastObj = obj;
          if ((key === '' || key === ' ') && j !== 0) {
            // Insert new dimension
            ct = -1;
            for (p in obj) {
              if (obj.hasOwnProperty(p)) {
                if (+p > ct && p.match(/^\d+$/g)) {
                  ct = +p;
                }
              }
            }
            key = ct + 1;
          }
          // if primitive value, replace with object
          if (Object(obj[key]) !== obj[key]) {
            obj[key] = {};
          }
          obj = obj[key];
        }
        lastObj[key] = value;
      }
    }
    if (getKey && Ember.typeOf(result) === 'object' && result[getKey]) {
      // console.log('[uriToObject:result]', result[getKey]);
      return result[getKey];
    }

    // console.log('[uriToObject:result]', result);

    return result;
  }
  var _default = Ember.Helper.helper(uriToObject);
  _exports.default = _default;
});