define("@fixflo/frontend/templates/dialog/zone-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "2g12BedW",
    "block": "{\"symbols\":[\"layers\",\"zone\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"table table-bordered mb-3\"],[8],[0,\"\\n\\t\"],[7,\"tbody\",true],[8],[0,\"\\n\\t\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\\t\"],[7,\"td\",true],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"strong\",true],[8],[0,\"Name:\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"span\",true],[8],[1,[24,[\"port\",\"name\"]],false],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"rate\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"td\",true],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"strong\",true],[8],[0,\"Rate:\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"span\",true],[8],[1,[22,\"rate\"],false],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"leaflet-map\",[],[[\"@lat\",\"@lng\",\"@zoom\"],[[24,[\"port\",\"lat\"]],[24,[\"port\",\"lng\"]],[28,\"if\",[[28,\"eq\",[[24,[\"port\",\"type\"]],\"port\"],null],12,6],null]]],{\"statements\":[[0,\"\\n\\t\"],[6,[23,1,[\"tile\"]],[],[[\"@url\"],[\"https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png\"]]],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"port\",\"type\"]],\"port\"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[6,[23,1,[\"marker\"]],[],[[\"@lat\",\"@lng\"],[[24,[\"port\",\"lat\"]],[24,[\"port\",\"lng\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"port\",\"boundaries\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"log\",[[23,2,[\"coordinates\"]],[23,2,[\"latFirstCoordinates\"]]],null],false],[0,\"\\n\\t\\t\\t\"],[6,[23,1,[\"polygon\"]],[],[[\"@locations\",\"@color\"],[[23,2,[\"latFirstCoordinates\"]],\"#48BB78\"]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]}]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/zone-details.hbs"
    }
  });
  _exports.default = _default;
});