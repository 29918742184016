define("@fixflo/frontend/services/crud", ["exports", "@fixflo/frontend/utils/get-model-skeleton"], function (_exports, _getModelSkeleton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let CrudService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = (_temp = class CrudService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "dialog", _descriptor3, this);
      _initializerDefineProperty(this, "notifications", _descriptor4, this);
    }
    /**
     * Inject the `currentUser` service
     *
     * @var {Service}
     */
    /**
     * Inject the `store` service
     *
     * @var {Service}
     */
    /**
     * Inject the `dialog` service
     *
     * @var {Service}
     */
    /**
     * Inject the `notifications` service
     *
     * @var {Service}
     */
    /**
     * Creates a vessel
     *
     * @void
     */
    createVessel(properties = {}) {
      this.createModel('vessel', properties);
    }

    /**
     * Edits a vessel
     * 
     * @param {Model} vessel
     * 
     * @void
     */
    editVessel(vessel) {
      this.dialog.one('created', presenter => {
        this.presenter = presenter;
      });
      this.dialog.open('vessel-form', vessel, {
        title: `Edit ${Ember.get(vessel, 'name')} vessel`,
        acceptText: 'Save Changes',
        showMore: false,
        showMoreFields: () => this.presenter.toggleProperty('showMore'),
        accept: () => {
          return vessel.save().then(() => {
            this.notifications.success('Vessel saved.');
            this.presenter._accept();
          }).catch(error => {
            this.notifications.serverError(error);
          });
        },
        decline: () => {
          this.presenter._decline();
        }
      });
    }

    /**
     * Ability to create a model on the fly
     *
     * @void
     */
    createModel(type, modelProps = {}) {
      switch (type) {
        case 'vessel':
          const vessel = this.store.createRecord('vessel', _objectSpread(_objectSpread({}, (0, _getModelSkeleton.default)('vessel')), modelProps));
          this.dialog.one('created', presenter => {
            this.presenter = presenter;
          });
          this.dialog.open('vessel-form', vessel, {
            title: 'Create a new vessel',
            acceptText: 'Save',
            className: 'dialog-lg',
            showMore: false,
            showMoreFields: () => this.presenter.toggleProperty('showMore'),
            controller: this,
            accept: () => {
              this.presenter.startLoading();
              return vessel.save().then(() => {
                this.notifications.success('New vessel added.');
                this.presenter.stopLoading();
                this.presenter._accept();
              }).catch(error => {
                this.presenter.stopLoading();
                this.notifications.serverError(error);
              });
            },
            decline: () => {
              vessel.destroyRecord();
              this.presenter._decline();
            }
          });
          break;
        case 'grade':
        case 'cargo-grade':
          const cargoGrade = this.store.createRecord('cargo-grade', {
            name: null,
            display_name: null,
            aliases: [],
            pendingChildren: []
          });
          this.dialog.one('created', presenter => {
            this.presenter = presenter;
          });
          this.dialog.open('cargo-grade-form', cargoGrade, {
            title: 'Create new cargo grade',
            acceptText: 'Save',
            controller: this,
            accept: () => {
              this.presenter.startLoading();
              return cargoGrade.save().then(() => {
                this.notifications.success('New cargo grade added');
                this.presenter.stopLoading();
                this.presenter._accept();
              }).catch(error => {
                this.presenter.stopLoading();
                this.notifications.serverError(error);
              });
            },
            decline: () => {
              cargoGrade.destroyRecord();
              this.presenter._decline();
            }
          });
          break;
        case 'charterer':
          const charterer = this.store.createRecord('charterer', _objectSpread({
            name: null
          }, modelProps));
          this.dialog.one('created', presenter => {
            this.presenter = presenter;
          });
          this.dialog.open('charterer-form', charterer, {
            title: 'Create new charterer',
            acceptText: 'Save',
            accept: () => {
              this.presenter.startLoading();
              return charterer.save().then(() => {
                this.notifications.success('New charterer added');
                this.presenter.stopLoading();
                this.presenter._accept();
              }).catch(error => {
                this.presenter.stopLoading();
                this.notifications.serverError(error);
              });
            },
            decline: () => {
              charterer.destroyRecord();
              this.presenter._decline();
            }
          });
          break;
        case 'discharge':
        case 'load':
        case 'port':
        case 'zone':
          const zone = this.store.createRecord('zone', _objectSpread({
            type: 'zone',
            aliases: []
          }, modelProps));
          this.dialog.one('created', presenter => {
            this.presenter = presenter;
          });
          this.dialog.open('zone-form', zone, {
            title: 'Create a new zone',
            acceptText: 'Save',
            controller: this,
            countries: countries.all.map(c => c.name),
            addAlias: tag => zone.get('aliases').pushObject(tag),
            removeAlias: i => zone.get('aliases').removeAt(i),
            accept: () => {
              const firstBoundary = Ember.get(this, 'pendingBoundaries.firstObject');
              if (firstBoundary && !Ember.get(zone, 'longitude')) {
                // set the zone latitude and longitude from boundary
                setProperties(zone, {
                  longitude: Ember.get(firstBoundary, 'longitude'),
                  latitude: Ember.get(firstBoundary, 'latitude')
                });
              }
              // start loading indicator
              this.presenter.startLoading();
              // save the zone
              return zone.save().then(zone => {
                // set zone to pending boundaries
                const boundaries = this.pendingBoundaries.map(b => {
                  b.set('zone_uuid', zone.get('id'));
                  return b;
                });
                // save boundaries
                return all(boundaries.invoke('save')).then(boundaries => {
                  // clear pending zones
                  this.resetZoneEditor();
                  // set boundaries to zones
                  zone.get('boundaries').pushObjects(boundaries);
                  // save boundaries
                  this.notifications.success('New zone added');
                  this.presenter.stopLoading();
                  this.presenter._accept();
                }).catch(error => {
                  this.presenter.stopLoading();
                  this.notifications.serverError(error);
                });
              });
            },
            decline: () => {
              zone.destroyRecord();
              this.resetZoneEditor();
              this.presenter._decline();
            }
          });
          break;
      }
    }

    /**
     * Generic model delete dialog
     *
     * @param {Model} model
     * @param {String} modelName
     * @param {String} nameProperty
     *
     * @returns {Promise}
     */
    delete(model, modelName, callback = null, nameProperty = 'name') {
      return this.dialog._confirm({
        isDeleteDialog: true,
        title: `Delete ${Ember.String.capitalize(modelName)}`,
        acceptText: 'Confirm and delete',
        message: `Are you sure you want to delete this ${modelName} (${model[nameProperty]})?`
      }).then(() => {
        return model.destroyRecord().then(response => {
          this.notifications.info(`${Ember.String.capitalize(modelName)} deleted.`);
          if (typeof callback === 'function') {
            return callback(response);
          }
          return response;
        }).catch(error => {
          this.notifications.serverError(error);
        });
      });
    }
  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CrudService;
});