define("@fixflo/frontend/utils/geojson-to-coords", ["exports", "@mapbox/geojson-coords"], function (_exports, _geojsonCoords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = geojsonToCoords;
  /**
   * Pulls the coordiantes from a instance of GeoJSON to coordiantes object or Array
   * by default the coordinates are returned in order of longitude and latitude w/ the option to return
   * coordinates in reverse as lat,lng as well
   *
   * @param  {Mixed}    geojson                   Instance of GeoJSON or an Leaflet Layer
   * @param  {Boolean}  withProperties=true       Option to return object with lat/lng keys
   * @param  {Boolean}  asArray=false             Return as array instead of object
   * @param  {Boolean}  reverseCoordinates=false  Reverse the coordinates
   * @return {Mixed}    Object/Array
   */
  function geojsonToCoords(geojson, withProperties = true, asArray = false, reverseCoordinates = false) {
    // the object to return
    const coordinatesObject = {};
    // if layer convert to geojson
    if (Ember.get(geojson, '_leaflet_id')) {
      // assume this is layer, convert layer to geojson
      geojson = geojson.toGeoJSON();
    }
    // get coordinates
    const coordinates = (0, _geojsonCoords.default)(geojson);
    // if only array of coords
    if (asArray) {
      if (reverseCoordinates) {
        return coordinates.map(coord => coord.reverse());
      }
      return coordinates;
    }
    // map into object
    coordinates.forEach((coord, index) => {
      if (withProperties) {
        if (reverseCoordinates) {
          coordinatesObject[index] = {
            lat: coord[1],
            lng: coord[0]
          };
        } else {
          coordinatesObject[index] = {
            lng: coord[0],
            lat: coord[1]
          };
        }
      } else {
        if (reverseCoordinates) {
          coordinatesObject[index] = coord;
        } else {
          coordinatesObject[index] = coord.reverse();
        }
      }
    });
    // return
    return coordinatesObject;
  }
});