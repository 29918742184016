define("@fixflo/frontend/components/fixture-board", ["exports", "@fixflo/frontend/mixins/fixtures-table", "ember-concurrency"], function (_exports, _fixturesTable, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const AUTO_REFRESH_MINUTES_INTERVAL = 10;
  var _default = Ember.Component.extend(_fixturesTable.default, {
    actions: {
      /**
       * Handle the scroll to load more at the end of wtHolder scroll
       *
       * @void
       */
      handleScroll: function () {
        const scrollElement = document.querySelector(`[id="${Ember.get(this, 'source.id')}-${Ember.get(this, 'widget.id')}"] > .ht_master > .wtHolder`);
        const isLastPage = this.isLastPage;
        if (scrollElement && scrollElement.scrollHeight - scrollElement.scrollTop - scrollElement.clientHeight < 1 && isLastPage === false) {
          return this.infinityLoadMoreFixtures.perform(Ember.get(this, 'source.fixtures'));
        }
      }
    },
    /**
     * Reload when any filter value has changed
     */
    // eslint-disable-next-line ember/no-observers
    paramsChanged: Ember.observer('query', 'sort', 'filters.{discharge,load,vessel,boundary,quantity[min],quantity[max],grade,charterer,rate,laycanRangeStart,laycanRangeEnd,before,after,internal,confidentialityLevel,vesselFleet,vesselDeadWeight[min],vesselDeadWeight[max],vesselYearBuilt[min],vesselYearBuilt[max],vesselCubicCapacity[min],vesselCubicCapacity[max],vesselLengthOverAll[min],vesselLengthOverAll[max]}', function () {
      return this.loadFixtures.perform();
    }),
    /**
     * The fixture board to source
     */
    source: null,
    /**
     * The api rest adapter service
     */
    store: Ember.inject.service(),
    /**
     * The ajax service
     */
    ajax: Ember.inject.service(),
    /**
     * The infinity loader service
     */
    infinity: Ember.inject.service(),
    /**
     * The notificationss service
     */
    notifications: Ember.inject.service(),
    /**
     * The dialog service
     */
    dialog: Ember.inject.service(),
    /**
     * The can (permissions) service
     */
    can: Ember.inject.service(),
    /**
     * The containing widget
     */
    widget: null,
    /**
     * Default sorting
     */
    sort: 'created_at',
    /**
     * Direction of sorting
     */
    sortDirection: 'asc',
    /**
     * Golden layout instance
     */
    goldenlayout: null,
    /**
     * Determines if the last page of fixtues has been loaded
     */
    isLastPage: Ember.computed('source.fixtures.meta', 'source.fixtures.meta.current_page', 'source.fixtures.meta.last_page', function () {
      const meta = Ember.get(this, 'source.fixtures.meta');
      if (meta && typeof meta === 'object') {
        return Ember.get(meta, 'current_page') === Ember.get(meta, 'last_page');
      }
      return false;
    }),
    /**
     * The fixture values
     */
    fixtures: Ember.computed('source.fixtures.@each', function () {
      if (!Ember.get(this, 'source.fixtures')) {
        return [];
      }
      return Ember.get(this, 'source.fixtures').map(f => Ember.get(f, 'asTableData'));
    }),
    /**
     * Render the table when fixtures model has been reloaded
     */
    renderTable: Ember.observer('source.fixtures.@each', 'handsontable', function () {
      if (this.handsontable && this.fixtures) {
        this.handsontable.loadData(this.fixtures);
        setTimeout(() => {
          this.handsontable.refreshDimensions();
        }, 500);
      }
    }),
    /**
     * Refresh table dimensions if goldenlayout state changes
     */
    refreshDimensions: function () {
      // console.log('[refreshDimensions]', new Date().toLocaleTimeString());
      const goldenlayout = this.goldenlayout;
      const handsontable = this.handsontable;
      if (goldenlayout && Ember.typeOf(goldenlayout.on) === 'function') {
        goldenlayout.on('stateChanged', () => {
          // console.log('[refreshDimensions:goldenlayout:stateChanged]', new Date().toLocaleTimeString());
          if (handsontable && Ember.typeOf(handsontable.refreshDimensions) === 'function') {
            // console.log('[refreshDimensions:performing]', new Date().toLocaleTimeString());
            handsontable.refreshDimensions();
          } else {
            Ember.run.next(this, 'refreshDimensions');
          }
        });
      } else {
        Ember.run.next(this, 'refreshDimensions');
      }
    },
    /**
     * Concurrency task to refresh route every 10 minutes
     */
    autoRefresh: (0, _emberConcurrency.task)(function* () {
      while (true) {
        yield (0, _emberConcurrency.timeout)(AUTO_REFRESH_MINUTES_INTERVAL * 60000);
        return this.loadFixtures.perform();
      }
    }).enqueue().cancelOn('willDestroyElement'),
    /**
     * Concurrency task to load fixtures
     */
    loadFixtures: (0, _emberConcurrency.task)(function* () {
      Ember.set(this, 'isLoading', true);
      // get the sort
      const sort = this.sort;
      // get all params
      const boardParams = Ember.get(this, 'source.params');
      const params = this.filters;
      // get the query
      const query = this.query;
      if (query) {
        params.query = query;
      }
      // set initial pagination params
      const startingPage = 1;
      const perPage = 32;
      // delete all param keys if existing in board params
      const boardParamKeys = Object.keys(boardParams);
      const paramKeys = Object.keys(params);
      const paramsToDelete = paramKeys.filter(p => RegExp(boardParamKeys.join('|'), 'gmi').test(p));
      paramsToDelete.forEach(p => delete params[p]);
      // load fixtures
      const fixtures = yield this.infinity.model('fixture', _objectSpread(_objectSpread(_objectSpread({}, params), boardParams), {}, {
        sort,
        perPage,
        startingPage,
        published: 1,
        perPageParam: 'limit',
        pageParam: 'page',
        totalPagesParam: 'meta.last_page',
        countParam: 'meta.total'
      }));
      // set fixtures and update loading state
      Ember.set(this, 'source.fixtures', fixtures);
      // set yield for rendering
      yield (0, _emberConcurrency.timeout)(600);
      // update loading state
      Ember.set(this, 'isLoading', false);
    }).enqueue().cancelOn('willDestroyElement'),
    /**
     * Concurrency task to load more fixtures via infinity
     */
    infinityLoadMoreFixtures: (0, _emberConcurrency.task)(function* (model) {
      // load fixtures from infinity model
      const fixtures = yield this.infinity.infinityLoad(model);
      // set fixtures and update loading state
      Ember.set(this, 'source.fixtures', fixtures);
    }).drop().cancelOn('willDestroyElement'),
    /**
     * Load fixtures on initial load
     */
    didInsertElement: async function () {
      // if widget is not active don't do rendering
      if (!this.isActive) {
        return Ember.run.next(this, function () {
          return this.didInsertElement();
        });
      }
      // set the default source fixtures to array
      Ember.set(this, 'source.fixtures', []);
      // do initial load
      this.loadFixtures.perform();
      // search to see if user can edit board
      const collaboratorCanEdit = await this.fetch.get('actions/verify-can-edit-board', {
        board: Ember.get(this, 'source.id')
      }).then(r => r.can_edit);
      // set default properties
      Ember.setProperties(this, {
        isBoard: true,
        contextName: Ember.get(this, 'source.name'),
        collaboratorCanEdit
      });
      // track when to refresh dimensions
      this.refreshDimensions();
      // perform autoreload task
      this.autoRefresh.perform();
    }
  });
  _exports.default = _default;
});