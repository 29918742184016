define("@fixflo/frontend/components/cell/dropdown-menu", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <FixFlo::DropdownButton @icon="ellipsis-h-alt" @contentClass="dropdown-menu-content context-menu mt-1" @buttonSize="xs"
      @triggerClass="float-right" @buttonIcon="icon-navigation_more_horizontal" as |dd|>
      {{#each @column.items as |item|}}
          <a href="#" class="block dd-item {{item.className}}" role="menuitem"
              {{on "click" (fn this.actionHandler dd item)}}>
              {{item.label}}
          </a>
      {{/each}}
  </FixFlo::DropdownButton>
  */
  {
    id: "8OWXxwfQ",
    block: "{\"symbols\":[\"dd\",\"item\",\"@column\"],\"statements\":[[5,\"fix-flo/dropdown-button\",[],[[\"@icon\",\"@contentClass\",\"@buttonSize\",\"@triggerClass\",\"@buttonIcon\"],[\"ellipsis-h-alt\",\"dropdown-menu-content context-menu mt-1\",\"xs\",\"float-right\",\"icon-navigation_more_horizontal\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,3,[\"items\"]]],null,{\"statements\":[[0,\"        \"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",[29,[\"block dd-item \",[23,2,[\"className\"]]]]],[12,\"role\",\"menuitem\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"actionHandler\"]],[23,1,[]],[23,2,[]]],null]]],[8],[0,\"\\n            \"],[1,[23,2,[\"label\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]}]],\"hasEval\":false}",
    meta: {
      moduleName: "@fixflo/frontend/components/cell/dropdown-menu.hbs"
    }
  });
  let CellDropdownMenuComponent = (_dec = Ember._action, (_class = class CellDropdownMenuComponent extends _component.default {
    /**
     * Handles link click action
     *
     * @void
     */
    actionHandler(dd, item) {
      if (dd.actions && typeof dd.actions.close === 'function') {
        dd.actions.close();
      }
      // trigger item action if any
      if (item.action && typeof item.action === 'function') {
        item.action(this.args.row.content || this.args.row, this.args.column);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "actionHandler", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "actionHandler"), _class.prototype)), _class));
  _exports.default = CellDropdownMenuComponent;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CellDropdownMenuComponent);
});