define("@fixflo/frontend/components/free-table-board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      /**
       * Action to reload data with latest params
       */
      reload: function () {
        Ember.set(this, 'isLoading', true);
        // load the table data
        this.store.query('free-board-input', {
          board_uuid: Ember.get(this, 'source.id'),
          limit: 999999
        }).then(data => {
          Ember.setProperties(this, {
            data
          });
        });
        // load the table columns
      },

      /**
       * Prompt dialog for user to set and manage board columns
       */
      manageColumns: function () {
        this.freeTable.send('manageColumns');
      },
      /**
       * Set the handsontable instance
       */
      setInstance: function (handsontable, freeTable) {
        Ember.setProperties(this, {
          handsontable,
          freeTable
        });
      },
      /**
       * Display dialog to delete this board
       */
      deleteBoard: function () {
        const board = this.source;
        this.dialog._confirm({
          title: 'Delete board',
          acceptText: 'Confirm and delete',
          message: `Are you sure you want to delete this board?`
        }).then(() => {
          board.destroyRecord().then(() => {
            return this.transitionToRoute('dashboard.boards');
          });
        });
      },
      /**
       * Share the board with other sers or groups
       */
      shareBoard: function () {
        const query = query => {
          let request = [];
          request.push(this.store.query('user', {
            without: ['permissions'],
            query
          }));
          request.push(this.store.query('group', {
            without: ['members'],
            query
          }));
          return Ember.RSVP.all(request).then(response => [...response[0].toArray(), ...response[1].toArray()].filter(u => Ember.get(u, 'id') !== Ember.get(this, 'source.owner_uuid')));
        };
        const contextObject = {
          selected: [],
          canEdit: false
        };
        this.dialog.one('created', presenter => {
          this.set('presenter', presenter);
        });
        this.dialog.open('share-board-form', contextObject, {
          title: 'Share with others',
          query,
          acceptText: 'Save',
          accept: () => {
            const groups = contextObject.selected.filter(s => Ember.get(s, '_internalModel.modelName') === 'group').map(s => Ember.get(s, 'uuid'));
            const users = contextObject.selected.filter(s => Ember.get(s, '_internalModel.modelName') === 'user').map(s => Ember.get(s, 'uuid'));
            if (users.length || groups.length) {
              return this.fetch.post('actions/share-board', {
                data: {
                  board: Ember.get(this, 'source.id'),
                  groups,
                  users,
                  canEdit: contextObject.canEdit
                }
              }).then(response => {
                this.notifications.success('Board shared');
                this.presenter._accept();
              }).catch(error => {
                this.notifications.serverError(error);
                this.presenter._accept();
              });
            }
            this.presenter._accept();
          }
        });
      },
      /**
       * View the board collaborators
       */
      viewBoardCollaborators: async function () {
        const {
          collaborators,
          owner
        } = await this.fetch.get('actions/get-board-collaborators', {
          data: {
            board: Ember.get(this, 'source.id')
          }
        });
        // check if current user is board owner
        const isBoardOwner = Ember.get(owner, 'uuid') === this.currentUser.id;
        // set dialog presenter
        this.dialog.one('created', presenter => {
          this.set('presenter', presenter);
        });
        // triger dialog
        this.dialog.open('board-collaborators', null, {
          title: 'View Collaborators',
          collaborators,
          owner,
          isBoardOwner,
          controller: this,
          acceptText: 'Done',
          accept: () => {
            // convert board groups and board members to models
            const boardMembers = collaborators.users.map(member => {
              return this.store.push(this.store.normalize('board-member', member));
            });
            // convert board groups to models
            const boardGroups = collaborators.groups.map(group => {
              return this.store.push(this.store.normalize('board-group', group));
            });
            // save collaborators and close dialog
            Ember.RSVP.all([...boardMembers, ...boardGroups].invoke('save')).then(response => {
              this.presenter._accept();
            });
          }
        });
      },
      /**
       * Removes a board collaborator
       */
      removeCollaborator: function (collaborator) {
        // check collaborator type
        const isGroup = Ember.get(collaborator, 'user_uuid') === undefined;
        // convert to model
        if (isGroup) {
          collaborator = this.store.push(this.store.normalize('board-group', collaborator));
        } else {
          collaborator = this.store.push(this.store.normalize('board-member', collaborator));
        }
        this.dialog._confirm({
          title: 'Remove collaborator',
          acceptText: 'Confirm and remove',
          message: `Are you sure you want to remove this collaborator (${isGroup ? Ember.get(collaborator, 'group.name') : Ember.get(collaborator, 'user.name')})?`
        }).then(() => {
          collaborator.destroyRecord().then(collaborator => {
            document.getElementById(`collaborator-${Ember.get(collaborator, 'id')}`).remove();
          });
        });
      }
    },
    /**
     * The api rest adapter service
     */
    store: Ember.inject.service(),
    /**
     * The ajax service
     */
    ajax: Ember.inject.service(),
    /**
     * Inject the current user service
     *
     * @var {Service}
     */
    currentUser: Ember.inject.service(),
    /**
     * The notificationss service
     */
    notifications: Ember.inject.service(),
    /**
     * The dialog service
     */
    dialog: Ember.inject.service(),
    /**
     * The can (permissions) service
     */
    can: Ember.inject.service(),
    /**
     * Handsontable instance
     */
    handsontable: null,
    /**
     * Free table component instance
     */
    freeTable: null,
    /**
     * The board source
     */
    source: null,
    /**
     * The table data
     */
    data: Ember.A([]),
    /**
     * Search table
     */
    // eslint-disable-next-line ember/no-observers
    search: Ember.observer('query', function () {
      if (this.handsontable) {
        const search = this.handsontable.getPlugin('search');
        search.query(this.query);
        this.handsontable.render();
      }
    }),
    /**
     * Refresh table dimensions if goldenlayout state changes
     */
    refreshDimensions: function () {
      const goldenlayout = this.goldenlayout;
      const handsontable = this.handsontable;
      if (goldenlayout && Ember.typeOf(goldenlayout.on) === 'function') {
        goldenlayout.on('stateChanged', () => {
          if (handsontable && Ember.typeOf(handsontable.refreshDimensions) === 'function') {
            handsontable.refreshDimensions();
          } else {
            Ember.run.next(this, 'refreshDimensions');
          }
        });
      } else {
        Ember.run.next(this, 'refreshDimensions');
      }
    },
    /**
     * Load fixtures on initial load
     */
    didInsertElement: async function () {
      // do initial load
      this.send('reload');
      // track when to refresh dimensions
      this.refreshDimensions();
    }
  });
  _exports.default = _default;
});