define("@fixflo/frontend/controllers/dashboard/settings/groups/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      /**
       * Forwards and handles actions from table/action components
       */
      tableAction: function (action, model) {
        if (action === 'manage') {
          return this.transitionToRoute('dashboard.settings.groups.manage-group', model);
        }
        this.send(action, model);
      },
      /**
       * Sorts the current data listed
       */
      tableSort: function (sorts) {
        // TODO
      },
      /**
       * Create a new group
       */
      create: async function () {
        const group = this.store.createRecord('group', {
          name: null,
          status: 'active'
        });
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // render dialog
        this.dialog.open('group-form', group, {
          title: 'Create new group',
          acceptText: 'Save',
          controller: this,
          accept: () => {
            group.save().then(group => {
              this.notifications.success('New group added');
              this.presenter._accept();
            });
          },
          decline: () => {
            group.destroyRecord();
            this.presenter._decline();
          }
        });
      },
      /**
       * Edit a group
       */
      edit: async function (group) {
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // render dialog
        this.dialog.open('group-form', group, {
          title: Ember.String.htmlSafe(`Edit group <i>${Ember.get(group, 'name')}</i>`),
          acceptText: 'Save Changes',
          controller: this,
          accept: () => {
            this.dialog.startLoading();
            // save changes
            group.save().then(group => {
              this.dialog.stopLoading();
              this.notifications.info(`Changes to group ${group.get('name')} saved.`);
              this.dialog._close();
            }).catch(error => {
              this.dialog.stopLoading();
              this.notifications.serverError(error);
            });
          }
        });
      },
      /**
       * Delete a group
       */
      delete: function (group) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Delete group',
          acceptText: 'Delete Group',
          message: Ember.String.htmlSafe(`Are you sure you want to delete this group <i>${Ember.get(group, 'name')}</i>?`)
        }).then(() => {
          group.destroyRecord().then(() => {
            this.notifications.info('Group deleted');
          }).catch(error => {
            this.notifications.serverError(error);
          });
        });
      }
    },
    /**
     * Query parameters
     */
    queryParams: ['page', 'limit', 'sort', 'query', 'role'],
    /**
     * Current page
     */
    page: 1,
    /**
     * Default pagination limit
     */
    limit: 10,
    /**
     * Default sorting
     */
    sort: 'created_at',
    /**
     * Columns to display in table
     * @type array
     */
    columns: [{
      name: `Name`,
      valuePath: `name`,
      linkTo: {
        route: 'dashboard.settings.groups.manage-group'
      }
    }, {
      name: `Leader`,
      valuePath: `owner_name`
    }, {
      name: `Members`,
      valuePath: `members_count`
    }, {
      name: `Created`,
      valuePath: `createdAt`
    }, {
      name: ``,
      menuItems: [{
        action: 'manage',
        icon: 'icon-main_navigation_settings',
        can: 'edit group',
        label: 'Manage Group'
      }, {
        action: 'edit',
        icon: 'icon-content_edit',
        can: 'edit group',
        label: 'Edit Group'
      }, {
        action: 'delete',
        icon: 'icon-navigation_delete',
        can: 'delete group',
        label: 'Delete Group'
      }]
    }]
  });
  _exports.default = _default;
});