define("@fixflo/frontend/templates/auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "eO5EUeHl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ff_layout\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ff_frontpage\"],[8],[0,\"\\n\\t\\t\"],[7,\"main\",true],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"minHeight\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ff_frontpage img\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"bg_img\",[28,\"if\",[[24,[\"signUpForm\"]],\" signup\"],null],[28,\"if\",[[24,[\"signInForm\"]],\" signin\"],null],[28,\"if\",[[24,[\"passwordForm\"]],\" password\"],null]]]],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"bg_img__offset\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"img\",true],[10,\"src\",\"/images/bg_img.jpg\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/auth.hbs"
    }
  });
  _exports.default = _default;
});