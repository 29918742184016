define("@fixflo/frontend/components/s3-upload", ["exports", "ember-uploader/components/file-field", "ember-uploader/uploaders/s3", "ember-uuid", "ember-get-config", "jquery"], function (_exports, _fileField, _s, _emberUuid, _emberGetConfig, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _fileField.default.extend({
    actions: {
      /**
       * Trigger event after upload completed
       *
       * @void
       */
      triggerOnDidUpload: function (file) {
        if (typeof this.onDidUpload === 'function') {
          this.onDidUpload(file);
        }
      },
      /**
       * Trigger event after upload completed
       *
       * @void
       */
      triggerOnUploadStart: function (files) {
        if (typeof this.onUploadStart === 'function') {
          this.onUploadStart(files);
        }
      }
    },
    /**
     * Set the name to file
     *
     * @var {string}
     */
    name: 'file',
    /**
     * The URL to sign the upload request
     *
     * @var {string}
     */
    signingUrl: `${_emberGetConfig.default.api.host}/${_emberGetConfig.default.api.namespace}/files/sign`,
    /**
     * Inject the store into this componennt
     *
     * @var {Service}
     *  */
    store: Ember.inject.service(),
    /**
     * Inject the notifications into this componennt
     *
     * @var {Service}
     */
    notifications: Ember.inject.service(),
    /**
     * Inject the current user service into this component
     *
     * @var {Service}
     */
    currentUser: Ember.inject.service(),
    /**
     * The path to upload the file in the bucket
     *
     * @var {string}
     */
    key: null,
    /**
     * The file object once upload is completed
     *
     * @var {Model}
     */
    file: Ember.A([]),
    /**
     * The plan upload object response
     *
     * @var {object}
     */
    object: null,
    /**
     * The type of file
     *
     * @var {string}
     */
    fileType: null,
    /**
     * The ID of the file assosciated
     *
     * @var {string}
     */
    keyId: null,
    /**
     * The upload progress percent
     *
     * @var {integer}
     */
    progress: 0,
    /**
     * The uploaded files URL
     *
     * @var {string}
     *  */
    fileUrl: null,
    /**
     * The file ID
     *
     * @var {string}
     */
    fileId: Ember.A([]),
    /**
     * Upload multiple files
     *
     * @var {boolean}
     */
    multiple: false,
    /**
     * Ember model to auto save to upon upload completion
     *
     * @var {DS.Model}
     */
    autoSaveTo: null,
    /**
     * The property of the model to save to from "autoSaveTo"
     *
     * @var {string}
     */
    saveProperty: null,
    /**
     * Function to rename the file to make unique
     *
     * @void
     */
    keyPath: function (key) {
      const fileName = key.split('\\').pop().split('/').pop();
      const ext = '.' + fileName.split('.').pop();
      const name = fileName.substr(0, fileName.lastIndexOf('.'));
      const newFileName = name + (0, _emberUuid.v1)() + ext;
      // set in key path
      return key.replace(fileName, newFileName);
    },
    /**
     * Function to create fleetbase file and save
     *
     * @retun Promise
     */
    createFile: async function (response, originalName = null) {
      const file = this.store.createRecord('file', {
        type: this.fileType,
        key_uuid: this.keyId,
        uploader_uuid: this.currentUser.id,
        company_uuid: this.currentUser.companyId,
        key: (0, _jquery.default)(response).find('Key')[0].textContent,
        bucket: (0, _jquery.default)(response).find('Bucket')[0].textContent,
        etag: (0, _jquery.default)(response).find('ETag')[0].textContent
      });
      // retun promise to save file
      return file.save().catch(error => {
        this.notifications.serverError('File upload failed.');
      });
    },
    /**
     * Set the uploaded file url
     *
     * @return {String}
     */
    setFileUrl: function (response) {
      const fileUrl = decodeURIComponent((0, _jquery.default)(response).find('Location')[0].textContent);
      Ember.setProperties(this, {
        fileUrl
      });
    },
    /**
     * Auto save the ember model upon completion
     *
     * @return {Promise}
     */
    autoSave: async function (file) {
      const model = this.autoSaveTo;
      const property = this.saveProperty;
      if (!model) return;
      Ember.set(model, property, file.get('id'));
      return model.save();
    },
    /**
     * Handle the upload when a file is selected
     *
     * @void
     */
    filesDidChange: function (files) {
      const uploader = _s.default.create({
        signingUrl: this.signingUrl
      });
      const isMultiple = this.multiple;
      const filesValue = this.file;
      const firstFile = files[0];
      const keyPath = this.key;

      // Handle the upload completion
      uploader.on('didUpload', response => {
        this.setFileUrl(response);
        return this.createFile(response).then(file => {
          if (isMultiple) {
            filesValue.pushObject(file);
          } else {
            Ember.setProperties(this, {
              file,
              fileId: file.get('id'),
              object: file.toJSON()
            });
            return this.autoSave(file);
          }
          this.send('triggerOnDidUpload', file);
        });
      });

      // Handle upload progression
      uploader.on('progress', e => {
        this.set('progress', e.percent);
      });

      // Do upload
      if (!Ember.isEmpty(files)) {
        if (isMultiple) {
          this.send('triggerOnUploadStart', files);
          return Array.from(files).forEach(file => {
            return uploader.upload(file, {
              key: `${this.currentUser.companyId}/uploads/${keyPath}`
            });
          });
        } else {
          this.send('triggerOnUploadStart', files);
          return uploader.upload(firstFile, {
            key: `${this.currentUser.companyId}/uploads/${keyPath}`
          });
        }
      }
    }
  });
  _exports.default = _default;
});