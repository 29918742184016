define("@fixflo/frontend/controllers/dashboard/widgets/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      /**
       * Create a new widget
       */
      create: async function () {
        // check if user can create widget first
        if (this.can.cannot('create widget')) {
          return;
        }
        // send create action for widget
        this.send('createWidget');
      },
      /**
       * Edit a widget
       */
      edit: async function (widget) {
        // check if user can edit widget first
        if (this.can.cannot('edit widget')) {
          return;
        }
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // render dialog
        this.dialog.open('widget-form', widget, {
          title: Ember.String.htmlSafe(`Edit widget <i>${Ember.get(widget, 'name')}</i>`),
          acceptText: 'Save Changes',
          controller: this,
          accept: () => {
            widget.save().then(widget => {
              this.notifications.success('Changes to widget saved');
              this.presenter._accept();
            });
          }
        });
      },
      /**
       * Delete a widget
       */
      delete: function (widget) {
        // check if user can delete widget first
        if (this.can.cannot('delete widget')) {
          return;
        }
        // prompt user for deletion
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Delete widget',
          acceptText: 'Confirm and delete',
          message: Ember.String.htmlSafe(`Are you sure you want to delete this widget <i>${Ember.get(widget, 'name')}</i>?`),
          accept: () => {
            this.dialog.startLoading();
            widget.destroyRecord().then(widget => {
              this.dialog.stopLoading();
              this.notifications.info(`Widget ${widget.get('name')} was deleted.`);
              this.dialog._close();
            }).catch(error => {
              this.dialog.stopLoading();
              this.notifications.serverError(error);
            });
          }
        });
      }
    },
    currentUser: Ember.inject.service(),
    /**
     * All widgets loaded in the store
     */
    allWidgets: Ember.computed(function () {
      return this.store.peekAll('widget');
    }),
    /**
     * Current widgets for this user
     */
    widgets: Ember.computed('allWidgets.@each', function () {
      return this.allWidgets.filter(w => this.currentUser.id === Ember.get(w, 'owner_uuid'));
    })
  });
  _exports.default = _default;
});