define("@fixflo/frontend/app", ["exports", "ember-resolver", "ember-load-initializers", "@fixflo/frontend/config/environment", "logrocket", "@sentry/browser", "@sentry/integrations"], function (_exports, _emberResolver, _emberLoadInitializers, _environment, _logrocket, Sentry, _integrations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class App extends Ember.Application {
    constructor() {
      super(...arguments);
      _defineProperty(this, "modulePrefix", _environment.default.modulePrefix);
      _defineProperty(this, "podModulePrefix", _environment.default.podModulePrefix);
      _defineProperty(this, "Resolver", _emberResolver.default);
      if (!['development', 'local'].includes(_environment.default.environment)) {
        // Initialize Sentry
        Sentry.init({
          environment: _environment.default.environment,
          dsn: 'https://2e950d4159034e92a0b56fc3b949f547@sentry.io/1522708',
          integrations: [new _integrations.Ember()],
          ignoreErrors: [/TransitionAborted.*/]
        });

        // Initialize LogRocket
        _logrocket.default.init('vantage-shipbrokers-pte-ltd/fixflo');

        // Add LogRocket session to sentry scope
        _logrocket.default.getSessionURL(sessionURL => {
          Sentry.configureScope(scope => {
            scope.setExtra('sessionURL', sessionURL);
          });
        });
      }
    }
  }
  _exports.default = App;
  window.__CLASSIC_HAS_CONSTRUCTOR__.set(App, true);
  window.__CLASSIC_OWN_CLASSES__.set(App, true);
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
});