define("@fixflo/frontend/initializers/text-field", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  function initialize() {
    Ember.TextField.reopen({
      /**
       * Add attribute names to text fields
       *
       * @var array
       */
      attributeNames: ['autocomplete'],
      /**
       * Add class name bindings
       *
       * @var array
       */
      classNameBindings: ['isInvalid'],
      /**
       * Set autocomplete to off for all text fields
       *
       * @var string
       */
      autocomplete: 'new-password',
      /**
       * Determines if the field is invalid
       *
       * @var string
       */
      isInvalid: false,
      /**
       * Determines if the field is required
       *
       * @var boolean
       */
      isRequired: false,
      /**
       * Determines if is required should auto show message
       *
       * @var boolean
       */
      showRequiredMessage: true,
      /**
       * The name of the required field
       *
       * @var string
       */
      requiredName: 'Input',
      /**
       * Adds required message below input field
       *
       * @void
       */
      setIsRequiredMessage: Ember.observer('value', 'isRequired', 'showRequiredMessage', 'requiredName', function () {
        return this.checkForInput();
      }),
      /**
       * Checks to see if field has input
       *
       * @void
       */
      checkForInput: function () {
        if (this.isRequired === false) return;
        if (this.isRequired && this.showRequiredMessage && !this.value) {
          if ((0, _jquery.default)(this.element).next().hasClass('is-required-message')) {
            return;
          }
          (0, _jquery.default)(this.element).addClass('is-invalid').after('<div class="invalid-feedback is-required-message animated fadeIn">' + this.requiredName + ' is required.</div>');
        } else {
          if ((0, _jquery.default)(this.element).next().hasClass('is-required-message')) {
            (0, _jquery.default)(this.element).removeClass('is-invalid').next().remove();
          }
        }
      },
      /**
       * Run these on insert
       *
       * @void
       */
      didInsertElement: function () {
        this._super();
        this.checkForInput();
      }
    });
  }
  var _default = {
    initialize
  };
  _exports.default = _default;
});