define("@fixflo/frontend/initializers/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  function initialize() {
    Ember.Controller.reopen({
      /**
       * Function to refresh the route
       * @return Promise
       */
      refreshRoute: function () {
        const routerJs = Ember.get(this, 'target.targetState.routerJs');
        const router = Ember.get(this, 'target.targetState.router');
        if (routerJs) {
          return routerJs.refresh();
        }
        return router.refresh();
      },
      /**
       * Function to refresh the route
       * @var boolean
       */
      isRouteLoading: false
    });
  }
  var _default = {
    initialize
  };
  _exports.default = _default;
});