define("@fixflo/frontend/utils/geo-json-to-boundary", ["exports", "@mapbox/geojson-coords"], function (_exports, _geojsonCoords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = geoJsonToBoundary;
  function geoJsonToBoundary(geojson) {
    // if layer convert to geojson
    if (Ember.get(geojson, '_leaflet_id')) {
      // assume this is layer, convert layer to geojson
      geojson = geojson.toGeoJSON();
    }
    // get coordinates
    const coordinates = (0, _geojsonCoords.default)(geojson);
    // map into object
    const boundary = coordinates.map(coord => `(${coord[1]}, ${coord[0]})`);
    // join by comma
    return boundary.join(',');
  }
});