define("@fixflo/frontend/serializers/report-type-source", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _rest.default.extend(_rest.EmbeddedRecordsMixin, {
    primaryKey: 'uuid',
    attrs: {
      board: {
        embedded: 'always'
      }
    },
    serialize: function (snapshot, options) {
      var json = this._super(snapshot, options);
      // if source board is model change to id
      if (snapshot.attr('board_uuid') instanceof Object) {
        json.board_uuid = snapshot.attr('board_uuid').get('id');
      }
      return json;
    }
  });
  _exports.default = _default;
});