define("@fixflo/frontend/services/fetch", ["exports", "@fixflo/frontend/config/environment", "fetch"], function (_exports, _environment, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2, _descriptor3, _temp;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const classic = __EMBER_CLASSIC_DECORATOR;
  let FetchService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, classic(_class = (_class2 = (_temp = class FetchService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "_headers", _descriptor3, this);
      _defineProperty(this, "credentials", 'include');
    }
    /**
     * The default namespace for the fetch service
     *
     * @var {String}
     */
    get host() {
      return Ember.get(_environment.default, 'api.host');
    }

    /**
     * The default namespace for the fetch service
     *
     * @var {String}
     */
    get namespace() {
      return Ember.get(_environment.default, 'api.namespace');
    }

    /**
     * Inject the session service
     * 
     * @var {Service}
     */

    /**
     * Inject the session service
     * 
     * @var {Service}
     */

    /**
     * Mutable headers property.
     *
     * @var {Array}
     */

    /**
     * The headers to send with request.
     *
     * @var {Object}
     */
    get headers() {
      if (this._headers) {
        return this._headers;
      }
      return this.getHeaders();
    }

    /**
     * Setter fucntion to overwrite headers.
     */
    set headers(headers) {
      this._headers = headers;
    }

    /**
     * Gets headers that should be sent with request.
     *
     * @return {Object}
     */
    getHeaders() {
      const headers = {};

      // check if user is authenticated
      const isAuthenticated = this.session.isAuthenticated;
      const isViewingBoard = this.currentUser.options.get('viewingBoard');
      headers['Content-Type'] = 'application/json';
      if (isAuthenticated) {
        headers['Authorization'] = `Bearer ${this.session.data.authenticated.token}`;
      }
      if (isViewingBoard) {
        headers['X-FIXFLO-BOARD'] = isViewingBoard;
      }
      return headers;
    }

    /**
     * Gets fresh headers and sets them.
     *
     * @return {Object}
     */
    refreshHeaders() {
      const headers = this.getHeaders();
      this.headers = headers;
      return headers;
    }

    /**
     * Credentials
     *
     * @var {String}
     */

    /**
     * The base request method
     */
    request(path, method = 'GET', options = {}) {
      this.refreshHeaders();
      return (0, _fetch.default)(`${this.host}/${this.namespace}/${path}`, _objectSpread({
        method,
        credentials: this.credentials,
        headers: _objectSpread(_objectSpread({}, this.headers || {}), options.headers || {})
      }, options)).then(response => {
        // console.log('[fetch:response]', response);
        return response.json();
      });
    }

    /**
     * Makes a GET request with fetch
     */
    get(path, query = {}, options = {}) {
      if (query.sanctum_token) {
        query.sanctum_token = this.session.data.authenticated.token;
      }
      return this.request(`${path}?${!Ember.isBlank(query) ? new URLSearchParams(query).toString() : ''}`, 'GET', options);
    }

    /**
     * Makes a POST request with fetch
     */
    post(path, data = {}, options = {}) {
      return this.request(path, 'POST', _objectSpread(_objectSpread({}, options), {}, {
        body: JSON.stringify(data)
      }));
    }

    /**
     * Makes a PUT request with fetch
     */
    put(path, data = {}, options = {}) {
      return this.request(path, 'PUT', _objectSpread(_objectSpread({}, options), {}, {
        body: JSON.stringify(data)
      }));
    }

    /**
     * Makes a DELETE request with fetch
     */
    delete(path, data = {}, options = {}) {
      return this.request(path, 'DELETE', _objectSpread(_objectSpread({}, options), {}, {
        body: JSON.stringify(data)
      }));
    }

    /**
     * Makes a PATCH request with fetch
     */
    patch(path, data = {}, options = {}) {
      return this.request(path, 'PATCH', _objectSpread(_objectSpread({}, options), {}, {
        body: JSON.stringify(data)
      }));
    }

    /**
     * Makes a upload request with fetch
     */
    upload(path, files = [], options = {}) {
      const body = new FormData();
      files.forEach(file => {
        body.append('file', file);
      });
      return this.request(path, 'POST', _objectSpread(_objectSpread({}, options), {}, {
        body
      }));
    }
  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "currentUser", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "_headers", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = FetchService;
});