define("@fixflo/frontend/helpers/defaults-to", ["exports", "ember-content-loader/helpers/defaults-to"], function (_exports, _defaultsTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _defaultsTo.default;
    }
  });
  Object.defineProperty(_exports, "defaultsTo", {
    enumerable: true,
    get: function () {
      return _defaultsTo.defaultsTo;
    }
  });
});