define("@fixflo/frontend/mixins/track-reloads", ["exports", "ember-local-storage", "@fixflo/frontend/utils/is-model"], function (_exports, _emberLocalStorage, _isModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    actions: {
      loading: function (transition, originRoute) {
        this._super(transition, originRoute);
        this.setLoadingFlag(transition, this._trackedReloads === 0);
        if (this._trackedReloads > 0) {
          return false;
        }
        return true;
      },
      willTransition: function (transition) {
        this._super(transition);
        if (this.routeName !== transition.targetName) {
          this.set('_trackedReloads', 0);
        } else {
          this.incrementProperty('_trackedReloads');
        }
      }
    },
    /**
     * Display a loading indicator in the center of the content container
     */
    showLoadingIndicator: function () {},
    /**
     * Display a loading indicator in the center of the content container
     */
    hideLoadingIndicator: function () {},
    /**
     * This function is to flag a `isRouteLoading` property true/false depending on loading state
     */
    setLoadingFlag: function (transition, firstLoad) {
      const controller = this.controllerFor(this.routeName);
      if (controller && transition) {
        controller.setProperties({
          isRouteLoading: true,
          firstLoad
        });
        transition.finally(() => {
          controller.set('isRouteLoading', false);
          // trigger a optional callback
          if (typeof this.onRouteLoaded === 'function') {
            this.onRouteLoaded({
              transition,
              controller
            });
          }
        });
      }
    },
    /**
     * Set the storage adapter for user options
     */
    userOptions: (0, _emberLocalStorage.storageFor)('user-options'),
    /**
     * the number of reloads tracked
     */
    _trackedReloads: 0,
    /**
     * The loading state of the model
     */
    isRouteLoading: false,
    /**
     * afterModel hook track the reload
     */
    afterModel: function (model) {
      this._super(model);
      this.incrementProperty('_trackedReloads');
      // track the number of resources loaded for this route
      if ((0, _isModel.default)(model) & model.length) {
        this.userOptions.set(`${Ember.String.camelize(this.routeName)}ResourceCount`, model.length);
      }
    }
  });
  _exports.default = _default;
});