define("@fixflo/frontend/controllers/admin/panel/vessels/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      /**
       * Save the vessel
       */
      save: function (vessel) {
        const isCreating = Ember.get(vessel, 'id') === null;
        vessel.save().then(vessel => {
          this.notifications.success('Vessel saved.');
          if (isCreating) {
            return this.transitionToRoute('admin.panel.vessels.edit', vessel);
          }
        });
      }
    }
  });
  _exports.default = _default;
});