define("@fixflo/frontend/utils/start-of-decade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = startOfDecade;
  function startOfDecade(dirtyDate = null) {
    var date = dirtyDate === null ? new Date() : new Date(dirtyDate);
    var year = date.getFullYear();
    var decade = Math.floor(year / 10) * 10;
    date.setFullYear(decade, 0, 1);
    date.setHours(0, 0, 0, 0);
    return date;
  }
});