define("@fixflo/frontend/routes/dashboard/settings/vessels", ["exports", "@fixflo/frontend/mixins/track-reloads"], function (_exports, _trackReloads) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class DashboardSettingsVesselsRoute extends Ember.Route.extend(_trackReloads.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "queryParams", {
        query: {
          refreshModel: true
        },
        type: {
          refreshModel: true
        },
        fleet: {
          refreshModel: true
        },
        perPage: {
          refreshModel: true
        },
        page: {
          refreshModel: true
        },
        sort: {
          refreshModel: true
        }
      });
    }
    model(params) {
      return this.store.query('vessel', _objectSpread(_objectSpread({}, params), {}, {
        internal: true
      }));
    }
    async setupController(controller, model) {
      super.setupController(controller, model);
      // only if empty load fleets
      if (Ember.isEmpty(controller.fleets)) {
        // get all fleets
        controller.fleets = await this.store.query('vessel', {
          columns: ['fleet'],
          distinct: true,
          limit: 9999999
        });
      }

      // set rows to table
      if (controller.table) {
        controller.table.setRows(model);
      }
    }
  }
  _exports.default = DashboardSettingsVesselsRoute;
  window.__CLASSIC_OWN_CLASSES__.set(DashboardSettingsVesselsRoute, true);
});