define("@fixflo/frontend/helpers/fixture-to-text", ["exports", "@fixflo/frontend/helpers/n-a"], function (_exports, _nA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fixtureToText = fixtureToText;
  _exports.default = void 0;
  function fixtureToText([fixture]) {
    return Ember.get(fixture, 'values').map(v => (0, _nA.nA)([Ember.get(v, 'entry'), '']).toUpperCase()).join(' ');
  }
  var _default = Ember.Helper.helper(fixtureToText);
  _exports.default = _default;
});