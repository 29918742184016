define("@fixflo/frontend/components/file-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Attributes to bind to file upload
     *
     * @var array
     */
    attributeBindings: ['name'],
    /**
     * Style class names to be applied
     *
     * @var array
     */
    classNames: ['fixflo-file-upload'],
    /**
     * The path to upload the file in the bucket
     *
     * @var string
     */
    key: null,
    /**
     * The file object once upload is completed
     *
     * @var DS.Model
     */
    file: [],
    /**
     * Ember model to auto save to upon upload completion
     *
     * @var DS.Model
     */
    autoSaveTo: null,
    /**
     * The property of the model to save to from "autoSaveTo"
     *
     * @var string
     */
    saveProperty: null,
    /**
     * The plan upload object response
     *
     * @var object
     */
    object: null,
    /**
     * The ID of the file assosciated
     *
     * @var string
     */
    keyId: null,
    /**
     * The upload progress percent
     *
     * @var integer
     */
    progress: 0,
    /**
     * The uploaded files URL
     *
     * @var string
     */
    fileUrl: null,
    /**
     * The type of file, default turnn the key into slug
     *
     * @var string
     */
    fileType: Ember.computed('key', function () {
      const key = this.key;
      return Ember.String.underscore(key.replace('/', ' '));
    }),
    /**
     * The file ID
     *
     * @var string
     */
    fileId: [],
    /**
     * Upload multiple files
     *
     * @var boolean
     */
    multiple: false
  });
  _exports.default = _default;
});