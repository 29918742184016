define("@fixflo/frontend/routes/dashboard/reports/edit-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    templateName: 'dashboard.reports.new-template',
    model: function (params) {
      return this.store.queryRecord('report-template', {
        slug: params.slug
      });
    },
    afterModel: function (model, transition) {
      transition.finally(() => {
        this.controller.set('template', model);
      });
    }
  });
  _exports.default = _default;
});