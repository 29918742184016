define("@fixflo/frontend/components/cell-comment-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      /**
       * Cancel making the comment
       */
      cancel: function () {
        Ember.set(this, 'isCommenting', false);
      },
      /**
       * Publish  active comment
       */
      comment: function () {
        const comment = this.comment;
        if (!Ember.get(comment, 'content')) return;
        comment.save().then(comment => {
          Ember.set(this, 'comment', this.store.createRecord('cell-comment', {
            subject_uuid: Ember.get(this, 'subject.fixture_uuid'),
            subject: 'fixture',
            cell: this.cell,
            content: null
          }));
          this.thread.pushObject(comment);
        });
      }
    },
    /**
     * Default classes to be applied
     */
    classNames: ['cell-comment-block'],
    /**
     * Classes binded to properties
     */
    classNameBindings: ['isCommenting', 'isLoading'],
    /**
     * The api rest adapter service
     */
    store: Ember.inject.service(),
    /**
     * The comment to be published
     */
    comment: Ember.computed('subject.fixture_uuid', 'cell', function () {
      return this.store.createRecord('cell-comment', {
        subject_uuid: Ember.get(this, 'subject.fixture_uuid'),
        subject: 'fixture',
        cell: this.cell,
        content: null
      });
    }),
    /**
     * Current user making the comment
     */
    user: null,
    /**
     * The subject of the comment
     */
    subject: null,
    /**
     * The cell the comment is in
     */
    cell: null,
    /**
     * If a loading request is being made
     */
    isLoading: false,
    /**
     * If a user is trying to make a comment
     */
    isCommenting: false,
    /**
     * If a user is preparing to type reply
     */
    isReplying: false,
    /**
     * The event that toggled this comment block
     */
    toggleBoxEvent: null,
    /**
     * The thread of comments
     */
    thread: Ember.A([]),
    /**
     * Set this element position
     */
    // eslint-disable-next-line ember/no-observers
    setCommentBoxPosition: Ember.observer('toggleBoxEvent', function () {
      const event = this.toggleBoxEvent;
      if (event) {
        this.element.style.left = `${Math.ceil(event.pageX / 1.1)}px`;
        this.element.style.top = `${Math.ceil(event.pageY / 2.3)}px`;
        this.element.querySelector('input').focus();
      }
    }),
    /**
     * Load thread
     */
    loadThread: function () {
      Ember.setProperties(this, {
        isLoading: true,
        thread: []
      });
      this.store.query('cell-comment', {
        subject_uuid: Ember.get(this, 'subject.fixture_uuid'),
        'cell->col': Ember.get(this, 'cell.col')
      }).then(comments => {
        if (comments.length) {
          this.thread.pushObjects(comments.toArray());
        }
        Ember.set(this, 'isLoading', false);
      });
    },
    /**
     * Reload thread when isCommenting is true
     */
    // eslint-disable-next-line ember/no-observers
    reloadThread: Ember.observer('isCommenting', function () {
      if (this.isCommenting === true) {
        this.loadThread();
      }
    })
  });
  _exports.default = _default;
});