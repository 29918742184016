define("@fixflo/frontend/templates/layouts/alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "afpmydXu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"ember-dialog-dialog \",[22,\"className\"],\" \",[28,\"if\",[[24,[\"substrate\"]],\"substrate\"],null]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"dialog-content\"],[10,\"tabindex\",\"-1\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"dialog-header\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"dialog-close\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"accept\"]],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"dialog-title\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"templateName\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"dialog-body\"],[8],[15,[24,[\"templateName\"]],[]],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"component\",[\"dialog-body\"],[[\"layout\",\"contextObject\",\"context\",\"class\"],[[24,[\"template\"]],[24,[\"contextObject\"]],[24,[\"context\"]],\"dialog-body\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"dialog-footer\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn btn__accept\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"accept\"]],[8],[0,\"OK\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/layouts/alert.hbs"
    }
  });
  _exports.default = _default;
});