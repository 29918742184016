define("@fixflo/frontend/helpers/json-pretty-print", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function jsonPrettyPrint([json]) {
    return JSON.stringify(json, null, '  ');
  });
  _exports.default = _default;
});