define("@fixflo/frontend/components/min-max-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Conver the array to an object
     */
    values: Ember.computed(function () {
      const defaultValue = this.value;
      return {
        min: defaultValue[0],
        max: defaultValue[1]
      };
    }),
    /**
     * Update values
     */
    mutateValue: Ember.observer('values.min', 'values.max', function () {
      Ember.set(this, 'value', Object.values(this.values).map(Number));
    }),
    /**
     * Default value 
     */
    value: [0, 100]
  });
  _exports.default = _default;
});