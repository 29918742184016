define("@fixflo/frontend/templates/dashboard/settings/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KfS5SOvN",
    "block": "{\"symbols\":[],\"statements\":[[5,\"fix-flo/section-header\",[],[[\"@title\"],[\"Profile\"]]],[0,\"\\n\"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"bg-white\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"sm:hidden\"],[8],[0,\"\\n            \"],[7,\"select\",true],[10,\"class\",\"form-select block w-full\"],[8],[0,\"\\n                \"],[7,\"option\",true],[8],[0,\"\\n                    Edit Profile\\n                \"],[9],[0,\"\\n                \"],[7,\"option\",true],[8],[0,\"\\n                    Confidentiality Settings\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"hidden sm:block\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"border-b border-gray-200\"],[8],[0,\"\\n                \"],[7,\"nav\",true],[10,\"class\",\"-mb-px flex\"],[8],[0,\"\\n                    \"],[5,\"link-to\",[[12,\"class\",\"full-width-tab\"]],[[\"@route\"],[\"dashboard.settings.index.edit-profile\"]],{\"statements\":[[0,\"\\n                        Edit Profile\\n                    \"]],\"parameters\":[]}],[0,\"\\n                    \"],[5,\"link-to\",[[12,\"class\",\"full-width-tab\"]],[[\"@route\"],[\"dashboard.settings.index.confidentiality-settings\"]],{\"statements\":[[0,\"\\n                        Confidentiality Settings\\n                    \"]],\"parameters\":[]}],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dashboard/settings/index.hbs"
    }
  });
  _exports.default = _default;
});