define("@fixflo/frontend/routes/auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    /**
     * If user is already authenticated then redirect to dashboard
     *
     * @void
     */
    beforeModel() {
      this.session.prohibitAuthentication('dashboard');
    }
  });
  _exports.default = _default;
});