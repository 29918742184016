define("@fixflo/frontend/services/ajax", ["exports", "ember-ajax/services/ajax", "ember-get-config", "jquery"], function (_exports, _ajax, _emberGetConfig, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _jquery.default.ajaxSetup({
    xhrFields: {
      withCredentials: true
    }
  });
  var _default = _ajax.default.extend({
    /**
     * API Namespace
     *
     * @var {String}
     */
    namespace: _emberGetConfig.default.api.namespace,
    /**
     * API Host
     *
     * @var {String}
     */
    host: _emberGetConfig.default.api.host,
    /**
     * Inject the cookies service
     *
     * @var {Service}
     */
    cookies: Ember.inject.service(),
    /**
     * Inject the cookies service
     *
     * @var {Service}
     */
    currentUser: Ember.inject.service(),
    /**
     * The request headers
     *
     * @var {Object}
     */
    get headers() {
      return {
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': this.cookies.read('XSRF-TOKEN')
      };
    },
    /**
     * Customize ajax request before sending
     *
     * @void
     */
    options: function (url, type, options) {
      // remove params from url
      url = url.includes('?') ? url.substring(0, url.indexOf('?')) : url;
      // if type has not type default to GET
      if (type && !type.type) {
        type.type = 'GET';
      } else if (!type) {
        type = {
          type: 'GET'
        };
      }
      // get headers
      const headers = this.headers;
      // if user is viewing board set
      if (this.currentUser.options.get('viewingBoard')) {
        headers['X-FIXFLO-BOARD'] = this.currentUser.options.get('viewingBoard');
      }
      // double check xsrf token
      headers['X-XSRF-TOKEN'] = this.cookies.read('XSRF-TOKEN');

      // recreate hash
      const hash = options || {};
      hash.crossDomain = true;
      hash.xhrFields = {
        withCredentials: true
      };
      hash.url = url;
      hash.type = type;
      hash.dataType = 'json';
      hash.context = this;
      // set headers
      if (headers && hash) {
        hash.beforeSend = function (xhr) {
          for (let header in headers) {
            xhr.setRequestHeader(header, headers[header]);
          }
        };
      }
      return this._super(...arguments);
    }
  });
  _exports.default = _default;
});