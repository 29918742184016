define("@fixflo/frontend/templates/components/quill-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "g/wm69kH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"id\",\"toolbar\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"editor\"],[8],[9],[0,\"\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/components/quill-editor.hbs"
    }
  });
  _exports.default = _default;
});