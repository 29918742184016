define("@fixflo/frontend/utils/is-element", ["exports", "@fixflo/ui/utils/is-element"], function (_exports, _isElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _isElement.default;
    }
  });
});