define("@fixflo/frontend/templates/dialog/boundary-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fjGVDZr6",
    "block": "{\"symbols\":[],\"statements\":[[5,\"leaflet-map\",[],[[\"@lat\",\"@lng\",\"@zoom\",\"@streetViewControl\",\"@mapTypeControl\",\"@onLoad\"],[\"1.3521\",\"103.8198\",5,false,false,[28,\"action\",[[23,0,[]],\"setBoundaryMapInstance\",[24,[\"boundary\"]],[24,[\"fixtures\"]]],[[\"target\"],[[24,[\"controller\"]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/boundary-map.hbs"
    }
  });
  _exports.default = _default;
});