define("@fixflo/frontend/templates/dialog/zone-finder-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XLaxbf/z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Search for a zone to add\\n    \"],[9],[0,\"\\n    \"],[5,\"model-select\",[],[[\"@selectedModel\",\"@modelName\",\"@searchEnabled\",\"@infiniteScroll\",\"@renderInPlace\",\"@onChange\"],[[24,[\"contextObject\",\"zone\"]],\"zone\",true,false,false,[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"contextObject\",\"zone\"]]],null]],null]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/zone-finder-select.hbs"
    }
  });
  _exports.default = _default;
});