define("@fixflo/frontend/routes/admin/panel", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    /**
     * Inject the socket service
     *
     * @var {Service}
     */
    socket: Ember.inject.service(),
    /**
     * Inject the analytics service
     *
     * @var {Service}
     */
    analytics: Ember.inject.service(),
    /**
     * Inject the logrocket service
     *
     * @var {Service}
     */
    logrocket: Ember.inject.service(),
    /**
     * Inject the sentry service
     *
     * @var {Service}
     */
    sentry: Ember.inject.service(),
    /**
     * Inject the current user service
     *
     * @var {Service}
     */
    currentUser: Ember.inject.service(),
    /**
     * Inject the fetch service
     *
     * @var {Service}
     */
    fetch: Ember.inject.service(),
    /**
     * Check and only allow authenticated users in dashboard
     *
     * @void
     */
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'admin.login');
      return this.loadUser();
    },
    /**
     * Identify the authenticated user to services
     *
     * @void
     */
    identify() {
      // start socket listening for user events
      this.socket.startWatching();
      // track every page view for current user
      this.analytics.trackPageViews();
      // identify session for sentry
      this.sentry.identify();
      // identify session for logrocket
      this.logrocket.identify();
    },
    /**
     * Remind the user when their session is expiring
     *
     * @void
     */
    remindUserSessionEnding() {
      const sessionExpiresAt = this.session.get('data.authenticated.expires_at');
      const momentExpires = _moment.default.unix(sessionExpiresAt);
      const alertUserAt = momentExpires.subtract(5, 'minutes');
      // if not valid
      if (!alertUserAt.isValid()) {
        return;
      }
      let duration = alertUserAt.diff((0, _moment.default)());
      const durationMinutes = Math.round(duration / (60 * 1000));
      duration = durationMinutes <= 5 ? 0 : duration;
      // console.log(alertUserAt, duration, durationMinutes);
      setTimeout(() => {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Your session is about to expire!',
          declineText: 'Continue',
          acceptText: 'Extend Session & Continue',
          message: `Your session is going to expire ${momentExpires.fromNow()}, at approximately ${momentExpires.format('LT')}, you can extend by clicking extend or let it expire.`
        }).then(() => {
          return this.fetch.post('auth/extend-session');
        });
      }, duration);
    },
    /**
     * Handles the loading of the current user via session
     *
     * @void
     */
    async loadUser() {
      await this.session.loadCurrentUser();
      this.identify();
      this.remindUserSessionEnding();
    }
  });
  _exports.default = _default;
});