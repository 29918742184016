define("@fixflo/frontend/components/golden-layout", ["exports", "golden-layout", "array-chunk"], function (_exports, _goldenLayout, _arrayChunk) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const updateLayoutSize = layout => {
    layout.updateSize(document.getElementById('main-container').scrollWidth, document.getElementById('main-container').scrollHeight);
  };
  var _default = Ember.Component.extend({
    /**
     * Class styles
     */
    classNames: ['golden-layout'],
    /**
     * Inject the current user service
     *
     * @var {Service}
     */
    currentUser: Ember.inject.service(),
    /**
     * Inject store service for state saving
     */
    store: Ember.inject.service(),
    /**
     * The golden layout instance
     */
    layout: null,
    /**
     * Layout initialized
     */
    layoutInitialized: false,
    /**
     * Layout changged tracker id
     */
    layoutChanged: null,
    /**
     * Save the layout save
     */
    saveState: function (layout) {
      if (!this.isDestroyed || !this.isDestroying) {
        const state = JSON.stringify(layout.toConfig());
        this.currentUser.user.set('layout_state', state);
        Ember.set(this, 'layoutChanged', Math.random());
        return Ember.run.debounce(this.currentUser.user, 'save', 1000);
      }
    },
    /**
     * Returns the layout state
     */
    layoutState: Ember.computed('currentUser.layout_state', 'layoutChanged', function () {
      return Ember.get(this, 'currentUser.layout_state');
    }),
    /**
     * Widgets to be registered
     */
    widgets: Ember.A([]),
    /**
     * All registered widgets
     */
    registeredWidgets: Ember.computed('layoutChanged', 'layoutInitialized', function () {
      const layoutConfig = this.layoutState;
      const layoutState = JSON.parse(layoutConfig);
      const draggableWidgets = this.draggableWidgets;
      const registered = [];
      draggableWidgets.forEach(widget => {
        if (layoutState && layoutState.content && JSON.stringify(layoutState.content).includes(Ember.get(widget, 'name'))) {
          registered.pushObject(widget);
        }
      });
      return registered;
    }),
    /**
     * All draggable widgets
     */
    draggableWidgets: Ember.A([]),
    /**
     * Register widgets as draggable components as they're added
     */
    // eslint-disable-next-line ember/no-observers
    makeWidgetsDraggable: Ember.observer('widgets.@each', 'layoutChanged', function () {
      const layout = this.layout;
      const widgets = this.widgets;
      const draggableWidgets = this.draggableWidgets;
      widgets.forEach(widget => {
        if (!draggableWidgets.includes(widget) && document.getElementById(`${Ember.get(widget, 'id')}_draggable`)) {
          layout.registerComponent(Ember.get(widget, 'name'), function (container) {
            container.getElement().html(document.getElementById(Ember.get(widget, 'id')));
          });
          // create draggable item available
          layout.createDragSource(document.getElementById(`${Ember.get(widget, 'id')}_draggable`), {
            type: 'component',
            componentName: Ember.get(widget, 'name'),
            componentState: {
              label: Ember.get(widget, 'name'),
              id: Ember.get(widget, 'id')
            }
          });
          // register widget
          draggableWidgets.pushObject(widget);
          // console.log(`[makeWidgetsDraggable] widget ${get(widget, 'name')} is now draggable`);
        }
      });
    }),

    /**
     * If or when a widget is registered hide the dom element
     */
    // eslint-disable-next-line ember/no-observers
    destroyRegisteredWidgetsDraggables: Ember.observer('registeredWidgets.@each', 'layoutChanged', function () {
      const draggableWidgets = this.draggableWidgets;
      const registeredWidgets = this.registeredWidgets;
      draggableWidgets.forEach(widget => {
        if (!registeredWidgets.includes(widget) && document.getElementById(`${Ember.get(widget, 'id')}_draggable`)) {
          document.getElementById(`${Ember.get(widget, 'id')}_draggable`).style.display = 'flex';
        }
      });
      registeredWidgets.forEach(widget => {
        Ember.set(widget, 'isActive', true);
        if (document.getElementById(`${Ember.get(widget, 'id')}_draggable`)) {
          document.getElementById(`${Ember.get(widget, 'id')}_draggable`).style.display = 'none';
        }
      });
    }),
    /**
     * Default config
     */
    config: Ember.computed('widgets', function () {
      const widgets = this.widgets;
      const config = {
        content: [{
          type: 'row',
          content: []
        }]
      };
      // chunk to config
      const widgetColumns = (0, _arrayChunk.default)(widgets.toArray(), 2);
      // iterate into configg
      widgetColumns.forEach(column => {
        const columnConfig = {
          type: 'column',
          content: []
        };
        column.forEach(widget => {
          columnConfig.content.push({
            type: 'component',
            componentName: Ember.get(widget, 'name'),
            componentState: {
              label: Ember.get(widget, 'name')
            }
          });
        });
        // push to main config
        config.content[0].content.push(columnConfig);
      });
      return config;
    }),
    /**
     * Initialize golden layout on dom inserted
     */
    didInsertElement: function () {
      const widgets = this.widgets;
      const draggableWidgets = this.draggableWidgets;
      const config = this.layoutState ? JSON.parse(this.layoutState) : this.config;
      const layout = new _goldenLayout.default(config, this.element);
      // register widgets
      widgets.forEach((widget, index) => {
        layout.registerComponent(Ember.get(widget, 'name'), function (container, componentState) {
          container.getElement().html(document.getElementById(Ember.get(widget, 'id')));
        });
        // create draggable item available
        layout.createDragSource(document.getElementById(`${Ember.get(widget, 'id')}_draggable`), {
          type: 'component',
          componentName: Ember.get(widget, 'name'),
          componentState: {
            label: Ember.get(widget, 'name')
          }
        });
        // save to registed widgets
        draggableWidgets.pushObject(widget);
        // console.log(`[didInsertElement] widget ${get(widget, 'name')} is now draggable`);
      });
      // track state changed
      layout.on('stateChanged', () => {
        if (typeof this.onStateChange === 'function') {
          this.onStateChange();
        }
        this.saveState(layout);
      });
      // initialize layout
      try {
        layout.init();
      } catch (error) {
        if (this.currentUser.user) {
          // delete broken state
          Ember.set(this, 'layoutState', null);
          if (!this.isDestroyed || !this.isDestroying) {
            this.currentUser.user.set('layout_state', null);
            this.currentUser.user.save();
          }
          // retry initialization
          return Ember.run.next(this, function () {
            return this.didInsertElement();
          });
        }
      }
      // send oninit event
      if (typeof this.onInit === 'function') {
        this.onInit(layout);
      }
      // set to component
      Ember.setProperties(this, {
        layout,
        layoutInitialized: true
      });
      // refresh dimensions when sidebar is open or closed
      document.addEventListener('contextbar.open', () => updateLayoutSize(layout));
      document.addEventListener('contextbar.closed', () => updateLayoutSize(layout));
    },
    /**
     * Remove event listeners
     */
    willDestroyElement: function () {
      const layout = this.layout;
      this.currentUser.user.save();
      layout.destroy();
      document.removeEventListener('contextbar.open', () => updateLayoutSize(layout));
      document.removeEventListener('contextbar.closed', () => updateLayoutSize(layout));
    }
  });
  _exports.default = _default;
});