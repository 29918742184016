define("@fixflo/frontend/templates/dialog/view-fixture-activity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "eOOkEFNT",
    "block": "{\"symbols\":[\"activity\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"view-fixture-activity simple-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"activities\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"actity-item\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"details\"],[8],[1,[23,1,[\"details\"]],false],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"date flex-shrink-0 ml-3 text-right\"],[8],[1,[23,1,[\"createdAgo\"]],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"flex flex-row items-center justify-center w-full p-4 font-semibold\"],[8],[0,\"\\n\\t\\tNo activity found\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/view-fixture-activity.hbs"
    }
  });
  _exports.default = _default;
});