define("@fixflo/frontend/templates/dialog/fixtures-add-zone-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "UhEQVjDe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Zone\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-10\"],[8],[0,\"\\n        \"],[5,\"model-select\",[],[[\"@selectedModel\",\"@modelName\",\"@searchEnabled\",\"@infiniteScroll\",\"@renderInPlace\",\"@onChange\"],[[24,[\"contextObject\",\"relation_uuid\"]],\"zone\",true,false,true,[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"contextObject\",\"relation_uuid\"]]],null]],null]]]],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Rate\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-10\"],[8],[0,\"\\n        \"],[5,\"fix-flo/input\",[],[[\"@type\",\"@value\"],[\"text\",[24,[\"contextObject\",\"rate\"]]]]],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/fixtures-add-zone-form.hbs"
    }
  });
  _exports.default = _default;
});