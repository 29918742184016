define("@fixflo/frontend/controllers/dashboard/reports/types", ["exports", "@fixflo/frontend/mixins/manages-report-templates"], function (_exports, _managesReportTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend(_managesReportTemplates.default, {
    actions: {
      /**
       * Forwards and handles actions from table/action components
       */
      tableAction: function (action, model) {
        this.send(action, model);
      },
      /**
       * Sorts the current data listed
       */
      tableSort: function (sorts) {
        // TODO
      },
      /**
       * Create a new report type
       */
      create: async function () {
        const reportType = this.store.createRecord('report-type', {
          filters: {
            confidentialityLevel: []
          },
          name: null
        });
        const confidentialityLevels = this.confidentialityLevels;
        // set dialog presenter to controller
        this.dialog.one('created', createPresenter => this.setProperties({
          createPresenter
        }));
        // render dialog
        this.dialog.open('report-type-form', reportType, {
          title: 'Create a new report type',
          acceptText: 'Save',
          className: 'dialog-xl',
          confidentialityLevels,
          isAddingSource: false,
          isSavingSource: false,
          user: this.currentUser.user,
          controller: this,
          accept: () => {
            // make sure type has sources
            if (Ember.isArray(reportType.type_sources) && reportType.type_sources.length === 0) {
              return this.notifications.warning('Cannot save report type without sources');
            }
            this.createPresenter.startLoading();
            // save report type
            return reportType.save().then(reportType => {
              // save sources
              const sources = reportType.type_sources.map(source => {
                source.set('report_type_uuid', reportType.get('id'));
                return source;
              });
              // save sources in background
              return Ember.RSVP.all(sources.invoke('save')).then(() => {
                this.notifications.success(`New report ${reportType.name} created`);
                this.createPresenter.stopLoading();
                this.createPresenter._accept();
              });
            }).catch(error => {
              this.createPresenter.stopLoading();
              this.notifications.serverError(error);
            });
          },
          decline: () => {
            reportType.destroyRecord();
            this.createPresenter._decline();
          }
        });
      },
      /**
       * Edit a report type
       */
      edit: function (reportType) {
        const confidentialityLevels = this.confidentialityLevels;
        // set dialog presenter to controller
        this.dialog.one('created', editPresenter => this.setProperties({
          editPresenter
        }));
        // render dialog
        this.dialog.open('report-type-form', reportType, {
          title: Ember.String.htmlSafe(`Edit <i>${Ember.get(reportType, 'name')}</i> report type`),
          acceptText: 'Save Changes',
          className: 'dialog-xl',
          confidentialityLevels,
          isAddingSource: false,
          isSavingSource: false,
          controller: this,
          accept: () => {
            // make sure type has sources
            if (Ember.isArray(reportType.type_sources) && reportType.type_sources.length === 0) {
              return this.notifications.warning('Cannot save report type without sources');
            }
            this.editPresenter.startLoading();
            // save changes
            return reportType.save().then(reportType => {
              this.notifications.info(`Changes to report type ${reportType.get('name')} saved`);
              this.editPresenter.stopLoading();
              this.editPresenter._accept();
            }).catch(error => {
              this.editPresenter.stopLoading();
              this.notifications.serverError(error);
            });
          }
        });
      },
      /**
       * Remove a source from report type
       */
      removeSource: function (reportType, source) {
        reportType.type_sources.removeObject(source);
        source.destroyRecord();
      },
      /**
       * Prompt dialog to add report type source
       */
      quickAddSource: function (reportType, board) {
        const source = this.store.createRecord('report-type-source', {
          report_type_uuid: reportType.get('id'),
          board,
          board_uuid: board.get('id')
        });
        // make sure it isnt already a source
        if (reportType.type_sources.includes(source)) {
          return this.notifications.warning('This source has already been added');
        }
        // if the report type is new just queue the source and save after type
        if (reportType.get('isNew')) {
          source.set('board', board);
          return reportType.type_sources.pushObject(source);
        }
        // set the edit or create presenter to saving state for source
        if (this.editPresenter && (!this.editPresenter.isDestroyed || !this.editPresenter.isDestroying)) {
          this.editPresenter.set('isSavingSource', true);
        } else if (this.createPresenter && (!this.createPresenter.isDestroyed || !this.createPresenter.isDestroying)) {
          this.createPresenter.set('isSavingSource', true);
        }
        // save report type
        return source.save().then(source => {
          // add to types then save
          reportType.type_sources.pushObject(source);
          // set the edit or create presenter to saving state for source
          if (this.editPresenter) {
            this.editPresenter.set('isSavingSource', false);
          } else if (this.createPresenter) {
            this.createPresenter.set('isSavingSource', false);
          }
        }).catch(error => {
          this.notifications.serverError(error);
        });
      },
      /**
       * Prompt dialog to add report type source
       */
      addSource: function (reportType) {
        const source = this.store.createRecord('report-type-source', {
          report_type_uuid: reportType.get('id'),
          board_uuid: null
        });
        // set dialog presenter to controller
        this.dialog.one('created', addSourcePresenter => this.setProperties({
          addSourcePresenter
        }));
        // render dialog
        this.dialog.open('report-type-source-form', source, {
          title: 'Select board to source report',
          acceptText: 'Add Source',
          controller: this,
          accept: () => {
            // make sure it isnt already a source
            if (reportType.type_sources.includes(source)) {
              return this.notifications.warning('This source has already been added');
            }
            this.addSourcePresenter.startLoading();
            // save report type
            source.save().then(source => {
              reportType.type_sources.pushObject(source);
              this.addSourcePresenter.stopLoading();
              this.addSourcePresenter._accept();
            }).catch(error => {
              this.addSourcePresenter.stopLoading();
              this.notifications.serverError(error);
            });
          },
          decline: () => {
            source.destroyRecord();
            this.addSourcePresenter._decline();
          }
        });
      },
      /**
       * Delete a report type
       */
      delete: function (reportType) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Delete report type',
          acceptText: 'Confirm and delete',
          message: Ember.String.htmlSafe(`Are you sure you want to delete this report type <i>${Ember.get(reportType, 'name')}</i>?`),
          accept: () => {
            this.dialog.startLoading();
            reportType.destroyRecord().then(() => {
              return this.refreshRoute().then(() => {
                this.dialog.stopLoading();
                this.dialog._close();
                this.notifications.info('Report type deleted');
              });
            }).catch(error => {
              this.dialog.stopLoading();
              this.notifications.serverError(error);
            });
          }
        });
      },
      /**
       * Toggle a selected column
       */
      toggleConfidentialitySelected: function (reportType, level) {
        if (!Ember.isArray(Ember.get(reportType, 'filters.confidentialityLevel'))) {
          Ember.set(reportType, 'filters.confidentialityLevel', []);
        }
        let selected = Ember.get(reportType, 'filters.confidentialityLevel');
        if (selected.includes(level)) {
          selected.removeObject(level);
        } else {
          selected.pushObject(level);
        }
      },
      setTemplateFilter: function (templateModel) {
        this.set('template', templateModel ? templateModel.get('id') : null);
      }
    },
    currentUser: Ember.inject.service(),
    /**
     * Query parameters
     */
    queryParams: ['page', 'limit', 'sort', 'query', 'template'],
    /**
     * Current page
     */
    page: 1,
    /**
     * Default pagination limit
     */
    limit: 10,
    /**
     * Default sorting
     */
    sort: 'created_at',
    /**
     * Optional confidentiality levels for fixtures
     */
    confidentialityLevels: Ember.A([{
      name: 'Public',
      key: 'public'
    }, {
      name: 'Private & Confidential',
      key: 'private'
    }, {
      name: 'Super Private & Confidential',
      key: 'super_private'
    }]),
    /**
     * Columns to display in table
     * @type array
     */
    columns: Ember.A([{
      name: `Name`,
      valuePath: `name`
    }, {
      name: `Template`,
      valuePath: `template_name`
    }, {
      name: `Created`,
      valuePath: `createdAt`
    }, {
      name: `Modified`,
      valuePath: `updatedAt`
    }, {
      name: ``,
      menuItems: [{
        action: 'edit',
        icon: 'icon-content_edit',
        can: false,
        label: 'Edit Report Type'
      }, {
        action: 'delete',
        icon: 'icon-navigation_delete',
        can: false,
        label: 'Delete Report Type'
      }]
    }]),
    /**
     * All report type loaded in the store
     */
    allReportTypes: Ember.computed(function () {
      return this.store.peekAll('report-type');
    }),
    /**
     * Current report types for this company
     */
    reportTypes: Ember.computed('allReportTypes.@each.id', function () {
      return this.allReportTypes.filter(r => !r.get('isNew'));
    })
  });
  _exports.default = _default;
});