define("@fixflo/frontend/instance-initializers/register-handsontable-editors", ["exports", "handsontable", "@fixflo/frontend/handsontable/editors/autocomplete-editor"], function (_exports, _handsontable, _autocompleteEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  function initialize( /* appInstance */
  ) {
    _handsontable.default.editors.registerEditor('fixflo.autocomplete', _autocompleteEditor.default);
  }
  var _default = {
    initialize
  };
  _exports.default = _default;
});