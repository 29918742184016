define("@fixflo/frontend/templates/dialog/loaded-zone-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xo3MzlTB",
    "block": "{\"symbols\":[\"g\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"table table-bordered mb-3\"],[8],[0,\"\\n\\t\"],[7,\"tbody\",true],[8],[0,\"\\n\\t\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\\t\"],[7,\"td\",true],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"strong\",true],[8],[0,\"Name:\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"span\",true],[8],[1,[24,[\"port\",\"relation\",\"name\"]],false],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"td\",true],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"strong\",true],[8],[0,\"Rate:\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"span\",true],[8],[1,[24,[\"port\",\"rate\"]],false],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"g-map\",[],[[\"@lat\",\"@lng\",\"@zoom\",\"@streetViewControl\",\"@mapTypeControl\",\"@onLoad\"],[[24,[\"port\",\"relation\",\"latitude\"]],[24,[\"port\",\"relation\",\"longitude\"]],10,false,false,[28,\"action\",[[23,0,[]],\"setZoneBoundary\",[24,[\"port\"]]],[[\"target\"],[[24,[\"controller\"]]]]]]],{\"statements\":[[0,\"\\n\\t\"],[6,[23,1,[\"marker\"]],[],[[\"@lat\",\"@lng\"],[[24,[\"port\",\"relation\",\"latitude\"]],[24,[\"port\",\"relation\",\"longitude\"]]]]],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/loaded-zone-details.hbs"
    }
  });
  _exports.default = _default;
});