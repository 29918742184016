define("@fixflo/frontend/controllers/dashboard/fixtures/groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      /**
       * Creates a new fixtues group
       *
       * @void
       */
      newGroup: function () {
        const fixtureGroup = this.store.createRecord('fixture-group', {
          name: null,
          status: 'published'
        });
        this.dialog.one('created', presenter => {
          this.set('presenter', presenter);
        });
        this.dialog.open('fixture-group/create-form', fixtureGroup, {
          title: 'New fixtures group',
          acceptText: 'Save',
          acceptIcon: 'fa fa-save',
          accept: () => {
            return fixtureGroup.save().then(fixtureGroup => {
              this.presenter._accept();
              this.notifications.success('Fixtures group created.');
            }).catch(error => {
              this.notifications.serverError(error);
            });
          },
          decline: () => {
            fixtureGroup.deleteRecord();
            this.presenter._decline();
          }
        });
      }
    }
  });
  _exports.default = _default;
});