define("@fixflo/frontend/routes/dashboard/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    beforeModel: function () {
      // temp rediert to boards
      return this.transitionTo('dashboard.boards');
    }
  });
  _exports.default = _default;
});