define("@fixflo/frontend/serializers/cargo-grade", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _rest.default.extend(_rest.EmbeddedRecordsMixin, {
    primaryKey: 'uuid',
    attrs: {
      children: {
        embedded: 'always'
      },
      sub_grades: {
        embedded: 'always'
      }
    },
    serialize(snapshot, options) {
      const json = this._super(...arguments);

      // if pending children is array make sure it's array of id's
      if (Ember.typeOf(snapshot.attr('sub_grades')) === 'array') {
        json.sub_grades = snapshot.attr('sub_grades').map(grade => {
          return grade instanceof Object ? Ember.get(grade, 'uuid') : grade;
        });
      }
      return json;
    }
  });
  _exports.default = _default;
});