define("@fixflo/frontend/templates/dialog/widget-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "OdgRZU1C",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Name\\n    \"],[9],[0,\"\\n    \"],[5,\"fix-flo/input\",[[12,\"placeholder\",\"Give your widget a name\"]],[[\"@type\",\"@value\"],[\"text\",[24,[\"contextObject\",\"name\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Board\\n    \"],[9],[0,\"\\n    \"],[5,\"model-select\",[],[[\"@selectedModel\",\"@modelName\",\"@onChange\"],[[24,[\"contextObject\",\"source_uuid\"]],\"board\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"contextObject\",\"source_uuid\"]]],null]],null]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[0,\"\\n\"],[4,\"if\",[[28,\"can\",[\"share widget\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n        \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n            Share with company\\n        \"],[9],[0,\"\\n        \"],[5,\"x-toggle\",[],[[\"@theme\",\"@size\",\"@value\",\"@onToggle\"],[\"light\",\"small\",[24,[\"contextObject\",\"shared_with_company\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"contextObject\",\"shared_with_company\"]]],null]],null]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/widget-form.hbs"
    }
  });
  _exports.default = _default;
});