define("@fixflo/frontend/utils/object-from-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = objectFromModel;
  function objectFromModel(model, callback = null, removeProps = []) {
    removeProps = typeof callback === 'array' ? callback : removeProps;
    const object = {};
    const exclude = [...removeProps, 'store', 'becameError', 'becameInvalid', 'currentState', '_internalModel', 'errors', 'ready', 'rolledBack', 'adapterError', 'didCreate', 'didLoad', 'didDelete', 'didUpdate', 'isDeleted', 'isEmpty', 'isError', 'isLoaded', 'isLoading', 'isNew', 'isReloading', 'isSaving', 'isValid'];
    for (let prop in model) {
      if (exclude.includes(prop)) {
        continue;
      }
      if (typeof model[prop] === 'string' || typeof model[prop] === 'object' || typeof model[prop] === 'array' || typeof model[prop] === 'boolean') {
        if (typeof callback === 'function') {
          object[prop] = callback(prop, model[prop]);
        } else {
          object[prop] = model[prop];
        }
      }
    }
    return object;
  }
});