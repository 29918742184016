define("@fixflo/frontend/controllers/dashboard/settings/diff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      /**
       * Use Me, deletes the duplicate and unflags the other
       */
      deleteDuplicate: function (useMe, deleteMe) {
        this.dialog._confirm({
          title: 'Remove Duplicate',
          acceptText: 'Confirm and delete',
          message: `Are you sure you want to use this fixture?`
        }).then(() => {
          return this.fetch.post('actions/select-from-duplicates', {
            data: {
              fixtureToUse: Ember.get(useMe, 'uuid'),
              fixtureToDelete: Ember.get(deleteMe, 'uuid')
            }
          }).then(() => {
            return this.get('target.targetState.router').refresh().then(() => {
              this.notifications.success('Duplicate removed');
            });
          }).catch(error => {
            this.notifications.serverError(error);
          });
        });
      }
    }
  });
  _exports.default = _default;
});