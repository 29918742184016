define("@fixflo/frontend/controllers/admin/panel/users/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      /**
       * Create a new user
       */
      create: async function () {
        const user = this.store.createRecord('user', {
          name: null,
          type: 'user'
        });
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // render dialog
        this.dialog.open('user-form', user, {
          title: 'Create new user',
          acceptText: 'Save',
          controller: this,
          accept: () => {
            user.save().then(user => {
              this.notifications.success('New user added');
              this.presenter._accept();
            });
          },
          decline: () => {
            user.destroyRecord();
            this.presenter._decline();
          }
        });
      },
      /**
       * Edit a user
       */
      edit: async function (user) {
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // render dialog
        this.dialog.open('user-form', user, {
          title: Ember.String.htmlSafe(`Edit user <i>${Ember.get(user, 'name')}</i>`),
          acceptText: 'Save Changes',
          controller: this,
          accept: () => {
            user.save().then(user => {
              this.notifications.success('Changes to user saved');
              this.presenter._accept();
            });
          }
        });
      },
      /**
       * Delete a user
       */
      delete: function (user) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Delete user',
          acceptText: 'Confirm and delete',
          message: Ember.String.htmlSafe(`Are you sure you want to delete this user <i>${Ember.get(user, 'name')}</i>?`)
        }).then(() => {
          user.destroyRecord().then(() => {
            this.notifications.info('User deleted');
          }).catch(error => {
            this.notifications.serverError(error);
          });
        });
      },
      /**
       * Filter by company
       */
      filterByCompany: function (company) {
        this.set('company', company ? Ember.get(company, 'id') : null);
      }
    },
    /**
     * Query parameters
     */
    queryParams: ['page', 'limit', 'sort', 'query', 'role'],
    /**
     * Current page
     */
    page: 1,
    /**
     * Default pagination limit
     */
    limit: 10,
    /**
     * Default sorting
     */
    sort: '-created_at',
    /**
     * The filtered company
     */
    filteredCompany: Ember.computed('company', function () {
      if (this.company) {
        return this.store.findRecord('company', this.company);
      }
      return null;
    })
  });
  _exports.default = _default;
});