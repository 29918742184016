define("@fixflo/frontend/services/socket", ["exports", "howler", "socketcluster", "ember-get-config"], function (_exports, _howler, _socketcluster, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    /**
     * Initialize socket cluster
     */
    init() {
      this._super(...arguments);
      // create socket instance
      const socket = _socketcluster.default.create(_emberGetConfig.default.socket);
      // create alias methods for this service
      this.on = socket.on.bind(socket);
      this.subscribe = socket.subscribe.bind(socket);
    },
    /**
     * Inject the notifications service
     *
     * @var {Service}
     */
    notifications: Ember.inject.service(),
    /**
     * Inject the current user service
     *
     * @var {Service}
     */
    currentUser: Ember.inject.service(),
    /**
     * The instance of the chat notification ping
     */
    chatPing: Ember.computed(function () {
      return new _howler.Howl({
        src: ['/sounds/noty.mp3']
      });
    }),
    /**
     * Start the listening session for the current authenticated company and user channel
     *
     * @void
     */
    startWatching() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        // watch for company wide events then handle
        this.on('connect', () => {
          const channels = {
            company: this.subscribe(this.currentUser.companyId),
            user: this.subscribe(this.currentUser.id)
          };
          // // watch company channel
          // channels.company.watch((event) => {
          //     // console.log('[company event]', event);
          // });
          // watch user channel
          channels.user.watch(event => {
            // watch for chat messag events
            if (Ember.get(event, 'evt') === 'chat_message.created' && Ember.get(event, 'recipients').includes(this.currentUser.id)) {
              // play the chat ping noise and show a notification
              this.chatPing.play();
              // notification
              this.notifications.info(`${Ember.get(event, 'user_name')} sent you a chat message`);
            }
          });
        });
      });
    }
  });
  _exports.default = _default;
});