define("@fixflo/frontend/templates/dialog/fixtures-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gxT0ImWg",
    "block": "{\"symbols\":[\"sb\",\"option\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label required\"],[8],[0,\"\\n        Name\\n    \"],[9],[0,\"\\n    \"],[5,\"fix-flo/input\",[],[[\"@type\",\"@value\",\"@placeholder\"],[\"text\",[24,[\"contextObject\",\"name\"]],\"Fixtures Import Name\"]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Status\\n    \"],[9],[0,\"\\n    \"],[5,\"native-select-box\",[[12,\"class\",\"fixflo-input form-select\"]],[[\"@value\",\"@onSelect\"],[[24,[\"contextObject\",\"status\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"contextObject\",\"status\"]]],null]],null]]],{\"statements\":[[0,\"\\n        \"],[6,[23,1,[\"Option\"]],[],[[\"@value\",\"@selected\"],[null,true]],{\"statements\":[[0,\"\\n            Select status\\n        \"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"each\",[[24,[\"statusOptions\"]]],null,{\"statements\":[[0,\"            \"],[6,[23,1,[\"Option\"]],[],[[\"@value\"],[[23,2,[]]]],{\"statements\":[[0,\"\\n                \"],[1,[28,\"humanize\",[[23,2,[]]],null],false],[0,\"\\n            \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"]],\"parameters\":[1]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/fixtures-form.hbs"
    }
  });
  _exports.default = _default;
});