define("@fixflo/frontend/serializers/fixture-value", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _rest.default.extend(_rest.EmbeddedRecordsMixin, {
    primaryKey: 'uuid',
    attrs: {
      zone: {
        embedded: 'always'
      }
    },
    serialize(snapshot, options) {
      const json = this._super(...arguments);

      // if relation_uuid is a zone model we know to set id and entry
      if (snapshot.attr('relation_uuid') instanceof Object) {
        json.entry = snapshot.attr('relation_uuid').get('system_display_name');
        json.relation_uuid = snapshot.attr('relation_uuid').get('id');
      }

      // always set value to entry
      json.value = json.display_entry ? json.display_entry : json.entry;
      return json;
    }
  });
  _exports.default = _default;
});