define("@fixflo/frontend/services/staged-changes", ["exports", "ember-local-storage", "@fixflo/frontend/utils/is-empty-object"], function (_exports, _emberLocalStorage, _isEmptyObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    /**
     * Set the storage adapter for staged chanves
     *
     * @var {StorageObject}
     */
    data: (0, _emberLocalStorage.storageFor)('staged'),
    /**
     * Inject the ajax service to commit staged changes
     *
     * @var {Service}
     */
    ajax: Ember.inject.service(),
    /**
     * Inject the current user service to track user and company details on commits
     *
     * @var {Service}
     */
    currentUser: Ember.inject.service(),
    /**
     * Track a model to be saved
     *
     * @void
     */
    track: function (commit, changes = {}, row = null, prop = null) {
      const date = new Date();
      const user = this.currentUser.id;
      const tracked_at = date.toUTCString();
      const id = date.valueOf();
      this.data.set(`${user}_${id}_stagedCommit`, {
        id,
        commit,
        changes,
        row,
        prop,
        user,
        tracked_at
      });
    },
    /**
     * Get all commits for the current user
     *
     * @return array
     */
    getUserCommits() {
      const userId = this.currentUser.id;
      const allCommits = this.data.content;
      const userCommits = [];
      for (let id in allCommits) {
        if (allCommits.hasOwnProperty(id) && id.contains(userId)) {
          userCommits.pushObject(allCommits[id]);
        }
      }
      return userCommits;
    },
    /**
     * Get the number of uncomitted changes
     *
     * @var integer
     */
    get numberOfChanges() {
      return Object.values(this.data.content).length;
    },
    /**
     * Commit saved changes to server
     *
     * @void
     */
    commit: function () {
      const commits = this.data.content;
      if (!commits || (0, _isEmptyObject.default)(commits) || Ember.isEmpty(commits)) {
        return;
      }
      return this.fetch.patch('actions/bulk-commit', {
        data: {
          commits
        }
      }).then(response => {
        this.data.clear();
        return response;
      });
    }
  });
  _exports.default = _default;
});