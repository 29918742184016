define("@fixflo/frontend/helpers/is-active", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.extend({
    router: Ember.inject.service(),
    compute([routeName, models]) {
      return this.router.isActive(routeName, models);
    },
    didTransition: Ember.observer('router.currentRoute', function () {
      this.recompute();
    })
  });
  _exports.default = _default;
});