define("@fixflo/frontend/serializers/zone", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _rest.default.extend(_rest.EmbeddedRecordsMixin, {
    primaryKey: 'uuid',
    attrs: {
      boundaries: {
        embedded: 'always'
      }
    }
  });
  _exports.default = _default;
});