define("@fixflo/frontend/helpers/n-a", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nA = nA;
  _exports.default = void 0;
  function nA([defaultValue, displayValue = 'Not set']) {
    return defaultValue ? defaultValue : displayValue;
  }
  var _default = Ember.Helper.helper(nA);
  _exports.default = _default;
});