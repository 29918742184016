define("@fixflo/frontend/utils/cp/outline-class", ["exports", "fixflo-ui/utils/cp/outline-class"], function (_exports, _outlineClass) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _outlineClass.default;
    }
  });
});