define("@fixflo/frontend/routes/dashboard/reports/types", ["exports", "ember-cli-pagination/remote/route-mixin", "@fixflo/frontend/mixins/track-reloads"], function (_exports, _routeMixin, _trackReloads) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_routeMixin.default, _trackReloads.default, {
    actions: {
      loading: function (transition, originRoute) {
        if (this._trackedReloads > 0) {
          return false;
        }
        return true;
      }
    },
    queryParams: {
      query: {
        refreshModel: true
      },
      perPage: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      template: {
        refreshModel: true
      }
    },
    model: function (params) {
      params.paramMapping = {
        perPage: 'limit',
        total_pages: 'last_page'
      };
      return this.findPaged('report-type', params);
    }
  });
  _exports.default = _default;
});