define("@fixflo/frontend/controllers/dashboard/settings/index/confidentiality-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      /**
       * Save confidentiality settings
       */
      saveConfidentialitySettings: function () {
        this.set('savingSettings', true);
        Ember.RSVP.all(this.userConfidentialitySettings.invoke('save')).then(r => {
          this.set('savingSettings', false);
          this.notifications.success('Confidentiality settings saved.');
        });
      },
      /**
       * Set confidentiality level color
       *
       * @void
       */
      setColor(levelSetting, property, color) {
        levelSetting[property] = color.toRGBA().toString();
      }
    },
    /**
     * Optional confidentiality levels for fixtures
     */
    confidentialityLevels: Ember.A([{
      name: 'Public',
      key: 'public'
    }, {
      name: 'Private & Confidential',
      key: 'private'
    }, {
      name: 'Super Private & Confidential',
      key: 'super_private'
    }]),
    /**
     * User's confidential settings
     */
    userConfidentialitySettings: Ember.computed('confidentialityLevels.@each.key', function () {
      return this.confidentialityLevels.map(level => {
        let setting = this.store.peekAll('user-confidentiality-setting').find(s => {
          return s.level === level.key;
        });
        if (!setting) {
          setting = this.store.createRecord('user-confidentiality-setting', {
            level: level.key,
            background_color: 'rgba(255, 255, 255, 1)',
            font_color: 'rgba(0, 0, 0, 1)'
          });
        }
        // set the confidentiality level name to the setting
        setting.name = level.name;
        // send
        return setting;
      });
    })
  });
  _exports.default = _default;
});