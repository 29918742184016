define("@fixflo/frontend/initializers/link-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  function initialize() {
    Ember.LinkComponent.reopen({
      /**
       * Add additional attributes
       *
       * @var array
       */
      attributeBindings: ['data-toggle', 'data-placement', 'aria-haspopup', 'aria-expanded']
    });
  }
  var _default = {
    initialize
  };
  _exports.default = _default;
});