define("@fixflo/frontend/controllers/dashboard/settings/vessels", ["exports", "ember-light-table", "ember-inflector"], function (_exports, _emberLightTable, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let DashboardSettingsVesselsController = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, (_class = (_temp = class DashboardSettingsVesselsController extends Ember.Controller {
    /**
     * On initializtion create instance of the light table
     *
     * @void
     */
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "queryParams", _descriptor2, this);
      _initializerDefineProperty(this, "page", _descriptor3, this);
      _initializerDefineProperty(this, "limit", _descriptor4, this);
      _initializerDefineProperty(this, "sort", _descriptor5, this);
      _initializerDefineProperty(this, "fleet", _descriptor6, this);
      _initializerDefineProperty(this, "fleets", _descriptor7, this);
      _initializerDefineProperty(this, "columns", _descriptor8, this);
      this.table = _emberLightTable.default.create({
        columns: this.columns
      }, {
        enableSync: true
      });
    }

    /**
     * Inject the `currentUser` service
     *
     * @var {Service}
     */

    /**
     * Create a vessel
     */
    create() {
      this.crud.createVessel({
        company_uuid: this.currentUser.companyId
      });
    }

    /**
     * Edit a vessel
     */
    edit(vessel) {
      this.crud.editVessel(vessel);
    }

    /**
     * Deletes a vessel
     *
     * @void
     */
    delete(vessel) {
      this.crud.delete(vessel, 'vessel', () => {
        this.table.removeRow(vessel);
      });
    }

    /**
     * Toggles server side sorting
     */
    toggleSort(sort) {
      const currentSort = this.sort;
      if (currentSort && currentSort.includes(sort) && currentSort.startsWith('-')) {
        this.setProperties({
          sort: currentSort.substr(1)
        });
      } else {
        this.setProperties({
          sort: `-${sort}`
        });
      }
    }

    /**
     * Query parameters
     *
     * @var {Array}
     */

    /**
     * Current page
     *
     * @var {Integer}
     */

    /**
     * Default pagination limit
     *
     * @var {Integer}
     */

    /**
     * Default sorting
     *
     * @var {String}
     */

    /**
     * Filter by fleet
     *
     * @var {String|Null}
     */

    /**
     * All fleet options
     *
     * @var {Array}
     */

    /**
     * Columns for table
     *
     * @var {Array}
     */

    // // /**
    // //  * Togggles all checked
    // //  */

    // // toggleCheckAll: observer('checkAll', function () {
    // //     if (this.checkAll) {
    // //         this.model.forEach((i) => set(i, 'checked', true));
    // //     } else {
    // //         this.model.forEach((i) => set(i, 'checked', false));
    // //     }
    // // })
  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "create", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "create"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "edit", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleSort", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSort"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "queryParams", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return ['page', 'limit', 'sort', 'query', 'fleet'];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 30;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sort", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '-created_at';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fleet", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "fleets", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "columns", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([{
        label: 'Name',
        valuePath: 'name',
        width: '50%',
        sortable: false
      }, {
        label: 'Fleet',
        valuePath: 'fleet',
        width: '20%',
        sortable: false
      }, {
        label: 'Last Modified',
        valuePath: 'updatedAt',
        width: '20%',
        sortable: false
      }, {
        label: '',
        valuePath: null,
        sortable: false,
        width: '10%',
        cellComponent: 'cell/dropdown-menu',
        cellClassNames: 'dropdown-menu-cell',
        align: 'right',
        items: [{
          label: 'Edit...',
          action: this.edit
        }, {
          label: 'Delete...',
          action: this.delete,
          className: 'text-red-700 hover:text-red-800'
        }]
      }]);
    }
  })), _class));
  _exports.default = DashboardSettingsVesselsController;
});