define("@fixflo/frontend/templates/dialog/report-type-source-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8DsIC8RQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Select board to use\\n    \"],[9],[0,\"\\n    \"],[5,\"model-select\",[],[[\"@placeholder\",\"@selectedModel\",\"@searchEnabled\",\"@infiniteScroll\",\"@renderInPlace\",\"@modelName\",\"@onChange\"],[\"Select a board to add to report\",[24,[\"contextObject\",\"board_uuid\"]],true,false,true,\"board\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"contextObject\",\"board_uuid\"]]],null]],null]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/report-type-source-form.hbs"
    }
  });
  _exports.default = _default;
});