define("@fixflo/frontend/templates/components/golden-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FoZwYvQ1",
    "block": "{\"symbols\":[\"widget\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"widgets\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"widgets\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"source\",\"type\"]],\"free\"],null]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[11,\"id\",[23,1,[\"id\"]]],[10,\"class\",\"widget\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[5,\"free-table-board\",[],[[\"@source\",\"@goldenlayout\"],[[23,1,[\"source\"]],[22,\"layout\"]]]],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[11,\"id\",[23,1,[\"id\"]]],[10,\"class\",\"widget\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[5,\"fixture-board\",[],[[\"@source\",\"@goldenlayout\",\"@isActive\",\"@widget\"],[[23,1,[\"source\"]],[22,\"layout\"],[23,1,[\"isActive\"]],[23,1,[]]]]],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/components/golden-layout.hbs"
    }
  });
  _exports.default = _default;
});