define("@fixflo/frontend/services/session", ["exports", "ember-simple-auth/services/session", "moment"], function (_exports, _session, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let SessionService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class SessionService extends _session.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _defineProperty(this, "redirectTo", 'dashboard.boards');
    }
    /**
     * Inject the router service
     *
     * @var {Service}
     */
    /**
     * Inject the current user service
     *
     * @var {Service}
     */
    /**
     * Set where to transition to
     *
     * @var {String}
     */
    /**
     * Overwrite the handle authentication method
     *
     * @void
     */
    async handleAuthentication() {
      const loaderNode = this.showLoader('Starting session...');
      this.router.transitionTo(this.redirectTo).then(() => {
        setTimeout(() => {
          // remove node from body after 300ms
          document.body.removeChild(loaderNode);
        }, 300);
      });
    }

    /**
     * Loads the current authenticated user
     *
     * @void
     */
    async asyncloadCurrentUser() {
      try {
        const user = await this.currentUser.load();
        if (!user) {
          return this.invalidateWithLoader(`Session authentication failed...`);
        }
        return user;
      } catch (error) {
        await this.invalidateWithLoader(error.message || `Session authentication failed...`);
      }
    }

    /**
     * Loads the current authenticated user
     *
     * @param {Transition} transition
     * @void
     */
    loadCurrentUser(transition = null) {
      const invalidateWithLoader = this.invalidateWithLoader.bind(this);
      return new Promise((resolve, reject) => {
        this.currentUser.promiseUser().then(user => {
          if (!user) {
            if (transition !== null) {
              transition.abort();
            }
            reject(invalidateWithLoader(`Session authentication failed...`));
          }
          resolve(user);
        }).catch(error => {
          if (transition !== null) {
            transition.abort();
          }
          reject(invalidateWithLoader(error.message || `Session authentication failed...`));
        });
      });
    }

    /**
     * Creates an HTML element node for a loading overlay with a message.
     *
     * @param {String} loadingMessage
     * @return {HTMLElement} loader
     */
    showLoader(loadingMessage) {
      const loader = document.createElement('div');
      loader.classList.add('overloader');
      loader.innerHTML = `<div class="flex items-center justify-center">
            <div>
                <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" role="img" focusable="false" aria-hidden="true" data-icon="spinner-third" data-prefix="fad" id="ember240" class="svg-inline--fa fa-spinner-third fa-w-16 fa-spin ember-view text-sky-500 fa-spin-800ms mr-3"><g class="fa-group"><path class="fa-secondary" fill="currentColor" d="M478.71 364.58zm-22 6.11l-27.83-15.9a15.92 15.92 0 0 1-6.94-19.2A184 184 0 1 1 256 72c5.89 0 11.71.29 17.46.83-.74-.07-1.48-.15-2.23-.21-8.49-.69-15.23-7.31-15.23-15.83v-32a16 16 0 0 1 15.34-16C266.24 8.46 261.18 8 256 8 119 8 8 119 8 256s111 248 248 248c98 0 182.42-56.95 222.71-139.42-4.13 7.86-14.23 10.55-22 6.11z"></path><path class="fa-primary" fill="currentColor" d="M271.23 72.62c-8.49-.69-15.23-7.31-15.23-15.83V24.73c0-9.11 7.67-16.78 16.77-16.17C401.92 17.18 504 124.67 504 256a246 246 0 0 1-25 108.24c-4 8.17-14.37 11-22.26 6.45l-27.84-15.9c-7.41-4.23-9.83-13.35-6.2-21.07A182.53 182.53 0 0 0 440 256c0-96.49-74.27-175.63-168.77-183.38z"></path></g>
                </svg>
            </div>

            <span class="font-semibold text-white test-base">${loadingMessage}</span>
        </div>`;
      document.body.appendChild(loader);
      return loader;
    }

    /**
     * Invalidates the current session while displaying a loading message on the page.
     *
     * @param {String} loadingMessage
     * @return {Promise}
     */
    invalidateWithLoader(loadingMessage = 'Ending session...') {
      const loaderNode = this.showLoader(loadingMessage);
      return this.session.invalidate().then(() => {
        setTimeout(() => {
          document.body.removeChild(loaderNode);
        }, 600);
      });
    }

    /**
     * Set the redirect route after authentication
     *
     * @void
     */
    setRedirect(whereTo = 'dashboard') {
      this.redirectTo = whereTo;
    }

    /**
     * Get session time expiry date in moment
     *
     * @return {Date}
     */
    getExpiresAtDate() {
      const sessionExpiresAt = this.get('data.authenticated.expires_at');
      return _moment.default.unix(sessionExpiresAt);
    }

    /**
     * Get session time expiry in seconds
     *
     * @return {Integer}
     */
    getSessionSecondsRemaining() {
      const date = this.getExpiresAtDate();
      const duration = date.diff((0, _moment.default)());
      return Math.round(duration / 1000);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SessionService;
});