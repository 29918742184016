define("@fixflo/frontend/controllers/dashboard", ["exports", "@fixflo/frontend/utils/is-model"], function (_exports, _isModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let DashboardController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.alias('currentUser.user'), _dec4 = Ember.computed.alias('sidebarTabs.activeTabs'), _dec5 = Ember._action, _dec6 = Ember._action, (_class = (_temp = class DashboardController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "sidebarTabs", _descriptor2, this);
      _initializerDefineProperty(this, "user", _descriptor3, this);
      _initializerDefineProperty(this, "activeTabs", _descriptor4, this);
      _defineProperty(this, "navItems", Ember.A([{
        route: 'dashboard.boards.index',
        title: 'Boards',
        icon: 'si si-layers'
      }, {
        route: 'dashboard.fixtures.index',
        title: 'Workspace',
        icon: 'si si-drawer'
      }, {
        route: 'dashboard.reports.index',
        title: 'Reports',
        icon: 'si si-doc'
      }]));
    }
    /**
     * Inject the `currentUser` service.
     *
     * @var {Service}
     */
    /**
     * Inject the `sidebarTabs` service.
     *
     * @var {Service}
     */
    /**
     * Alias the currently authenticated user.
     *
     * @var {Model}
     */
    /**
     * The active tabs from the sidebarTabs service.
     *
     * @var {Model}
     */
    /**
     * Opens a sidebar tab.
     * 
     * @void
     */
    transitionToTab(tab) {
      let {
        board
      } = tab.data;
      board = (0, _isModel.default)(board) ? board : this.store.push(this.store.normalize('board', board));
      return this.transitionToRoute('dashboard.boards.view', board, {
        queryParams: {
          _tab: tab.id
        }
      });
    }

    /**
     * Close a sidebar tab.
     * 
     * @void
     */
    closeTab(tab) {
      let {
        board
      } = tab.data;
      board = (0, _isModel.default)(board) ? board : this.store.push(this.store.normalize('board', board));
      let tabIndex = this.sidebarTabs.getTabIndex(tab.id);
      if (tabIndex >= 0) {
        this.sidebarTabs.removeTab(tabIndex);

        // check if current board is equal to removed board, redirect to boards index
        this.transitionToRoute('dashboard.boards.index');
      }
    }

    /**
     * Navigation items.
     *
     * @var {Array}
     */
  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sidebarTabs", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "activeTabs", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "transitionToTab", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToTab"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeTab", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "closeTab"), _class.prototype)), _class));
  _exports.default = DashboardController;
  ;
});