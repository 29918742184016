define("@fixflo/frontend/components/right-click-deletable", ["exports", "@roncodes/context-menu"], function (_exports, _contextMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Default class name
     */
    classNames: ['right-click-deletable'],
    /**
     * Attribute bindings
     */
    attributeBindings: ['data-toggle'],
    /**
     * The record to be deleted
     */
    record: null,
    /**
     * Inject notifications server
     */
    notifications: Ember.inject.service(),
    /**
     * Hide menu on outside document click
     */
    hideMenu: function (event) {
      if (event.target && !event.target.classList.contains('ContextMenu')) {
        this.hide();
      }
    },
    /**
     * Optional action to be trigggered on click
     */
    click: function () {
      if (this.clickAction) {
        this.clickAction();
      }
    },
    /**
     * Initialize context menu for element
     */
    didInsertElement: function () {
      const menu = new _contextMenu.default([{
        name: 'Delete',
        fn: target => {
          // get the record
          const record = this.record;
          // if record found delete it
          if (record) {
            // remove toolltips if any
            document.querySelectorAll('.tooltip.show').forEach(tooltip => {
              tooltip.remove();
            });
            // then destroy the record
            record.destroyRecord().then(record => {
              // remove this element immediate visual effect
              this.element.remove();
            }).catch(error => {
              this.notifications.serverError(error);
            });
          }
        }
      }]);
      // set menu instance to component
      Ember.set(this, 'menu', menu);
      // show context menu on right click
      this.element.addEventListener('contextmenu', function (event) {
        event.preventDefault();
        menu.show(event);
      });
      // add listener to close menu outside target
      document.addEventListener('click', this.hideMenu.bind(menu));
    },
    /**
     * Remove document event listener on destroy
     */
    willDestroyElement: function () {
      const menu = this.menu;
      document.removeEventListener('click', this.hideMenu.bind(menu));
    }
  });
  _exports.default = _default;
});