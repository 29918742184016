define("@fixflo/frontend/templates/dialog/fixtures-raw-text-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jspHd/eL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"fixtureInput\"],[10,\"contenteditable\",\"\"],[10,\"placeholder\",\"Copy and paste or drag and drop fixtures data here\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/fixtures-raw-text-form.hbs"
    }
  });
  _exports.default = _default;
});