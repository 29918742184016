define("@fixflo/frontend/services/current-user", ["exports", "ember-local-storage", "moment"], function (_exports, _emberLocalStorage, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let CurrentUserService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _emberLocalStorage.storageFor)('user-options'), _dec4 = Ember.computed.alias('user.id'), _dec5 = Ember.computed.alias('user.name'), _dec6 = Ember.computed.alias('user.email'), _dec7 = Ember.computed.alias('user.phone'), _dec8 = Ember.computed.alias('user.ip_address'), _dec9 = Ember.computed.alias('user.company_uuid'), _dec10 = Ember.computed.alias('user.company_name'), _dec11 = Ember.computed.notEmpty('id'), _dec12 = Ember.computed('id'), (_class = (_temp = class CurrentUserService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "options", _descriptor3, this);
      _initializerDefineProperty(this, "id", _descriptor4, this);
      _initializerDefineProperty(this, "name", _descriptor5, this);
      _initializerDefineProperty(this, "email", _descriptor6, this);
      _initializerDefineProperty(this, "phone", _descriptor7, this);
      _initializerDefineProperty(this, "ip", _descriptor8, this);
      _initializerDefineProperty(this, "companyId", _descriptor9, this);
      _initializerDefineProperty(this, "companyName", _descriptor10, this);
      _initializerDefineProperty(this, "isLoaded", _descriptor11, this);
    }
    /**
     * Inject the `session` service
     *
     * @var {Service}
     */
    /**
     * Inject the `store` service
     *
     * @var {Service}
     */
    /**
     * User options in localStorage
     *
     * @var StorageObject
     */
    /**
     * Alias for current user id
     *
     * @var {String}
     */
    /**
     * Alias for current user name
     *
     * @var {String}
     */
    /**
     * Alias for current user email
     *
     * @var {String}
     */
    /**
     * Alias for current user phone
     *
     * @var {String}
     */
    /**
     * Alias for current user ip
     *
     * @var {String}
     */
    /**
     * Alias for current user's company id
     *
     * @var {String}
     */
    /**
     * Alias for current user's company id
     *
     * @var {String}
     */
    /**
     * Determines if the user has already been loaded
     *
     * @var {Boolean}
     */
    /**
     * Loads the current authenticated user
     *
     * @void
     */
    async load() {
      if (this.session.isAuthenticated) {
        let user = await this.store.queryRecord('user', {
          me: true
        });
        this.set('user', user);
      }
    }

    /**
     * Resolves a user model.
     *
     * @return {Promise}
     */
    promiseUser() {
      const NoUserAuthenticatedError = new Error('Failed to authenticate user.');
      return new Promise((resolve, reject) => {
        if (this.session.isAuthenticated) {
          return this.store.queryRecord('user', {
            me: true
          }).then(user => {
            this.set('user', user);
            resolve(user);
          }).catch(() => {
            reject(NoUserAuthenticatedError);
          });
        }
        reject(NoUserAuthenticatedError);
      });
    }

    /**
     * The prefix for this user options
     *
     * @var {String}
     */
    get optionsPrefix() {
      return `${this.id}:`;
    }

    /**
     * Sets a user's option in local storage
     *
     * @param {String} key
     * @param {Mixed} value
     * @return {CurrentUserService}
     */
    setOption(key, value) {
      key = `${this.optionsPrefix}${Ember.String.dasherize(key)}`;
      this.options.set(key, value);
      return this;
    }

    /**
     * Retrieves a user option from local storage
     *
     * @param {String} key
     * @return {Mixed}
     */
    getOption(key) {
      key = `${this.optionsPrefix}${Ember.String.dasherize(key)}`;
      return this.options.get(key);
    }

    /**
     * Checks if an option exists in users local storage
     *
     * @param {String} key
     * @return {Boolean}
     */
    hasOption(key) {
      key = `${this.optionsPrefix}${Ember.String.dasherize(key)}`;
      return this.getOption(key) !== undefined;
    }
  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "options", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "id", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "email", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "phone", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "ip", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "companyId", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "companyName", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isLoaded", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "optionsPrefix", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "optionsPrefix"), _class.prototype)), _class));
  _exports.default = CurrentUserService;
});