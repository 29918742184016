define("@fixflo/frontend/routes/admin/panel/vessels/create", ["exports", "@fixflo/frontend/helpers/generate-relation-object"], function (_exports, _generateRelationObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    templateName: 'admin.panel.vessels.edit',
    controllerName: 'admin.panel.vessels.edit',
    model: function (params) {
      return this.store.createRecord('vessel', (0, _generateRelationObject.generateRelationObject)(['vessel']));
    },
    afterModel: function (model) {
      console.log(model);
    }
  });
  _exports.default = _default;
});