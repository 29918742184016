define("@fixflo/frontend/routes/admin/panel/companies/index", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_routeMixin.default, {
    queryParams: {
      query: {
        refreshModel: true
      },
      perPage: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    model: function (params) {
      params.paramMapping = {
        perPage: 'limit',
        total_pages: 'last_page'
      };
      return this.findPaged('company', params);
    }
  });
  _exports.default = _default;
});