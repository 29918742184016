define("@fixflo/frontend/components/context-menu-element", ["exports", "@roncodes/context-menu"], function (_exports, _contextMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Default class name
     */
    classNames: ['context-menu-element'],
    /**
     * The menu options, which should be array containing of hash of name and fn
     *
     * [
     * 	{
     * 		name: 'Item Name',
     * 		fn: () => {}
     * 	}
     * ]
     *
     * @param array
     */
    menuOptions: [],
    /**
     * Hide menu on outside document click
     */
    hideMenu: function (event) {
      if (event.target && !event.target.classList.contains('ContextMenu')) {
        this.hide();
      }
    },
    /**
     * Optional action to be trigggered on click
     */
    click: function () {
      if (this.clickAction) {
        this.clickAction();
      }
    },
    /**
     * Initialize context menu for element
     */
    didInsertElement: function () {
      const menu = new _contextMenu.default(this.menuOptions);
      // set menu instance to component
      Ember.set(this, 'menu', menu);
      // show context menu on right click
      this.element.addEventListener('contextmenu', function (event) {
        event.preventDefault();
        menu.show(event);
      });
      // add listener to close menu outside target
      document.addEventListener('click', this.hideMenu.bind(menu));
    },
    /**
     * Remove document event listener on destroy
     */
    willDestroyElement: function () {
      const menu = this.menu;
      document.removeEventListener('click', this.hideMenu.bind(menu));
    }
  });
  _exports.default = _default;
});