define("@fixflo/frontend/templates/dialog/cargo-grade-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3rshxSjW",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Name\\n    \"],[9],[0,\"\\n    \"],[5,\"fix-flo/input\",[],[[\"@placeholder\",\"@type\",\"@value\"],[\"Cargo grade name\",\"text\",[24,[\"contextObject\",\"name\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Display Name\\n    \"],[9],[0,\"\\n    \"],[5,\"fix-flo/input\",[],[[\"@placeholder\",\"@type\",\"@value\"],[\"Optionally enter a display name\",\"text\",[24,[\"contextObject\",\"display_name\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Aliases\\n    \"],[9],[0,\"\\n    \"],[5,\"tag-input\",[[12,\"class\",\"fixflo-input\"]],[[\"@tags\",\"@addTag\",\"@removeTagAtIndex\",\"@\",\"@allowSpacesInTags\",\"@placeholder\"],[[24,[\"contextObject\",\"aliases\"]],[22,\"addAlias\"],[22,\"removeAlias\"],\"\",true,\"Add alias names\"]],{\"statements\":[[0,\"\\n        \"],[1,[23,1,[]],false],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"\\n        Sub Cargo Grades\\n    \"],[9],[0,\"\\n    \"],[5,\"model-select-multiple\",[[12,\"class\",\"model-select-lg\"]],[[\"@placeholder\",\"@selectedModel\",\"@pageSize\",\"@modelName\",\"@allowClear\",\"@onChange\"],[\"Select other grades to group under this grade\",[24,[\"contextObject\",\"sub_grades\"]],40,\"cargo-grade\",true,[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"contextObject\",\"sub_grades\"]]],null]],null]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/cargo-grade-form.hbs"
    }
  });
  _exports.default = _default;
});