define("@fixflo/frontend/initializers/route", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  function initialize() {
    Ember.Route.reopen({
      /**
       * Upon all route activation initialize this method
       *
       * @void
       */
      activate: function () {
        // reset scroll
        window.scrollTo(0, 0);
        // generate css class
        const cssClass = this.bodyClass !== undefined ? this.toCssClass() + ' ' + this.bodyClass : this.toCssClass();
        // add the route class to the body
        if (cssClass !== 'application') {
          (0, _jquery.default)('body').addClass(cssClass);
        }
        // initialize stuff for template
        Ember.run.scheduleOnce('afterRender', this, () => {
          this.layout.initialize();
        });
      },
      /**
       * Upon route deadctivation run this method
       *
       * @void
       */
      deactivate: function () {
        (0, _jquery.default)('body').removeClass(this.toCssClass());
      },
      /**
       * Convert a route name to a css acceptable class name
       *
       * @return string
       */
      toCssClass: function () {
        return this.routeName.replace(/\./g, '-').dasherize();
      }
    });
  }
  var _default = {
    initialize
  };
  _exports.default = _default;
});