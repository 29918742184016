define("@fixflo/frontend/mixins/model-creator", ["exports", "@fixflo/frontend/helpers/generate-relation-object", "country-data", "@fixflo/frontend/utils/get-boundary-center", "@fixflo/frontend/utils/geojson-to-coords"], function (_exports, _generateRelationObject, _countryData, _getBoundaryCenter, _geojsonToCoords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    actions: {
      /**
       * Ability to create a model on the fly
       *
       * @void
       */
      createModel: function (type) {
        // console.log('[createModel]', type);
        switch (type) {
          case 'vessel':
            const vessel = this.store.createRecord('vessel', (0, _generateRelationObject.generateRelationObject)(['vessel']));
            // render dialog
            this.dialog.one('created', createVesselPresenter => this.setProperties({
              createVesselPresenter
            }));
            this.dialog.open('vessel-form', vessel, {
              title: 'Create a new vessel',
              acceptText: 'Save',
              className: 'dialog-lg',
              showMore: false,
              showMoreFields: () => this.createVesselPresenter.toggleProperty('showMore'),
              controller: this,
              accept: () => {
                this.createVesselPresenter.startLoading();
                return vessel.save().then(zone => {
                  this.notifications.success('New vessel added');
                  this.createVesselPresenter.stopLoading();
                  this.createVesselPresenter._accept();
                }).catch(error => {
                  this.createVesselPresenter.stopLoading();
                  this.notifications.serverError(error);
                });
              },
              decline: () => {
                vessel.destroyRecord();
                this.createVesselPresenter._decline();
              }
            });
            break;
          case 'grade':
          case 'cargo-grade':
            const cargoGrade = this.store.createRecord('cargo-grade', {
              name: null,
              display_name: null,
              aliases: [],
              pendingChildren: []
            });
            // render dialog
            this.dialog.one('created', createGradePresenter => this.setProperties({
              createGradePresenter
            }));
            this.dialog.open('cargo-grade-form', cargoGrade, {
              title: 'Create new cargo grade',
              acceptText: 'Save',
              controller: this,
              accept: () => {
                this.createGradePresenter.startLoading();
                return cargoGrade.save().then(zone => {
                  this.notifications.success('New cargo grade added');
                  this.createGradePresenter.stopLoading();
                  this.createGradePresenter._accept();
                }).catch(error => {
                  this.createGradePresenter.stopLoading();
                  this.notifications.serverError(error);
                });
              },
              decline: () => {
                cargoGrade.destroyRecord();
                this.createGradePresenter._decline();
              }
            });
            break;
          case 'charterer':
            const charterer = this.store.createRecord('charterer', {
              name: null
            });
            // set dialog presenter to controller
            this.dialog.one('created', createChartererPresenter => this.setProperties({
              createChartererPresenter
            }));
            // render dialog
            this.dialog.open('charterer-form', charterer, {
              title: 'Create new charterer',
              acceptText: 'Save',
              controller: this,
              accept: () => {
                this.createChartererPresenter.startLoading();
                return charterer.save().then(zone => {
                  this.notifications.success('New charterer added');
                  this.createChartererPresenter.stopLoading();
                  this.createChartererPresenter._accept();
                }).catch(error => {
                  this.createChartererPresenter.stopLoading();
                  this.notifications.serverError(error);
                });
              },
              decline: () => {
                charterer.destroyRecord();
                this.createChartererPresenter._decline();
              }
            });
            break;
          case 'discharge':
          case 'load':
          case 'port':
          case 'zone':
            // create zone to be created
            const zone = this.store.createRecord('zone', {
              type: 'zone',
              aliases: []
            });
            // set dialog presenter to controller
            this.dialog.one('created', createZonePresenter => this.setProperties({
              createZonePresenter
            }));
            // render dialog
            this.dialog.open('zone-form', zone, {
              title: 'Create a new zone',
              acceptText: 'Save',
              controller: this,
              countries: _countryData.countries.all.map(c => c.name),
              addAlias: tag => zone.get('aliases').pushObject(tag),
              removeAlias: i => zone.get('aliases').removeAt(i),
              accept: () => {
                const firstBoundary = Ember.get(this, 'pendingBoundaries.firstObject');
                if (firstBoundary && !Ember.get(zone, 'longitude')) {
                  // set the zone latitude and longitude from boundary
                  Ember.setProperties(zone, {
                    longitude: Ember.get(firstBoundary, 'longitude'),
                    latitude: Ember.get(firstBoundary, 'latitude')
                  });
                }
                // start loading indicator
                this.createZonePresenter.startLoading();
                // save the zone
                return zone.save().then(zone => {
                  // set zone to pending boundaries
                  const boundaries = this.pendingBoundaries.map(b => {
                    b.set('zone_uuid', zone.get('id'));
                    return b;
                  });
                  // save boundaries
                  return Ember.RSVP.all(boundaries.invoke('save')).then(boundaries => {
                    // clear pending zones
                    this.resetZoneEditor();
                    // set boundaries to zones
                    zone.get('boundaries').pushObjects(boundaries);
                    // save boundaries
                    this.notifications.success('New zone added');
                    this.createZonePresenter.stopLoading();
                    this.createZonePresenter._accept();
                  }).catch(error => {
                    this.createZonePresenter.stopLoading();
                    this.notifications.serverError(error);
                  });
                });
              },
              decline: () => {
                zone.destroyRecord();
                this.resetZoneEditor();
                this.createZonePresenter._decline();
              }
            });
            break;
        }
      },
      /**
       * Delete layers selected to be deleted
       *
       * @void
       */
      deleteBoundaries: function ({
        layers
      }) {
        layers.getLayers().forEach(layer => {
          // get the boundary from id reference
          const boundary = this.store.peekRecord('boundary', Ember.get(layer, 'boundary_id'));
          if (boundary) {
            boundary.destroyRecord();
          }
        });
      },
      /**
       * Delete layers selected to be deleted
       *
       * @void
       */
      saveBoundaryChanges: function ({
        target
      }) {
        const layers = Object.values(Ember.get(target, '_layers'));
        layers.forEach(layer => {
          const boundary_id = Ember.get(layer, 'boundary_id');
          if (!boundary_id) {
            return;
          }
          const boundary = (0, _geojsonToCoords.default)(layer, true, true);
          this.fetch.put(`boundaries/${boundary_id}`, {
            data: {
              boundary
            }
          });
        });
      },
      /**
       * Set the active drawing control to the controllers
       *
       * @void
       */
      setFeatureGroup: function (activeFeatureGroup, map) {
        Ember.setProperties(this, {
          activeFeatureGroup
        });
      },
      /**
       * Add a new boundary to be saved to the zone being edited
       *
       * @void
       */
      queueZoneBoundary: function (event, layer) {
        const boundary = (0, _geojsonToCoords.default)(layer, true, true);
        if (Ember.isEmpty(boundary)) {
          return;
        }
        const {
          latitude,
          longitude
        } = (0, _getBoundaryCenter.default)(boundary, true);
        const zoneBoundary = this.store.createRecord('boundary', {
          boundary,
          latitude,
          longitude
        });
        this.pendingBoundaries.push(zoneBoundary);
      },
      /**
       * Add an existing layer to editableLayers
       *
       * @void
       */
      makeLayerEditable: function (boundary, {
        target
      }) {
        const activeFeatureGroup = this.activeFeatureGroup;
        const editableLayers = this.editableLayers;
        if (editableLayers.includes(target) || !activeFeatureGroup) {
          return;
        }
        // set metadata for boundary id
        Ember.set(target, 'boundary_id', Ember.get(boundary, 'id'));
        // push to editable layers & feature group
        editableLayers.pushObject(target);
        activeFeatureGroup.addLayer(target);
      }
    },
    /**
     * Reset controller properties when dialog closes
     *
     * @void
     */
    resetZoneEditor: function () {
      Ember.setProperties(this, {
        pendingBoundaries: [],
        editableLayers: [],
        activeFeatureGroup: null
      });
    },
    /**
     * Array of bounndaries pending creation
     */
    pendingBoundaries: [],
    /**
     * Array of editable layers
     */
    editableLayers: [],
    /**
     * The current active feature group
     */
    activeFeatureGroup: null
  });
  _exports.default = _default;
});