define("@fixflo/frontend/controllers/admin/panel/vessels/index", ["exports", "@fixflo/frontend/mixins/model-creator", "ember-inflector"], function (_exports, _modelCreator, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend(_modelCreator.default, {
    actions: {
      /**
       * Edit a vessel
       */
      edit: function (vessel) {
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // render dialog
        this.dialog.open('vessel-form', vessel, {
          title: `Edit ${Ember.get(vessel, 'name')} vessel`,
          acceptText: 'Save Changes',
          showMore: false,
          showMoreFields: () => this.presenter.toggleProperty('showMore'),
          controller: this,
          accept: () => {
            return vessel.save().then(zone => {
              this.notifications.success('Vessel saved.');
              this.presenter._accept();
            }).catch(error => {
              this.notifications.serverError(error);
            });
          },
          decline: () => {
            this.presenter._decline();
          }
        });
      },
      /**
       * Deletes a vessel
       */
      delete: function (vessel) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Delete Vessel',
          acceptText: 'Confirm and delete',
          message: `Are you sure you want to delete this vessel (${vessel.get('name')})?`
        }).then(() => {
          vessel.destroyRecord().then(() => {
            this.notifications.info('Vessel deleted.');
          }).catch(error => {
            this.notifications.serverError(error);
          });
        });
      },
      /**
       * Performs the selected bulk action
       */
      performBulkAction: function () {
        // get selected action
        const action = this.bulkAction;
        if (!action) {
          return this.notifications.warning('No action selected');
        }
        // get selected items
        const selected = this.model.filter(i => Ember.get(i, 'checked'));
        if (!selected.length) {
          return this.notifications.warning('No items selected');
        }
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // display dialogs based on action
        switch (action) {
          case 'edit':
            // values to apply to models
            const values = {};
            // display edit dialog
            this.dialog.open('bulk-edit-form', null, {
              title: 'Bulk edit vessels',
              selected,
              editable: ['fleet'],
              values,
              acceptText: 'Apply Changes',
              controller: this,
              accept: () => {
                // apply values to all selected
                selected.forEach(i => Ember.setProperties(i, values));
                // save the changes
                return Ember.RSVP.all(selected.invoke('save')).then(() => {
                  this.notifications.info(`${(0, _emberInflector.pluralize)(selected.length, 'item')} saved`);
                  this.presenter._accept();
                });
              }
            });
            break;
          case 'delete':
            this.dialog.open('bulk-delete-form', null, {
              title: 'Bulk delete vessels',
              selected,
              acceptText: 'Confirm & Delete',
              controller: this,
              accept: () => {
                return Ember.RSVP.all(selected.invoke('destroyRecord')).then(() => {
                  this.notifications.info(`${(0, _emberInflector.pluralize)(selected.length, 'item')} deleted`);
                  this.presenter._accept();
                });
              }
            });
            break;
        }
      }
    },
    /**
     * Query parameters
     */
    queryParams: ['page', 'limit', 'sort', 'query', 'fleet'],
    /**
     * Current page
     */
    page: 1,
    /**
     * Default pagination limit
     */
    limit: 30,
    /**
     * Default sorting
     */
    sort: '-created_at',
    /**
     * The bulk action to perform
     */
    bulkAction: null,
    /**
     * Filter by fleet
     */
    fleet: null,
    /**
     * All fleet options
     */
    fleets: Ember.A([]),
    /**
     * Determines if all items are checked
     */
    checkAll: false,
    /**
     * Toggles server side sorting
     */
    toggleSort(sort) {
      const currentSort = this.sort;
      if (currentSort && currentSort.includes(sort) && currentSort.startsWith('-')) {
        this.setProperties({
          sort: currentSort.substr(1)
        });
      } else {
        this.setProperties({
          sort: `-${sort}`
        });
      }
    },
    /**
     * All selected vessels
     */
    selectedVessels: Ember.computed('model.@each.checked', function () {
      return this.model.filter(v => v.checked);
    }),
    /**
     * Togggles all checked
     */
    toggleCheckAll: Ember.observer('checkAll', function () {
      if (this.checkAll) {
        this.model.forEach(i => Ember.set(i, 'checked', true));
      } else {
        this.model.forEach(i => Ember.set(i, 'checked', false));
      }
    })
  });
  _exports.default = _default;
});