define("@fixflo/frontend/mixins/fixtures-table", ["exports", "@fixflo/frontend/helpers/uri-to-object", "@fixflo/frontend/utils/boundary-to-array", "@fixflo/frontend/utils/geo-json-to-boundary", "@fixflo/frontend/utils/group-consecutive", "@fixflo/frontend/utils/is-model", "@fixflo/frontend/utils/is-model-or-proxy", "moment"], function (_exports, _uriToObject, _boundaryToArray, _geoJsonToBoundary, _groupConsecutive, _isModel, _isModelOrProxy, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); } // import cleanObject from '@fixflo/frontend/utils/clean-object';
  var _default = Ember.Mixin.create({
    actions: {
      /**
       * Set the handsontable instance
       */
      setInstance: function (handsontable, fixturesTable) {
        Ember.setProperties(this, {
          handsontable,
          fixturesTable
        });
      },
      /**
       * Allow an impot from raw text
       */
      importRawText: function () {
        this.fixturesTable.send('importRawText');
      },
      /**
       * Insert a row above
       */
      insertRow: function (direction) {
        this.fixturesTable.send('insertRow', direction);
      },
      /**
       * Insert a row above
       */
      insertRowTop: function (dd = null) {
        if (dd.actions) {
          Ember.tryInvoke(dd.actions, 'close');
        }
        this.fixturesTable.send('insertRowTop');
      },
      /**
       * Undo an action
       */
      undo: function () {
        this.fixturesTable.send('undo');
      },
      /**
       * Redo an action
       */
      redo: function () {
        this.fixturesTable.send('redo');
      },
      /**
       * Exports data in table as csv
       */
      exportAsCsv: function () {
        this.fixturesTable.send('exportAsCsv');
      },
      /**
       * Export all fixtures as a report
       */
      exportAll: function (dd = null) {
        if (dd.actions) {
          Ember.tryInvoke(dd.actions, 'close');
        }
        const filters = {};
        Object.keys(this.filters).forEach(filterKey => {
          filters[filterKey] = this.get(filterKey);
        });
        // query is not included in filters
        if (this.query) {
          filters['query'] = this.query;
        }
        this.fixturesTable.send('exportAll', filters);
      },
      /**
       * Allow user toc customize columns
       */
      customizeColumns: function (dd = null) {
        if (dd.actions) {
          Ember.tryInvoke(dd.actions, 'close');
        }
        this.fixturesTable.send('customizeColumns');
      },
      /**
       * Set the geo filter map instance
       */
      setGeoFilterMapInstance: function (board, forDischarge, {
        target
      }) {
        // console.log('[setGeoFilterMapInstance]', forDischarge, target);
        // target to map
        const map = target;
        // get the param applying boundary to
        const boundaryParam = forDischarge ? 'discharge_boundary' : 'boundary';
        // is the current filter being used on a board
        const isBoard = this.isBoard;
        // get the existing boundary if any
        const existingBoundary = (0, _boundaryToArray.default)(Ember.get(board, `params.${boundaryParam}`));
        // display existing boundary on map if exists
        if (isBoard && existingBoundary) {
          // create a polygon from existing boundary
          const polygon = L.polygon(existingBoundary, {
            color: '#48BB78'
          }).addTo(map);
          // set map to polygon
          map.fitBounds(polygon.getBounds());
        }
      },
      /**
       * Applies the boundary from the user drawn layer to queryparams
       *
       * @void
       */
      setGeoFilter: function (forDischarge, event, layer, replaceInBoard = null) {
        // if first param is board model, then replace geo filters
        if ((0, _isModel.default)(forDischarge, 'board')) {
          return this.send('replaceGeoFilter', forDischarge, event, layer, replaceInBoard);
        }
        // console.log('[setGeoFilter]', forDischarge, event, layer);
        const boundaryParam = forDischarge ? 'discharge_boundary' : 'boundary';
        const boundary = (0, _geoJsonToBoundary.default)(layer);
        Ember.set(this, `filters.${boundaryParam}`, boundary);
      },
      /**
       * Applies the boundary from the user drawn layer to queryparams
       *
       * @void
       */
      replaceGeoFilter: function (board, forDischarge, event, layer) {
        const boundaryParam = forDischarge ? 'discharge_boundary' : 'boundary';
        const boundary = (0, _geoJsonToBoundary.default)(layer);
        Ember.set(board, `params.${boundaryParam}`, boundary);
      },
      /**
       * The official geo filter
       */
      geoFilter: function (forDischarge = false, dd = null) {
        if (dd.actions) {
          Ember.tryInvoke(dd.actions, 'close');
        }
        // get the boundary param
        const boundaryParam = forDischarge ? 'discharge_boundary' : 'boundary';
        // get filters
        const filters = this.filters;
        // is the current filter being used on a board
        const isBoard = this.isBoard;
        // this board
        const board = this.source;
        // get the existing boundary if any
        const existingBoundary = (0, _boundaryToArray.default)(Ember.get(this, `source.params.${boundaryParam}`));
        // set presenter to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // open presenter dialog
        this.dialog.open('fixtures-geo-filter-form', null, {
          title: `Filter ${forDischarge ? 'discharge' : 'load'} geographically`,
          className: 'dialog-xl',
          acceptText: 'Done',
          forDischarge,
          board,
          isBoard,
          existingBoundary,
          controller: this,
          accept: () => {
            Ember.setProperties(this, {
              loadingMessage: ''
            });
            Ember.setProperties(this, filters);
            this.presenter._accept();
          }
        });
      },
      /**
       * The official value filter
       */
      valueFilter: function () {
        const filters = this.filters;
        const isBoard = this.isBoard;
        const confidentialityLevels = this.confidentialityLevels;
        const stickyParams = isBoard ? Ember.get(this, 'source.params') : {};
        // prompt the dialog
        this.dialog.one('created', presenter => {
          this.set('presenter', presenter);
        });
        this.dialog.open('fixtures-filter-form', filters, {
          title: 'Filter fixtures',
          showMoreFilters: false,
          useRelativeLaycan: Ember.typeOf(Ember.get(filters, 'laycanRangeStart')) === 'string' && ['+', '-'].includes(Ember.get(filters, 'laycanRangeStart').substr(0, 1)),
          useRelativeDate: Ember.typeOf(Ember.get(filters, 'before')) === 'string' && ['+', '-'].includes(Ember.get(filters, 'before').substr(0, 1)),
          acceptText: 'Done',
          confidentialityLevels,
          isBoard,
          stickyParams,
          className: 'more-filters-dialog',
          controller: this,
          accept: () => {
            Object.keys(filters).map(f => {
              Ember.set(filters, f, Ember.typeOf(filters[f]) === 'instance' ? filters[f].get('id') : filters[f]);
              if (Ember.typeOf(filters[f]) === 'array' && filters[f].every(m => Ember.typeOf(m) === 'instance')) {
                Ember.set(filters, f, filters[f].map(m => Ember.get(m, 'id')));
              }
            });
            // console.log(filters);
            Ember.setProperties(this, filters);
            this.presenter._accept();
          }
        });
      },
      applyFilters: function () {
        const filters = this.filters;
        const tempFilters = _objectSpread({}, filters);
        const board = this.source;

        // start loading
        Ember.setProperties(this, {
          isLoadingFilters: true,
          loadingMessage: 'Applying filters...'
        });

        // set filters
        Object.keys(filters).map(f => {
          // set filter valuew
          const value = filters[f];

          // set filter
          tempFilters[f] = value;

          // console.log(f, value);

          // if fitler value is a model
          if ((0, _isModelOrProxy.default)(value)) {
            tempFilters[f] = value.get('id');
          }

          // if filter value is an array of models
          if (Ember.isArray(value) && value.every(v => (0, _isModelOrProxy.default)(v))) {
            tempFilters[f] = value.map(v => v.get('id'));
          }

          // hack/hotfix empty load/discharge multiples
          if (['load', 'discharge'].includes(f) && Ember.isEmpty(value)) {
            tempFilters[f] = null;
          }

          // if filter already set on board param dont set to temp
          if (board && !Ember.isBlank(board.params) && !Ember.isEmpty(board.params[f]) && board.params[f] === value) {
            delete tempFilters[f];
          }
        });
        const filtersToApply = tempFilters;
        Ember.setProperties(this, _objectSpread({}, filtersToApply));
      },
      /**
       * The official geo filter
       */
      viewActiveParameters: function (dd = null) {
        if (dd.actions) {
          Ember.tryInvoke(dd.actions, 'close');
        }
        const filters = (0, _uriToObject.uriToObject)();
        const isBoard = this.isBoard;
        const board = this.source;
        const stickyParams = isBoard ? Ember.get(this, 'source.params') : {};
        const params = Ember.assign(filters, stickyParams);
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        this.dialog.open('view-board-params', null, {
          title: 'Active Parameters',
          className: 'dialog-xl',
          params,
          board,
          controller: this,
          hasGeoFilter: !Ember.isEmpty(Ember.get(params, 'boundary')) || !Ember.isEmpty(Ember.get(params, 'discharge_boundary')),
          isDischarge: !Ember.isEmpty(Ember.get(params, 'discharge_boundary')),
          acceptText: 'Done'
        });
      },
      /**
       * Do a search
       *
       * @void
       */
      doSearch: function (query) {
        Ember.setProperties(this, {
          loadingMessage: query ? `Searching fixtures for keyword "${query}"...` : 'Clearing search...',
          query
        });
      },
      /**
       * Toggle a loading message that quick report is generating or completed
       *
       * @void
       */
      toggleQuickReportIndicator: function (reportType, request = null) {
        if (request === null) {
          Ember.setProperties(this, {
            isRouteLoading: true,
            loadingMessage: `Generating ${Ember.get(reportType, 'name')} report...`
          });
        } else {
          Ember.setProperties(this, {
            isRouteLoading: false,
            loadingMessage: ''
          });
        }
      },
      /**
       * Clear a filter
       */
      clearFilter: function (filter) {
        Ember.setProperties(this, {
          [filter]: null
        });
        Ember.set(this, `filters.${filter}`, null);
      },
      /**
       * Create a board from filter
       */
      createBoard: function (dd = null) {
        if (dd.actions) {
          Ember.tryInvoke(dd.actions, 'close');
        }
        const filters = (0, _uriToObject.uriToObject)();
        // also merge the sort if applicable
        filters.sort = this.sort;
        // merge params w/ source
        const boardParams = !Ember.isEmpty(Ember.get(this, 'source.params')) ? _objectSpread({}, Ember.get(this, 'source.params')) : {};
        const params = Ember.assign(boardParams, filters);
        // create board
        const board = this.store.createRecord('board', {
          name: null,
          type: 'fixtures',
          params
        });
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        this.dialog.open('board-form', board, {
          title: 'Create new board',
          acceptText: 'Save',
          accept: () => {
            // start loading
            this.presenter.startLoading();
            // save board
            board.save().then(filter => {
              this.notifications.success('New board created');
              this.presenter.stopLoading();
              this.presenter._accept();
            });
          },
          decline: () => {
            board.destroyRecord();
            this.presenter._decline();
          }
        });
      },
      /**
       * Display dialog to edit this board
       */
      editBoard: function (dd = null) {
        if (dd.actions) {
          Ember.tryInvoke(dd.actions, 'close');
        }
        const board = this.source;
        // hacky hotfix for setting quantity filters on board params
        if (!Ember.get(board, 'params.quantity')) {
          board.set('params.quantity', {
            min: null,
            max: null
          });
        }
        this.dialog.one('created', editBoardPresenter => this.setProperties({
          editBoardPresenter
        }));
        this.dialog.open('board-creator', board, {
          title: `Edit ${Ember.get(board, 'name')} board`,
          acceptText: 'Save Changes',
          showMoreFilters: false,
          confidentialityLevels: this.confidentialityLevels,
          controller: this,
          toggleInternal: onlyInternal => {
            board.set('params.internal', onlyInternal ?? null);
          },
          setGradeSelectorInstance: () => {},
          accept: () => {
            this.editBoardPresenter.startLoading();
            Object.keys(Ember.get(board, 'params')).map(f => {
              Ember.set(board, `params.${f}`, Ember.typeOf(Ember.get(board, `params.${f}`)) === 'instance' ? Ember.get(board, `params.${f}.id`) : Ember.get(board, `params.${f}`));
              if (Ember.typeOf(Ember.get(board, `params.${f}`)) === 'array' && Ember.get(board, `params.${f}`).every(m => Ember.typeOf(m) === 'instance')) {
                Ember.set(board, `params.${f}`, Ember.get(board, `params.${f}`).map(m => Ember.get(m, 'id')));
              }
            });
            board.save().then(() => {
              this.notifications.success('Changes to board saved.');
              this.editBoardPresenter.stopLoading();
              this.editBoardPresenter._accept();
              return this.refreshRoute();
            }).catch(error => {
              this.editBoardPresenter.stopLoading();
              this.notifications.serverError(error);
            });
          }
        });
      },
      /**
       * Display dialog to delete this board
       */
      deleteBoard: function (dd = null) {
        if (dd.actions) {
          Ember.tryInvoke(dd.actions, 'close');
        }
        const board = this.source;
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Delete board',
          acceptText: 'Confirm and delete',
          message: `Are you sure you want to delete this board?`
        }).then(() => {
          board.destroyRecord().then(() => {
            return this.transitionToRoute('dashboard.boards');
          });
        });
      },
      /**
       * Share the board with other sers or groups
       */
      shareBoard: function (dd = null) {
        if (dd.actions) {
          Ember.tryInvoke(dd.actions, 'close');
        }
        const board = this.model;
        const query = query => {
          return this.fetch.request('actions/search-collaborators', {
            data: {
              query
            }
          }).then(collaborators => collaborators.filter(u => Ember.get(u, 'uuid') !== Ember.get(this, 'model.owner_uuid')));
        };
        this.dialog.one('created', shareBoardPresenter => this.setProperties({
          shareBoardPresenter
        }));
        this.dialog.open('share-board-form', null, {
          title: `Share "${board.name}" Board`,
          query,
          board: this.model,
          acceptText: 'Save',
          accept: () => {
            const groups = this.shareBoardPresenter.selected.filter(s => Ember.get(s, 'owner_uuid')).map(s => Ember.get(s, 'uuid'));
            const users = this.shareBoardPresenter.selected.filter(s => Ember.get(s, 'email')).map(s => Ember.get(s, 'uuid'));
            if (users.length || groups.length) {
              return this.fetch.post('actions/share-board', {
                data: {
                  board: Ember.get(this, 'model.id') || Ember.get(this, 'source.id'),
                  groups,
                  users,
                  canEdit: this.shareBoardPresenter.canEdit
                }
              }).then(response => {
                this.shareBoardPresenter.stopLoading();
                this.notifications.success('Board shared');
                this.shareBoardPresenter._accept();
              }).catch(error => {
                this.shareBoardPresenter.stopLoading();
                this.notifications.serverError(error);
                this.shareBoardPresenter._accept();
              });
            }
            this.shareBoardPresenter._accept();
          }
        });
      },
      /**
       * The official geo filter
       */
      addGeoFilter: function (board, forDischarge = false) {
        // get the boundary param
        const boundaryParam = forDischarge ? 'discharge_boundary' : 'boundary';
        // get filters
        const filters = Ember.get(board, 'params');
        // is the current filter being used on a board
        const isBoard = true;
        // get the existing boundary if any
        const existingBoundary = (0, _boundaryToArray.default)(Ember.get(board, `params.${boundaryParam}`));
        // set presenter to controller
        this.dialog.one('created', geoFilterPresenter => this.setProperties({
          geoFilterPresenter
        }));
        // open presenter dialog
        this.dialog.open('board-creator-geo-filter-form', null, {
          title: `Filter ${forDischarge ? 'discharge' : 'load'} geographically`,
          acceptText: 'Done',
          className: 'dialog-xl',
          board,
          forDischarge,
          isBoard,
          existingBoundary,
          controller: this,
          accept: () => {
            Ember.setProperties(board, {
              params: filters
            });
            this.geoFilterPresenter._accept();
          }
        });
      },
      /**
       * View the board collaborators
       */
      viewBoardCollaborators: async function (dd = null) {
        if (dd.actions) {
          Ember.tryInvoke(dd.actions, 'close');
        }
        const {
          collaborators,
          owner
        } = await this.fetch.get('actions/get-board-collaborators', {
          data: {
            board: Ember.get(this, 'model.id') || Ember.get(this, 'source.id')
          }
        });
        // check if current user is board owner
        const isBoardOwner = Ember.get(owner, 'uuid') === this.currentUser.id;
        // set dialog presenter
        this.dialog.one('created', presenter => {
          this.set('presenter', presenter);
        });
        // triger dialog
        this.dialog.open('board-collaborators', null, {
          title: 'View Collaborators',
          collaborators,
          owner,
          isBoardOwner,
          controller: this,
          acceptText: 'Done',
          accept: () => {
            // convert board groups and board members to models
            const boardMembers = collaborators.users.map(member => {
              return this.store.push(this.store.normalize('board-member', member));
            });
            // convert board groups to models
            const boardGroups = collaborators.groups.map(group => {
              return this.store.push(this.store.normalize('board-group', group));
            });
            // save collaborators and close dialog
            Ember.RSVP.all([...boardMembers, ...boardGroups].invoke('save')).then(response => {
              this.presenter._accept();
            });
          }
        });
      },
      /**
       * Removes a board collaborator
       */
      removeCollaborator: function (collaborator) {
        // check collaborator type
        const isGroup = Ember.get(collaborator, 'user_uuid') === undefined;
        // convert to model
        if (isGroup) {
          collaborator = this.store.push(this.store.normalize('board-group', collaborator));
        } else {
          collaborator = this.store.push(this.store.normalize('board-member', collaborator));
        }
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Remove collaborator',
          acceptText: 'Confirm and remove',
          message: `Are you sure you want to remove this collaborator (${isGroup ? Ember.get(collaborator, 'group.name') : Ember.get(collaborator, 'user.name')})?`
        }).then(() => {
          collaborator.destroyRecord().then(collaborator => {
            document.getElementById(`collaborator-${Ember.get(collaborator, 'id')}`).remove();
          });
        });
      },
      /**
       * Save the current filter
       */
      saveFilter: function () {
        const params = (0, _uriToObject.uriToObject)();
        // remove params that are already apart of the board
        const boardParams = Ember.get(this, 'source.params');
        // delete all param keys if existing in board params
        const boardParamKeys = Object.keys(boardParams);
        const paramKeys = Object.keys(params);
        const paramsToDelete = paramKeys.filter(p => RegExp(boardParamKeys.join('|'), 'gmi').test(p));
        paramsToDelete.forEach(p => delete params[p]);
        // save filters
        const savedFilter = this.store.createRecord('saved-filter', {
          for_uuid: Ember.get(this, 'source.id'),
          for: Ember.get(this, 'source._internalModel.modelName'),
          shared_with_company: false,
          params
        });
        this.dialog.one('created', presenter => {
          this.set('presenter', presenter);
        });
        this.dialog.open('save-filter-form', savedFilter, {
          title: 'Save current search filter',
          acceptText: 'Save',
          accept: () => {
            savedFilter.save().then(filter => {
              this.notifications.success('New search filter saved');
              this.presenter._accept();
            });
          },
          decline: () => {
            savedFilter.destroyRecord();
            this.presenter._decline();
          }
        });
      },
      /**
       * Delete a saved filter
       */
      deleteFilter: function (filterName, dd = null) {
        if (dd.actions) {
          Ember.tryInvoke(dd.actions, 'close');
        }
        const filter = this.store.peekAll('saved-filter').find(f => Ember.get(f, 'name') === filterName);
        this.dialog._confirm({
          title: 'Delete filter',
          acceptText: 'Confirm and delete',
          message: `Are you sure you want to delete this filter?`
        }).then(() => {
          filter.destroyRecord().then(() => {
            return this.transitionToRoute('dashboard.boards');
          });
        });
      },
      /**
       * Fixture saved
       */
      fixtureSaved: function (newValue) {
        this.notifications.info('[Changes saved]');
      },
      /**
       * Reresh the route model
       */
      reload: function () {
        Ember.get(this, 'target.targetState.router').refresh();
      },
      /**
       * Toggle the sort asc/desc
       */
      toggleSortAscDesc: function () {
        const sort = this.sort;
        const sortDirection = this.sortDirection;
        if (sortDirection === 'asc') {
          // switch to desc
          Ember.setProperties(this, {
            sort: '-' + sort,
            sortDirection: 'desc'
          });
        } else {
          Ember.setProperties(this, {
            sort: sort.substr(1),
            sortDirection: 'asc'
          });
        }
      },
      /**
       * Set the geo filter map instance
       */
      setBoundaryMapInstance: function (boundary, fixtures, {
        map
      }) {
        // convert boundary to array
        boundary = (0, _boundaryToArray.default)(boundary);
        // display boundary
        const polygon = new google.maps.Polygon({
          paths: boundary,
          strokeColor: '#38A169',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#48BB78',
          fillOpacity: 0.35
        });
        // set markers
        fixtures.forEach(fixture => {
          Ember.get(fixture, 'load_options').forEach(port => {
            let marker = new google.maps.Marker({
              title: Ember.get(port, 'relation.system_display_name'),
              position: new window.google.maps.LatLng(Ember.get(port, 'relation.latitude'), Ember.get(port, 'relation.longitude')),
              map
            });
          });
        });
        // move map center to boundary center
        polygon.setMap(map);
        // move to center of boundary
        const {
          latitude,
          longitude
        } = getCenter(boundary);
        map.panTo(new window.google.maps.LatLng(latitude, longitude));
        map.setZoom(2);
      },
      /**
       * View the boundary map and all zones/ports within it
       */
      viewBoundaryMap: function () {
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // get the boundary
        const boundary = this.boundary || Ember.get(this, 'source.params.boundary');
        // get all the zones within the boundary
        const fixtures = Ember.get(this, 'model.fixtures');
        // display prompt
        this.dialog.open('boundary-map', null, {
          title: 'Boundary Map',
          acceptText: 'Done',
          noDeclineButton: true,
          controller: this,
          fixtures,
          boundary,
          accept: () => {
            this.presenter._accept();
          }
        });
      },
      /**
       * Quickly toggle internal fixtures true/null
       */
      toggleInternalFilter: function (onlyInternal) {
        this.set('filters.internal', onlyInternal || null);
      },
      /**
       * Toggles server side sorting
       */
      toggleSort: function (sort, dd = null) {
        if (dd.actions) {
          Ember.tryInvoke(dd.actions, 'close');
        }
        this.set('loadingMessage', 'Updating sort...');
        const currentSort = this.sort;
        if (currentSort && currentSort.includes(sort) && currentSort.startsWith('-')) {
          this.setProperties({
            sort: currentSort.substr(1)
          });
        } else {
          this.setProperties({
            sort: `-${sort}`
          });
        }
      },
      /**
       * Sets a fixtures confidentiality level via meta
       */
      setBackdate: function (selectedFixtures, dd = null) {
        if (dd.actions) {
          Ember.tryInvoke(dd.actions, 'close');
        }
        this.dialog.one('created', setBackdatePresenter => this.setProperties({
          setBackdatePresenter
        }));
        // display prompt
        this.dialog.open('backdate-fixtures', null, {
          title: 'Backdate Fixtures',
          acceptText: 'Done',
          backdate: null,
          controller: this,
          accept: () => {
            const date = this.setBackdatePresenter.get('backdate');
            const data = this.handsontable.getSourceData();
            const changes = selectedFixtures.map(fixture => {
              return {
                row: data.indexOf(fixture),
                id: Ember.get(fixture, 'fixture_uuid')
              };
            });
            const rows = changes.map(change => Ember.get(change, 'row'));
            const queue = changes.map(change => Ember.get(change, 'id'));
            // set for each selection
            rows.forEach(row => {
              this.fixturesTable.setRowData(row, 'created_at', (0, _moment.default)(date).format('DD-MMM-YYYY'));
              this.fixturesTable.setRowData(row, 'created_at_datetime', date);
            });
            this.handsontable.render();
            this.setBackdatePresenter._accept();
            // execute requests handle responses
            return this.fetch.patch('actions/bulk-backdate-fixtures', {
              data: {
                queue,
                date
              }
            });
          }
        });
      },
      /**
       * Sets a fixtures confidentiality level via meta
       */
      setConfidentialityLevel: function (selectedFixtures, confidentiality, dd = null) {
        if (dd.actions) {
          Ember.tryInvoke(dd.actions, 'close');
        }
        const data = this.handsontable.getSourceData();
        const changes = selectedFixtures.map(fixture => {
          return {
            row: data.indexOf(fixture),
            id: Ember.get(fixture, 'fixture_uuid')
          };
        });
        const rows = changes.map(change => Ember.get(change, 'row'));
        const queue = changes.map(change => Ember.get(change, 'id'));
        // set for each selection
        rows.forEach(row => {
          this.fixturesTable.setRowData(row, 'confidentiality', confidentiality);
        });
        this.handsontable.render();
        // execute requests handle responses
        return this.fetch.patch('actions/bulk-set-confidentiality', {
          data: {
            queue,
            confidentiality
          }
        });
      },
      /**
       * Toggle fixture to be internal or not internal
       */
      toggleInternal: function (selectedFixtures, internal = 1, dd = null) {
        if (dd.actions) {
          Ember.tryInvoke(dd.actions, 'close');
        }
        const data = this.handsontable.getSourceData();
        const changes = selectedFixtures.map(fixture => {
          return {
            row: data.indexOf(fixture),
            id: Ember.get(fixture, 'fixture_uuid')
          };
        });
        const rows = changes.map(change => Ember.get(change, 'row'));
        const queue = changes.map(change => Ember.get(change, 'id'));
        // set for each selection
        rows.forEach(row => {
          this.fixturesTable.setRowData(row, 'internal', internal);
        });
        this.handsontable.render();
        // execute requests handle responses
        return this.fetch.patch('actions/bulk-set-internal', {
          data: {
            queue,
            internal
          }
        });
      },
      /**
       * Removes selected rows or fixtures
       */
      deleteSelected: function (selectedFixtures, dd = null) {
        if (dd.actions) {
          Ember.tryInvoke(dd.actions, 'close');
        }
        const data = this.handsontable.getSourceData();
        const changes = selectedFixtures.map(fixture => {
          return {
            row: data.indexOf(fixture),
            id: Ember.get(fixture, 'fixture_uuid')
          };
        });
        const rows = changes.map(change => Ember.get(change, 'row'));
        const queue = changes.map(change => Ember.get(change, 'id'));
        const groups = (0, _groupConsecutive.default)(rows).map(group => {
          return [group[0], group.length];
        });
        // deleted grouped
        this.handsontable.alter('remove_row', groups);
        this.notifyPropertyChange('selectedFixtures');
        // execute requests handle responses
        return this.fetch.delete('actions/bulk-delete-fixtures', {
          data: {
            queue
          }
        });
      },
      /**
       * Notifies this object a property has changes
       */
      notifyPropertyChange: function (property) {
        if (property && typeof property === 'string') {
          this.notifyPropertyChange(property);
        }
      },
      /**
       * Removes selected rows or fixtures
       */
      generateReport: function (selectedFixtures, dd = null) {
        if (dd.actions) {
          Ember.tryInvoke(dd.actions, 'close');
        }
        this.fixturesTable.send('generateReportFromSelected', selectedFixtures);
      },
      /**
       * Generate quick report from context menu
       */
      generateQuickReport: function (selectedFixtures, quickReportType) {
        this.fixturesTable.send('generateQuickReport', selectedFixtures, quickReportType);
      },
      /**
       * Toggles nullable filters between null or "null"
       */
      toggleNullableFilters: function (filters, property) {
        if (Ember.get(filters, property) === null) {
          Ember.set(filters, property, 'null');
        } else {
          Ember.set(filters, property, null);
        }
      },
      /**
       * Resets all filters
       */
      resetFilters: function () {
        // set a loading message
        this.set('loadingMessage', 'Removing applied filters...');
        // reset all filters
        const filters = this.filters;
        for (let filter in filters) {
          Ember.set(this, `filters.${filter}`, null);
          Ember.set(this, filter, null);
        }
      },
      /**
       * Register a dropdown menu instance to the controller
       *
       * @void
       */
      registerDropdownMenu: function (ddName, dd) {
        this[`${ddName}DropdownMenu`] = dd;
      },
      /**
       * Toggles the loading indicator flag, which uses `isRouteLoading`
       */
      toggleLoading: function (eventName) {
        switch (eventName) {
          case 'insertRowTop':
            this.set('loadingMessage', 'Creating new fixture...');
            break;
          case 'deleteSelected':
            this.set('loadingMessage', 'Deleting selected fixtures...');
            break;
        }
        this.toggleProperty('isRouteLoading');
      },
      /**
       * Toggle switch between relative date, this is a hotfix but later this
       * functionality should be moved into a single components.
       */
      toggleDatePicker: function (property = 'useRelativeDate', params = []) {
        if (typeof this.source === 'object' && Ember.get(this, 'source.slug') !== 'company-board' && this.source.hasParams(params)) {
          return this.source.toggleProperty(property);
        }
        this.toggleProperty(property);
      }
    },
    /**
     * Query parameters
     */
    queryParams: ['page', 'limit', 'sort', 'load', 'discharge', 'vessel', 'boundary', 'discharge_boundary', 'grade', 'quantity[min]', 'quantity[max]', 'charterer', 'rate', 'query', 'laycanRangeStart', 'laycanRangeEnd', 'before', 'after', 'createdBy', 'internal', 'confidentialityLevel', 'vesselFleet', 'vesselDeadWeight[min]', 'vesselDeadWeight[max]', 'vesselYearBuilt[min]', 'vesselYearBuilt[max]', 'vesselCubicCapacity[min]', 'vesselCubicCapacity[max]', 'vesselLengthOverAll[min]', 'vesselLengthOverAll[max]'],
    /**
     * Filters for querying the fixtures
     */
    /**
     * Filters for querying the fixtures
     */
    filters: Ember.computed('source.params.{createdBy,discharge,load,vessel,quantity[min],quantity[max],grade,charterer,rate,laycanRangeStart,laycanRangeEnd,before,after,internal,confidentialityLevel,vesselFleet,vesselDeadWeight[min],vesselDeadWeight[max],vesselYearBuilt[min],vesselYearBuilt[max],vesselCubicCapacity[min],vesselCubicCapacity[max],vesselLengthOverAll[min],vesselLengthOverAll[max]}', function () {
      const defaultFilters = {
        discharge: this.discharge ?? Ember.get(this, 'source.params.discharge'),
        load: this.load ?? Ember.get(this, 'source.params.load'),
        vessel: this.vessel ?? Ember.get(this, 'source.params.vessel'),
        boundary: this.boundary,
        discharge_boundary: this.discharge_boundary,
        grade: this.grade ?? Ember.get(this, 'source.params.grade'),
        charterer: this.charterer ?? Ember.get(this, 'source.params.charterer'),
        rate: this.rate ?? Ember.get(this, 'source.params.rate'),
        laycanRangeStart: this.laycanRangeStart ?? Ember.get(this, 'source.params.laycanRangeStart'),
        laycanRangeEnd: this.laycanRangeEnd ?? Ember.get(this, 'source.params.laycanRangeEnd'),
        before: this.before ?? Ember.get(this, 'source.params.before'),
        after: this.after ?? Ember.get(this, 'source.params.after'),
        internal: this.internal ?? Ember.get(this, 'source.params.internal'),
        confidentialityLevel: this.confidentialityLevel ?? Ember.get(this, 'source.params.confidentialityLevel'),
        vesselFleet: this.vesselFleet ?? Ember.get(this, 'source.params.vesselFleet'),
        createdBy: this.createdBy ?? Ember.get(this, 'source.params.createdBy'),
        // ranges -- {min: x, max: y}
        'quantity[min]': this['quantity[min]'] ?? Ember.get(this, 'source.params.quantity[min]'),
        'quantity[max]': this['quantity[max]'] ?? Ember.get(this, 'source.params.quantity[max]'),
        'vesselDeadWeight[min]': this['vesselDeadWeight[min]'] ?? Ember.get(this, 'source.params.vesselDeadWeight[min]'),
        'vesselDeadWeight[max]': this['vesselDeadWeight[max]'] ?? Ember.get(this, 'source.params.vesselDeadWeight[max]'),
        'vesselYearBuilt[min]': this['vesselYearBuilt[min]'] ?? Ember.get(this, 'source.params.vesselYearBuilt[min]'),
        'vesselYearBuilt[max]': this['vesselYearBuilt[max]'] ?? Ember.get(this, 'source.params.vesselYearBuilt[max]'),
        'vesselCubicCapacity[min]': this['vesselCubicCapacity[min]'] ?? Ember.get(this, 'source.params.vesselCubicCapacity[min]'),
        'vesselCubicCapacity[max]': this['vesselCubicCapacity[max]'] ?? Ember.get(this, 'source.params.vesselCubicCapacity[max]'),
        'vesselLengthOverAll[min]': this['vesselLengthOverAll[min]'] ?? Ember.get(this, 'source.params.vesselLengthOverAll[min]'),
        'vesselLengthOverAll[max]': this['vesselLengthOverAll[max]'] ?? Ember.get(this, 'source.params.vesselLengthOverAll[max]')
      };
      return defaultFilters;
    }),
    /**
     * Inject the socket service
     *
     * @var {Service}
     */
    currentUser: Ember.inject.service(),
    isNullVesselFilter: Ember.computed.equal('filters.vessel', 'null'),
    isNullRateFilter: Ember.computed.equal('filters.rate', 'null'),
    isTouring: true,
    isNotTouring: Ember.computed.not('isTouring'),
    /**
     * Board sticky params
     */
    stickyParams: Ember.computed('source.params', function () {
      return this.isBoard ? Ember.get(this, 'source.params') : {};
    }),
    /**
     * Is user using relative date range for laycan filters
     */
    useRelativeLaycan: Ember.computed('laycanRangeStart', 'laycanRangeEnd', function () {
      const laycanStartFirstChar = Ember.typeOf(this.laycanRangeStart) === 'string' ? this.laycanRangeStart.substring(0, 1) : false;
      const laycenEndFirstChar = Ember.typeOf(this.laycanRangeEnd) === 'string' ? this.laycanRangeEnd.substring(0, 1) : false;
      return laycanStartFirstChar && this.dateStrStartChars.includes(laycanStartFirstChar) || laycenEndFirstChar && this.dateStrStartChars.includes(laycenEndFirstChar);
    }),
    /**
     * Is user using relative date range for date filters
     */
    useRelativeDate: Ember.computed('before', 'after', function () {
      const beforeFirstChar = Ember.typeOf(this.before) === 'string' ? this.before.substring(0, 1) : false;
      const afterFirstChar = Ember.typeOf(this.after) === 'string' ? this.after.substring(0, 1) : false;
      return beforeFirstChar && this.dateStrStartChars.includes(beforeFirstChar) || afterFirstChar && this.dateStrStartChars.includes(afterFirstChar);
    }),
    /**
     * Starting characters of a php date string
     */
    dateStrStartChars: ['+', '-', 't', 'l', 'n', 'f', 's'],
    /**
     * Has a filter on
     */
    hasFilter: Ember.computed('filters.{createdBy,discharge,load,vessel,boundary,discharge_boundary,quantity[min],quantity[max],grade,charterer,rate,laycanRangeStart,laycanRangeEnd,before,after,internal,confidentialityLevel,vesselFleet,vesselDeadWeight[min],vesselDeadWeight[max],vesselYearBuilt[min],vesselYearBuilt[max],vesselCubicCapacity[min],vesselCubicCapacity[max],vesselLengthOverAll[min],vesselLengthOverAll[max]}', function () {
      for (let filter in this.filters) {
        if (Ember.get(this, `filters.${filter}`) && Ember.get(this, `filters.${filter}`) !== 0) {
          return true;
        }
      }
      return false;
    }),
    /**
     * Manually insert fixtures into the table
     */
    insertFixtures: function (fixtures) {
      const handsontable = this.handsontable;
      const sourceData = handsontable.getSourceData();
      fixtures = fixtures.map(f => Ember.get(f, 'asTableData'));
      handsontable.loadData(sourceData.concat(fixtures));
      handsontable.render();
    },
    /**
     * Optional confidentiality levels for fixtures
     */
    confidentialityLevels: Ember.A(['public', 'private', 'super_private']),
    /**
     * Inject the ajax service
     */
    ajax: Ember.inject.service(),
    /**
     * Inject the infinity loader service
     */
    infinity: Ember.inject.service(),
    /**
     * Default records limit for query
     */
    limit: 22,
    /**
     * The search query
     */
    query: null,
    /**
     * Default sorting
     */
    sort: '-laycan_range_start',
    /**
     * Direction of sorting
     */
    sortDirection: Ember.computed('sort', function () {
      return this.sort.startsWith('-') ? 'desc' : 'asc';
    }),
    /**
     * Determines if a geographic filter is set
     */
    hasLoadBoundaryFilter: Ember.computed('boundary', 'source.params.boundary', function () {
      return this.boundary ?? Ember.get(this, 'source.params.boundary');
    }),
    /**
     * Determines if a geographic filter is set
     */
    hasDischargeBoundaryFilter: Ember.computed('discharge_boundary', 'source.params.discharge_boundary', function () {
      return this.discharge_boundary ?? Ember.get(this, 'source.params.discharge_boundary');
    }),
    /**
     * Get the selected fixtures
     */
    selectedFixtures: Ember.computed('fixtures.@each.{isChecked}', function () {
      return this.fixtures.filter(fixture => fixture.isChecked);
    }),
    /**
     * Discharge port filter
     */
    discharge: null,
    /**
     * Load port filter
     */
    load: null,
    /**
     * Vessel filter
     */
    vessel: null,
    /**
     * Load oundary filter
     */
    boundary: null,
    /**
     * Discharge boundary filter
     */
    discharge_boundary: null,
    /**
     * Quantity min filter
     */
    'quantity[min]': 0,
    /**
     * Quantity max filter
     */
    'quantity[max]': 0,
    /**
     * Vessel LOA min filter
     */
    'vesselLengthOverAll[min]': 0,
    /**
     * Vessel LOA max filter
     */
    'vesselLengthOverAll[max]': 0,
    /**
     * Vessel DWT min filter
     */
    'vesselDeadWeight[min]': 0,
    /**
     * Vessel DWT max filter
     */
    'vesselDeadWeight[max]': 0,
    /**
     * Vessel year buillt min filter
     */
    'vesselYearBuilt[min]': 0,
    /**
     * Vessel year built max filter
     */
    'vesselYearBuilt[max]': 0,
    /**
     * Vessel cubic capacity min filter
     */
    'vesselCubicCapacity[min]': 0,
    /**
     * Vessel cubic capacity max filter
     */
    'vesselCubicCapacity[max]': 0,
    /**
     * Grade filter
     */
    grade: null,
    /**
     * Charterer filter
     */
    charterer: null,
    /**
     * Rate filter
     */
    rate: null,
    /**
     * Laycan start date filter
     */
    laycanRangeStart: null,
    /**
     * Laycan end date filter
     */
    laycanRangeEnd: null,
    /**
     * Created before this date
     */
    before: null,
    /**
     * Created after this date
     */
    after: null,
    /**
     * Who created these fixtures
     */
    createdBy: null,
    /**
     * The instance of the table
     */
    handsontable: null,
    /**
     * The instance of the fixtures-table component
     */
    fixturesTable: null,
    /**
     * The instance of the geo filter map
     */
    geoFilterMap: null
  });
  _exports.default = _default;
});