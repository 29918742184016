define("@fixflo/frontend/serializers/user", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _rest.default.extend(_rest.EmbeddedRecordsMixin, {
    primaryKey: 'uuid',
    attrs: {
      permissions: {
        embedded: 'always'
      },
      confidentiality_settings: {
        embedded: 'always'
      }
    },
    serialize: function (snapshot, options) {
      var json = this._super(snapshot, options);

      // if company model set for company, set the id
      if (snapshot.attr('company_uuid') instanceof Object) {
        json.company_uuid = snapshot.attr('company_uuid').get('id');
      }

      // if role model set for role, set the id
      if (snapshot.attr('role_uuid') instanceof Object) {
        json.role_uuid = snapshot.attr('role_uuid').get('id');
      }
      return json;
    }
  });
  _exports.default = _default;
});