define("@fixflo/frontend/templates/components/note-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nmM9dXAX",
    "block": "{\"symbols\":[\"note\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"companyNotes\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"c-note\"],[8],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[1,[28,\"html-safe\",[[23,1,[\"content\"]]],null],false],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"text-xs\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[8],[0,\"Posted by \"],[1,[23,1,[\"author_name\"]],false],[0,\" \"],[1,[23,1,[\"createdAgo\"]],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"js-chat-form block-content p-2 bg-body-dark\"],[8],[0,\"\\n\\t\"],[7,\"form\",false],[3,\"action\",[[23,0,[]],\"postNote\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n    \\t\"],[5,\"input\",[[12,\"class\",\"js-chat-input form-control form-control-alt\"],[12,\"placeholder\",\"Type a note...\"]],[[\"@type\",\"@value\"],[\"text\",[22,\"newNote\"]]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[14,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/components/note-box.hbs"
    }
  });
  _exports.default = _default;
});