define("@fixflo/frontend/controllers/dashboard/settings/zones", ["exports", "@roncodes/context-menu", "country-data", "ember-cli-pagination/computed/paged-array", "@fixflo/frontend/utils/get-boundary-center", "@fixflo/frontend/utils/geojson-to-coords", "@mapbox/geojson-coords"], function (_exports, _contextMenu, _countryData, _pagedArray, _getBoundaryCenter, _geojsonToCoords, _geojsonCoords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      /**
       * Forwards and handles actions from table/action components
       */
      tableAction: function (action, user) {
        this.send(action, user);
      },
      /**
       * Sorts the current data listed
       */
      tableSort: function (sorts) {
        // TODO
      },
      /**
       * Delete layers selected to be deleted
       *
       * @void
       */
      deleteBoundaries: function ({
        layers
      }) {
        layers.getLayers().forEach(layer => {
          // get the boundary from id reference
          const boundary = this.store.peekRecord('boundary', Ember.get(layer, 'boundary_id'));
          if (boundary) {
            boundary.destroyRecord();
          }
        });
      },
      /**
       * Delete layers selected to be deleted
       *
       * @void
       */
      saveBoundaryChanges: function ({
        target
      }) {
        const layers = Object.values(Ember.get(target, '_layers'));
        layers.forEach(layer => {
          const boundary_id = Ember.get(layer, 'boundary_id');
          if (!boundary_id) {
            return;
          }
          const boundary = (0, _geojsonToCoords.default)(layer, true, true);
          this.fetch.put(`boundaries/${boundary_id}`, {
            data: {
              boundary
            }
          });
        });
      },
      /**
       * Set the active drawing control to the controllers
       *
       * @void
       */
      setFeatureGroup: function (activeFeatureGroup, map) {
        Ember.setProperties(this, {
          activeFeatureGroup
        });
      },
      /**
       * Add a new boundary to be saved to the zone being edited
       *
       * @void
       */
      queueZoneBoundary: function (event, layer) {
        const boundary = (0, _geojsonToCoords.default)(layer, true, true);
        if (Ember.isEmpty(boundary)) {
          return;
        }
        const {
          latitude,
          longitude
        } = (0, _getBoundaryCenter.default)(boundary, true);
        const zoneBoundary = this.store.createRecord('boundary', {
          boundary,
          latitude,
          longitude
        });
        this.pendingBoundaries.push(zoneBoundary);
      },
      /**
       * Add an existing layer to editableLayers
       *
       * @void
       */
      makeLayerEditable: function (boundary, {
        target
      }) {
        const activeFeatureGroup = this.activeFeatureGroup;
        const editableLayers = this.editableLayers;
        if (editableLayers.includes(target)) {
          return;
        }
        // set metadata for boundary id
        Ember.set(target, 'boundary_id', Ember.get(boundary, 'id'));
        // push to editable layers & feature group
        editableLayers.pushObject(target);
        activeFeatureGroup.addLayer(target);
      },
      /**
       * Creates a zone
       */
      create: function () {
        // create zone to be created
        const zone = this.store.createRecord('zone', {
          type: 'zone',
          aliases: []
        });
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          this.set('presenter', presenter);
        });
        // render dialog
        this.dialog.open('zone-form', zone, {
          title: 'Create a new zone',
          acceptText: 'Save',
          controller: this,
          countries: _countryData.countries.all.map(c => c.name),
          addAlias: tag => zone.get('aliases').pushObject(tag),
          removeAlias: i => zone.get('aliases').removeAt(i),
          accept: () => {
            const firstBoundary = Ember.get(this, 'pendingBoundaries.firstObject');
            if (firstBoundary && !Ember.get(zone, 'longitude')) {
              // set the zone latitude and longitude from boundary
              Ember.setProperties(zone, {
                longitude: Ember.get(firstBoundary, 'longitude'),
                latitude: Ember.get(firstBoundary, 'latitude')
              });
            }
            // start loading indicator
            this.presenter.startLoading();
            // save the zone
            return zone.save().then(zone => {
              // set zone to pending boundaries
              const boundaries = this.pendingBoundaries.map(b => {
                b.set('zone_uuid', zone.get('id'));
                return b;
              });
              // save boundaries
              return Ember.RSVP.all(boundaries.invoke('save')).then(boundaries => {
                // clear pending zones
                this.resetZoneEditor();
                // set boundaries to zones
                zone.get('boundaries').pushObjects(boundaries);
                // save boundaries
                this.notifications.success('New zone added');
                this.presenter.stopLoading();
                this.presenter._accept();
              }).catch(error => {
                this.presenter.stopLoading();
                this.notifications.serverError(error);
              });
            });
          },
          decline: () => {
            zone.destroyRecord();
            this.resetZoneEditor();
            this.presenter._decline();
          }
        });
      },
      /**
       * Edit a zone
       */
      edit: function (zone) {
        // set dialog presenter to controller
        this.dialog.one('created', presenter => {
          this.set('presenter', presenter);
        });
        // render dialog
        this.dialog.open('zone-form', zone, {
          title: `Edit ${zone.get('name')} zone`,
          acceptText: 'Save Changes',
          controller: this,
          editableLayers: this.editableLayers,
          // countries: countries.map(c => c.name),
          addAlias: tag => zone.get('aliases').pushObject(tag),
          removeAlias: i => zone.get('aliases').removeAt(i),
          accept: () => {
            const editableLayers = this.editableLayers;
            const firstBoundary = Ember.get(this, 'pendingBoundaries.firstObject');
            if (firstBoundary && !Ember.get(zone, 'longitude')) {
              // set the zone latitude and longitude from boundary
              Ember.setProperties(zone, {
                longitude: Ember.get(firstBoundary, 'longitude'),
                latitude: Ember.get(firstBoundary, 'latitude')
              });
            }
            // start loading indicator
            this.presenter.startLoading();
            // save the zone
            return zone.save().then(zone => {
              // set zone to pending boundaries
              const boundaries = this.pendingBoundaries.map(b => {
                b.set('zone_uuid', zone.get('id'));
                return b;
              });
              // save boundaries
              return Ember.RSVP.all(boundaries.invoke('save')).then(boundaries => {
                // set boundaries to zones
                zone.get('boundaries').pushObjects(boundaries);
                // clear pending zones
                this.resetZoneEditor();
                // refresh content
                this.notifications.success('Zone saved.');
                this.presenter.stopLoading();
                this.presenter._accept();
              }).catch(error => {
                this.presenter.stopLoading();
                this.notifications.serverError(error);
              });
            });
          },
          decline: () => {
            this.resetZoneEditor();
            this.presenter._decline();
          }
        });
      },
      /**
       * Deletes a zone
       */
      delete: function (zone) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Delete Zone',
          acceptText: 'Confirm and delete',
          message: `Are you sure you want to delete this zone (${zone.get('name')})?`,
          accept: () => {
            this.dialog.startLoading();
            zone.destroyRecord().then(zone => {
              this.dialog.stopLoading();
              this.notifications.info(`Zone ${zone.get('name')} was deleted.`);
              this.dialog._close();
            }).catch(error => {
              this.dialog.stopLoading();
              this.notifications.serverError(error);
            });
          }
        });
      }
    },
    /**
     * Reset controller properties when dialog closes
     *
     * @void
     */
    resetZoneEditor: function () {
      Ember.setProperties(this, {
        pendingBoundaries: [],
        editableLayers: [],
        activeFeatureGroup: null
      });
    },
    /**
     * Columns to display in table
     * @type array
     */
    columns: [{
      name: `Name`,
      valuePath: `name`
    }, {
      name: `Display Name`,
      valuePath: `display_name`
    }, {
      name: `Aliases`,
      valuePath: `aliasesList`
    }, {
      name: `Created`,
      valuePath: `createdAt`
    }, {
      name: `Modified`,
      valuePath: `updatedAt`
    }, {
      name: ``,
      menuItems: [{
        action: 'edit',
        icon: 'icon-content_edit',
        can: 'edit zone',
        label: 'Edit Zone'
      }, {
        action: 'delete',
        icon: 'icon-navigation_delete',
        can: 'delete zone',
        label: 'Delete Zone'
      }]
    }],
    /**
     * Query parameters
     */
    queryParams: ['page', 'limit', 'sort', 'query'],
    /**
     * Current page
     */
    page: 1,
    /**
     * Default pagination limit
     */
    limit: 10,
    /**
     * Default sorting
     */
    sort: 'created_at',
    /**
     * Array of bounndaries pending creation
     */
    pendingBoundaries: [],
    /**
     * Array of editable layers
     */
    editableLayers: [],
    /**
     * The current active feature group
     */
    activeFeatureGroup: null
  });
  _exports.default = _default;
});