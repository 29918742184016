define("@fixflo/frontend/templates/components/report-downloader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ROFdRAVJ",
    "block": "{\"symbols\":[\"@buttonClass\",\"@buttonIcon\"],\"statements\":[[4,\"if\",[[23,0,[\"isIdle\"]]],null,{\"statements\":[[0,\"    \"],[5,\"fix-flo/button\",[[12,\"class\",[23,1,[]]],[12,\"type\",\"button\"]],[[\"@size\",\"@scheme\",\"@icon\",\"@title\",\"@onClick\"],[[23,0,[\"buttonSize\"]],[23,0,[\"buttonScheme\"]],[23,2,[]],[23,0,[\"buttonTitle\"]],[28,\"fn\",[[23,0,[\"startDownload\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"flex items-center\"],[8],[0,\"\\n        \"],[5,\"fix-flo/spinner\",[[12,\"class\",\"blue-ring\"]],[[],[]]],[0,\"\\n        \"],[1,[23,0,[\"downloadProgressText\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/components/report-downloader.hbs"
    }
  });
  _exports.default = _default;
});