define("@fixflo/frontend/templates/dialog/share-board-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0NB/sX2j",
    "block": "{\"symbols\":[\"people\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"col-form-label required\"],[8],[0,\"\\n        People\\n    \"],[9],[0,\"\\n    \"],[5,\"power-select-multiple\",[],[[\"@searchEnabled\",\"@search\",\"@selected\",\"@placeholder\",\"@onChange\"],[true,[22,\"query\"],[22,\"selected\"],\"Search for users or groups\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"selected\"]]],null]],null]]],{\"statements\":[[0,\"\\n        @\"],[1,[23,1,[\"name\"]],false],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"col-form-label required\"],[8],[0,\"\\n        Allow shared board to be edited\\n    \"],[9],[0,\"\\n    \"],[5,\"x-toggle\",[],[[\"@theme\",\"@size\",\"@value\",\"@onToggle\"],[\"light\",\"small\",[22,\"canEdit\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"canEdit\"]]],null]],null]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/share-board-form.hbs"
    }
  });
  _exports.default = _default;
});