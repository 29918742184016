define("@fixflo/frontend/serializers/widget", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _rest.default.extend(_rest.EmbeddedRecordsMixin, {
    primaryKey: 'uuid',
    attrs: {
      source: {
        embedded: 'always'
      }
    },
    serialize(snapshot, options) {
      const json = this._super(...arguments);

      // if source_uuid is a board model we know to set id
      if (snapshot.attr('source_uuid') instanceof Object) {
        json.source_uuid = snapshot.attr('source_uuid').get('id');
      }
      return json;
    }
  });
  _exports.default = _default;
});