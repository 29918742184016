define("@fixflo/frontend/templates/dialog/save-filter-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "GyPSxkj4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"Name\"],[9],[0,\"\\n    \"],[5,\"fix-flo/input\",[],[[\"@type\",\"@value\"],[\"text\",[24,[\"contextObject\",\"name\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"can\",[\"share filter\"],null]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"fixflo-field\"],[8],[0,\"\\n\\t    \"],[7,\"label\",true],[10,\"class\",\"fixflo-label\"],[8],[0,\"Share with company\"],[9],[0,\"\\n\\t    \"],[5,\"x-toggle\",[],[[\"@theme\",\"@size\",\"@value\",\"@onToggle\"],[\"light\",\"small\",[24,[\"contextObject\",\"shared_with_company\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"contextObject\",\"shared_with_company\"]]],null]],null]]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dialog/save-filter-form.hbs"
    }
  });
  _exports.default = _default;
});