define("@fixflo/frontend/routes/dashboard/settings/diff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function () {
      return this.fetch.request('fixtures', {
        data: {
          is_duplicate: 1,
          with: ['duplicateFixture']
        }
      }).then(r => r.fixtures);
    }
  });
  _exports.default = _default;
});