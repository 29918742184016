define("@fixflo/frontend/serializers/generated-report", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _rest.default.extend(_rest.EmbeddedRecordsMixin, {
    primaryKey: 'uuid',
    serialize: function (snapshot, options) {
      var json = this._super(snapshot, options);

      // if user model set for member, set the id
      if (snapshot.attr('report_template_uuid') instanceof Object) {
        json.report_template_uuid = snapshot.attr('report_template_uuid').get('id');
      }
      return json;
    }
  });
  _exports.default = _default;
});