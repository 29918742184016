define("@fixflo/frontend/components/quill-editor", ["exports", "quill"], function (_exports, _quill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Class styles
     */
    classNames: ['quill-editor'],
    /**
     * This editor instance
     */
    editor: null,
    /**
     * Placeholer for the editor
     */
    placeholder: 'Start typing...',
    /**
     * The editor value
     */
    value: '',
    /**
     * Toolbar options
     */
    toolbar: [['bold', 'italic', 'underline', 'strike'], ['blockquote', 'code-block'], [{
      'header': 1
    }, {
      'header': 2
    }], [{
      'list': 'ordered'
    }, {
      'list': 'bullet'
    }], [{
      'script': 'sub'
    }, {
      'script': 'super'
    }], [{
      'indent': '-1'
    }, {
      'indent': '+1'
    }], [{
      'direction': 'rtl'
    }], [{
      'header': [1, 2, 3, 4, 5, 6, false]
    }], [{
      'color': []
    }, {
      'background': []
    }], [{
      'font': []
    }], [{
      'align': []
    }], ['clean']],
    /**
     * Initialize editor
     */
    didInsertElement: function () {
      const editor = new _quill.default(this.element.querySelector('#editor'), {
        // debug: 'info',
        modules: {
          toolbar: this.toolbar
        },
        placeholder: this.placeholder,
        theme: 'snow'
      });
      Ember.setProperties({
        editor
      });
      editor.on('text-change', () => {
        Ember.set(this, 'value', editor.root.innerHTML);
      });
      if (!Ember.isEmpty(this.value)) {
        Ember.set(editor, 'root.innerHTML', this.value);
      }
    }
  });
  _exports.default = _default;
});