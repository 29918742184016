define("@fixflo/frontend/controllers/dashboard/reports/templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      /**
       * Forwards and handles actions from table/action components
       */
      tableAction: function (action, model) {
        this.send(action, model);
      },
      /**
       * Sorts the current data listed
       */
      tableSort: function (sorts) {
        // TODO
      },
      /**
       * Delete a template
       */
      delete: function (template) {
        this.dialog._confirm({
          isDeleteDialog: true,
          title: 'Delete template',
          acceptText: 'Confirm and delete',
          message: Ember.String.htmlSafe(`Are you sure you want to delete this template <i>${Ember.get(template, 'name')}</i>?`),
          accept: () => {
            this.dialog.startLoading();
            template.destroyRecord().then(template => {
              return this.refreshRoute().then(() => {
                this.dialog.stopLoading();
                this.notifications.info(`Template ${template.get('name')} was deleted.`);
                this.dialog._close();
              });
            }).catch(error => {
              this.dialog.stopLoading();
              this.notifications.serverError(error);
            });
          }
        });
      }
    },
    /**
     * Query parameters
     */
    queryParams: ['page', 'limit', 'sort', 'query'],
    /**
     * Current page
     */
    page: 1,
    /**
     * Default pagination limit
     */
    limit: 10,
    /**
     * Default sorting
     */
    sort: 'created_at',
    /**
     * Columns to display in table
     * @type array
     */
    columns: [{
      name: `Name`,
      valuePath: `name`,
      linkTo: {
        route: 'dashboard.reports.edit-template'
      }
    }, {
      name: `Created`,
      valuePath: `createdAt`
    }, {
      name: `Modified`,
      valuePath: `updatedAt`
    }, {
      name: ``,
      menuItems: true
    }]
  });
  _exports.default = _default;
});