define("@fixflo/frontend/controllers/dashboard/reports/index", ["exports", "@fixflo/frontend/mixins/manages-reports", "@fixflo/frontend/mixins/manages-report-types", "@fixflo/frontend/mixins/manages-report-templates", "ember-get-config"], function (_exports, _managesReports, _managesReportTypes, _managesReportTemplates, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var _default = Ember.Controller.extend(_managesReports.default, _managesReportTypes.default, _managesReportTemplates.default, {
    actions: {
      /**
       * Forwards and handles actions from table/action components
       */
      tableAction: function (action, model) {
        this.send(action, model);
      },
      /**
       * Sorts the current data listed
       */
      tableSort: function (sorts) {
        // TODO
      },
      /**
       * Reload the preview
       *
       * @void
       */
      reloadPreview: function () {
        this.loadPreview();
      },
      /**
       * Set report download format
       *
       * @void
       */
      setReportDownloadFormat: function (model, format, dd) {
        Ember.tryInvoke(dd.actions, 'close');
        model.format = format;
      },
      /**
       * Download a report
       *
       * @void
       */
      download: function (format) {
        let dateString = '';
        if (this.useSpecificDateRange) {
          dateString = `&before=${Ember.get(this, 'report.before')}&after=${Ember.get(this, 'report.after')}`;
        }
        return window.open(`${_emberGetConfig.default.api.host}/${_emberGetConfig.default.api.namespace}/reports/download?format=${format}&report=${Ember.get(this, 'report.report_type_uuid')}${dateString}&user=${this.currentUser.id}&sort=${Ember.get(this, 'report.sort')}`);
      },
      /**
       * Toggle the sort asc/desc
       *
       * @void
       */
      toggleSortAscDesc: function (sortDirection) {
        const sort = Ember.get(this, 'report.sort');
        if (sortDirection === 'asc') {
          Ember.setProperties(this, {
            'report.sort': sort.substr(1),
            sortDirection
          });
        } else {
          Ember.setProperties(this, {
            'report.sort': `-${sort}`,
            sortDirection
          });
        }
      },
      toggleSelectAll: function () {},
      setTemplateFilter: function (templateModel) {
        this.set('template', templateModel ? templateModel.get('id') : null);
      },
      setCreatorFilter: function (userModel) {
        this.set('creator', userModel ? userModel.get('id') : null);
      },
      setReportType: function (reportTypeModel) {
        this.set('report.report_type_uuid', reportTypeModel ? reportTypeModel.get('id') : null);
        this.set('selectedReportType', reportTypeModel);
      }
    },
    /**
     * Downloadable formats
     */
    formats: Ember.A(['txt', 'docx', 'pdf', 'xlsx', 'xls', 'csv', 'ods', 'html']),
    /**
     * Current page
     */
    page: 1,
    /**
     * Default pagination limit
     */
    limit: 10,
    /**
     * Default sorting
     */
    sort: 'created_at',
    /**
     * Default template to filter by
     */
    template: null,
    /**
     * The report being created
     */
    report: Ember.computed(function () {
      return this.store.createRecord('report', {
        report_type_uuid: null,
        before: null,
        after: null,
        quantity: {
          min: 0,
          max: 0
        },
        sort: '-laycan_range_start'
      });
    }),
    /**
     * Columns to display in table
     * @type array
     */
    columns: Ember.A([{
      name: `Name`,
      valuePath: `name`,
      action: 'editReportType'
    }, {
      name: `Created By`,
      valuePath: `created_by`
    }, {
      name: `Description`,
      valuePath: `description`
    }, {
      name: `Format`,
      valuePath: `format`
    }, {
      name: `Export`,
      valuePath: null,
      action: 'downloadReport'
    }, {
      name: `Created`,
      valuePath: `createdAt`
    }, {
      name: `Modified`,
      valuePath: `updatedAt`
    }, {
      name: ``,
      menuItems: [{
        action: 'downloadReport',
        icon: 'icon-content_download',
        can: false,
        label: 'Export Report'
      }, {
        action: 'editReportType',
        icon: 'icon-content_edit',
        can: false,
        label: 'Edit Report'
      }, {
        action: 'deleteReportType',
        icon: 'icon-navigation_delete',
        can: false,
        label: 'Delete Report'
      }]
    }]),
    currentUser: Ember.inject.service(),
    /**
     * Direction of sorting
     */
    sortDirection: 'desc',
    /**
     * Determines if user needs to supply a specific date range
     */
    useSpecificDateRange: false,
    /**
     * Determines if preview is being loaded
     */
    isPreviewLoading: false,
    /**
     * The preview html
     */
    previewHtml: null,
    /**
     * Load the preview
     */
    // eslint-disable-next-line no-dupe-keys
    loadPreview: function () {
      Ember.setProperties(this, {
        loadingPreview: true,
        previewHtml: null
      });
      let date = {};
      if (this.useSpecificDateRange) {
        date = {
          before: Ember.get(this, 'report.before'),
          after: Ember.get(this, 'report.after')
        };
      }
      this.fetch.request('reports/preview', {
        data: _objectSpread({
          report: Ember.get(this, 'report.report_type_uuid'),
          sort: Ember.get(this, 'report.sort'),
          quantity: Ember.get(this, 'report.quantity'),
          sanctum_token: 1
        }, date)
      }).then(response => {
        Ember.setProperties(this, {
          loadingPreview: false,
          previewHtml: response.html
        });
      });
    },
    /**
     * Load preview when report type changes
     */
    // eslint-disable-next-line ember/no-observers
    autoReloadPreview: Ember.observer('report.report_type_uuid', 'report.sort', 'report.quantity.min', 'report.quantity.max', function () {
      if (!Ember.get(this, 'report.report_type_uuid')) {
        return;
      }
      this.loadPreview();
    })
  });
  _exports.default = _default;
});