define("@fixflo/frontend/models/session", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    uuid: (0, _model.attr)('string'),
    is_authenticated: (0, _model.attr)('boolean'),
    ip_address: (0, _model.attr)('string'),
    origin_app: (0, _model.attr)('string'),
    isp: (0, _model.attr)('string'),
    timezone: (0, _model.attr)('string'),
    currency: (0, _model.attr)('string'),
    country: (0, _model.attr)('string'),
    region: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    postal_code: (0, _model.attr)('string'),
    longitude: (0, _model.attr)('string'),
    latitude: (0, _model.attr)('string'),
    deleted_at: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('string'),
    updated_at: (0, _model.attr)('string'),
    isAuthenticated: Ember.computed('is_authenticated', function () {
      return this.is_authenticated;
    }),
    updatedAgo: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).fromNow();
    }),
    updatedAt: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).format('YYYY-MM-DD HH:mm A');
    }),
    createdAgo: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).fromNow();
    }),
    createdAt: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).format('YYYY-MM-DD HH:mm A');
    })
  });
  _exports.default = _default;
});