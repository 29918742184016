define("@fixflo/frontend/utils/create-report-filters-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createReportFiltersObject;
  const isNullVesselMetric = (key, value) => {
    return key.includes('vessel') && value === 0;
  };
  function createReportFiltersObject(obj) {
    const filters = {};
    for (var p in obj) {
      if (obj.hasOwnProperty(p) && obj[p] !== null && obj[p] !== undefined && !isNullVesselMetric(p, obj[p])) {
        if (p.includes('[')) {
          const bracketValue = p.match(/\[([a-z]+)\]/i).lastObject;
          const baseProperty = p.replace(`[${bracketValue}]`, '');
          if (!Ember.get(filters, baseProperty)) {
            Ember.set(filters, baseProperty, {});
          }
          Ember.set(filters, `${baseProperty}.${bracketValue}`, obj[p]);
          continue;
        }
        Ember.set(filters, p, obj[p]);
      }
    }
    return filters;
  }
});