define("@fixflo/frontend/mixins/can-insert-fixtures", ["exports", "@fleetbase/fixture-extractor", "@fixflo/frontend/mixins/can-make-fixture-suggestions", "ember-concurrency", "accounting/format-number", "moment"], function (_exports, _fixtureExtractor, _canMakeFixtureSuggestions, _emberConcurrency, _formatNumber, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var _default = Ember.Mixin.create(_canMakeFixtureSuggestions.default, {
    actions: {
      /**
       * Prompt user to upload and proccess excel
       * @void
       */
      importExcel: function (dd) {
        Ember.tryInvoke(dd.actions, 'close');
        // set dialog to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
          // when dialog appears
          setTimeout(() => {
            // get progress div element
            const progressElement = document.getElementById('import-progress');
            const progressBar = progressElement.querySelector('.progress > .progress-bar');
            const progressMessage = progressElement.querySelector('.progress-message');
            // setup socket to listen to
            const userChannel = this.socket.subscribe(this.currentUser.id);
            userChannel.watch(event => {
              if (Ember.get(event, 'evt') === 'import.processing') {
                const percent = Math.round(Ember.get(event, 'progress'));
                const current = Ember.get(event, 'current');
                const count = Ember.get(event, 'count');
                progressBar.style.width = `${percent}%`;
                progressBar.setAttribute('aria-valuenow', percent);
                progressBar.querySelector('span').innerHTML = `${percent}%`;
                if (current > 10) {
                  progressMessage.innerHTML = `Imported ${(0, _formatNumber.default)(current)} of ${(0, _formatNumber.default)(count)} fixtures...`;
                }
                if (percent > 95) {
                  progressMessage.innerHTML = 'Wrapping up import...';
                }
              } else if (Ember.get(event, 'evt') === 'import.started') {
                progressElement.classList.remove('d-none');
                progressMessage.innerHTML = `Importing ${(0, _formatNumber.default)(Ember.get(event, 'count'))} fixtures...`;
              }
            });
          }, 500);
        });
        // array of files uploaded
        const uploads = [];
        // prompt user
        this.dialog.open('upload-fixtures', null, {
          title: 'Import fixtures from Excel',
          acceptText: 'Process Upload',
          acceptIcon: 'fa fa-magic',
          addFile: file => {
            uploads.pushObject(file);
            this.dialog.stopLoading();
            this.dialog.setAcceptButtonText('Process Upload');
          },
          uploadStarted: () => {
            this.dialog.setAcceptButtonText('Uploading...');
            this.dialog.startLoading();
          },
          removeFile: file => {
            uploads.removeObject(file);
          },
          uploads,
          accept: () => {
            if (!uploads.length) {
              return this.notifications.warning('No files in the upload queue');
            }
            this.dialog.setAcceptButtonText('Processing...');
            // set loading state
            this.presenter.startLoading();
            // get array of file ids to import from
            const files = uploads.map(file => Ember.get(file, 'id'));
            // send files for processing import
            const importFilesInstance = this.importFiles.perform(files);
            // set instance to controller
            Ember.setProperties(this, {
              importFilesInstance
            });
            // done
          },

          decline: () => {
            const importFilesInstance = this.importFilesInstance;
            if (importFilesInstance) {
              importFilesInstance.cancel();
            }
            return this.presenter._decline();
          }
        });
      },
      /**
       * Manually process raw text
       */
      processRawText: function () {
        const input = document.querySelector('#fixtureInput[contenteditable]');
        // const handsontable = get(this, 'handsontable');
        const extractor = new _fixtureExtractor.default(input);
        // const data = handsontable.getSourceData();
        // new fixture set record
        const fixtureSet = {
          name: `${this.currentUser.name} imported on ${(0, _moment.default)().format('YYYY-MM-DD HH:mm:ss')}`,
          status: 'published'
        };
        if (this.get('model.fixtureSet')) {
          Ember.setProperties(fixtureSet, _objectSpread({}, this.get('model.fixtureSet').serialize()));
        }
        // flag extractor as running
        Ember.set(this, 'isExtracting', true);
        // start extraction
        // give 100ms to flag state
        setTimeout(() => {
          extractor.parse().then(output => {
            const fixtures = output.map(fixture => {
              delete fixture['meta'];
              delete fixture['has_load_options'];
              delete fixture['has_discharge_options'];
              delete fixture['load_options'];
              delete fixture['discharge_options'];
              return fixture;
            });
            // set loading state
            Ember.set(this, 'isLoading', true);
            // send import request
            return this.fetch.post('fixtures/import', {
              data: {
                fixtures,
                fixtureSet,
                withFixtures: true
              }
            }).then(importResults => {
              // handle import results
              const hasSuggestions = importResults.manual.has_suggestions && importResults.manual.has_suggestions === true || importResults.manual.has_duplicates && importResults.manual.has_duplicates === true;
              // handle
              if (hasSuggestions) {
                return this.makeSuggestions(importResults);
              }
              return this.finallySave(importResults);
            }).catch(error => {
              this.notifications.serverError(error);
              Ember.setProperties(this, {
                isExtracting: false,
                isLoading: false
              });
            });
          });
        }, 100);
      },
      /**
       * Allow bulk insert
       *
       * @void
       */
      bulkInsert: function (dd) {
        Ember.tryInvoke(dd.actions, 'close');
        this.set('isBulkInserting', true);
      }
    },
    /**
     * Inject the socket service
     */
    socket: Ember.inject.service(),
    /**
     * Inject the socket service
     *
     * @var {Service}
     */
    currentUser: Ember.inject.service(),
    /**
     * Determines whether the fixtures are being bulk inserted
     * @type {Boolean}
     */
    isBulkInserting: false,
    /**
     * Save the fixture set, and allow option for user to publish
     */
    finallySave: function (importResults) {
      // get table data
      const data = this.handsontable.getSourceData();
      // if import has fixtures insert
      if (importResults.fixtures) {
        const fixtures = Ember.get(importResults, 'fixtures').map(f => {
          const fixture = this.store.push(this.store.normalize('fixture', f));
          return fixture.asTableData;
        });
        const addedFixtures = importResults.flush_table ? fixtures : data.unshiftObjects(fixtures);
        const addedFixturesCount = importResults.flush_table ? fixtures.length - data.length : fixtures.length;

        // reload w/ new data
        this.handsontable.loadData(addedFixtures);
        // set flags to completed
        Ember.setProperties(this, {
          isExtracting: false,
          isLoading: false
        });
        // notify done
        this.notifications.success('(' + addedFixturesCount + ') Fixtures inserted.');
      }
    },
    /**
     * Create a task to process file uploads
     */
    importFiles: (0, _emberConcurrency.task)(function* (files) {
      const fixtureSet = {
        status: 'published'
      };
      if (this.get('model.fixtureSet')) {
        Ember.setProperties(fixtureSet, _objectSpread({}, this.get('model.fixtureSet').serialize()));
      }
      const importResults = yield this.fetch.post('fixtures/import-from-excel', {
        data: {
          files,
          withFixtures: true,
          fixtureSet
        }
      });
      // handle import error
      if (importResults === undefined) {
        this.presenter.stopLoading();
        return this.notifications.warning('Import failed');
      }
      // if import results
      if (Ember.get(importResults, 'status') === 'success') {
        // end loading state
        this.presenter.stopLoading();
        // handle import results
        const hasSuggestions = importResults.manual.has_suggestions && importResults.manual.has_suggestions === true || importResults.manual.has_duplicates && importResults.manual.has_duplicates === true;
        // clost this dialog
        this.presenter._accept();
        // handle
        if (hasSuggestions) {
          return this.makeSuggestions(importResults);
        }
        return this.finallySave(importResults);
      }
    }).drop()
  });
  _exports.default = _default;
});