define("@fixflo/frontend/utils/generate-relational-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = generateRelationalObject;
  function generateRelationalObject(key) {
    switch (key) {
      case 'vessel':
        return {
          name: null,
          ex_name: null,
          imo: null,
          mmsi: null,
          call_sign: null,
          hull: null,
          age: null,
          flag: null,
          type: null,
          record_type: null,
          division: null,
          kind: null,
          category: null,
          class: null,
          company: {
            group: {
              GroupOwner: null,
              GroupOwnerEmail: null,
              GroupOwnerRegion: null,
              GroupOwnerCountry: null,
              GroupOwnerWebsite: null,
              GroupOwnerTelephone: null
            },
            owner: {
              BeneficialOwner: null,
              BeneficialOwnerRegion: null,
              BeneficialOwnerCountry: null
            },
            builder: {
              Builder: null,
              BuilderRegion: null,
              BuilderCountry: null
            }
          },
          measurement: {
            power: {
              TotalMainHP: null,
              TotalMainKW: null
            },
            metrics: {
              metric: {
                name: null,
                value: null
              }
            },
            tonnage: {
              CGT: null,
              DWT: null,
              GRT: null
            },
            capacity: {
              CubicBale: null
            },
            dimension: {
              Depth: null,
              Draft: null,
              LengthBP: null,
              LengthOA: null
            }
          },
          mechanical: {
            mainEngines: {
              engine: {
                Speed: null,
                TotalHP: null,
                TotalKW: null,
                company: {
                  builder: {
                    EngineBuilder: null,
                    EngineBuilderRegion: null,
                    EngineBuilderCountry: null
                  },
                  designer: {
                    EngineDesigner: null,
                    EngineDesignerRegion: null,
                    EngineDesignerCountry: null
                  }
                },
                EngineHP: null,
                EngineKW: null,
                Propulsion: null,
                EngineCount: null,
                EngineModel: null
              }
            }
          },
          history: {
            notes: {
              note: {
                Notes: null,
                NoteType: null
              }
            },
            events: {
              rename: {
                event: {
                  date: {
                    EventDate: null,
                    RenameYear: null,
                    RenameMonth: null,
                    RecordCreated: null,
                    RecordUpdated: null,
                    RenameQuarter: null
                  },
                  NewName: null,
                  OldName: null
                }
              },
              salesFurtherTrading: {
                event: {
                  Flag: null,
                  date: {
                    EventDate: null,
                    EventYear: null,
                    EventMonth: null,
                    EventQuarter: null,
                    RecordCreated: null,
                    RecordUpdated: null
                  },
                  Details: null,
                  company: {
                    buyer: {
                      Buyer: null,
                      BuyerRegion: null,
                      BuyerCountry: null
                    },
                    seller: {
                      Seller: null,
                      SellerRegion: null,
                      SellerCountry: null,
                      SellerTelephone: null
                    }
                  },
                  SaleType: null
                }
              }
            }
          },
          delivery_date: null,
          fleet: null
        };
      case 'load':
      case 'discharge':
        return {};
    }
  }
});