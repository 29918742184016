define("@fixflo/frontend/helpers/is-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isObject = isObject;
  _exports.default = void 0;
  function isObject([obj]) {
    return Ember.typeOf(obj) === 'object';
  }
  var _default = Ember.Helper.helper(isObject);
  _exports.default = _default;
});