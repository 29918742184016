define("@fixflo/frontend/routes/dashboard/boards/company", ["exports", "ember-query-params-reset/mixins/query-params-reset-route", "ember-onbeforeunload/mixins/confirmation", "@fixflo/frontend/mixins/track-reloads"], function (_exports, _queryParamsResetRoute, _confirmation, _trackReloads) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var _default = Ember.Route.extend(_queryParamsResetRoute.default, _trackReloads.default, _confirmation.default, {
    /**
     * Template to use for this route
     *
     * @var string
     */
    templateName: 'dashboard.fixtures.view',
    /**
     * Controller to use for this route
     *
     * @var string
     */
    controllerName: 'dashboard.boards.view',
    /**
     * Actions
     *
     * @var object
     */
    actions: {
      /**
       * On transition save all uncomitted changes if any
       *
       * @void
       */
      willTransition: function (transition) {
        this.stagedChanges.commit();
        this._super(transition);
      }
    },
    /**
     * Determines if page has staged changes that have not been saved
     *
     * @return boolean
     */
    isPageDirty: function () {
      return this.stagedChanges.numberOfChanges;
    },
    /**
     * handle on onload
     */
    onBeforeunload(event) {
      if (this.isPageDirty()) {
        this.stagedChanges.commit();
        return this._super(event);
      }
    },
    /**
     * Inject the staged changes service
     *
     * @var Service
     */
    stagedChanges: Ember.inject.service(),
    /**
     * Inject the current user service
     *
     * @var {Service}
     */
    currentUser: Ember.inject.service(),
    queryParams: {
      query: {
        refreshModel: true,
        replace: true
      },
      boundary: {
        refreshModel: true,
        replace: true
      },
      discharge_boundary: {
        refreshModel: true,
        replace: true
      },
      load: {
        refreshModel: true,
        replace: true
      },
      discharge: {
        refreshModel: true,
        replace: true
      },
      vessel: {
        refreshModel: true,
        replace: true
      },
      'quantity[min]': {
        refreshModel: true,
        replace: true,
        type: 'number'
      },
      'quantity[max]': {
        refreshModel: true,
        replace: true,
        type: 'number'
      },
      grade: {
        refreshModel: true,
        replace: true
      },
      charterer: {
        refreshModel: true,
        replace: true
      },
      rate: {
        refreshModel: true,
        replace: true
      },
      laycanRangeStart: {
        refreshModel: true,
        replace: true
      },
      laycanRangeEnd: {
        refreshModel: true,
        replace: true
      },
      before: {
        refreshModel: true,
        replace: true
      },
      after: {
        refreshModel: true,
        replace: true
      },
      createdBy: {
        refreshModel: true,
        replace: true
      },
      internal: {
        refreshModel: true,
        replace: true
      },
      confidentialityLevel: {
        refreshModel: true,
        replace: true
      },
      vesselFleet: {
        refreshModel: true,
        replace: true
      },
      'vesselDeadWeight[min]': {
        refreshModel: true,
        replace: true
      },
      'vesselDeadWeight[max]': {
        refreshModel: true,
        replace: true
      },
      'vesselYearBuilt[min]': {
        refreshModel: true,
        replace: true
      },
      'vesselYearBuilt[max]': {
        refreshModel: true,
        replace: true
      },
      'vesselCubicCapacity[min]': {
        refreshModel: true,
        replace: true
      },
      'vesselCubicCapacity[max]': {
        refreshModel: true,
        replace: true
      },
      'vesselLengthOverAll[min]': {
        refreshModel: true,
        replace: true
      },
      'vesselLengthOverAll[max]': {
        refreshModel: true,
        replace: true
      },
      page: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    model: function (params) {
      const startingPage = params.page;
      const perPage = params.limit;
      delete params.page;
      delete params.limit;
      // if no sort param
      if (!params.sort) {
        Ember.set(params, 'sort', '-laycan_range_start');
      }
      return Ember.RSVP.hash({
        board: {
          name: 'Company Board',
          slug: 'company-board',
          id: this.currentUser.companyId,
          _internalModel: {
            modelName: 'company-board'
          }
        },
        fixtures: this.infinity.model('fixture', _objectSpread(_objectSpread({}, params), {}, {
          published: 1,
          perPage,
          startingPage,
          perPageParam: 'limit',
          pageParam: 'page',
          totalPagesParam: 'meta.last_page',
          countParam: 'meta.total'
        }))
      });
    },
    /**
     * After loading completed
     */
    onRouteLoaded: function ({
      controller
    }) {
      if (controller.filtersMenu) {
        controller.filtersMenu.close();
      }
    },
    afterModel: async function (model, transition) {
      transition.finally(() => {
        this.controller.set('contextName', Ember.get(model, 'board.name'));
        this.controller.set('source', model.board);
        this.controller.set('collaboratorCanEdit', true);
      });
      // get report types
      const reportTypesStorageKey = `${this.currentUser.id.replace(/\-/g, '_')}_reportTypes`;
      let quickReportTypes = this.currentUser.options.get(reportTypesStorageKey);
      if (!quickReportTypes || quickReportTypes.length === 0) {
        quickReportTypes = await this.store.findAll('report-type');
        // mapping to convert from iterable class to array
        this.currentUser.options.set(reportTypesStorageKey, quickReportTypes.map(rt => rt));
      }
      // set report types
      Ember.set(model, 'quickReportTypes', quickReportTypes);
    }
  });
  _exports.default = _default;
});