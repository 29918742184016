define("@fixflo/frontend/utils/dom-element-within", ["exports", "@fixflo/ui/utils/dom-element-within"], function (_exports, _domElementWithin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _domElementWithin.default;
    }
  });
});