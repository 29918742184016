define("@fixflo/frontend/routes/dashboard/settings/index/edit-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function () {
      return this.modelFor('dashboard.settings.index');
    },
    setupController: async function (controller, model) {
      this._super(controller, model);
      const timezones = await this.fetch.get('actions/get-timezones');
      Ember.setProperties(controller, {
        timezones
      });
    }
  });
  _exports.default = _default;
});