define("@fixflo/frontend/helpers/google-boundary-to-array", ["exports", "@fixflo/frontend/utils/boundary-to-array"], function (_exports, _boundaryToArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.googleBoundaryToArray = googleBoundaryToArray;
  _exports.default = void 0;
  function googleBoundaryToArray([boundary]) {
    return (0, _boundaryToArray.default)(boundary);
  }
  var _default = Ember.Helper.helper(googleBoundaryToArray);
  _exports.default = _default;
});