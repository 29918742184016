define("@fixflo/frontend/services/layout", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    /**
     * Run a dashmix method
     *
     * @void
     */
    do: function (method) {
      const dashmix = window.Dashmix;
      if (dashmix) {
        return dashmix[method].apply(dashmix, Array.prototype.slice.call(arguments, 1));
      } else {
        Ember.run.next(this, function () {
          return this.do(method);
        });
      }
    },
    /**
     * Initialize the UI
     *
     * @void
     */
    initialize: function () {
      if (window.Dashmix && window.Dashmix._lSidebarScroll !== false && (0, _jquery.default)('#page-overlay').length) {
        return false;
      }
      return this.do('_uiInit');
    },
    /**
     * Layout API
     *
     * @void
     */
    layout: function (mode = 'init') {
      return this.do('_uiApiLayout', mode);
    },
    /*
     * Blocks API
     *
     * @void
     */
    block: function (mode, block) {
      return this.do('_uiApiBlocks', mode, block);
    },
    /*
     * Handle Page Loader
     *
     * @void
     */
    loader: function (mode, colorClass) {
      return this.do('_uiHandlePageLoader', mode, colorClass);
    },
    /*
     * Run Helpers
     *
     * @void
     */
    helpers: function (helpers, options = {}) {
      return window.Dashmix.Helpers.run(helpers, options);
    },
    /**
     * Create a loading overlay over the screen
     *
     * @void
     */
    createLoadingOverlay(message) {
      const overlay = document.createElement('div');
      overlay.classList.add('body-overlay');
      overlay.innerHTML = `<div class="loading-message-content text-white text-xl font-semibold text-center">${message}</div>`;
      this.loadingOverlayElement = overlay;
      document.body.appendChild(overlay);
    },
    /**
     * Clears the loading overlay element
     *
     * @void
     */
    removeLoadingOverlay() {
      if (this.loadingOverlayElement) {
        this.loadingOverlayElement.remove();
      }
    }
  });
  _exports.default = _default;
});