define("@fixflo/frontend/routes/dashboard/boards/free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model(params) {
      return this.store.queryRecord('board', {
        slug: params.slug,
        single: true
      }).catch(this.invalidBoard.bind(this));
    },
    async setupController(controller, model) {
      this._super(controller, model);
      const data = await this.store.query('free-board-input', {
        board_uuid: model.id,
        limit: 999999
      });
      controller.data = data;
      controller.board = model;
    },
    /**
     * No user or company found, end session
     */
    invalidBoard: function () {
      return this.transitionTo('dashboard.boards.index');
    }
  });
  _exports.default = _default;
});