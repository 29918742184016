define("@fixflo/frontend/controllers/dashboard/fixtures/new", ["exports", "@fixflo/frontend/mixins/can-make-fixture-suggestions", "@fixflo/frontend/mixins/model-creator", "ember-concurrency", "accounting/format-number", "moment"], function (_exports, _canMakeFixtureSuggestions, _modelCreator, _emberConcurrency, _formatNumber, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend(_modelCreator.default, _canMakeFixtureSuggestions.default, {
    actions: {
      /**
       * Set the handsontable instance
       */
      setInstance: function (handsontable, fixturesTable) {
        // console.log('setInstance()', ...arguments);
        Ember.setProperties(this, {
          handsontable,
          fixturesTable
        });
      },
      /**
       * Allow an impot from raw text
       */
      importRawText: function () {
        this.fixturesTable.send('importRawText');
      },
      /**
       * Process text
       */
      processRawText: function () {
        const input = document.querySelector('#fixtureInput[contenteditable]');
        this.fixturesTable.send('processRawText', input);
      },
      /**
       * Insert a row above
       */
      insertRow: function (direction) {
        this.fixturesTable.send('insertRow', direction);
      },
      /**
       * Insert a row above
       */
      insertRowTop: function () {
        this.fixturesTable.send('insertRowTop');
      },
      /**
       * Undo an action
       */
      undo: function () {
        this.fixturesTable.send('undo');
      },
      /**
       * Redo an action
       */
      redo: function () {
        this.fixturesTable.send('redo');
      },
      /**
       * Exports data in table as csv
       */
      exportAsCsv: function () {
        this.fixturesTable.send('exportAsCsv');
      },
      /**
       * Function to publish fixtures
       *
       * @void
       */
      publish: function (fixtureSet) {
        // set dialog to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
        });
        // prompt user
        this.dialog.open('fixtures-form', fixtureSet, {
          // className: 'absolute',
          dialogId: 'saveFixturesDialog',
          title: 'Save Fixtures',
          acceptText: 'Done',
          acceptIcon: 'fa fa-save',
          statusOptions: ['draft', 'published'],
          accept: () => {
            // set loading state
            this.presenter.startLoading();
            // clean import results
            return fixtureSet.save().then(fixtureSet => {
              // transition
              return this.transitionToRoute('dashboard.fixtures.view', fixtureSet.get('slug')).then(() => {
                // reset the fixture set imported
                this.resetForm();
                // set loading state
                this.presenter.stopLoading();
                this.presenter._accept();
                this.notifications.success('Fixtures saved.');
              });
            }).catch(error => {
              // set loading state
              this.presenter.stopLoading();
              // close dialog
              this.presenter._decline();
              // handle duplicates via dialog
              if (error.code === 'duplicates_found') {
                return this.send('handleDuplicates', error.duplicates, fixtureSet);
              }
              // as normal error
              this.notifications.serverError(error);
            });
          },
          decline: () => {
            fixtureSet.destroyRecord();
            this.resetForm();
            return this.presenter._decline();
          }
        });
      },
      /**
       * Save fixtures extracted to server
       *
       * @void
       */
      save: function (tour = false, callback = null) {
        if (this.isLoading === true) return;
        const fixtures = this.handsontable.getSourceData().map(fixture => {
          delete fixture['meta'];
          delete fixture['has_load_options'];
          delete fixture['has_discharge_options'];
          delete fixture['load_options'];
          delete fixture['discharge_options'];
          return fixture;
        });
        // set loading state
        Ember.set(this, 'isLoading', true);
        // send import request
        return this.fetch.post('fixtures/import', {
          data: {
            fixtures,
            fixtureSet: this.fixtureSet,
            tour
          }
        }).then(importResults => {
          Ember.set(this, 'isLoading', false);
          const hasSuggestions = importResults.manual.has_suggestions && importResults.manual.has_suggestions === true || importResults.manual.has_duplicates && importResults.manual.has_duplicates === true;
          if (hasSuggestions) {
            return this.makeSuggestions(importResults);
          }
          // update fixture set state to saved
          this.fixturesSaved = true;
          // run callback if provided
          if (typeof callback === 'function') {
            callback(importResults);
          }
          // send to saved dialog
          return this.finallySave(importResults);
        }).catch(error => {
          this.notifications.serverError(error);
        });
      },
      /**
       * Prompt user to upload and proccess excel
       *
       * @void
       */
      importExcel: function () {
        // set dialog to controller
        this.dialog.one('created', presenter => {
          Ember.set(this, 'presenter', presenter);
          // when dialog appears
          setTimeout(() => {
            // get progress div element
            const progressElement = document.getElementById('import-progress');
            const progressBar = progressElement.querySelector('.progress > .progress-bar');
            const progressMessage = progressElement.querySelector('.progress-message');
            // setup socket to listen to
            const userChannel = this.socket.subscribe(this.currentUser.id);
            userChannel.watch(event => {
              if (Ember.get(event, 'evt') === 'import.processing') {
                const percent = Math.round(Ember.get(event, 'progress'));
                const current = Ember.get(event, 'current');
                const count = Ember.get(event, 'count');
                progressBar.style.width = `${percent}%`;
                progressBar.setAttribute('aria-valuenow', percent);
                progressBar.querySelector('span').innerHTML = `${percent}%`;
                if (current > 10) {
                  progressMessage.innerHTML = `Imported ${(0, _formatNumber.default)(current)} of ${(0, _formatNumber.default)(count)} fixtures...`;
                }
                if (percent > 95) {
                  progressMessage.innerHTML = 'Wrapping up import...';
                }
              } else if (Ember.get(event, 'evt') === 'import.started') {
                progressElement.classList.remove('d-none');
                progressMessage.innerHTML = `Importing ${(0, _formatNumber.default)(Ember.get(event, 'count'))} fixtures...`;
              }
            });
          }, 500);
        });
        // array of files uploaded
        const uploads = [];
        // prompt user
        this.dialog.open('upload-fixtures', null, {
          title: 'Import fixtures from Excel',
          acceptText: 'Process Upload',
          acceptIcon: 'fa fa-magic',
          addFile: file => {
            uploads.pushObject(file);
            this.dialog.stopLoading();
            this.dialog.setAcceptButtonText('Process Upload');
          },
          uploadStarted: () => {
            this.dialog.setAcceptButtonText('Uploading...');
            this.dialog.startLoading();
          },
          removeFile: file => {
            uploads.removeObject(file);
          },
          uploads,
          accept: () => {
            if (!uploads.length) {
              return this.notifications.warning('No files in the upload queue');
            }
            this.dialog.setAcceptButtonText('Processing...');
            // set loading state
            this.presenter.startLoading();
            // get array of file ids to import from
            const files = uploads.map(file => Ember.get(file, 'id'));
            // send files for processing import
            const importFilesInstance = this.importFiles.perform(files);
            // set instance to controller
            Ember.setProperties(this, {
              importFilesInstance
            });
            // done
          },

          decline: () => {
            const importFilesInstance = this.importFilesInstance;
            if (importFilesInstance) {
              importFilesInstance.cancel();
            }
            return this.presenter._decline();
          }
        });
      }
    },
    /**
     * Create a task to process file uploads
     */
    importFiles: (0, _emberConcurrency.task)(function* (files) {
      let importResults;
      try {
        importResults = yield this.fetch.post('fixtures/import-from-excel', {
          data: {
            files
          }
        });
      } catch (error) {
        let errorMessage = error.payload.message || error.message || 'Import failed';
        if (errorMessage === 'Start row (2) is beyond highest row (1)') {
          errorMessage += '\n\nThis means that your excel contains empty worksheets. Please remove any empty worksheets to continue with import.';
        }
        this.presenter.stopLoading();
        return this.notifications.error(errorMessage);
      }

      // handle import error
      if (!importResults) {
        this.presenter.stopLoading();
        return this.notifications.warning('Import failed');
      }

      // if import results
      if (Ember.get(importResults, 'status') === 'success') {
        // end loading state
        this.presenter.stopLoading();
        // handle import results
        const hasSuggestions = importResults.manual.has_suggestions && importResults.manual.has_suggestions === true || importResults.manual.has_duplicates && importResults.manual.has_duplicates === true;
        // clost this dialog
        this.presenter._accept();
        // handle
        if (hasSuggestions) {
          return this.makeSuggestions(importResults);
        }
        return this.finallySave(importResults);
      }
    }).drop(),
    /**
     * Inject the socket service
     *
     * @var {Service}
     */
    socket: Ember.inject.service(),
    /**
     * Inject the socket service
     *
     * @var {Service}
     */
    currentUser: Ember.inject.service(),
    /**
     * Is a process loading
     */
    isLoading: false,
    /**
     * Is a extraction process pending
     */
    isExtracting: false,
    /**
     * Has the fixtures been sent to server
     */
    fixturesSaved: false,
    /**
     * The instance of the table
     */
    handsontable: null,
    /**
     * The instance of the fixtures table component
     */
    fixturesTable: null,
    /**
     * Status options
     */
    statusOptions: Ember.A(['draft', 'published']),
    /**
     * Reset some of the default properties
     *
     * @void
     */
    resetForm: function () {
      this.set('fixtureSet', {
        name: `${Ember.get(this, 'currentUser.name')} imported on ${(0, _moment.default)().format('YYYY-MM-DD HH:mm:ss')}`,
        status: 'draft'
      });
      this.fixturesSaved = false;
    },
    /**
     * The new fixture set
     */
    fixtureSet: Ember.computed('currentUser.name', function () {
      return {
        name: `${Ember.get(this, 'currentUser.name')} imported on ${(0, _moment.default)().format('YYYY-MM-DD HH:mm:ss')}`,
        status: 'draft'
      };
    }),
    /**
     * Save the fixture set, and allow option for user to publish
     */
    finallySave: function (importResults) {
      // convert fixture set to model
      const fixtureSet = this.store.push(this.store.normalize('fixture-set', importResults.set));
      // publish
      return this.send('publish', fixtureSet);
    }
  });
  _exports.default = _default;
});