define("@fixflo/frontend/models/chat-session", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    uuid: (0, _model.attr)('string'),
    group_uuid: (0, _model.attr)('string'),
    participants: (0, _model.hasMany)('chat-participant'),
    messages: (0, _model.hasMany)('chat-message'),
    group: (0, _model.belongsTo)('group'),
    type: (0, _model.attr)('string'),
    deleted_at: (0, _model.attr)('string'),
    created_at: (0, _model.attr)('string'),
    updated_at: (0, _model.attr)('string'),
    users: Ember.computed('type', 'participants.@each', 'group.members.@each', function () {
      switch (this.type) {
        case 'group':
          return Ember.get(this, 'group.members').map(member => member.get('user'));
        case 'private':
          return this.participants.map(participant => participant.get('user'));
        default:
          return [];
      }
    }),
    updatedAgo: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).fromNow();
    }),
    updatedAt: Ember.computed('updated_at', function () {
      return (0, _moment.default)(this.updated_at).format('YYYY-MM-DD HH:mm A');
    }),
    createdAgo: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).fromNow();
    }),
    createdAt: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.created_at).format('YYYY-MM-DD HH:mm A');
    })
  });
  _exports.default = _default;
});