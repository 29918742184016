define("@fixflo/frontend/templates/dashboard/settings/groups/manage-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "896gcPYC",
    "block": "{\"symbols\":[\"member\"],\"statements\":[[5,\"fix-flo/section-header\",[],[[\"@title\"],[[24,[\"model\",\"name\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"can\",[\"edit group\"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[5,\"fix-flo/button\",[],[[\"@type\",\"@scheme\",\"@icon\",\"@title\",\"@onClick\"],[\"button\",\"light\",\"icon-content_add\",\"Add member\",[28,\"action\",[[23,0,[]],\"addMember\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"fixflo-table\"],[8],[0,\"\\n\\t\"],[7,\"table\",true],[8],[0,\"\\n\\t\\t\"],[7,\"thead\",true],[8],[0,\"\\n\\t\\t\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"th\",true],[10,\"style\",\"width: 90%;\"],[8],[0,\"Name\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"th\",true],[8],[0,\"Actions\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"tbody\",true],[8],[0,\"\\n\\t\\t\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"td\",true],[8],[1,[24,[\"model\",\"owner_name\"]],false],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"td\",true],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"groupMembers\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"td\",true],[8],[1,[23,1,[\"user\",\"name\"]],false],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"td\",true],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[5,\"fix-flo/button\",[],[[\"@scheme\",\"@size\",\"@onClick\"],[\"danger\",\"xs\",[28,\"action\",[[23,0,[]],\"removeMember\",[23,1,[]]],[[\"on\"],[\"click\"]]]]],{\"statements\":[[0,\"Remove\"]],\"parameters\":[]}],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@fixflo/frontend/templates/dashboard/settings/groups/manage-group.hbs"
    }
  });
  _exports.default = _default;
});